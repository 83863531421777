import React, {Component} from 'react'
import images from "../../Constants/Images";
import { Image, Segment, Grid, Menu } from 'semantic-ui-react'
import { startCase } from 'lodash'
import AddressDetails from "./AddressDetails";
import DateFormatDetails from "./DateFormatDetails";
import NameExampleDetails from "./NameExampleDetails";
import CompanyDetails from "./CompanyDetails";
import EmailDetails from "./EmailDetails";
import CurrencyDetails from "./CurrencyDetails";
import SentimentDetails from "./SentimentDetails";
import { isMobile } from 'react-device-detect'
import KeywordDetails from "./KeywordDetails";
import cleanDataTypes from "../../Constants/cleanDataTypes";
import PhoneDetails from "./PhoneDetails";

class CleaningExampleTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeFunction: "address"
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, name){
        this.setState({activeFunction: name})
    }


    render() {
        const functionInfo = {
            "name": {icon: images.nameIcon, desc: <NameExampleDetails/>},
            "address": {icon: images.addressIcon, desc: <AddressDetails/>},
            "date": {icon: images.dateIcon, desc: <DateFormatDetails/>},
            "email": {icon: images.emailIcon, desc: <EmailDetails />},
            "phone": {icon: images.phoneIcon, desc: <PhoneDetails/>},
            "company": {icon: images.companyIcon, desc: <CompanyDetails/>},
            "currency": {icon: images.currencyIcon, desc: <CurrencyDetails />},
            "notes": {icon: images.keywordIcon, desc: <KeywordDetails/>},
        };

        const firstRow = cleanDataTypes;

        // const menuItems = firstRow.map((x, idx) =>
        //     <Menu.Item name={x} active={this.state.activeFunction === x} key={idx} onClick={this.handleClick}>
        //         <Image src={functionInfo[x].icon} avatar />
        //         <span>{startCase(x)}</span>
        //     </Menu.Item>);

        const generalStyle = { color: "white", cursor: "pointer", padding: "15px 0 15px 15px", fontSize: "14px" }
        const selectedStyle = JSON.parse(JSON.stringify(generalStyle))
        selectedStyle['backgroundColor'] = 'whitesmoke'
        selectedStyle['color'] = 'black'

        const menuItems = firstRow.map((x, idx) =>
            <div style={this.state.activeFunction === x ? selectedStyle : generalStyle} key={idx} onClick={e => this.handleClick(e, x)}>
                <Image src={functionInfo[x].icon} avatar />
                <span>{startCase(x)}</span>
            </div>);

        const cleanWidth = isMobile ? "31.25%" : "25%";
        const exampleWidth = isMobile ? "68.75%" : "75%";

        return (
            <div style={{display: "grid",
                boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)", gridTemplateColumns: cleanWidth + " " + exampleWidth, backgroundColor: "black"}}>
                <div style={{padding: "10px 0 10px 0"}}>
                    {menuItems}
                </div>
                <div style={{backgroundColor: "whitesmoke", padding: "25px 50px 50px 50px"}}>
                    {functionInfo[this.state.activeFunction].desc}
                </div>
            </div>
        )
    }
}

export default CleaningExampleTable