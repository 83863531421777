import React, {Component} from 'react'
import ReactJson from 'react-json-view'
import DisplayBox from "../DisplayBox";
import ProLabel from "./ProLabel"
import APIRespBoxHeader from "./APIRespBoxHeader";

class CurrencyResponse extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPro: true,
            height: 0
        }
    }

    componentDidMount(){
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        let height = 0;
        const div = document.getElementById("apiCurrencySectionIntro")

        if (div !== null && div !== undefined){
            height = div.offsetHeight;
        }

        this.setState({ height: height })
    }

    changeProToggle = (isPro) => {
        this.setState({ isPro: isPro })
    }

    render() {
        let resp = [{"formatted":"10322.00","split":{"numberValue":"10322.00","symbol":"£","name":"Pound Sterling","code":"GBP"},"usd":"13763.43","error":false,"score":100,"input":"10,322 Pound Sterling"}]

        if (!this.state.isPro){
            delete resp['usd']
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="apiSectionDesc thinHeading">
                            <div id="apiCurrencySectionIntro">
                                <p>Cleaning a currency value will convert it into a decimal format with everything else removed. The currency would also be split into its various components.</p>
                                <br/>
                                <p>The currency can be input in any widely used format, such as with the currency sign, the currency code or the currency name spelled out</p>
                                <br/>
                                <p>If you are doing <ProLabel/> cleaning, you will also see get your currency converted to USD at the daily rate</p>
                                <br/>
                            </div>
                            <div className="apiDocSection">
                                <h5>Formatted String</h5>
                                <p>The formatted value is a string <b>numberValue</b> that is the number (rounded to 2 decimal places) part of the currency value that you input</p>
                            </div>
                            <br/>
                            <div className="apiDocSection">
                                <h5>Split Object Components</h5>
                                <p><span className="parameterName">numberValue</span> <span className="parameterType">string</span></p>
                                <p className="parameterSecondLine">Default ""</p>
                                <p className="parameterDesc">The number part of the currency value. This will be the same as the formatted value.</p>
                                <br/>
                                <p><span className="parameterName">symbol</span> <span className="parameterType">string</span></p>
                                <p className="parameterSecondLine">Default ""</p>
                                <p className="parameterDesc">Symbol of the currency. For e.g., $ </p>
                                <br/>
                                <p><span className="parameterName">name</span> <span className="parameterType">string</span></p>
                                <p className="parameterSecondLine">Default ""</p>
                                <p className="parameterDesc">Official internationally recognized name for the currency. For e.g., Pound Sterling, Singapore Dollar</p>
                                <br/>
                                <p><span className="parameterName">code</span> <span className="parameterType">string</span></p>
                                <p className="parameterSecondLine">Default ""</p>
                                <p className="parameterDesc">Official internationally recognized 3 digit code for the currency. The code is all uppercased. For e.g., GBP, USD</p>
                                <br/>
                            </div>
                            <br/>
                            <div className="apiDocSection">
                                <h5 style={{color: 'green'}}>There is one more parameter in the <b>PRO</b> Email response object</h5>
                                <p><span className="parameterName">usd</span> <span className="parameterType">string</span> <ProLabel/></p>
                                <p className="parameterSecondLine">Default ""</p>
                                <p className="parameterDesc">Currency converted to USD based on that day's exchange rate according to the <a href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html" target="_blank" rel="noopener noreferrer">European Central Bank</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={{marginTop: this.state.height}}>
                            <DisplayBox
                                header={
                                    <APIRespBoxHeader
                                        header="10,322 Pound Sterling"
                                        isPro={this.state.isPro}
                                        changeProToggle={this.changeProToggle}
                                    />
                                }
                                desc={<div>
                                    <ReactJson src={resp}
                                               name={null}
                                               theme="ocean"
                                               displayObjectSize={false}/>
                                </div>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CurrencyResponse