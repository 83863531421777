import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleToast} from "../../Actions/ToggleToast";
import {modifyStepsCompleted} from "../../Actions/ModifyStepsCompleted";
import {modifyStatus} from "../../Actions/ModifyStatus";
import API_Root from "../../Constants/API_Root";
import {initSampleResults} from "../../Actions/InitSampleResults";
import {getColumnValuesForSelectedColumns} from "../../Constants/getColumnValuesForSelectedColumns";
import {createAddressSteps} from "../../Constants/createAddressSteps";
import cleanDataTypes from "../../Constants/cleanDataTypes";
import {modifyCleanedSourceIdx} from "../../Actions/ModifyCleanedSourceIdx";

class CleanDataUploadButton extends Component {
    constructor(props) {
        super(props);

        this.getColumnValues = this.getColumnValues.bind(this);
        this.goToCleanData = this.goToCleanData.bind(this);
    }

    getColumnValues(selectedColumns, fileInformation){

    }

    goToCleanData(e){
        if (this.props.selectedColumns.length  === 0){
            this.props.toggleToast({show: true, message: "Select at least 1 column before cleaning data", type: "warn"})
        }
        else{
            window.scrollTo({top: 0, behavior: 'smooth'});

            this.props.initSampleResults("fetching")
            this.props.modifyStatus("clean")
            this.props.modifyStepsCompleted(1);

            const errorSampleResults = () => this.props.initSampleResults(null)

            fetch(API_Root + 'api/save-clean-spreadsheets-progress/', {
                method: "POST",
                body: JSON.stringify({
                    pk: this.props.filePK,
                    cleanStatus: 'uploaded',
                    cleanInfo: {
                        "columnValues": getColumnValuesForSelectedColumns(this.props.selectedColumns, this.props.fileInformation),
                        "columnCleanTypes": this.props.columnCleanTypes,
                        "selectedColumns": this.props.selectedColumns,
                        "columnCleanInfo": this.props.columnCleanInfo,
                        "cleanSteps": createAddressSteps(this.props.fileInformation.columnNames, this.props.columnCleanInfo, this.props.selectedColumns)
                    }
                }),
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
            }).then((res) => {
                    if (res.status === 200){
                        res.json().then((data) => {
                            this.props.initSampleResults(data.cleanedValues, data.proCleanedValues, data.cleanedSource)
                            // this.props.modifyCleanedSourceIdx(data.cleanedSource.length > 0 ? 0 : -1)
                        }).catch(err => errorSampleResults())
                    }
                    else{
                        errorSampleResults()
                    }
                }
            ).catch(err =>
                errorSampleResults()
            )
        }
    }

    render() {
        let columnSelectWarning = null;
        let isDisabled = false;

        if (this.props.selectedColumns.length === 0){
            isDisabled = true
            columnSelectWarning = <div style={{color: 'red', textAlign: 'left'}}><small><i>Select at least 1 column or confirm address format before cleaning data</i></small></div>
        }
        else{
            for (let i=0; i<this.props.selectedColumns.length; i++){
                if (!(cleanDataTypes.includes(this.props.columnCleanTypes[this.props.selectedColumns[i]]))){
                    isDisabled = true
                    columnSelectWarning = <div style={{color: 'red'}}><small><i>Select a clean type for all cleaning columns</i></small></div>
                    break
                }
            }
        }

        return (
            <div className="cleanDataUploadButtonDiv">
                <div>
                    <Button id="reviewAndCleanButton" onClick={this.goToCleanData} disabled={isDisabled} size="massive" icon="columns" content="Review & Clean" color="orange"/>
                </div>
                {isDisabled ? columnSelectWarning : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    columnCleanInfo: state.mainState.columnCleanInfo
})

const mapActionsToProps = {
    toggleToast: toggleToast,
    modifyStatus: modifyStatus,
    modifyStepsCompleted: modifyStepsCompleted,
    initSampleResults: initSampleResults,
    modifyCleanedSourceIdx: modifyCleanedSourceIdx
}

export default connect(mapStateToProps, mapActionsToProps)(CleanDataUploadButton)