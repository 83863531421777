import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Label, Popup, Icon } from 'semantic-ui-react'
// import ColumnViz from "./ColumnViz"

class ColumnInfo extends Component {
    constructor(props) {
        super(props);

        this.getTotalValue = this.getTotalValue.bind(this);
    }

    getTotalValue(colInfo){
        let missing = 0 ;

        if (colInfo['missing'] > 0){
            missing = 1
        }

        return colInfo['unique']
    }

    render() {
        let colInfo = null;

        if ('columnInfo' in this.props.fileInformation){
            colInfo = this.props.fileInformation['columnInfo'][this.props.colIdx];
        }

        if (colInfo !== null){
            const unique = 'unique' in colInfo ? <Label color="black">Unique: {colInfo['unique']}</Label> : null;
            const missing = 'missing' in colInfo ? <Label color="orange">Missing: {colInfo['missing']}</Label> : null;
            const total = 'total' in colInfo ? <Label>Total: {colInfo['total']}</Label> : null;

            let colType = null;

            if ('type' in colInfo){
                if (colInfo['type'] === "string" || colInfo['type'] === "datetime"){
                    colType = <Label color="blue">Categorical</Label>
                }
                else{
                    colType = <Label color="violet">Numerical</Label>
                }

            }

            // const colViz = 'vizDistribution' in colInfo && 'type' in colInfo ? <ColumnViz vizDistribution={colInfo['vizDistribution']} type={colInfo['type']} /> : null

            return (
                <div style={{width: '200px'}}>
                    {/*{missing} {unique} {total} {colType}*/}
                    {/*{colViz}*/}
                    <h2 className="thinHeading" style={{color: 'green', textAlign: 'center'}}>{this.getTotalValue(colInfo)} <span style={{fontSize: '12px', color: 'black'}}>unique values</span> <Popup content='Unique values DO NOT include missing values 😀' trigger={<Icon name='info circle' style={{fontSize: '12px', color: 'lightblue'}}/>} /></h2>
                </div>
            )
        }
        else{
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnInfo)