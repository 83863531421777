import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import currencyInfo from "../../../../Constants/currencyInfo";
import monthlyPricing from "../../../../Constants/monthlyPricing";
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";

class UpgradeButton extends Component {
    constructor(props) {
        super(props);

        this.openUpgradeModal = this.openUpgradeModal.bind(this);
    }

    openUpgradeModal(e){
        this.props.modifyModalInfo({
            priceIdx: this.props.planIdx,
        })
        this.props.toggleModal("paymentModal")
    }

    render() {
        const currencyObject = currencyInfo[this.props.currency];
        const monthlyPricingList = monthlyPricing[this.props.currency];

        const price = currencyObject.symbol + monthlyPricingList[this.props.planIdx].price.toString()

        return (
            <Button color="purple" content={"Upgrade to " + monthlyPricingList[this.props.planIdx].name.toUpperCase() + " Monthly Plan for " + price + " per month"} size={'size' in this.props ? this.props.size : "medium"} fluid onClick={this.openUpgradeModal}/>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeButton)