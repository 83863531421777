import React, {Component} from 'react'
import { connect } from 'react-redux'
import {modifyAddressColumns} from "../../../../../../Actions/ModifyAddressColumns";
import {toggleInAddressDropZone} from "../../../../../../Actions/ToggleInAddressDropZone";
import {modifyAddressDropIdx} from "../../../../../../Actions/ModifyAddressDropIdx";

class AddressColumnDropTarget extends Component{
    constructor(props){
        super(props);

        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    handleDragEnter(e){
        e.preventDefault();
        e.stopPropagation();

        this.props.modifyAddressDropIdx(this.props.idx)
    }

    handleDragLeave(e){
        e.preventDefault();
        e.stopPropagation();

        this.props.toggleInAddressDropZone(false)
        this.props.modifyAddressDropIdx(-1)
    }

    handleDragOver(e){
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop(e){
        console.log("drop")
        const idx = this.props.addressDropIdx;
        const geocodeType = this.props.cleanOptions.addressGeocode;
        const colDraggedType = this.props.columnBeingDragged.colType;
        const colDraggedIdx = this.props.columnBeingDragged.idx;

        console.log(this.props.columnBeingDragged);
        
        const copiedAddrColumns = JSON.parse(JSON.stringify(this.props.addressColumns))
        let geocodeTypeAddr = copiedAddrColumns[geocodeType]

        if (colDraggedType === "colName"){
            const colName = this.props.fileInformation.columnNames[colDraggedIdx]

            if (idx === this.props.addressColumns[geocodeType].length){
                geocodeTypeAddr.push(colName)
            } else{
                if (idx === 0){
                    geocodeTypeAddr = [colName].concat(geocodeTypeAddr)
                } else {
                    geocodeTypeAddr = geocodeTypeAddr.slice(0, idx).concat([colName]).concat(geocodeTypeAddr.slice(idx, geocodeTypeAddr.length))
                }
            }
        } else {
            const colName = geocodeTypeAddr[colDraggedIdx]

            geocodeTypeAddr.splice(colDraggedIdx, 1)

            if (idx === this.props.addressColumns[geocodeType].length){
                geocodeTypeAddr.push(colName)
            } else{
                if (idx === 0){
                    geocodeTypeAddr = [colName].concat(geocodeTypeAddr)
                } else {
                    geocodeTypeAddr = geocodeTypeAddr.slice(0, idx).concat([colName]).concat(geocodeTypeAddr.slice(idx, geocodeTypeAddr.length))
                }
            }
        }


        copiedAddrColumns[geocodeType] = geocodeTypeAddr
        this.props.modifyAddressColumns(copiedAddrColumns)

        this.props.toggleInAddressDropZone(true)
        // this.props.modifyAddressDropIdx(-1)
        this.props.toggleInAddressDropZone(false)
        e.preventDefault();
        e.stopPropagation();
    }

    render(){
        return(
            <span className='addressColumnDropTarget'
                 onDrop={this.handleDrop}
                 onDragOver={this.handleDragOver}
                 onDragEnter={this.handleDragEnter}
                 onDragLeave={this.handleDragLeave}>
                {this.props.dropSource}
            </span>
        )
    }
}

const mapStateToProps = (state) => ({
    addressDropIdx: state.mainState.addressDropIdx,
    inAddressDropZone: state.mainState.inAddressDropZone,
    addressColumns: state.mainState.addressColumns,
    columnBeingDragged: state.mainState.columnBeingDragged,
    cleanOptions: state.mainState.cleanOptions,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyAddressColumns: modifyAddressColumns,
    toggleInAddressDropZone: toggleInAddressDropZone,
    modifyAddressDropIdx: modifyAddressDropIdx
}


export default connect(mapStateToProps, mapActionsToProps)(AddressColumnDropTarget);
