import React, {Component} from 'react'
import {connect} from 'react-redux'
import DownloadActionNotTaken from "./DownloadActionNotTaken"
import DownloadDisplay from "./DownloadDisplay"
import DownloadProgressBar from "./DownloadProgressBar"

class DownloadIndex extends Component {
    render() {
        let body = <DownloadActionNotTaken />;

        if (this.props.stepsCompleted > 1){
            if (this.props.cleanDataProgress === null){
                body = null
            }
            else{
                if (this.props.cleanDataProgress === 100){
                    body = <DownloadDisplay />
                }
                else{
                    body = <DownloadProgressBar />
                }
            }
        }

        return (
            <div id="cleanIndex">
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stepsCompleted: state.mainState.stepsCompleted,
    cleanDataProgress: state.mainState.cleanDataProgress
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DownloadIndex)