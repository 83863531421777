import React, {Component} from 'react'

class PageHero extends Component {
    render() {
        return (
            <div className="pageHero">
                <h1 style={{paddingLeft: '40px', paddingRight: '40px'}} className="thinHeading">
                    {this.props.title}
                </h1>
                <p style={{paddingRight: '80px', paddingLeft: '80px', paddingTop: '20px', fontSize: '150%'}}>{this.props.desc}</p>
            </div>
        )
    }
}

export default PageHero