import React, {Component} from 'react'
import ModalApp from '../../Modal'
import CongratsPaymentModalBody from "./CongratsPaymentModalBody";
import { Icon } from 'semantic-ui-react'

class CongratsPaymentModal extends Component {
    render() {
        return (
            <ModalApp name="congratsPaymentModal"
                      header={<h3> Thank you for buying more credits! </h3>}
                      body={<CongratsPaymentModalBody />}
                      size="small"
            />

        )
    }
}

export default CongratsPaymentModal



