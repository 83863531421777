import React, {Component} from 'react'
import {connect} from 'react-redux'
import PaymentInfo from "./PaymentInfo";
import PaymentSegment from "./PaymentSegment"

class RefillCreditsModalBody extends Component {
    render() {
        return (
            <div id="refillCreditsModalBody">
                <div id="refillCreditsModalBodyGrid">
                    <div id="refillCreditsModalBodyPay" className="segment-raised-rounded">
                        <PaymentSegment />
                    </div>
                    <div id="refillCreditsModalBodyRates" className="segment-raised-rounded">
                        <PaymentInfo/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(RefillCreditsModalBody)