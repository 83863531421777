import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {modifyFullVizInfo} from "../../Actions/ModifyFullVizInfo";
import API_Root from '../../Constants/API_Root'
import dateToString from '../../Constants/dateToString'
import SummaryGraphHeader from './SummaryGraphHeader'
import filterAPICalls from "./FilterAPICalls";
import stringToDate from "../../Constants/stringToDate";

class SummaryGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            error: false
        }

        this.reshapeData = this.reshapeData.bind(this);
        this.shouldAddToSuccess = this.shouldAddToSuccess.bind(this);
        this.changeCleanType = this.changeCleanType.bind(this);
        this.createCleanTypeArrays = this.createCleanTypeArrays.bind(this);
    }

    componentDidMount(){
        this.setState({ fetching: true });

        if (this.props.fullVizInfo === null){
            fetch(API_Root + "api/get-woyera-api-analytics/", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                })
            }).then(res => res.json())
                .then((data) => {
                    if (!data.error){
                        this.props.modifyFullVizInfo(data.vizInfo);
                        this.props.toggleIsNewUser(data.vizInfo.length <= 0);

                        this.setState({
                            fetching: false,
                            error: false
                        })
                    }
                    else{
                        this.setState({ fetching: false, error: true })
                    }
                }).catch((err) => {
                this.setState({ fetching: false, error: true });
            });
        } else{
            this.setState({
                fetching: false,
                error: false
            })
        }

    }

    reshapeData(fullVizInfo){
        let dateArray = [];

        let endDate;
        let startDate;

        endDate = new Date();
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 30);

        if ('filters' in this.props){
            if ('date' in this.props.filters){
                endDate = stringToDate(this.props.filters.date.endDate);
                startDate = stringToDate(this.props.filters.date.startDate);
            }
        }

        let name = [];
        let address = [];
        let company = [];
        let date = [];
        let email = [];
        let phone = [];
        let currency = [];
        let notes = [];

        while (startDate <= endDate){
            dateArray.push(dateToString(new Date(startDate)).date)

            name.push(0);
            address.push(0);
            company.push(0);
            date.push(0);
            email.push(0);
            phone.push(0);
            currency.push(0);
            notes.push(0);

            startDate.setDate(startDate.getDate() + 1)
        }


        if (fullVizInfo.length > 0){
            const filteredAPICalls = filterAPICalls(this.props, fullVizInfo);
            for (let i=0; i < filteredAPICalls.length; i++){
                let apiCall = filteredAPICalls[i];

                let apiDate = apiCall.request_dt.substr(0, 10);
                let dateIdx = dateArray.indexOf(apiDate);

                let cleanType = apiCall.clean_type;

                if (cleanType === "email"){
                    email[dateIdx] = email[dateIdx] + apiCall.values;
                }
                else if (cleanType === "name"){
                    name[dateIdx] = name[dateIdx] + apiCall.values;
                }
                else if (cleanType === "address"){
                    address[dateIdx] = address[dateIdx] + apiCall.values;
                }
                else if (cleanType === "company"){
                    company[dateIdx] = company[dateIdx] + apiCall.values
                }
                else if (cleanType === "date"){
                    date[dateIdx] = date[dateIdx] + apiCall.values;
                }
                else if (cleanType === "phone"){
                    phone[dateIdx] = phone[dateIdx] + apiCall.values;
                }
                else if (cleanType === "notes"){
                    notes[dateIdx] = notes[dateIdx] + apiCall.values;
                }
                else if (cleanType === "currency"){
                    currency[dateIdx] = currency[dateIdx] + apiCall.values;
                }
            }
        }

        let vizInfo = [];

        for (let i=0; i<dateArray.length; i++){
            vizInfo.push(
                {
                    callDate: dateArray[i],
                    name: name[i],
                    address: address[i],
                    company: company[i],
                    date: date[i],
                    email: email[i],
                    phone: phone[i],
                    notes: notes[i],
                    currency: currency[i]
                }
            )
        }

        return vizInfo
    }


    shouldAddToSuccess(statusCode){
        return (statusCode === 200 || statusCode === 201)
    }

    changeCleanType(cleanTypes){
        this.props.modifyFilters("cleanType", cleanTypes)
    }

    createCleanTypeArrays(){
        let cleanType = ['name', 'address', 'company', 'email', 'date', 'phone', 'notes', 'currency'];

        if ('filters' in this.props){
            if ('cleanType' in this.props.filters){
                cleanType = this.props.filters.cleanType;

                if (cleanType.length === 0){
                    cleanType = ['name', 'address', 'company', 'email', 'date', 'phone', 'notes', 'currency'];
                }
            }
        }

        let cleanTypeArrays = {};

        for (let i=0; i<cleanType.length; i++){
            cleanTypeArrays[cleanType[i]] = []
        }

        return cleanTypeArrays
    }

    render() {
        let cleanType = [];

        if ('filters' in this.props){
            if ('cleanType' in this.props.filters){
                cleanType = this.props.filters.cleanType;
            }
        }

        let vizInfo;
        if (this.props.fullVizInfo === null){
            vizInfo = this.reshapeData([])
        }
        else{
            vizInfo = this.reshapeData(this.props.fullVizInfo)
        }

        return (
            <div>
                <SummaryGraphHeader
                    pageLocation={this.props.pageLocation}
                    cleanType={cleanType}
                    changeCleanType={this.changeCleanType}
                />
                <BarChart
                    width={850}
                    height={225}
                    data={vizInfo}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    {/*<CartesianGrid strokeDasharray="3 3" />*/}
                    <XAxis dataKey="callDate" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="name" stackId="a" fill="#7293CB" />
                    <Bar dataKey="address" stackId="a" fill="#E1974C" />
                    <Bar dataKey="company" stackId="a" fill="#84BA5B" />
                    <Bar dataKey="email" stackId="a" fill="#D35E60" />
                    <Bar dataKey="date" stackId="a" fill="#9067A7" />
                    <Bar dataKey="phone" stackId="a" fill="#008000" />
                    <Bar dataKey="notes" stackId="a" fill="#0081ff" />
                    <Bar dataKey="currency" stackId="a" fill="#FFA500" />
                </BarChart>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fullVizInfo: state.mainState.fullVizInfo
})

const mapActionsToProps = {
    modifyFullVizInfo: modifyFullVizInfo
}

export default connect(mapStateToProps, mapActionsToProps)(SummaryGraph)