import React, {Component} from 'react'
import RefillCreditsPrice from "../../SharedComponents/Payment/RefillCreditsModal/RefillCreditsPrice"
import {CreditPricing} from "../../Constants/CreditPricing";
import PricingPageBlockFeature from "./PricingPageBlockFeature";
import addCommaToNumbers from "../../Constants/addCommaToNumbers";

class PricingPageBlock extends Component {
    getPricePerCredit = () => {
        let pricePerCredits = 0.001;

        for (let i = 0; i < CreditPricing.length; i++) {
            let info = CreditPricing[i];

            if (i === CreditPricing.length - 1) {
                pricePerCredits = info['price']
            } else {
                if (info['values'] === this.props.creditsToRefill) {
                    pricePerCredits = info['price']
                    break
                } else if (this.props.creditsToRefill > info['values'] && this.props.creditsToRefill < CreditPricing[i + 1]['values']) {
                    pricePerCredits = CreditPricing[i + 1]['price']
                    break
                }
            }
        }

        if (this.props.pro) {
            pricePerCredits = pricePerCredits * 5
        }

        let addedStr = pricePerCredits.toString().length === 5 ? "0" : "";

        return "$" + pricePerCredits.toString() + addedStr
    }


    render() {
        const pro = this.props.pro;

        return (
            <div>
                <h4 style={{fontFamily: "museo sans rounded, sans-serif", fontWeight: "800"}}>{pro ? "Pro" : "Basic"} Credits</h4>
                <RefillCreditsPrice isPro={pro} currency="usd" credits={this.props.creditsToRefill}/>
                <h5 style={{marginBottom: "0", marginTop: "5px", paddingBottom: "0"}}>{addCommaToNumbers(this.props.creditsToRefill)} Credits</h5>
                <small style={{marginTop: "0", paddingBottom: "0"}}>{this.getPricePerCredit()} per credit</small>
                <br/><br/>
                <PricingPageBlockFeature dataType="address" pro={pro}/>
                <PricingPageBlockFeature dataType="name" pro={pro}/>
                <PricingPageBlockFeature dataType="company" pro={pro}/>
                <PricingPageBlockFeature dataType="date" pro={pro}/>
                <PricingPageBlockFeature dataType="email" pro={pro}/>
                <PricingPageBlockFeature dataType="phone" pro={pro}/>
                <PricingPageBlockFeature dataType="currency" pro={pro}/>
                <PricingPageBlockFeature dataType="notes" pro={pro}/>
            </div>
        )
    }
}

export default PricingPageBlock