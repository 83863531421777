import React, {Component} from 'react'
import {connect} from 'react-redux'
import CleanColumnsSelected from "./CleanColumnsSelected"
import CleanPayment from "./CleanPayment/CleanPayment"
import CleanDisplayPreview from "./CleanDisplayPreview/CleanDisplayPreview"
import {shouldFetchInfo} from "../../../Constants/shouldFetchInfo";
import API_Root from "../../../Constants/API_Root";
import {modifyPaymentInfo} from "../../../Actions/ModifyPaymentInfo";
import {modifyUserCredits} from "../../../Actions/ModifyUserCredits";
import {modifyFetchedTime} from "../../../Actions/ModifyFetchedTime";
import CleaningSteps from "../../DashboardDisplay/UploadFiles/CleaningSteps"
import {modifySubscriptionInfo} from "../../../Actions/ModifySubscriptionInfo";
import {modifyAPIKeys} from "../../../Actions/ModifyAPIKeys";
import {modifyIsUserNew} from "../../../Actions/ModifyIsUserNew";

class CleanDisplay extends Component {
    componentDidMount(){
        if (shouldFetchInfo(this.props.profileInformationFetchedTime, 5)){
            fetch(API_Root + "api/get-clean-spreadsheets-profile-info/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email")
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(
                        (data) =>
                        {
                            this.props.modifyIsUserNew(true)
                            this.props.modifyAPIKeys(data.apiKeys)
                            this.props.modifySubscriptionInfo(data.subscriptionInfo)
                            this.props.modifyUserCredits(data.totalCredits)
                            this.props.modifyFetchedTime("profile")
                            this.props.modifyPaymentInfo(data.paymentInfo)
                        }).catch(err => console.log(err))
                }
            }).catch(err => console.log(err))
        }
    }

    render() {
        return (
            <div>
                {/*<div id="cleanDisplayColumnsSelected">*/}
                    {/*<CleaningSteps cleanData={true}/>*/}
                    {/*<CleanColumnsSelected />*/}
                {/*</div>*/}
                {/*{*/}
                    {/*this.props.sampleResults !== null && this.props.sampleResults !== "fetching" ?*/}
                        {/*this.props.sampleResults.length > 0 ?*/}
                        {/*<div id="cleanDisplayPayment">*/}
                            {/*<CleanPayment isPro={true}/>*/}
                            {/*<CleanPayment isPro={false}/>*/}
                        {/*</div> : null : null*/}
                {/*}*/}
                {
                    this.props.sampleResults !== null ?
                        <div>
                            <CleanDisplayPreview />
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sampleResults: state.mainState.sampleResults,
    profileInformationFetchedTime: state.mainState.profileInformationFetchedTime
})

const mapActionsToProps = {
    modifyAPIKeys: modifyAPIKeys,
    modifyUserCredits: modifyUserCredits,
    modifyFetchedTime: modifyFetchedTime,
    modifyPaymentInfo: modifyPaymentInfo,
    modifySubscriptionInfo: modifySubscriptionInfo,
    modifyIsUserNew: modifyIsUserNew
}

export default connect(mapStateToProps, mapActionsToProps)(CleanDisplay)