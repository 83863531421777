import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import SocialMediaButtons from "./SocialMediaButtons";
import { MobileView, BrowserView } from 'react-device-detect'
import FullFooter from "./FullFooter"

class FooterIndex extends Component {
    constructor(props){
        super(props);

        this.openModal = this.openModal.bind(this);

    }

    openModal(){
        this.props.toggleModal("priceModal")
    }

    render() {
        return (
            <div>
                <BrowserView>
                    <FullFooter />
                </BrowserView>
                <MobileView>
                    <div style={{backgroundColor: '#F5F5F5', color: 'black'}}>
                        <br/>
                        <div style={{textAlign: 'center'}}>
                        <a
                            style={{fontSize: '125%'}} href="http://www.lovespreadsheets.com/" target="_blank" rel="noopener noreferrer">
                            Part of <span role="img" aria-label="heart">❤️</span> spreadsheets!
                        </a>
                        <br/><br/>
                        <div style={{width: '50%', margin: '0 auto'}}>
                            <SocialMediaButtons />
                        </div>
                        <br/><br/>
                        <a href="https://www.woyera.com/" target="_blank" rel="noopener noreferrer"><Icon name="copyright outline"/> {new Date().getFullYear()} Woyera Inc </a>
                        <br/>
                        <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms & Conditions</a>
                        <br/>
                        <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        <br/><br/>
                        </div>
                    </div>
                </MobileView>
            </div>
        )
    }
}

export default FooterIndex