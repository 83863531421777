const monthlyPricing = {
    "usd":
        [
            {price: 29, name: "analyst", credits: 35000, support: "Helpful"},
            {price: 49, name: "extra small", credits: 65000, support: "Helpful"},
            {price: 99, name: "small", credits: 150000, support: "Priority"},
            {price: 249, name: "medium", credits: 375000, support: "Priority"},
            {price: 499, name: "large", credits: 1000000, support: "Dedicated"},
            {price: 999, name: "extra large", credits: 2250000, support: "Dedicated"}
        ],
    "eur":
        [
            {price: 29, name: "analyst", credits: 35000, support: "Helpful"},
            {price: 49, name: "extra small", credits: 65000, support: "Helpful"},
            {price: 99, name: "small", credits: 150000, support: "Priority"},
            {price: 249, name: "medium", credits: 375000, support: "Priority"},
            {price: 499, name: "large", credits: 1000000, support: "Dedicated"},
            {price: 999, name: "extra large", credits: 2250000, support: "Dedicated"}
        ],
    "sgd":
        [
            {price: 39, name: "analyst", credits: 35000, support: "Helpful"},
            {price: 69, name: "extra small", credits: 65000, support: "Helpful"},
            {price: 129, name: "small", credits: 150000, support: "Priority"},
            {price: 329, name: "medium", credits: 375000, support: "Priority"},
            {price: 649, name: "large", credits: 1000000, support: "Dedicated"},
            {price: 1299, name: "extra large", credits: 2250000, support: "Dedicated"}
        ],
    "gbp":
        [
            {price: 19, name: "analyst", credits: 35000, support: "Helpful"},
            {price: 39, name: "extra small", credits: 65000, support: "Helpful"},
            {price: 79, name: "small", credits: 150000, support: "Priority"},
            {price: 199, name: "medium", credits: 375000, support: "Priority"},
            {price: 399, name: "large", credits: 1000000, support: "Dedicated"},
            {price: 799, name: "extra large", credits: 2250000, support: "Dedicated"}
        ],
    "brl":
        [
            {price: 89, name: "analyst", credits: 35000, support: "Helpful"},
            {price: 149, name: "extra small", credits: 65000, support: "Helpful"},
            {price: 299, name: "small", credits: 150000, support: "Priority"},
            {price: 749, name: "medium", credits: 375000, support: "Priority"},
            {price: 1499, name: "large", credits: 1000000, support: "Dedicated"},
            {price: 2999, name: "extra large", credits: 2250000, support: "Dedicated"}
        ],
    "aud":
        [
            {price: 39, name: "analyst", credits: 35000, support: "Helpful"},
            {price: 69, name: "extra small", credits: 65000, support: "Helpful"},
            {price: 139, name: "small", credits: 150000, support: "Priority"},
            {price: 349, name: "medium", credits: 375000, support: "Priority"},
            {price: 699, name: "large", credits: 1000000, support: "Dedicated"},
            {price: 1399, name: "extra large", credits: 2250000, support: "Dedicated"}
        ],
}

// const monthlyPricing =
//         [
//             {price: 29, name: "analyst", credits: 35000, support: "Helpful"},
//             {price: 49, name: "extra small", credits: 65000, support: "Helpful"},
//             {price: 99, name: "small", credits: 150000, support: "Priority"},
//             {price: 249, name: "medium", credits: 375000, support: "Priority"},
//             {price: 499, name: "large", credits: 1000000, support: "Dedicated"},
//             {price: 999, name: "extra large", credits: 2250000, support: "Dedicated"}
//         ]
    // ""

export default monthlyPricing