import React, {Component} from 'react'
import { Image } from 'semantic-ui-react';
import Images from "../../Constants/Images"
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import pricingPageBlockDesc from "../../Constants/pricingPageBlockDesc";

class PricingPageBlockFeature extends Component {
    openFeatureModal = () => {
        this.props.modifyModalInfo({
            dataType: this.props.dataType
        })
        this.props.toggleModal("pricingPageBlockFeatureModal")
    }

    render() {
        const imageURL = Images[this.props.dataType + "Icon"];
        const desc = pricingPageBlockDesc[this.props.pro ? "pro" : "basic"][this.props.dataType]


        return (
            <div onClick={this.openFeatureModal} className="pricingPageBlockFeatureDiv">
                <Image src={imageURL} avatar /> <span>{desc}</span>
            </div>
        )
    }
}


const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(null, mapActionsToProps)(PricingPageBlockFeature)