import React, {Component} from 'react'
import {connect} from 'react-redux'

class CurrencyNumberFormat extends Component {
    constructor(props) {
        super(props);

        this.changeCurrencyNumberFormat = this.changeCurrencyNumberFormat.bind(this);
    }

    changeCurrencyNumberFormat(e){
        this.props.changeCurrencyNumberFormat(e.target.value)
    }

    render() {
        return (
            <div>
                <input type="radio" name="currencyNumberFormat" id="currencyNumberFormat1" value="dotDecimalCommaThousands" onChange={this.changeCurrencyNumberFormat} checked={this.props.currencyNumberFormat === "dotDecimalCommaThousands"}/>
                <label style={{paddingLeft: '5px'}} htmlFor="currencyNumberFormat1">123,456.78</label>
                <input style={{marginLeft: '15px'}} type="radio" name="currencyNumberFormat" id="currencyNumberFormat2" value="commaDecimalDotThousands" onChange={this.changeCurrencyNumberFormat} checked={this.props.currencyNumberFormat === "commaDecimalDotThousands"} />
                <label style={{paddingLeft: '5px'}} htmlFor="currencyNumberFormat2">123.456,78</label>
                <br/>
                <input type="radio" name="currencyNumberFormat" id="currencyNumberFormat3" value="dotDecimalSpaceThousands" onChange={this.changeCurrencyNumberFormat} checked={this.props.currencyNumberFormat === "dotDecimalSpaceThousands"} />
                <label style={{paddingLeft: '5px'}} htmlFor="currencyNumberFormat3">123 456.78</label>
                <input style={{marginLeft: '15px'}} type="radio" name="currencyNumberFormat" id="currencyNumberFormat4" value="commaDecimalSpaceThousands" onChange={this.changeCurrencyNumberFormat} checked={this.props.currencyNumberFormat === "commaDecimalSpaceThousands"} />
                <label style={{paddingLeft: '5px'}} htmlFor="currencyNumberFormat4">123 456,78</label>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CurrencyNumberFormat)