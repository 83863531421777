import React, {Component} from 'react'

class LovePromoBanner extends Component {
    render() {
        return (
            <div style={{ backgroundColor: "#EE685F",
                color: "white", padding: "8px", fontWeight: "700", textAlign: "center" }}>
                Need any other help with Spreadsheets? <a href="https://www.lovespreadsheets.com/" target="_blank" rel="noopener noreferrer" style={{ color: "whitesmoke" }}>Click here to let us do your spreadsheet work</a>
            </div>
        )
    }
}

export default LovePromoBanner