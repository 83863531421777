import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'

class HelpSectionButton extends Component {
    constructor(props) {
        super(props);

        this.changeHelpSection = this.changeHelpSection.bind(this);
    }

    changeHelpSection(e){
        this.props.changeHelpSection(this.props.name)
    }

    render() {
        let divClassName = this.props.currHelpSection === this.props.name ? "segment-raised-rounded helpSectionButton helpSectionButtonSelected": "helpSectionButton";

        return (
            <div className={divClassName} onClick={this.changeHelpSection}>
                <Image src={this.props.icon} avatar />
                <h4 className="thinHeading">{this.props.name.charAt(0).toUpperCase() + this.props.name.substring(1, this.props.name.length)}</h4>
            </div>
        )
    }
}

export default HelpSectionButton