import React, {Component} from 'react'
import {connect} from 'react-redux'
import APIDisplaySegment from "./APIDisplaySegment";
import Introduction from "./Introduction";
import Authentication from "./Authentication";
import Request from "./Request";
import Response from "./Response";
import NameResponse from "./NameResponse";
import DateResponse from "./DateResponse";
import CompanyResponse from "./CompanyResponse";
import EmailResponse from "./EmailResponse";
import ResponseCodes from "./ResponseCodes";
import RateLimiting from "./RateLimiting";
import Versioning from "./Versioning";
import Help from "./Help";
import Address from "./Address";
import PhoneResponse from "./PhoneResponse";
import CurrencyResponse from "./CurrencyResponse";
import NotesResponse from "./NotesResponse";

class APIDisplayIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            segments: ["introduction", "authentication", "request", "response-format", "name-response", "address-response",
                "date-response", "company-response", "email-response", "phone-response", "currency-response", "notes-response", "response-codes", "rate-limiting", "versioning", "help-us"],
            segmentHeights: []
        }

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);

        const segmentHeights = this.state.segments.map(x =>
        {
            const d = document.getElementById(x + "-docs-segment")
            if (d !== null && d !== undefined){
                return d.offsetTop
            }
            else{
                return 0
            }
        })

        this.setState({
            segmentHeights: segmentHeights
        })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event){
        if (!this.props.clicked){
            const scrollOffset = window.scrollY - 185;

            if (scrollOffset < 100){
                // this.props.changeAPISection(this.state.segments[0])
                this.props.changeAPISectionScroll(this.state.segments[0])
            }
            else{
                for (let i=0; i<this.state.segmentHeights.length - 1; i++){
                    if (this.state.segmentHeights[i] < scrollOffset && scrollOffset < this.state.segmentHeights[i+1]){
                        const segmentName = this.state.segments[i+1];
                        this.props.changeAPISectionScroll(segmentName)
                        break;
                    }
                }
            }
        }

    }

    render() {
        return (
            <div className="apiDocsDisplay">
                <APIDisplaySegment name="introduction"
                    body={<Introduction/>}
                />
                <APIDisplaySegment name="authentication"
                                   body={<Authentication apiKey={this.props.apiKey}/>}
                />
                <APIDisplaySegment name="request"
                                   body={<Request apiKey={this.props.apiKey}/>}
                />
                <APIDisplaySegment name="response-format"
                                   body={<Response/>}
                />
                <APIDisplaySegment name="name-response"
                                   body={<div>
                                       <NameResponse/>
                                   </div>}
                />
                <APIDisplaySegment name="address-response"
                                   body={<div>
                                    <Address/>
                                   </div>}
                />
                <APIDisplaySegment name="date-response"
                                   body={<div>
                                       <DateResponse/>
                                   </div>}
                />
                <APIDisplaySegment name="company-response"
                                   body={<div>
                                       <CompanyResponse/>
                                   </div>}
                />
                <APIDisplaySegment name="email-response"
                                   body={<div>
                                       <EmailResponse/>
                                   </div>}
                />
                <APIDisplaySegment name="phone-response"
                                   body={<div>
                                       <PhoneResponse/>
                                   </div>}
                />
                <APIDisplaySegment name="currency-response"
                                   body={<div>
                                       <CurrencyResponse/>
                                   </div>}
                />
                <APIDisplaySegment name="notes-response"
                                   body={<div>
                                       <NotesResponse/>
                                   </div>}
                />
                <APIDisplaySegment name="response-codes"
                                   body={<div>
                                       <ResponseCodes/>
                                   </div>}
                />
                <APIDisplaySegment name="rate-limiting"
                                   body={<div>
                                       <RateLimiting/>
                                   </div>}
                />
                <APIDisplaySegment name="versioning"
                                   body={<div>
                                       <Versioning/>
                                   </div>}
                />
                <APIDisplaySegment name="help-us"
                                   body={<div>
                                       <Help/>
                                   </div>}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APIDisplayIndex)