import React, {Component} from 'react';
import withAuthorization from '../../Authentication/withAuthorization';
import HistoryIndex from "./HistoryIndex"

class AuthHistoryIndex extends Component {
    render() {
        return(
            <HistoryIndex />
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthHistoryIndex);
