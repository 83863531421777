import React, {Component} from 'react'

class DateExample extends Component {
    render() {
        let pro = 'pro' in this.props;

        if (pro){
            pro = this.props.pro
        }

        return (
            <div>
                <table className="table table-sm table-bordered">
                    <thead>
                    <tr>
                        {'faq' in this.props ? <th>Your Value</th> : <th>Input</th> }
                        {'faq' in this.props ? <th>Cleaned Value</th> : null }
                        <th>year</th>
                        <th>month</th>
                        <th>day</th>
                        <th>hour</th>
                        <th>minute</th>
                        <th>second</th>
                        {pro ? <th>weekday long</th> : null}
                        {pro ? <th>weekday short</th> : null}
                        {pro ? <th>month long</th> : null}
                        {pro ? <th>month short</th> : null}
                        {pro ? <th>AM/PM</th> : null}
                        {pro ? <th>M/D/Y</th> : null}
                        {pro ? <th>D/M/Y</th> : null}
                        {pro ? <th>D Month Y</th> : null}
                        {pro ? <th>Mon D, Y</th> : null}
                        {pro ? <th>D-Mon-Y</th> : null}
                        {pro ? <th>Mon-D-Y</th> : null}
                        {pro ? <th>HH:MM</th> : null}
                        {pro ? <th>HH:MM AM/PM</th> : null}
                        {pro ? <th>utc offset num</th> : null}
                        {pro ? <th>utc offset code</th> : null}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>January 10th, 2010</td>
                        {'faq' in this.props ? <td>2010-01-10 00:00:00</td> : null }
                        <td>2010</td>
                        <td>01</td>
                        <td>10</td>
                        <td>00</td>
                        <td>00</td>
                        <td>00</td>
                        {pro ? <td>Sunday</td> : null}
                        {pro ? <td>Sun</td> : null}
                        {pro ? <td>January</td> : null}
                        {pro ? <td>Jan</td> : null}
                        {pro ? <td>AM</td> : null}
                        {pro ? <td>1/10/2010</td> : null}
                        {pro ? <td>10/1/2010</td> : null}
                        {pro ? <td>10 January 2010</td> : null}
                        {pro ? <td>Jan 10, 2010</td> : null}
                        {pro ? <td>10-Jan-2010</td> : null}
                        {pro ? <td>Jan-10-2010</td> : null}
                        {pro ? <td>00:00</td> : null}
                        {pro ? <td>12:00 AM</td> : null}
                        {pro ? <td> </td> : null}
                        {pro ? <td> </td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>12/25/2015 3:45PM UTC</td>
                        {'faq' in this.props ? <td>2015-12-25 15:45:00</td> : null }
                        <td>2015</td>
                        <td>12</td>
                        <td>25</td>
                        <td>15</td>
                        <td>45</td>
                        <td>00</td>
                        {pro ? <td>Friday</td> : null}
                        {pro ? <td>Fri</td> : null}
                        {pro ? <td>December</td> : null}
                        {pro ? <td>Dec</td> : null}
                        {pro ? <td>PM</td> : null}
                        {pro ? <td>12/25/2015</td> : null}
                        {pro ? <td>25/12/2015</td> : null}
                        {pro ? <td>25 December 2015</td> : null}
                        {pro ? <td>Dec 25, 2015</td> : null}
                        {pro ? <td>25-Dec-2015</td> : null}
                        {pro ? <td>Dec-25-2015</td> : null}
                        {pro ? <td>15:45</td> : null}
                        {pro ? <td>03:45 PM</td> : null}
                        {pro ? <td>+0000</td> : null}
                        {pro ? <td>UTC</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>Aug 1 2019</td>
                        {'faq' in this.props ? <td>2019-08-01 00:00:00</td> : null }
                        <td>2019</td>
                        <td>08</td>
                        <td>01</td>
                        <td>00</td>
                        <td>00</td>
                        <td>00</td>
                        {pro ? <td>Thursday</td> : null}
                        {pro ? <td>Thu</td> : null}
                        {pro ? <td>August</td> : null}
                        {pro ? <td>Aug</td> : null}
                        {pro ? <td>AM</td> : null}
                        {pro ? <td>08/01/2019</td> : null}
                        {pro ? <td>01/08/2019</td> : null}
                        {pro ? <td>01 August 2019</td> : null}
                        {pro ? <td>Aug 01, 2019</td> : null}
                        {pro ? <td>01-Aug-2019</td> : null}
                        {pro ? <td>Aug-01-2019</td> : null}
                        {pro ? <td>00:00</td> : null}
                        {pro ? <td>12:00 AM</td> : null}
                        {pro ? <td> </td> : null}
                        {pro ? <td> </td> : null}
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default DateExample