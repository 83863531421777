import React, {Component} from 'react'
import { Menu, Icon } from 'semantic-ui-react'
import images from '../../Constants/Images'
import {connect} from "react-redux";
import {push} from "react-router-redux";
import AuthComponent from "../AuthComponent";
import NavbarAuth from "./NavbarAuth"
import NavbarNonAuth from "./NavbarNonAuth";

class NavbarMobile extends Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        }

        this.goToURL = this.goToURL.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
    }

    goToURL(e){
        e.preventDefault();

        this.props.navigateTo("/")
    }

    toggleNav(e){
        const isOpenCopy = JSON.parse(JSON.stringify(this.state.isOpen));

        this.setState({
            isOpen: !isOpenCopy
        })
    }

    render() {
        return (
            <div>
                <Menu secondary>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <img style={{cursor: 'pointer', height: '36px', width: '125px'}} src={images.logoNav} onClick={this.goToURL} alt="logo" />
                                {/*<Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="medium"/>*/}
                            </div>
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <div className="navbarMobileIcon" onClick={this.toggleNav}>
                            <Icon name={this.state.isOpen ? "window close outline" : "align justify"} />
                        </div>
                    </Menu.Menu>
                </Menu>
                {this.state.isOpen ? <AuthComponent authComponent={<NavbarAuth />} nonAuthComponent={<NavbarNonAuth />}/> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarMobile)