import React, {Component} from 'react'

class UploadHelp extends Component {
    render() {
        let emojiStyle = null;

        return (
            <div style={{color: "white", padding: "25px"}}>
                <h1 style={{textAlign: 'center'}}>Welcome to Clean Spreadsheets!</h1>
                <h4 style={{textAlign: "center"}}> <span role="img" aria-label="smile" style={emojiStyle}>😀</span>
                    <span role="img" aria-label="confetti" style={emojiStyle}>🎉</span>
                    <span role="img" aria-label="heart" style={emojiStyle}>❤️</span>
                    <span role="img" aria-label="beer" style={emojiStyle}>🍻</span>
                    <span role="img" aria-label="poppers" style={emojiStyle}>🎊</span></h4>
                <br/>
                <div className="segment-raised-rounded" style={{backgroundColor: "white"}}>
                    <h1 style={{textAlign: "center", color: "black"}}>Upload a file to get started and follow the steps</h1>
                </div>
                <br/><br/><br/>
            </div>
        )
    }
}

export default UploadHelp