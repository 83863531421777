import React, {Component} from 'react'
import PageHero from "../../SharedComponents/PageHero";
import GuidesCard from "./GuidesCard";

class GuidesIndex extends Component {
    render() {
        return (
            <div className="guidesIndex">
                <PageHero title="Guides" desc={<span>Guides & Tutorials to Help You Clean Spreadsheets</span>}/>
                <div className="guidesLayout">
                    <div className="row">
                       <div className="col-sm-4">
                        <GuidesCard
                            url="clean-spreadsheets-quickstart"
                            />
                       </div>
                       <div className="col-sm-4">
                            <GuidesCard
                                url="ultimate-guide-to-geocoding-tools"
                            />
                       </div>
                       <div className="col-sm-4">
                           <GuidesCard
                               url="geocode-quickstart"
                           />
                       </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <GuidesCard
                                url="5-reasons-marketing-effort-clean-data"
                            />
                        </div>
                        <div className="col-sm-4">
                            <GuidesCard
                                url="ultimate-guide-to-clean-date-time"
                            />
                        </div>
                        <div className="col-sm-4">
                        <GuidesCard
                            url="ultimate-guide-cleaning-phone-numbers"
                        />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <GuidesCard
                                url="clean-company-business-names-from-spreadsheets"
                            />
                        </div>
                        <div className="col-sm-4">
                            <GuidesCard
                                url="quick-guide-clean-names-spreadsheets"
                            />
                        </div>
                        <div className="col-sm-4">
                            <GuidesCard
                                url="ultimate-guide-to-clean-money-currency"
                            />
                        </div>

                        <div className="row">
                            <div className="col-sm-4">
                                <GuidesCard
                                    url="when-why-clean-your-spreadsheet-data"
                                />
                            </div>
                            <div className="col-sm-4">
                                {/*<GuidesCard*/}
                                {/*    url="quick-guide-clean-names-spreadsheets"*/}
                                {/*/>*/}
                            </div>
                            <div className="col-sm-4">
                                {/*<GuidesCard*/}
                                {/*    url="ultimate-guide-to-clean-money-currency"*/}
                                {/*/>*/}
                            </div>
                    </div>




                </div>
            </div>
            </div>

        )
    }
}

export default GuidesIndex