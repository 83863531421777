import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class CleanBody extends Component {
    render() {
        return (
            <div>
                <br/>
                <h4 className="thinHeading"><Icon name="angle right" /> Click Clean to Clean Your File</h4>
                <h4 className="thinHeading"><Icon name="angle right" /> Customize & Download Your Cleaned File</h4>
                <h4 className="thinHeading"><Icon name="angle right" /> Cleaned File is also Emailed to You</h4>
            </div>
        )
    }
}

export default CleanBody