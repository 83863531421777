import React, {Component} from 'react'
import ExampleTable from './ExampleTable'

class CurrencyDetails extends Component {
    render() {
        const defectInfo = {type: 'currencyCleaning', rows: {
                values: ['$232.32000231', '€1.343,76', '10,322 Pound Sterling', 'CAD$ 45', '₹ 1,41,245', '₱ 567,789 peso', 'R$ 124.50', '¥ 648000', '12.208 SEK'],
                suggestions: ['232.32', '1343.76', '10322.00', '45.00', '141245.00', '567789.00', '124.50', '648000.00', '12208.00'],
                splitComponents: [
                    [["username", "emma.watson"], ["domain", "gmail.com"]],
                    [["username", "alan.turing"], ["domain", "london.org"]],
                    [["username", "khaleesi"], ["domain", "redkeep.com"]]
                ]
            }
        }

        return (
            <div>
                <h3>Convert and Standardize Currency</h3>
                <br/>
                <ExampleTable defectInfo={defectInfo}/>
            </div>
        )
    }
}

export default CurrencyDetails