import React, {Component} from 'react';
import ProfileIndex from "./ProfileIndex"
import withAuthorization from '../../Authentication/withAuthorization';

class AuthProfileIndex extends Component {
    render() {
        return(
            <ProfileIndex/>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthProfileIndex);
