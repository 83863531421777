import React, {Component} from 'react'
import monthlyPricing from "../../Constants/monthlyPricing";
import { connect } from 'react-redux';
import currencyInfo from "../../Constants/currencyInfo";
import { Icon, Button, Popup } from 'semantic-ui-react';
import { push } from 'react-router-redux'
import {convertPrice} from "../../Constants/convertPrice";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import supportIconNames from "../../Constants/supportIconNames";
import SupportInfo from "./SupportInfo"

class PricingCard extends Component {
    constructor(props){
        super(props);

        this.goToSignUp = this.goToSignUp.bind(this);
        this.upgrade = this.upgrade.bind(this);
    }

    upgrade(e){
        this.props.modifyModalInfo({
            priceIdx: this.props.priceIdx,
        })
        this.props.toggleModal("paymentModal")
    }

    goToSignUp(e){
        this.signUpRef.click()
    }

    render() {
        const priceIdx = this.props.priceIdx;
        const currencyObject = currencyInfo[this.props.currency];
        const monthlyPricingList = monthlyPricing[this.props.currency];

        return (
            <div className="monthlyPricingCard">
                <a
                    style={{display: 'none'}}
                    ref={signUpRef => this.signUpRef = signUpRef}
                    href={"/signup?pricing=" + this.props.priceIdx.toString()}
                >Download hidden</a>
                <div className="monthlyPricingCardHeader">
                    {monthlyPricingList[priceIdx].name.toUpperCase()}
                </div>
                <div className="monthlyPricingCardPrice">
                    <span className="monthlyPricingCardSign">{currencyObject.symbol}</span>
                    <span className="monthlyPricingCardMoney">{monthlyPricingList[priceIdx].price.toString()}</span>
                    <span className="monthlyPricingMonth">/month</span>
                </div>
                <div className="monthlyPricingCardFeatures">
                    <span className="monthlyPricingCardRequests"><Icon name="check" style={{color: "darkblue"}}/> <Popup content='Our credits are soft limits. Read FAQs below for more info' trigger={<span>{monthlyPricingList[priceIdx].credits.toLocaleString()} Credits per Month</span>} /></span>
                    <span className="monthlyPricingCardSupport"><Icon name={supportIconNames[monthlyPricingList[priceIdx].support]} style={{color: 'darkblue'}}/> <Popup content={<SupportInfo supportType={monthlyPricingList[priceIdx].support} style={{listStyleType: "none", textAlign: "left", fontSize: "80%", marginBottom: "-10px"}} pricingCard />} trigger={<span>{monthlyPricingList[priceIdx].support} Support</span>} /></span>
                </div>
                {
                    'profile' in this.props ? <Button color="green" content="Upgrade" fluid onClick={this.upgrade}/> :
                        <Button color="blue" style={{backgroundColor: "#0081ff"}} content="Sign Up" fluid onClick={this.goToSignUp}/>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

const mapActionsToProps = {
    navigateTo: push,
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}


export default connect(mapStateToProps, mapActionsToProps)(PricingCard)