import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import {Button, Icon} from "semantic-ui-react";
import money from "./money.svg"
import addCommaToNumbers from "../../../Constants/addCommaToNumbers";


class CongratsPaymentModalBody extends Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(e) {
        this.props.toggleModal(null)
    }


    render() {
        const refillCredits = addCommaToNumbers(this.props.modalInfo.refillCredits);
        const isCreditsPro = this.props.modalInfo.pro;

        let credits;
        if (isCreditsPro) {
            credits = "Pro"
        } else {
            credits = "Basic"
        }


        return (
          <div style={{textAlign:'center',padding:'20px'}}>

              <img style={{width:'50%'}} src={money}/>

              <h4> Congrats, you have refilled {refillCredits} {credits} credits! </h4>



              <div style={{paddingTop:'20px'}}>
              <Button style={{padding:'10px', color:'white',borderRadius:'10px',height:'50px',fontSize:'140%', fontWeight:'900', width:'50%', backgroundImage:'linear-gradient(43deg, #1A2980 0%, #0084B4 51%,#1A2980 100%)'}} onClick={this.closeModal}>  Continue </Button>
              </div>
              <br/> <br/>
              <h6> Happy Data Cleaning <Icon name="smile outline"/> </h6>
          </div>

        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(CongratsPaymentModalBody)
