// import {getPostData} from "./getPostData";
//
import API_Root from "./API_Root";

export const getCodeSnippet = (language, apiKey, cleanType) => {
    let codeSnippet;
//     const fileInfo = fileInformation[pkID];
//
    switch (language) {
        case "cURL":
            codeSnippet = getCURLCode(apiKey, cleanType);
            break;
        case "python":
            codeSnippet = getPythonCode(apiKey, cleanType);
            break;
        case "javascript":
            codeSnippet = getJavascriptCode(apiKey, cleanType);
            break;
        case "ruby":
            codeSnippet = getRubyCode(apiKey, cleanType);
            break;
        case "php":
            codeSnippet = getPHPCode(apiKey, cleanType);
            break;
        default:
            codeSnippet = "hello"
    }
//
    return codeSnippet
}

const getDataArray = (cleanType) => {
    return {
        "name": '["dr. martin luther king Jr.", "Emilia r. Clarke", "Roosevelt, Franklin delano"]',
        "address": '["32 London Bridge St, London, UK", "413 w 52nd Street, New York, 10019", "624 Brush St, 48226"]',
        "date": '["January 10th, 2010", "12/25/2015 3:45PM", "Aug 1 2019"]',
        "email": '["joecool@gmail,,,com", "first.last@somecompany.org", "khaleesi@redkeep.c"]',
        "phone": '["(212) 555 1924", "+91-98-55-155555", "82 045-888-6666"]',
        "notes": '["I got this laptop yesterday. It is super fast", "The chicken tenders here are also a worthy quarantine comfort food", "Seinfeld and FRIENDS were the two most popular sitcoms of the 90s"]',
        "currency": '["$232.32000231", "€1.343,76", "10,322 Pound Sterling"]',
        "company": '["Glaxosmithkline Pharma gmbh", "apple incorporated", "Infosys Ltd."]'
    }[cleanType]
}

const getPOSTJson = (apiKey, cleanType) => {
    return '\'{"apiKey": "' + apiKey + '", "data": ' + getDataArray(cleanType) + '}\''
}

const getFullURL = (cleanType) => {
    return API_Root + "clean/" + cleanType
}

const getCURLCode = (apiKey, cleanType) => {
    return "$ curl -X POST '" +  getFullURL(cleanType) + "' \\\n-d " + getPOSTJson(apiKey, cleanType)
}

const getPythonCode = (apiKey, cleanType) => {
    let code = "import requests\n\n"

    let postBody = getPOSTJson(apiKey, cleanType)

    code += 'r = requests.post("' + getFullURL(cleanType) + '", json=' + postBody + ")\n\n"
    code += "if r.status_code == 200:\n\t# SUCCESS \n\tpass\nelse:\n\t# ERROR\n\tpass"
    return code
}
//
const getJavascriptCode = (apiKey, cleanType) => {
    let code = "fetch(";

    let postBody = getPOSTJson(apiKey, cleanType)

    code += '"' + getFullURL(cleanType) + '", {\n'
    code += '\tmethod: "POST",\n'
    code += "\tbody: JSON.stringify(" + postBody + "),\n"
    code += '}).then(res =>{\n'
    code += '\tif (res.status === 200){\n\t\t// SUCCESS\n\t'
    code += "}\n\telse{\n\t\t// ERROR\n\t}\n})"

    return code
}
//
const getRubyCode = (apiKey, cleanType) => {
    let code = "require 'net/http'\nrequire 'json'\n\n";

    code += 'url = "' + getFullURL(cleanType) + '"\n'
    code += 'uri = URI.parse(url)\n\n'

    let postBody = getPOSTJson(apiKey, cleanType)

    code += "http = Net::HTTP.new(uri.host, uri.port)\n"
    code += "request = Net::HTTP::Post.new(uri.request_uri)\n"
    code += "request.body = (" + postBody + ").to_json\n\n"
    code += "response = http.request(request)\n"
    code += "if response.code == '200'\n"
    code += "\t# SUCCESS\n"
    code += 'else\n'
    code += '\t# ERROR\n'
    code += 'end'

    return code
}
//
const getPHPCode = (apiKey, cleanType) => {
    let code = '<?php\n\n$url= "' + getFullURL(cleanType) + '";\n$curl = curl_init($url);\ncurl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n\n'

        let postBody = getPOSTJson(apiKey, cleanType)

        postBody = postBody.replace(/{/g, '[').replace(/}/g, ']').replace(/:/g, '=>')

        code += "$postJSON = json_encode(" + postBody + ");\n"

        code += "curl_setopt($curl,CURLOPT_URL, $url);\n" +
            "curl_setopt($curl,CURLOPT_POST, true);\n" +
            "curl_setopt($curl,CURLOPT_POSTFIELDS, $postJSON);\n\n"

        code += "$result = curl_exec($curl);\n\n$http_code = curl_getinfo($curl, CURLINFO_HTTP_CODE);\n\n"
        code += "if ($http_code == 200){\n\t// SUCCESS\n}\n"

    code += "else{\n"
    code += "\t// ERROR CODE\n"
    code += "}\n"
    code += "curl_close($curl)\n"
    code += "?>"

    return code
}
//
// const isPrivate = (fileInfo) => {
//     return fileInfo['private']
// }
//
// const getPostHeader = (fileInfo, language) => {
//     let postHeader;
//
//     switch (language){
//         case "curl":
//             postHeader = '-H "Content-Type: application/json" '
//             if (isPrivate(fileInfo)){
//                 const accessKey = fileInfo['accessKey'];
//                 const secretKey = fileInfo['secretKey'];
//
//                 postHeader += ' -H "accessKey: ' +  accessKey + '" -H "secretKey: ' + secretKey + '" '
//             }
//             postHeader += "\\";
//             break;
//         case "python":
//             postHeader = "{}"
//             if (isPrivate(fileInfo)){
//                 const accessKey = fileInfo['accessKey'];
//                 const secretKey = fileInfo['secretKey'];
//
//                 postHeader = '{"accessKey": "' +  accessKey + '", "secretKey": "' + secretKey + '"}'
//             }
//             break;
//         case "javascript":
//             postHeader = ""
//             if (isPrivate(fileInfo)){
//                 const accessKey = fileInfo['accessKey'];
//                 const secretKey = fileInfo['secretKey'];
//
//                 postHeader = 'headers: {"accessKey":"'  + accessKey + '", "secretKey":"' + secretKey + '"}'
//             }
//             break;
//         case "ruby":
//             postHeader = "{'Content-Type': 'text/json'"
//             if (isPrivate(fileInfo)){
//                 const accessKey = fileInfo['accessKey'];
//                 const secretKey = fileInfo['secretKey'];
//
//                 postHeader += ", 'accessKey': '" + accessKey + "', 'secretKey': '" + secretKey + "'"
//             }
//
//             postHeader += "}"
//             break
//         case "php":
//             postHeader = '"Content-type: application/json"'
//             if (isPrivate(fileInfo)){
//                 const accessKey = fileInfo['accessKey'];
//                 const secretKey = fileInfo['secretKey'];
//
//                 postHeader += ', "accessKey: ' + accessKey + '", "secretKey: ' + secretKey + '"'
//             }
//
//             postHeader = "array(" + postHeader + ")"
//             break
//         case "html":
//             postHeader = ""
//
//             if (isPrivate(fileInfo)){
//                 postHeader += '\t\t\t\theaders:{\n\t\t\t\t\taccessKey: "' + fileInfo['accessKey'] + '",\n\t\t\t\t\tsecretKey: "' + fileInfo['secretKey'] + '"},\n'
//             }
//             break
//         default:
//             postHeader = ""
//     }
//
//     return postHeader
// }
//
//
//
