import React, {Component} from 'react'
import Bubbles from "../Illustrations/Bubbles.svg"
import HowItWorksCard from "./HowItWorksCard";
import {isMobile} from "react-device-detect";
import { Icon } from 'semantic-ui-react'
import CleanIcon from "../Illustrations/CleanIcon.svg"
import ReviewIcon from "../Illustrations/ReviewIcon.svg"
import UploadIcon from "../Illustrations/UploadIcon.svg"


class HowItWorksLanding extends Component {
    render() {
        const stepsBoxStyle = {"marginBottom": "250px"}
        // upload, review, clean
        let headingTextColor = "orangered";
        const pStyle = {fontSize: "130%", marginBottom: "15px"}

        return (
            <div>
                <div style={stepsBoxStyle}>
                    <div className="row">
                        <div className="col-sm-5">
                            <div style={isMobile ? {padding: "3% 3% 5% 3%"} : {paddingLeft: "15%", paddingTop: "5%"}}>
                                <h1 style={{fontWeight: '800', color: headingTextColor}}> Upload Spreadsheet <img src={UploadIcon} style={{width: "15%"}} alt={"uploadLogo"}/></h1>
                                <br/>
                                <p style={pStyle}> Upload the spreadsheet or CSV file you want to clean</p>
                                <p style={pStyle}> You can upload any of the following extensions: <b>.xls, .csv, .xlm, .xlsx, .txt</b></p>
                                <p style={pStyle}> You can change settings to read the file properly</p>
                            </div>
                        </div>
                        <div className="col-sm-1" />
                        <div className="col-sm-6">
                            <div style={isMobile ? {padding: "3% 3% 5% 3%"} : {marginRight: "5%", borderRadius: "5px"}} className="heavy-box-shadow">
                                <img style={{width: "100%"}} src={"https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/LandingPage_3StepsGifs/Upload+GIf.gif"} alt="QUERY Code"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={stepsBoxStyle}>
                    <div className="row">
                        <div className={isMobile ? "col-sm-5" : "col-sm-6"}>
                            {
                                isMobile ? <div style={{paddingRight: "15%", paddingTop: "5%"}}>
                                        <h1 style={{fontWeight: '800', color: headingTextColor}}> Choose Your Options <img src={ReviewIcon} style={{width: "15%"}} alt={"reviewLogo"}/></h1>
                                        <p style={pStyle}> Choose the data type you want to clean</p>
                                        <p style={pStyle}> Choose the column(s) you want to clean</p>
                                        <p style={pStyle}> Review a sample of your cleaned data</p>
                                    </div> :
                                    <div className="heavy-box-shadow" style={{marginLeft: "5%", borderRadius: "5px"}}>
                                        <img style={{width: "100%"}} src={"https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/LandingPage_3StepsGifs/ReviewGIF.gif"} alt="Upload Data"/>
                                    </div>
                            }
                        </div>
                        <div className="col-sm-1" />
                        <div className={isMobile ? "col-sm-6" : "col-sm-5"}>
                            {
                                isMobile ? <div className="heavy-box-shadow" style={{padding: "3% 3% 5% 3%"}}>
                                        <img style={{width: "100%"}} src={"https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/LandingPage_3StepsGifs/ReviewGIF.gif"} alt="Review Data"/>
                                    </div> :
                                    <div style={{paddingRight: "15%", paddingTop: "5%"}}>
                                        <h1 style={{fontWeight: '800', color: headingTextColor}}> Choose Your Options <img src={ReviewIcon} style={{width: "15%"}} alt={"reviewLogo"}/></h1>
                                        <p style={pStyle}> Choose the data type you want to clean</p>
                                        <p style={pStyle}> Choose the column(s) you want to clean</p>
                                        <p style={pStyle}> Review a sample of your cleaned data</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div style={stepsBoxStyle}>
                    <div className="row">
                        <div className="col-sm-5">
                            <div style={isMobile ? {padding: "3% 3% 5% 3%"} : {paddingLeft: "15%", paddingTop: "5%"}}>
                                <h1 style={{fontWeight: '800', color: headingTextColor}}> Clean Your Data <img src={CleanIcon} style={{width: "15%"}} alt={"cleanLogo"}/></h1>
                                <p style = {pStyle}> Click <span style={{fontWeight: "800", color: "green"}}>Clean Your Data</span> to clean your file</p>
                                <p style = {pStyle}> Download just cleaned column or the entire file with clean values</p>
                                <p style = {pStyle}> Cleaned File is also emailed to you 😀</p>
                            </div>
                        </div>
                        <div className="col-sm-1" />
                        <div className="col-sm-6">
                            <div style={ isMobile ? {padding: "3% 3% 5% 3%"} : {marginRight: "5%", borderRadius: "5px"}} className="heavy-box-shadow">
                                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/LandingPage_3StepsGifs/CleanGIF.gif"
                                     style={{width: "100%"}}
                                     alt="Clean Data"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HowItWorksLanding