import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Segment } from 'semantic-ui-react'
import SignUpForm from './SignUpForm'
import {toggleModal} from "../../../Actions/ToggleModal";

class SignUpModalBody extends Component {
    constructor(props) {
        super(props);

        this.login = this.login.bind(this);
    }

    login(e){
        this.props.toggleModal("loginModal");
    }


    render() {
        return (
            <div>
                <br/>
                <div className="row">
                    <div className="col-sm-2" />
                    <div className="col-sm-8">
                        <Segment raised>
                            <div>
                                <SignUpForm />
                                <br/>
                                <p style={{textAlign: 'center'}} onClick={this.login} className="linkStyle">I Already Have An Account! Let me Log In</p>
                                <br/>
                            </div>
                        </Segment>
                    </div>
                    <div className="col-sm-2" />
                </div>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(SignUpModalBody)