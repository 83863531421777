import React, {Component} from 'react'
import ExampleTable from './ExampleTable'

class AddressDetails extends Component {
    render() {
        const defectInfo = {type: 'address', rows: {
                values: ['32 London Bridge St, London, UK', '413 w 52nd Street, New York, 10019', '624 Brush St, 48226'],
                suggestions: [<span>32 London Bridge Street, London SE1 9SG, UK</span>,
                    <span>413 West 52nd Street, New York, NY 10019, United States of America</span>,
                    <span>624 Brush Street, Detroit, MI 48226, United States of America</span>],
                geocode: [<span><b>lat: </b>51.5042997, <b>lng: </b>-0.0863703</span>,
                    <span><b>lat: </b>40.7652249, <b>lng: </b>-73.988659</span>,
                    <span><b>lat: </b>42.3320389, <b>lng: </b>-83.042002</span>
                ]
                // splitComponents: [
                //     [["number", "20"], ["predirectional", "W"], ["street", "34th"], ["suffix", "St"], ["city", "New York"], ["state", "NY"], ["zip", "10001"]],
                //     [["number", "415"], ["predirectional", "W"], ["street", "52nd"], ["suffix", "St"], ["city", "New York"], ["state", "NY"], ["zip", "10019"]],
                //     [["number", "13735"], ["street", "Warren"], ["suffix", "Ave"], ["city", "Dearborn"], ["state", "MI"], ["zip", "48126    "]]
                // ]
            }
        }

        let errorColor = '#FFA07A';
        let validColor = '#556B2F';
        let textColor = 'white';

        return (
            <div>
                <h3>Clean and Geocode Worldwide Addresses</h3>
                <br/>
                <table className="table table-bordered table-sm table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th>Input</th>
                            <th>Formatted</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor: errorColor}}>32 London Bridge St, London, UK</td>
                            <td style={{backgroundColor: validColor, color: textColor}}>32 London Bridge Street, London SE1 9SG, UK</td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: errorColor}}>413 w 52nd Street, New York, 10019</td>
                            <td style={{backgroundColor: validColor, color: textColor}}>413 W 52nd St, New York, NY 10019, USA</td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: errorColor}}>624 Brush St, 48226</td>
                            <td style={{backgroundColor: validColor, color: textColor}}>624 Brush St, Detroit, MI 48226, USA</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table className="table table-bordered table-sm table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th>Input</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: errorColor}}>32 London Bridge St, London, UK</td>
                        <td style={{backgroundColor: validColor, color: textColor}}>51.5042997</td>
                        <td style={{backgroundColor: validColor, color: textColor}}>-0.0863703</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: errorColor}}>413 w 52nd Street, New York, 10019</td>
                        <td style={{backgroundColor: validColor, color: textColor}}>40.7652307</td>
                        <td style={{backgroundColor: validColor, color: textColor}}>-73.988682</td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: errorColor}}>624 Brush St, 48226</td>
                        <td style={{backgroundColor: validColor, color: textColor}}>42.33226579</td>
                        <td style={{backgroundColor: validColor, color: textColor}}>-83.0421731</td>
                    </tr>
                    </tbody>
                </table>
                {/*<ExampleTable defectInfo={defectInfo}/>*/}
            </div>
        )
    }
}

export default AddressDetails