import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import { push } from 'react-router-redux'
import { withRouter } from 'react-router-dom'
import {modifyStatus} from "../../Actions/ModifyStatus";

class NavbarItem extends Component {
    constructor(props){
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.goToURL = this.goToURL.bind(this);
    }

    toggleModal(e){
        this.props.toggleModal(this.props.to)
    }

    goToURL(e){
        this.props.changeURLEnd(this.props.to);
        if (this.props.to === "clean"){
            this.props.modifyStatus("upload")
        }
        this.props.navigateTo("/" + this.props.to)
    }

    render() {
        let leftIcon = null;
        let rightIcon = null;

        let marginTop = '6px';
        let fontSize = '115%';

        const style = {color: this.props.color, cursor: 'pointer', textDecoration: 'none', fontSize: fontSize, marginTop: marginTop}

        if ('icon' in this.props){
            if ('iconPosition' in this.props){
                if (this.props.iconPosition === "left"){
                    leftIcon = <Icon name={this.props.icon} />
                }
                else{
                    rightIcon = <Icon name={this.props.icon} />
                }
            }
            else{
                leftIcon = <Icon name={this.props.icon} />
            }
        }

        if ('modal' in this.props){
            return(
                <span onClick={this.toggleModal} style={style}>{leftIcon}{this.props.name}{rightIcon}</span>
            )
        }
        else{
            if (this.props.urlEnd === this.props.to){
                style["borderBottom"] = "5px solid #0081ff"
            }

            return (
                <p onClick={this.goToURL} style={style} className="navbarItem">{leftIcon}{this.props.name}{rightIcon}</p>
            )
        }
    }
}



const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    navigateTo: push,
    modifyStatus: modifyStatus
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(NavbarItem))