import React, {Component} from 'react'
import FAQ from './FAQ'
import { Header, Icon, Image } from 'semantic-ui-react'

class FAQBlock extends Component {
    render() {
        const FAQs = this.props.FAQs.map((x, idx) =>
            <FAQ key={idx} question={x.question} answer={x.answer}/>
        )
        return (
            <div>
                {/*<h1 className="thinHeading">*/}
                    {/*{this.props.title}*/}
                {/*</h1>*/}
                <Header as='h1'>
                    {'dataType' in this.props ? <Image avatar src={this.props.icon}/> : <Icon name={this.props.icon} />}
                    <Header.Content>
                        {this.props.title}
                    </Header.Content>
                </Header>
                {FAQs}
            </div>
        )
    }
}

export default FAQBlock