import React, {Component} from 'react'
import APISteps from "./APISteps"

class APIStepsIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <h1 className="thinHeading" style={{fontSize: "300%", textAlign: "center", fontFamily: "museo-sans, sans-serif", fontStyle: "normal", fontWeight: "bold"}}>Need to clean data not in a spreadsheet?</h1>
                <h1 className="thinHeading" style={{fontSize: "300%", textAlign: "center", fontFamily: "museo-sans, sans-serif", fontStyle: "normal", fontWeight: "bold"}}>Try our <span style={{color: "#167dff"}}> REST API</span></h1>
                <br/>
                <APISteps/>
            </div>
        )
    }
}

export default APIStepsIndex