import React, {Component} from 'react'
import { isMobile, BrowserView, MobileView} from 'react-device-detect'
import CleaningExampleTable from './CleaningExamples/CleaningExampleTable'
import UpdatedCTA from "./UpdatedCTA"
import ScheduleDemoButton from "./ScheduleDemoButton";

class LandingPageHero extends Component {
    render() {
        let heroStyle = {
            padding: "3%",
            paddingTop: "20px",
            background: 'url("../src/LandingPage/wave.svg") no-repeat',
            backgroundSize: "100% auto",
            fontFamily: "museo sans rounded, sans-serif"
        };

        if (isMobile){
            heroStyle = {textAlign: "center", marginTop: "20px"}
        }

        return (
            <section style={heroStyle}>
                <div className="row">
                    <div className="col-sm-5">
                        <BrowserView>
                            <br/><br/>
                        </BrowserView>
                        <h1 style={{fontSize: '350%'}}>Clean Your Customer Data <br/>in <span style={{color: "orangered"}}> 3</span> Easy Steps</h1>
                        <br/><br/>
                        <h4 style={{textAlign: "left"}}>Clean, standardize and parse hard-to-handle data types from your spreadsheets. <span style={{color: "orangered"}}>Auto-magically.</span></h4>
                        <br/><br/><br/>
                        <div style={{textAlign: "left"}}>
                            <UpdatedCTA style={{fontSize: "24px"}} />
                            <ScheduleDemoButton style={{fontSize: "24px"}} />
                        </div>
                        <MobileView>
                            <br/><br/>
                        </MobileView>
                    </div>
                    <div className="col-sm-7">
                        <CleaningExampleTable/>
                    </div>
                </div>
            </section>
        )
    }
}

export default LandingPageHero