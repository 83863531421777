export const MODIFY_CLEAN_RESULTS = 'MODIFY_CLEAN_RESULTS';

export function modifyCleanResults(cleanResults){

    return{
        type: MODIFY_CLEAN_RESULTS,
        mainState: {
            cleanResults: cleanResults
        }
    }
}