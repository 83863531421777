import React, {Component} from 'react'
import {Image} from 'semantic-ui-react'
import {isBrowser} from 'react-device-detect'

class SocialLogo extends Component {
    render() {
        // if (isBrowser){
            return (
                <div style={{textAlign: 'center'}}>
                    <a href={this.props.link} target="_blank" rel="noopener noreferrer" className="nostyle">
                        <img src={this.props.src} style={{height: '75px'}} alt="CustomerLogo"/>
                    </a>
                    {/*<Image src={this.props.src} href={this.props.link} centered={true} size="medium"/>*/}
                </div>
            )
        // }
        // else{
        //     return (
        //         <div style={{textAlign: 'center'}}>
        //             <Image src={this.props.src} href={this.props.link} centered={true} size="small"/>
        //         </div>
        //     )
        // }

    }
}

export default SocialLogo