import React from 'react'

const pricingPageBlockDesc = {
    pro: {
        address: <span>Proprietary <b>Address</b> Data</span>,
        name: <span>All <b>Name</b> Components</span>,
        company: <span>Full <b>Company</b> Data Enrichment</span>,
        date: <span>All <b>Date</b> Components</span>,
        email: <span>Auto Correct & Verify <b>Email</b></span>,
        phone: <span>Auto Correct & Verify <b>Phone</b></span>,
        currency: <span>Standardize <b>Currency</b> & Convert</span>,
        notes: <span>Multiple (AI included) <b>Notes</b> Algos</span>
    },
    basic: {
        address: <span>Open Source <b>Address</b> Data</span>,
        name: <span>First & Last <b>Name</b> Only</span>,
        company: <span><b>Company</b> Name & Suffix Only</span>,
        date: <span><b>Date</b> & Time Only</span>,
        email: <span>Auto Correct <b>Email</b> Format</span>,
        phone: <span>Auto Correct <b>Phone</b> format</span>,
        currency: <span>Standardize <b>Currency</b> Amounts</span>,
        notes: <span>Open Source <b>Notes</b> Keyword Algos</span>
    }
}

export default pricingPageBlockDesc