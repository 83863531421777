import React, {Component} from 'react'
import ModalApp from "../Modal"
import MatchesFAQModalBody from "./MatchesFAQModalBody"

class MatchesFAQModal extends Component {
    render() {
        return (
            <ModalApp
                name="matchesFAQModal"
                header="Matches FAQ"
                body={<MatchesFAQModalBody/>}
                size="medium"
            />
        )
    }
}

export default MatchesFAQModal