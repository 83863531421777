import React, {Component} from 'react'
import LandingPageHero from "./LandingPageHero";
import { BrowserView } from 'react-device-detect'
import {toggleModal} from "../Actions/ToggleModal";
import {push} from "react-router-redux";
import {connect} from "react-redux";
import SocialProofIndex from "./SocialProof/SocialProofIndex";
import WhatElseCanYouDo from "./WhatElseCanYouDo";
import UpdatedCTA from "./UpdatedCTA"
import HowItWorksLanding from "./HowItWorksCard/HowItWorksLanding";
import Benefits from "./Benefits";
import NeedMoreInfo from "./NeedMoreInfo";
import SecurityFeatures from "./SecurityFeatures";
import BottomSignUp from "./BottomSignUp";
import { Icon } from 'semantic-ui-react';
import LandingPagePricingIndex from "./LandingPagePricing/LandingPagePricingIndex";
import APIStepsIndex from "./APISteps/APIStepsIndex";

class LandingPageIndex extends Component {
    constructor(props){
        super(props);

        this.signUp = this.signUp.bind(this);
    }

    signUp(e){
        this.props.toggleModal("signupModal")
    }

    componentWillMount(){
        if (localStorage.getItem("email") !== null){
            this.props.navigateTo("/clean")
        }
    }

    render() {
        return (
            <div id="appAuthentication">
                <div className="landingPageHeroDiv">
                    <br/>
                    <LandingPageHero/>
                </div>
                <div className="landingPageBackground">
                    <br/><br/><br/><br/>
                    <SocialProofIndex/>
                    <h1 style={{fontSize: "350%", fontWeight: "800", color: "#06253b", textAlign: "center", padding: "10% 0 10% 0"}}>
                        Get started with <br/><span style={{color: "#0081ff", fontSize: "150%"}}>3 </span><br/>easy steps<br/><Icon name="caret down"/>
                    </h1>
                    <HowItWorksLanding/>
                    {/*<br/><br/><br/><br/>*/}
                    <div style={{textAlign: "center"}}>
                        <UpdatedCTA style={{fontSize: "36px"}} />
                    </div>
                    <br/><br/><br/><br/>
                        {/*<div style={{textAlign: "center"}}>*/}
                            {/*<UpdatedCTA style={{fontSize: "36px", padding: "20px 50px 20px 50px", fontWeight: "400"}}/>*/}
                        {/*</div>*/}
                        {/*<br/><br/><br/><br/><br/><br/><br/><br/>*/}
                    <WhatElseCanYouDo />
                    <LandingPagePricingIndex/>
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                    <div style={{textAlign: "center"}}>
                        <UpdatedCTA style={{fontSize: "36px"}} />
                    </div>
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                    <APIStepsIndex/>
                    <SecurityFeatures/>
                    <br/><br/><br/><br/>
                    {/*<br/><br/><br/><br/>*/}
                    {/*<Benefits/>*/}
                    {/*<br/><br/><br/><br/><br/><br/><br/><br/>*/}
                    {/*<div style={{textAlign: "center"}}>*/}
                        {/*<UpdatedCTA style={{fontSize: "36px", padding: "20px 50px 20px 50px", fontWeight: "400"}}/>*/}
                    {/*</div>*/}
                    {/*<br/><br/><br/><br/><br/><br/><br/><br/>*/}
                    {/*<NeedMoreInfo/>*/}
                    <div className="row">
                        <div className="col-sm-3" />
                        <div className="col-sm-6">
                            <div style={{textAlign: "center", margin: "100px 0 100px 0"}}>
                                <h2>Stop Struggling. Sign Up Today.</h2>
                                <br/>
                                <h4 className="thinHeading">All Accounts Come With <span style={{color: "green"}}>250</span> FREE PRO Credits</h4>
                                <br/><br/>
                                <UpdatedCTA style={{fontSize: "36px", padding: "20px 50px 20px 50px", fontWeight: "400"}}/>
                            </div>
                        </div>
                        <div className="col-sm-3" />
                    </div>
                    <br/><br/><br/><br/>
                    {/*<br/><br/><br/><br/>*/}
                    <BottomSignUp/>
                    {/*<br/><br/><br/><br/><br/><br/><br/><br/>*/}
                </div>
                {/*<br/><br/><br/><br/><br/>*/}
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(LandingPageIndex)