import React, {Component} from 'react'
import AceEditor from "react-ace";
import { Header, Icon, Dropdown } from 'semantic-ui-react'
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import Carousel from "../../SharedComponents/Carousel/Carousel";
import URLBox from "../../API/Docs/URLBox";

class APISteps extends Component {
    constructor(props){
        super(props);

        this.state = {
            cleanType: "name"
        }

        this.changeCleanType = this.changeCleanType.bind(this);
    }

    changeCleanType(e, {value}){
        this.setState({ cleanType: value })
    }

    render() {
        const requestBody = {
            company: '{\n  "apiKey": "API_KEY",\n  "data": [\n    "apple inc", \n    "Infosys ltd."\n  ]\n}',
            name: '{\n  "apiKey": "API_KEY",\n  "data": [\n    "Dr. james M. mcavoy Jr.", \n    "barack Obama"\n  ]\n}',
            address: '{\n  "apiKey": "API_KEY",\n  "data": [\n    "415 w 52nd st, new york"\n  ]\n}',
            date: '{\n  "apiKey": "API_KEY",\n  "data": [\n    "Dec 25 2015 3:45PM UTC", \n    "1/15/19"\n  ]\n}',
            email: '{\n  "apiKey": "API_KEY",\n  "data": [\n    "admin@woyera.com", \n    "boo"\n  ]\n}',
            phone: '{\n  "apiKey": "API_KEY",\n  "data": [\n    "+32 0423/67.78.98", \n    "(212) 555 1924"\n  ]\n}',
            currency: '{\n  "apiKey": "API_KEY",\n  "data": [\n    "10,322 Pound Sterling", \n    "€1.343,76"\n  ]\n}',
            notes: '{\n  "apiKey": "API_KEY",\n  "data": [\n    "Seinfeld and FRIENDS were the two most popular sitcoms of the \'90s"\n  ]\n}',
        }

        const responseBody = {
            company: '{\n  "cleanData": [\n   {\n      "formatted": "Apple",\n      "split": {\n          "name": "Apple",\n          "suffix": "INC",\n          "suffixFull": "INCORPORATED",\n          "domain": "apple.com",\n          "country": "United States",\n          "country": "Cupertino",\n          "employeesCount": "218112",\n          "industry": "Consumer electronics",\n          "yearFounded": "1976",\n          "linkedinURL": "linkedin.com/company/apple",\n          "otherNames": ""\n        }\n    },\n    {...},\n  ]\n}',
            name: '{\n  "cleanData": [\n   {\n      "formatted": "James Mcavoy",\n      "split": {\n          "prefix": "Dr.",\n          "suffix": "Jr.",\n          "first": "James",\n          "middle": "M",\n          "last": "Mcavoy",\n          ...\n        }\n    },\n    {...},\n  ]\n}',
            address: '{\n  "cleanData": [\n   {\n      "formatted": "415 West 52nd Street, New York, NY 10019, United States of America",\n      "lat": 40.7653144,\n      "lng": -73.9887279,\n      "split": {\n          "city": "New York",\n          "house_number": "415",\n          "postcode": "10019",\n          "road": "West 52nd Street",\n          "state": "New York",\n          ...\n        }\n    }\n  ]\n}',
            date: '{\n  "cleanData": [\n   {\n      "formatted": "2015-12-25 15:45:00",\n      "split": {\n          "year": "2015",\n          "month": "12",\n          "day": "25",\n          "hour": "15",\n          "minute": "45",\n          "second": "00",\n          "weekdayLong": "Friday",\n          "weekdayShort": "Fri",\n          "monthLong": "December",\n          "monthShort": "Dec",\n          "AM/PM": "PM",\n          "M/D/Y": "12/25/2015",\n          "D/M/Y": "25/12/2015",\n          "D Month Y": "25 December 2015",\n          "Mon D, Y": "Dec 25, 2015",\n          "D-Mon-Y": "25-Dec-2015",\n          "Mon-D-Y": "Dec-25-2015",\n          "HH:MM": "15:45",\n          "HH:MM AM/PM": "03:45 PM",\n          "utcOffsetNum": "+0000",\n          "utcOffsetCode": "UTC"\n        }\n    },\n    {...},\n  ]\n}',
            email: '{\n  "cleanData": [\n   {\n      "formatted": "admin@woyera.com",\n      "valid": "VALID",\n      "split": {\n          "username": "admin",\n          "domain": "woyera.com"\n        }\n    },\n    {...},\n  ]\n}',
            phone: '{\n  "cleanData": [\n   {\n      "formatted": "+32-423-677898",\n      "valid": "VALID",\n      "split": {\n          "countryCode": "32",\n          "areaCode": "0423",\n          "number": "677898"\n        }\n    },\n    {...},\n  ]\n}',
            currency: '{\n  "cleanData": [\n   {\n      "formatted": "10322.00",\n      "usd": "13763.43",\n      "split": {\n          "numberValue": "10322.00",\n          "symbol": "£",\n          "name": "Pound Sterling",\n          "code": "GBP"\n        }\n    },\n    {...},\n  ]\n}',
            notes: '{\n  "cleanData": [\n   {\n      "formatted": "popular sitcoms",\n      "split": {\n          "secondaryKeywords": "seinfeld, friends, 90s",\n          "textRankKeywords": "",\n          "extraKeywords": ""\n        }\n    },\n    {...},\n  ]\n}',
        }

        const stepHeight = "300px";

        const options = [
            {key: 0, text: 'name', value:'name'},
            {key: 1, text: 'address', value:'address'},
            {key: 2, text: 'company', value:'company'},
            {key: 3, text: 'date', value:'date'},
            {key: 4, text: 'email', value:'email'},
            {key: 5, text: 'phone', value:'phone'},
            {key: 6, text: 'currency', value:'currency'},
            {key: 7, text: 'notes', value:'notes'}
        ]

        return (
            <div>
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <div className="segment-no-margin segment-raised" style={{textAlign: 'center', color: 'gray'}}>
                            <h4 className="thinHeading">
                                <Icon name='cogs' style={{paddingRight: '30px'}}/>
                                I want to clean {' '}
                                <Dropdown
                                    inline
                                    options={options}
                                    value={this.state.cleanType}
                                    onChange={this.changeCleanType}
                                />
                            </h4>
                        </div>
                        <div className="segment-no-margin segment-raised">
                            <Carousel
                                content={
                                    [<div style={{height: stepHeight}}>
                                        <h5> <span className="step">1</span> Make POST Request with API Key and Data</h5>
                                        <div>
                                            <br/>
                                            <URLBox
                                                urlType={"Base URL for Cleaning " + this.state.cleanType.charAt(0).toUpperCase() + this.state.cleanType.substring(1, this.state.cleanType.length)}
                                                url={"https://api-woyera.com/clean/" + this.state.cleanType + "/"}
                                            />
                                        </div>
                                        <br/>
                                        <AceEditor
                                            mode="json"
                                            theme="monokai"
                                            name="pythonQuickstartGuide"
                                            value={requestBody[this.state.cleanType]}
                                            editorProps={{ $blockScrolling: true }}
                                            width='100%'
                                            height="125px"
                                        />
                                    </div>,
                                        <div style={{height: stepHeight}}>
                                            <h5> <span className="step">2</span> Get Cleaned Data in Response </h5>
                                            <AceEditor
                                                mode="json"
                                                theme="monokai"
                                                name="pythonQuickstartGuide"
                                                value={responseBody[this.state.cleanType]}
                                                editorProps={{ $blockScrolling: true }}
                                                width='100%'
                                                height="80%"
                                            />
                                        </div>
                                    ]}
                            />
                        </div>
                    </div>
                    <div className="col-sm-3">
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <div style={{marginTop: '30px', marginBottom: '15px'}}>
                            <div className="segment-no-margin segment-raised">
                                <p style={{color: 'purple', textAlign: 'center', fontSize: '125%'}}>Want even more details on the API? Check out our <a href="/api-docs" target="_blank" rel="noopener noreferrer">API docs</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3" />
                </div>
            </div>
        )
    }
}

export default APISteps