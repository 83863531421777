import React, {Component} from 'react'
import images from "../../../Constants/Images";
import EmailExample from "../../../LandingPage/DetailExamples/EmailExample"
import FAQBlock from "../FAQBlock";

const emailFAQ = [
    {question: 'How does email cleaning work?', answer: 'We check all emails for the correct format and suggest corrections for improper emails!'},
    {question: "Do you validate if the email is real?", answer: "No, we CURRENTLY do not validate whether the email is real or not. We are working on the capability and it will be released very soon."},
    {question: 'What is the output for email cleaning?', answer: <span>The final output from cleaning a column of emails will look like the following <br/> <br/> <EmailExample faq/></span>},
    {question: "What if my value isn't an email?", answer: "If your value isn't an email then you will get an INVALID result and the username and domain columns will be blank."}
]

class Email extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <FAQBlock
                        title="Email" icon={images.emailIcon}
                        FAQs={emailFAQ}
                        dataType
                    />
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default Email