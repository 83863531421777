import React, {Component} from 'react'
import {connect} from 'react-redux'
import SelectColumn from "../../../../FileSamples/SelectColumn"
import { Popup, Icon } from 'semantic-ui-react'

class ColumnNameTable extends Component {
    render() {
        if ('samples' in this.props.fileInformation) {
            const columnNames = this.props.fileInformation.columnNames;
            const allColInfo = this.props.fileInformation['columnInfo'];

            let tableRows = null;

            if (columnNames.length > 0){
                tableRows = []
                for (let i=0; i<columnNames.length; i++){
                    tableRows.push(
                        <tr key={i.toString()}>
                            <th scope="row"><SelectColumn colIdx={i}/></th>
                            <td><h4 className="thinHeading">{columnNames[i]}</h4></td>
                            <td>{allColInfo[i]['unique']}</td>
                        </tr>
                    )
                }
            }

            return (
                <table className="table">
                    <thead className="thead-dark">
                    <tr>
                        <th scope="col">Clean?</th>
                        <th scope="col">Column Name</th>
                        <th scope="col">Unique Values <Popup content='Unique values DO NOT include missing values 😀' trigger={<Icon name='info circle' style={{fontSize: '12px', color: 'lightblue'}}/>} /></th>
                    </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            )
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnNameTable)