import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Progress } from 'semantic-ui-react'
import {modifyCleanDataProgress} from "../../../Actions/ModifyCleanDataProgress";

class DownloadProgressBar extends Component {
    render() {
        let progress = this.props.cleanDataProgress !== null ? this.props.cleanDataProgress : 0;

        return (
            <div id="fileInfoDiv" style={{backgroundColor: 'black', color: 'white', textAlign: 'center'}}>
                <h4>Your data is being cleaned. You will get an email notification at {localStorage.getItem("email")} when the cleaning is done. So feel free to relax and do something else <span role="img" aria-label="smile emoji">😀</span></h4>
                <Progress inverted percent={progress} indicating/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cleanDataProgress: state.mainState.cleanDataProgress
})

const mapActionsToProps = {
    modifyCleanDataProgress: modifyCleanDataProgress()
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadProgressBar)