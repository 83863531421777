import React, {Component} from 'react'
import PaymentSegment from "../../SharedComponents/Payment/RefillCreditsModal/PaymentSegment"
import PaymentInfo from "../../SharedComponents/Payment/RefillCreditsModal/PaymentInfo"
import { MobileView, BrowserView } from 'react-device-detect';
import CTA from "../../LandingPage/CTA"
import { Button } from 'semantic-ui-react'

class PayAsYouGoPricing extends Component {
    render() {
        return (
            <div className="payAsYouGoPricingDiv">
                <BrowserView>
                    <div style={{display: 'grid', gridTemplateColumns: '50% 50%', padding: '10px 150px 30px 150px'}}>
                        <div className="creditsSegment" style={{backgroundColor: 'white', margin: '10px', textAlign: 'center'}}>
                            <PaymentSegment landing/>
                        </div>
                        <div className="creditsSegment" style={{backgroundColor: 'white', margin: '10px'}}>
                            <PaymentInfo />
                        </div>
                    </div>
                    <div style={{paddingTop: '50px', textAlign: 'center', paddingBottom: '50px', width: '50%', margin: '0 auto'}}>
                        <div className="cardDiv">
                            <h3 className="thinHeading">Enterprise? Custom Requirements?</h3>
                            <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
                                <Button basic color="orange" content="Contact Us" icon="paper plane outline" onClick={(e) => this.openModal(e, "contactUsModal")}/>
                                <Button basic content="Email Us" icon="envelope" href="mailto:info@cleanspreadsheets.com?Subject=Please%20Clean%20My%20Spreadsheets" />
                            </div>
                        </div>
                    </div>
                    <CTA />
                </BrowserView>
                <MobileView>
                    <div className="row" style={{marginBottom: '100px'}}>
                        <div className="col-sm-6">
                            <div className="creditsSegment" style={{backgroundColor: 'white', margin: '20px', textAlign: 'center'}}>
                                <PaymentSegment landing/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="creditsSegment" style={{backgroundColor: 'white', margin: '20px'}}>
                                <PaymentInfo />
                            </div>
                        </div>
                    </div>
                    <div style={{paddingTop: '50px', textAlign: 'center', paddingBottom: '20px', width: '50%', margin: '0 auto'}}>
                        <div className="cardDiv">
                            <h3 className="thinHeading">Enterprise? Custom Requirements?</h3>
                            <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
                                <Button basic color="orange" content="Contact Us" icon="paper plane outline" onClick={(e) => this.openModal(e, "contactUsModal")}/>
                                <Button basic content="Email Us" icon="envelope" href="mailto:info@cleanspreadsheets.com?Subject=Please%20Clean%20My%20Spreadsheets" />
                            </div>
                        </div>
                    </div>
                    <CTA />
                </MobileView>
            </div>
        )
    }
}


export default PayAsYouGoPricing