import React, {Component} from 'react'

class FAQ extends Component{
    render() {
        const answer = this.props.answer;
        let answerBlock;

        if (answer.constructor === Array){
            const answerList = answer.map((x, idx) => <li key={idx}>{x}</li>);
            answerBlock = <ul>{answerList}</ul>
        }
        else{
            answerBlock = <p>{answer}</p>
        }

        return (
            <div>
                <h4 className="thinHeading text-muted">{this.props.question}</h4>
                {answerBlock}
                <br/>
            </div>
        )
    }
}

export default FAQ