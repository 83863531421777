import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'

class ValidOrInvalidTag extends Component {
    render() {
        let color;
        let text;
        let icon;

        if (this.props.valid){
            color = "blue"
            text = "VALID"
            icon = "paper plane outline"
        } else{
            color = "grey"
            text = "INVALID"
            icon = "dont"
        }
        return (
            <Label icon={icon} color={color} content={text} />
        )
    }
}

export default ValidOrInvalidTag