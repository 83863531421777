import React, {Component} from 'react'
import withAuthorization from '../../Authentication/withAuthorization';
import SignUpCleanPaymentIndex from "./SignUpCleanPaymentIndex";

class AuthCleanPaymentIndex extends Component {
    render() {
        return (
            <div>
                <SignUpCleanPaymentIndex/>
            </div>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthCleanPaymentIndex);