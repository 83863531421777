import React, {Component} from 'react'
import CleanTypeURLs from "./CleanTypeURLs";
import { Divider, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import ReactJson from 'react-json-view'
import {toggleModal} from "../Actions/ToggleModal";

class APIQuickStart extends Component {
    render() {
        const apiKey = this.props.apiKey;
        const requestBody = '{\n  "apiKey": "' + apiKey + '",\n  "data": [\n    "Mar 27, 2012", \n    "Awesome Possum"\n  ]\n}'

        const requiredStyle = {
            fontStyle: 'italic',
            fontSize: '75%',
            color: 'black'
        }

        return (
            <div className="segment-raised segment-no-margin">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="apiDocSection">
                            <h4>Request</h4>
                            <br/>
                            <h5 className='thinHeading'> <span className="step" style={{backgroundColor: 'gray'}}>1</span> Select URL Based on Data Type You Want to Clean</h5>
                            <p style={{color: 'green'}}>For this guide we will be cleaning <b>date</b></p>
                            <CleanTypeURLs/>
                            <br/>
                            <h5 className='thinHeading'> <span className="step" style={{backgroundColor: 'gray'}}>2</span> Make POST Request to URL with JSON encoded body </h5>
                            <p style={{color: 'green'}}><code>data</code> <span style={requiredStyle}>(required)</span> needs to be an <b>array</b> with only <b>string</b> values</p>
                            <p style={{color: 'green'}}><code>apiKey</code> <span style={requiredStyle}>(required)</span> is your API Key found in your dashboard</p>
                            <AceEditor
                                mode="python"
                                theme="monokai"
                                name="pythonQuickstartGuide"
                                value={requestBody}
                                editorProps={{ $blockScrolling: true }}
                                width='100%'
                                height='135px'
                            />
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="apiDocSection">
                            <h4>Response</h4>
                            <br/>
                            <div>
                                <h5 style={{color: 'red'}} className="thinHeading">If your request had an error</h5>
                                <h6>You will get one of the following response codes: <code>400, 402, 403, 404, 405, 500</code></h6>
                                <p>And your Response body (a JSON encoded body just like your Request) will look like the following</p>
                                <ReactJson src={{error: "The API Key is either not valid or not specified in the body with 'apiKey'"}} name={null} theme="ocean" displayObjectSize={false}/>
                            </div>
                            <br/>
                            <Divider/>
                            <div>
                                <h5 style={{color: 'green'}} className="thinHeading">If your request was successful</h5>
                                <h6>You will get a <code>200</code> response code</h6>
                                <p>And your Response body (a JSON encoded body just like your Request) will look like the following</p>
                                <Divider/>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <br/>
                                        <p>Your cleaned data can be accessed using the <code>cleanData</code> key</p>
                                        <p>It will contain an <b>array</b> the same length as your Request <code>data</code> and each item in the array will be a <b>JSON object</b></p>
                                        <p>Every <b>JSON object</b> will always have the following 3 keys</p>
                                        <ul>
                                            <li><code>input</code> your input value</li>
                                            <li><code>score</code> confidence score in cleaning <i>integer, 0-100</i></li>
                                            <li><code>error</code> whether there was an error cleaning the value <i>boolean</i></li>
                                        </ul>
                                        <Divider/>
                                        <p style={{color: 'green', fontSize: '115%'}}>If your value was cleaned successfully, then the <code>error</code> key will equal <code>False</code></p>
                                        <p>No matter what kind of data type (name, address etc.) you clean, there will always be the following additional keys in a successful <b>JSON object</b></p>
                                        <ul>
                                            <li><code>formatted</code> the cleaned value</li>
                                            <li><code>split</code> components of the value</li>
                                        </ul>
                                        <p>You can see exactly what will be in the <b>JSON object</b> depending on the data type you are cleaning <span className="linkStyle" onClick={(e) => this.props.toggleModal("cleanDataTypes")}>here</span> or by testing a value with our <a href="/demo" target="_blank" rel="noopener noreferrer">Demo</a>.</p>
                                        <Divider/>
                                        <p style={{color: 'red', fontSize: '115%'}}>If your value could not be cleaned, then the <code>error</code> key will equal <code>True</code></p>
                                        <p>There will be a <code>errorMessage</code> key containing the error message why the value could not be cleaned</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <br/>
                                        <ReactJson src={{cleanData: [{"split":{"year":2012,"month":3,"day":27,"hour":0,"minute":0,"second":0},"formatted":"2012-03-27 00:00:00","score":100,"error":false,"input":"Mar 27, 2012"},{"error":true,"errorMessage":"Value could not be converted to a date","score":0,"input":"Awesome Possum"}]}} name={null} theme="ocean" displayObjectSize={false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="apiDocSection" style={{textAlign: 'center', color: 'gray'}}>
                            <h3 className="thinHeading">
                                Still confused or want to know more<Icon name='question circle outline' style={{paddingLeft: '15px'}}/>
                            </h3>
                            <h5 className="linkStyle thinHeading"><i><a href="/api-docs" target="_blank" rel="noopener noreferrer">Read our full API Documentation</a></i></h5>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(APIQuickStart)