import React, {Component} from 'react'

class CleanTypeURLs extends Component {
    render() {
        return (
            <table className="table table-sm table-bordered">
                {'header' in this.props ? <thead>
                <tr>
                    <th>Clean Type</th>
                    <th>URL</th>
                </tr>
                </thead> : null}
                <tbody>
                <tr>
                    <td><b>Name</b></td>
                    <td>https://api-woyera.com/clean/name/</td>
                </tr>
                <tr>
                    <td><b>Address</b></td>
                    <td>https://api-woyera.com/clean/address/</td>
                </tr>
                <tr>
                    <td><b>Company</b></td>
                    <td>https://api-woyera.com/clean/company/</td>
                </tr>
                <tr>
                    <td><b>Date</b></td>
                    <td>https://api-woyera.com/clean/date/</td>
                </tr>
                <tr>
                    <td><b>Email</b></td>
                    <td>https://api-woyera.com/clean/email/</td>
                </tr>
                <tr>
                    <td><b>Phone</b></td>
                    <td>https://api-woyera.com/clean/phone/</td>
                </tr>
                <tr>
                    <td><b>Notes</b></td>
                    <td>https://api-woyera.com/clean/notes/</td>
                </tr>
                <tr>
                    <td><b>Currency</b></td>
                    <td>https://api-woyera.com/clean/currency/</td>
                </tr>
                </tbody>
            </table>
        )
    }
}

export default CleanTypeURLs