import React, {Component} from 'react'
import { connect } from 'react-redux'
import {modifyColumnBeingDragged} from "../../../../../../Actions/ModifyColumnBeingDragged";
import { Icon } from 'semantic-ui-react'
import {modifyAddressColumns} from "../../../../../../Actions/ModifyAddressColumns";

class AddressColumnDropSource extends Component {
    constructor(props){
        super(props)

        this.state = {
            cursor: 'grab'
        }

        this.dragStart = this.dragStart.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
        this.removeSource = this.removeSource.bind(this);
    }

    dragStart(){
        this.setState({cursor: 'grabbing'}, () => this.props.modifyColumnBeingDragged({colType: this.props.colType, idx: this.props.idx}))
    }

    dragEnd(){
        this.setState({cursor: 'grab'}, () => this.props.modifyColumnBeingDragged({colType: "colName", idx: -1}))
    }

    removeSource(){
        const copiedAddrColumns = JSON.parse(JSON.stringify(this.props.addressColumns))
        let colTypeAddrColumns = copiedAddrColumns[this.props.colType]
        colTypeAddrColumns.splice(this.props.idx, 1)
        copiedAddrColumns[this.props.colType] = colTypeAddrColumns

        this.props.modifyAddressColumns(copiedAddrColumns)
    }

    render() {
        return (
            <span style={{cursor: this.state.cursor}} className="addressColumnDropSource" draggable={true} onDragStart={this.dragStart} onDragEnd={this.dragEnd}>
                <b>{this.props.colName}</b> {this.props.colType !== "colName" ? <Icon style={{cursor: 'pointer'}} name="remove circle" onClick={this.removeSource}/> : <span>  </span>}
            </span>
        )
    }
}

const mapStateToProps = (state) => ({
    columnBeingDragged: state.mainState.columnBeingDragged,
    addressColumns: state.mainState.addressColumns
})

const mapActionsToProps = {
    modifyColumnBeingDragged: modifyColumnBeingDragged,
    modifyAddressColumns: modifyAddressColumns
}

export default connect(mapStateToProps, mapActionsToProps)(AddressColumnDropSource)