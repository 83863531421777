import React, {Component} from 'react'
import APIMenuItem from "./APIMenuItem";

class APIMenu extends Component {
    constructor(props) {
        super(props);

        this.changeMenuItem = this.changeMenuItem.bind(this);
    }

    changeMenuItem(menuItem){
        this.props.changeAPISection(menuItem)
    }

    render() {
        return (
            <div className="apiDocsMenu">
                <h5 className="thinHeading" style={{textAlign: "center"}}>Clean Spreadsheet<br/> API Docs</h5>
                <br/>
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="introduction"
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="authentication"
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="request"
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="response-format"
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="name-response"
                             subMenuItem
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="address-response"
                             subMenuItem
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="date-response"
                             subMenuItem
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="company-response"
                             subMenuItem
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="email-response"
                             subMenuItem
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="phone-response"
                             subMenuItem
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="currency-response"
                             subMenuItem
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="notes-response"
                             subMenuItem
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="response-codes"
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="rate-limiting"
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="versioning"
                />
                <APIMenuItem apiSection={this.props.apiSection}
                             changeMenuItem={this.changeMenuItem}
                             name="help-us"
                />
            </div>
        )
    }
}

export default APIMenu