import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react';
import constants from "../../../../Constants/constants";

class UploadIndicator extends Component {
    render() {
        const startUpload =
            <div>
                <h3 className="thinHeading" style={{marginBottom: "0"}}>Upload a File and Follow the Steps to Get Started</h3>
                <p>{constants.extensions.join(", ")} extensions supported</p>
            </div>

        return (
            <div>
                <span className="uploadIcons">
                    <Icon name="file excel outline" />
                    <Icon name="file alternate outline" />
                </span>
                {
                    'type' in this.props ?
                        this.props.type === "end" ? <h4 className="thinHeading">ReUpload File to Start Over</h4> :
                            startUpload : startUpload}

            </div>
        )
    }
}

export default UploadIndicator