import React, {Component} from 'react'

class WelcomeMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true
        }
    }

    render() {
        if (this.state.show){
            let emojiStyle = null;

            return (
                <div className="segment-raised-rounded" style={{marginTop: "30px", textAlign: "center", backgroundColor:"#4ca6ff"}}>
                    <h4 style={{textAlign: "center", color: "white"}}>Welcome to Clean Spreadsheets!</h4>
                    {/*<h4 style={{textAlign: "center"}}> <span role="img" aria-label="smile" style={emojiStyle}>😀</span>*/}
                        {/*<span role="img" aria-label="confetti" style={emojiStyle}>🎉</span>*/}
                        {/*<span role="img" aria-label="heart" style={emojiStyle}>❤️</span>*/}
                        {/*<span role="img" aria-label="beer" style={emojiStyle}>🍻</span>*/}
                        {/*<span role="img" aria-label="poppers" style={emojiStyle}>🎊</span>*/}
                    {/*</h4>*/}
                    {/*<h4 style={{color:"white"}}>Upload a file to clean and we will walk you through step-by-step how to clean your data</h4>*/}
                </div>
            )
        } else {
            return null
        }
    }
}

export default WelcomeMessage