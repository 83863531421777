import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Message } from 'semantic-ui-react';
import API_Root from "../../../../Constants/API_Root";
import {modifyUserCredits} from "../../../../Actions/ModifyUserCredits";

class CreditConversionButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            converting: false,
            error: null
        }
    }

    handleDismiss = () => {
        this.setState({ error: null })
    }

    convertCredits = () => {
        this.setState({ converting: true })
        const error = () => this.setState({ converting: false, error: true })

        fetch(API_Root + "api/convert-pro-and-basic-credits/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                fromCreditsType: this.props.fromCreditsType,
                creditsToConvert: this.props.creditsToConvert
            })
        }).then(res => {
            if (res.status === 200) {
                res.json().then((data) => {
                        this.props.modifyUserCredits(data.totalCredits)
                        this.props.conversionFinished()
                        this.setState({ converting: false, error: false })
                }).catch((err) => error())
            } else {
                error()
            }
        }).catch((err) => error())
    }

    render() {
        let errorMsg;

        if (this.state.error === false){
            errorMsg = <div style={{marginTop: "20px"}}><Message
                onDismiss={this.handleDismiss}
                header='Congratulations!'
                content='Your credits were successfully converted!'
                positive
            /></div>
        } else if (this.state.error === true) {
            errorMsg = <div style={{marginTop: "20px"}}><Message
                onDismiss={this.handleDismiss}
                header='There was an error converting your credits :('
                content='Please contact our support if this problem persists'
                negative
            /></div>
        } else{
            errorMsg = null
        }

        return (
            <div style={{textAlign: "center", marginBottom: "40px"}}>
                <Button disabled={this.props.btnDisabled}
                        size="massive"
                        loading={this.state.converting}
                        onClick={this.convertCredits}
                        style={{backgroundColor: "orange", color: "white", fontWeight: "bold"}}>
                    {"Convert Credits".toUpperCase()}
                </Button>
                {errorMsg}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    modifyUserCredits: modifyUserCredits
}

export default connect(mapStateToProps, mapActionsToProps)(CreditConversionButton)