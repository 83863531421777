import React, {Component} from 'react'
import CleanDataMasterButton from "../../Clean/CleanDataMasterButton"
import addCommaToNumbers from "../../../../Constants/addCommaToNumbers";
import { connect } from 'react-redux'
import MatchesSelection from "./MatchesSelection";

class EnoughCredits extends Component {
    render() {
        const remainingCreditsAfterCleaning = this.props.userCredits - this.props.totalCleanValues;

        let color = "green";

        if (this.props.userCredits < 1000){
            color = "red"
        }
        else if (this.props.userCredits < 10000 && this.props.userCredits >= 1000){
            color = "orange"
        }

        const isMonthly = this.props.planIdx !== -1;

        let creditsMessage = null;

        if (this.props.stepsCompleted <= 1){
            if (!isMonthly){
                creditsMessage = <h6>You will have <span style={{color: color}}>{addCommaToNumbers(remainingCreditsAfterCleaning)}</span> <u>{this.props.isPro ? "Pro" : "Basic"}</u> credits remaining after cleaning</h6>
            } else {
                if (remainingCreditsAfterCleaning > 0){
                    creditsMessage = <h6>You will have <span style={{color: color}}>{addCommaToNumbers(remainingCreditsAfterCleaning)}</span> credits remaining this month after cleaning</h6>
                } else {
                    creditsMessage = <h6>You have exceeded your monthly credits by <span style={{color: color}}>{addCommaToNumbers(-1 * remainingCreditsAfterCleaning)}</span> credits. Don't worry our limits are soft limits so you can continue with no worries</h6>
                }
            }
        }

        return (
            <div className="enoughCreditsDiv">
                <div className={this.props.isPro ? "proEnoughCreditsBox" : null}>
                    {this.props.isPro ? <MatchesSelection /> : null}
                    <CleanDataMasterButton isPro={this.props.isPro}/>
                </div>
                <br/>
                {creditsMessage}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stepsCompleted: state.mainState.stepsCompleted
})

export default connect(mapStateToProps, null)(EnoughCredits)