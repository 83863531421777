export const getColumnValuesForSelectedColumns = (selectedColumns, fileInformation) => {
    const samples = 'samples' in fileInformation ? fileInformation['samples'] : null;
    let allColumnValues = []

    if (samples !== null){
        for (let i=0; i<selectedColumns.length; i++){
            let columnValues = []

            for (let j=0; j<samples.length; j++){
                columnValues.push(samples[j][selectedColumns[i]])
            }

            allColumnValues.push(columnValues)
        }
    }

    return allColumnValues
}