import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyStatus} from "../../../Actions/ModifyStatus";

class DownloadActionNotTaken extends Component {
    constructor(props) {
        super(props);

        this.changeStatus = this.changeStatus.bind(this);
    }

    changeStatus(e, status){
        this.props.modifyStatus(status)
    }


    render() {
        let body = null;

        if (this.props.filePK === -1){
            body =
                <div>
                    <h4>You need to Upload a File before you can Download</h4>
                    <Button content="Upload a File" icon="file alternate outline" size="massive" onClick={(e) => this.changeStatus(e, "upload")}/>
                </div>
        } else {
            if (this.props.cleanResults.length === 0){
                body =
                    <div>
                        <h4>You need to Clean Your Data before Downloading</h4>
                        <Button content="Clean Your Data" icon="cogs" size="massive" onClick={(e) => this.changeStatus(e, "clean")}/>
                    </div>
            }
        }

        return (
            <div id="fileInfoDiv" className="cleanActionNotTaken">
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    cleanResults: state.mainState.cleanResults
})

const mapActionsToProps = {
    modifyStatus: modifyStatus
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadActionNotTaken)