import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import CurrencyChangeDropdown from "../../SharedComponents/CurrencyChangeDropdown"
import MonthlyCredits from "../../InfoPages/Profile/Credits/MonthlyCredits";

class MonthlyPricingModal extends Component {
    render() {
        return (
            <ModalApp
                name="monthlyPricingModal"
                header="Monthly Plans"
                body={<div>
                    <b>Currency</b> <CurrencyChangeDropdown />
                    <br/>
                    <MonthlyCredits fetching={false}/>
                    <br/><br/><br/>
                </div>}
                size="full"
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(MonthlyPricingModal)