import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../../SharedComponents/Modal"
import PricingPageBlockFeatureModalBody from "./PricingPageBlockFeatureModalBody";

class PricingPageBlockFeatureModal extends Component {
    render() {
        let dataType = "address";

        if (this.props.modalInfo !== null){
            if ("dataType" in this.props.modalInfo){
                dataType = this.props.modalInfo['dataType']
            }
        }

        return (
            <ModalApp
                name="pricingPageBlockFeatureModal"
                header={dataType.charAt(0).toUpperCase() + dataType.substring(1, dataType.length) + " Pro Clean vs Basic Clean"}
                body={<PricingPageBlockFeatureModalBody dataType={dataType} />}
                size="large"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PricingPageBlockFeatureModal)