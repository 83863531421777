import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import {Popup, Icon} from 'semantic-ui-react'
import copy from 'copy-to-clipboard';
import ResetKey from './ResetKey';
import {toggleModal} from "../Actions/ToggleModal";


class AccessKey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: "Copy Key",
            resetPopupOpen: false,
            fetchingAPIKeys: false
        };

        this.copyURL = this.copyURL.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.openSupportModal = this.openSupportModal.bind(this);
        this.isFetchingAPIKeys = this.isFetchingAPIKeys.bind(this);
    }

    isFetchingAPIKeys(fetchingAPIKeys){
        this.setState({ fetchingAPIKeys: fetchingAPIKeys})
    }

    togglePopup(e, toggle){
        this.setState({
            resetPopupOpen: toggle
        })
    }

    copyURL(e){
        copy(this.props.apiKeys.accessKey);

        this.setState({
            popup: "Copied!"
        })
    }

    openSupportModal(e){
        this.props.toggleModal("supportModal")
    }

    render() {
        let apiKeyValue;
        let copyButton;

        if (this.state.fetchingAPIKeys){
            apiKeyValue = <Loader
                type="Puff"
                color="black"
                height="25"
                width="25"
            />
            copyButton = null;

        }
        else{
            if (this.props.apiKeys !== null){
                apiKeyValue = <span><b>{this.props.apiKeys.accessKey}</b></span>
                copyButton =  <Popup content={this.state.popup}
                                     trigger={<Icon name="copy" onClick={this.copyURL} style={{cursor: 'pointer', color: 'green', marginLeft: '5px'}}/>}/>

            }
            else{
                apiKeyValue = <span>Sorry there was an error getting or resetting your API key. Please <span className="linkStyle" onClick={this.openSupportModal}> Contact Our Support</span> </span>
                copyButton = null;
            }
        }


        return (
            <div>
                <h2 className="thinHeading">Your API Key</h2>
                <div className="row">
                    <div className="col-sm-6">
                        <span>
                            {apiKeyValue}
                            {copyButton}
                        </span>
                    </div>
                    <div className="col-sm-6">
                        <Popup flowing
                               on='click'
                               trigger={<p style={{cursor: 'pointer', color: 'darkblue', float: 'right'}}><Icon name="redo" /> Reset Key</p>}
                               content={<ResetKey isFetchingAPIKeys={this.isFetchingAPIKeys} togglePopup={this.togglePopup}/>}
                               open={this.state.resetPopupOpen}
                               onOpen={(e) => this.togglePopup(e, true)}
                               onClose={(e) => this.togglePopup(e, false)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    apiKeys: state.mainState.apiKeys,
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(AccessKey)