import React, {Component} from 'react'
import CommentSection from "../CommentSection";

class QuickstartGuide extends Component {
    render() {
        return (
            <div>
                <h4>1. Upload a Spreadsheet or CSV file </h4>
                <p>Click on the Upload File box and Select a File to Clean</p>
                <img  style={{width: '100%',margin: '0 auto', display: 'block'}}
                      src="https://images2.imgbox.com/7f/74/vIEGKgYx_o.png"
                      alt="upload file box"/>
                <br/>
                <h4>2. Preview & Edit your File (if needed)</h4>
                <p>Your file should have your column names on the first row. If it doesn't, then</p>
                <ol>
                    <li>you can edit your file to read starting another row</li>
                    <li>you can edit your file to read without headers</li>
                    <li>you can read another sheet if it's an excel file</li>
                </ol>
                <p>Click on the edit button to show options</p>
                <img src="https://images2.imgbox.com/bc/d1/rFy6aiZc_o.png" alt="edit file"/>
                <br/><br/>
                <p>Select options until preview has accurate column names. You can also update it in your file</p>
                <img src="https://images2.imgbox.com/0e/5f/GO9AR2V8_o.png" alt="edit options"/>
                {/*<img style={{width: '100%'}} src="h"*/}
                <br/><br/>
                <h4>3. Select what Data Type you want to clean</h4>
                <p>Remember you can keep adding data types and columns to clean in the same session</p>
                <p>For this example, let's start off with Address. </p>
                <img src="https://images2.imgbox.com/c6/a1/DtBPrCax_o.png" alt="select data type"/>
                <br/><br/>
                <p>In this file my address was split across components and I need to forward geocode. Click Confirm to add your column format to the cleaning</p>
                <img src="https://images2.imgbox.com/f0/0c/WH6DRQc2_o.png" alt="address options"/>
                <br/><br/>
                <h4>4. Select more Data Types (if you want)</h4>
                <p>You can keep selecting more data types. Let's say you also need to clean a Date column</p>
                <img src="https://images2.imgbox.com/da/f2/fDpgVymF_o.png" alt="date selection" style={{margin: '0 auto', display: 'block',width:"100%"}}/>
                <br/><br/>
                <h4>5. Select the column(s) you need to clean</h4>
                <p>Toggle on the column(s) you need to clean. You can select multiple columns at a time</p>
                <img src="https://images2.imgbox.com/04/ec/7qxsFKsT_o.png" alt="select column"/>
                <br/><br/>
                <h4>6. Look at your Cleaning Steps</h4>
                <p>Your cleaning selections are displayed. You can remove a selection if you want.</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/cleaningsteps.png"/>
                    <br/><br/>
                    <h4>7. Review & Clean your File</h4>
                <p>Click the Review & Clean button. This will show you a sample of your cleaned data as well as your credits</p>
                        <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/done.png"/>
            <br/><br/>
                    <h4> 8. Clean Your Data </h4>
                    <p>Before downloading your file you can see a sample of what your cleaned data will look like. If you made a mistake or want to something else go back to the "Upload a File" button on the left side of the page or reset it all. If you're good, hit "Clean Your Data" and move forward!</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/cleandata.png" style={{width:'150%',margin: '0 auto', display: 'block'}}/>
                    <br/><br/>
                    <h4>9. Download Data </h4>
                    <p>You can either download the cleaned columns separately by clicking on the column under "Select Cleaning Steps" and the button Download Your Cleaned Column. Or if you clean "Download Your Cleaned File" you will get a file with all your cleaned data together.  </p>
                    <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/download.png" style={{width:'150%',margin: '0 auto', display: 'block'}}/>
                    <br/><br/>
                    <h4>10. Enjoy Your Cleaned Data!</h4>
                    <p>If you still have questions, don’t hesitate to reach out to us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a></p>
                    <p> Happy Cleaning! :)</p>
                    <CommentSection commentDiv={<div className="fb-comments" data-href="https://cleanspreadsheets.com/guides/clean-spreadsheets-quickstart" data-numposts="5" data-width="" />}/>

            </div>

        )
    }
}

export default  QuickstartGuide