import React, {Component} from 'react'

class LandingPagePricingIndexFreeRow extends Component {
    render() {
        return (
            <h2 className="landingPagePricingIndexFreeRow"><span>Start off with 250 FREE <b style={{color: "orangered"}}>PRO</b> credits</span></h2>
        )
    }
}

export default LandingPagePricingIndexFreeRow