import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import PaymentBodyModal from "./PaymentBodyModal"

class PaymentModal extends Component {
    render() {
        return (
            <ModalApp
                name="paymentModal"
                bodyColor="#0081ff"
                header={null}
                body={<PaymentBodyModal />}
                size="medium"
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PaymentModal)