import React, {Component} from 'react'
import images from "../../../Constants/Images";
import FAQBlock from "../FAQBlock";
import CompanyExample from "../../../LandingPage/DetailExamples/CompanyExample"

const companyFAQ = [
    {question: 'Can you really clean worldwide company names?', answer: 'Yes! We can clean any worldwide company names. '},
    {question: 'Really?', answer: "Yes! Especially companies from G-20 countries. Outside of the G-20 we sometimes have issues so feel free to test out your data using the 250 free credits and see if this works for you."},
    {question: 'How does the final output look like?', answer: <div>The final output from cleaning company names will look like the following <br/> <br/> <CompanyExample faq/> </div>},
    {question: "What if my value isn't a company?", answer: "If your value hit an error. Then the Cleaned Value and the components will be completely blank."},
]

class Company extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <FAQBlock
                        title="Company" icon={images.companyIcon}
                        FAQs={companyFAQ}
                        dataType
                    />
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default Company