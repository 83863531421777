import React, {Component} from 'react'
import images from "../../Constants/Images";
import { Image, Input, Button, Message } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import FormattedResults from "../../Dashboard/UpdatedDashboard/Download/DisplayResults/FormattedResults.js"
import cleanDataTypes from "../../Constants/cleanDataTypes";
import { Menu, Dropdown } from 'semantic-ui-react'
import dataTypeInfo from "../../Constants/dataTypeInfo";
import { isMobile, isBrowser } from "react-device-detect";

class LiveDemoIndex extends Component {
    constructor(props){
        super(props)

        this.state = {
            uploadDataType: "address",
            addressType: "forward",
            inputValue: "",
            latLng: {lat: "", lng: ""},
            cleaning: false,
            results: null,
            error: false,
            resultType: null,
            currencyCode: "usd",
            currencyNumberFormat: "dotDecimalCommaThousands"
        }

        this.changeDataType = this.changeDataType.bind(this);
        this.getClassName = this.getClassName.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeLatLng = this.changeLatLng.bind(this);
        this.cleanData = this.cleanData.bind(this);
        this.updateCurrencyCode = this.updateCurrencyCode.bind(this);

        this.changeCurrencyNumberFormat = this.changeCurrencyNumberFormat.bind(this);
        this.changeDataTypeDropdown = this.changeDataTypeDropdown.bind(this);
    }

    changeCurrencyNumberFormat(currencyNumberFormat){
        this.setState({ currencyNumberFormat: currencyNumberFormat })
    }

    updateCurrencyCode(currencyCode){
        this.setState({ currencyCode: currencyCode })
    }

    changeDataType(cleanType){
        this.setState({ uploadDataType: cleanType})
    }

    getClassName(dataType){
        if (this.state.uploadDataType === dataType){
            return "segment-raised-rounded helpSectionButton helpSectionButtonSelected"
        }
        else{
            return "helpSectionButton"
        }
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeLatLng(e){
        const currLatLng = JSON.parse(JSON.stringify(this.state.latLng))

        if (e.target.name === "inputValueLat"){
            currLatLng.lat = e.target.value
        } else {
            currLatLng.lng = e.target.value
        }

        this.setState({ latLng: currLatLng })

    }

    cleanData(e){
        this.setState({ cleaning: true })

        const endCleaning = () => this.setState({ error: true, cleaning: false })

        const url = this.state.uploadDataType + (this.state.uploadDataType === "address" ? this.state.addressType : "") + "/"
        let value = this.state.inputValue.trim();

        if (this.state.uploadDataType === "address" && this.state.addressType === "reverse"){
            value = this.state.latLng.lat.trim() + "|" + this.state.latLng.lng.trim()
        }

        let options = null;

        // if (this.state.uploadDataType === "currency"){
        //     options = {
        //         currencyCode: this.state.currencyCode,
        //         currencyNumberFormat: this.state.currencyNumberFormat
        //     }
        // }

        fetch(API_Root + "api/clean-demo/" + url, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                value: value,
                options: options
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then(
                    (data) =>
                    {
                        this.setState({ results: data.results, cleaning: false, resultType: url.substring(0, url.length-1)})
                    }).catch(err => endCleaning())
            }
        }).catch(err => endCleaning())

    }

    changeDataTypeDropdown(event, { value }){
        this.setState({ uploadDataType: value })
    }

    render() {
        let addressOptions = null;
        let valueInput = <Input fluid name="inputValue" onChange={this.handleInputChange} value={this.state.inputValue} placeholder="Enter Dirty Value Here"/>
        if (this.state.uploadDataType === "address") {
            if (this.state.addressType === "reverse") {
                valueInput = <div className="row">
                    <div className="col-sm-6">
                        <b>Latitude</b>
                        <Input fluid name="inputValueLat" onChange={this.changeLatLng} value={this.state.latLng.lat}
                               placeholder="Enter Your Latitude"/>
                    </div>
                    <div className="col-sm-6">
                        <b>Longitude</b>
                        <Input fluid name="inputValueLng" onChange={this.changeLatLng} value={this.state.latLng.lng}
                               placeholder="Enter Your Longitude"/>
                    </div>
                </div>
            }

            addressOptions =
                <div>
                    <h4 style={{color: '#0081ff'}}>Select Geocoding Preference</h4>
                    <div className="row">
                        <div className="col-sm-6">
                            <input type="radio" onClick={this.handleInputChange} name="addressType" value="forward"
                                   id="addressTypeLandingForward" checked={this.state.addressType === "forward"}/>
                            <label id="addressTypeLandingForwardLabel" htmlFor="addressTypeLandingForward">Forward
                                Geocode</label>
                        </div>
                        <div className="col-sm-6">
                            <input type="radio" onClick={this.handleInputChange} name="addressType" value="reverse"
                                   id="addressTypeLandingReverse" checked={this.state.addressType === "reverse"}/>
                            <label id="addressTypeLandingReverseLabel" htmlFor="addressTypeLandingReverse">Reverse
                                Geocode</label>
                        </div>
                    </div>
                    <br/><br/>
                </div>
        } else if (this.state.uploadDataType === "notes"){
            valueInput = <textarea name="inputValue" onChange={this.handleInputChange} value={this.state.inputValue} className="textAreaNoShadow" placeholder="Enter your notes" rows={5} style={{width: "100%", border: "1 px whitesmoke", borderRadius: "5px", boxShadow: "none", resize: "none"}}/>
        }

        let resultTable;

        if (this.state.results === null){
            if (this.state.error){
                resultTable = <Message header="There was an error cleaning your value" content="Please try again with another value" negative={true}/>
            } else {
                resultTable = <Message header="Your cleaning results will appear here" positive={true}/>
            }
        } else {
            resultTable = <FormattedResults cleanType={this.state.resultType.includes("address") ? "address" : this.state.resultType}
                                            isSample={true}
                                            isPro={true}
                                            cleanedSource={this.state.resultType.includes("address") ? {type: this.state.resultType.includes("reverse") ? "reverseAddress" : "forwardAddress"} : {type: "selectedColumn"}}
                                            results={this.state.results} />
        }

        let menuItems;
        let menu;

        if (isBrowser){
            menuItems = cleanDataTypes.map((x, idx) =>
                <Menu.Item style={{fontSize: '115%'}} name={x} active={this.state.uploadDataType === x} key={idx} onClick={(e) => this.changeDataType(x)}>
                    <Image src={images[x + "Icon"]} avatar /><span>{x.charAt(0).toUpperCase() + x.substring(1, x.length)}</span>
                </Menu.Item>)

            menu = <Menu pointing vertical secondary>
                {menuItems}
            </Menu>
        } else {
            menuItems = cleanDataTypes.map((x, idx) => { return { key: idx, value: x, text: <span><Image src={images[x + "Icon"]} avatar /><span>{x.charAt(0).toUpperCase() + x.substring(1, x.length)}</span></span>} })

            menu =
            <div style={{margin: "0 auto", width: "50%", marginBottom: "50px"}}>
                <h4 style={{textAlign: "center"}}>Choose a Data Type</h4>
                <Dropdown
                    placeholder="Select Data Type to Demo"
                    name="DataTypeCleaning"
                    value={this.state.uploadDataType}
                    options={menuItems}
                    onChange={this.changeDataTypeDropdown}
                    // fluid
                    selection
                />
            </div>
        }


        return (
            <div style={{marginTop: "50px"}}>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-2">
                        {menu}
                    </div>
                    <div className="col-sm-8">
                        <div className="liveDemoBox">
                            <h2 className="thinHeading">{this.state.uploadDataType.charAt(0).toUpperCase() + this.state.uploadDataType.substring(1, this.state.uploadDataType.length)}</h2>
                            <p style={{color: "grey"}}>{dataTypeInfo[this.state.uploadDataType].desc}
                                {
                                    dataTypeInfo[this.state.uploadDataType].sampleValues.length > 0 ?
                                        <span> like {dataTypeInfo[this.state.uploadDataType].sampleValues.map((x, idx) => <span key={idx}><b>{x}</b>{idx === 0 ? " and " : null}</span>)}</span> :
                                    null
                                }
                            </p>
                            <br/>
                            {addressOptions}
                            <h4 style={{color: '#0081ff'}}>Enter Your Value to Clean</h4>
                            {valueInput}
                            <br/><br/>
                            <Button loading={this.state.cleaning} content="Clean your Data" icon="cogs" color="green" size="large" fluid onClick={this.cleanData}/>
                            <br/><br/>
                            <div className="landingPageDemoResults">
                                {resultTable}
                            </div>
                        </div>
                        <br/><br/>
                    </div>
                    </div>
                    <div className="col-sm-1" />
            </div>
        )
    }
}

export default LiveDemoIndex