import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTotalCleanValues} from "../../../../Constants/getTotalCleanValues";
import EnoughCredits from "./EnoughCredits";
import NotEnoughCredits from "./NotEnoughCredits"
import addCommaToNumbers from "../../../../Constants/addCommaToNumbers";
import isObjectEmpty from "../../../../Constants/isObjectEmpty";
import NotEnoughCreditsSubscription from "./NotEnoughCreditsSubscription"
import MonthlyAccountInfo from "./MonthlyAccountInfo";
import UpgradeMonthly from "./UpgradeMonthly";
import monthlyPricing from "../../../../Constants/monthlyPricing";

class CleanPayment extends Component {
    constructor(props){
        super(props);

        this.state = {
            showType: 'monthly'
        }

        this.changeShowType = this.changeShowType.bind(this);
    }

    changeShowType(showType){
        this.setState({ showType: showType })
    }


    render() {
        if (this.props.selectedColumns.length === 0){
            return(
                null
            )
        }
        else{
            const columnInfo = 'columnInfo' in this.props.fileInformation ? this.props.fileInformation['columnInfo'] : null;
            const totalCleanValues = getTotalCleanValues(this.props.selectedColumns, columnInfo, this.props.fileInformation['columnNames'], this.props.columnCleanTypes, this.props.columnCleanInfo)

            let color = "green";

            let userCredits;

            if (this.props.isPro){
                userCredits = this.props.userCredits.pro;
            } else {
                userCredits = this.props.userCredits.basic;
            }

            if (userCredits < totalCleanValues  || userCredits < 100){
                color = "red"
            }
            else if (userCredits < 1000 && userCredits >= 100){
                color = "orange"
            }

            // 1. Enough Credits to pay - show how many will be gone after
            // 2. Not enough credits to pay - Refill. After refilling modal closes to screen and screen 1 pops up

            // let userCredits = this.props.userCredits;

            let planType = "free"
            let planIdx = -1

            // if (!isObjectEmpty(this.props.subscriptionInfo)){
            //     if ('subscription' in this.props.subscriptionInfo){
            //         if (this.props.subscriptionInfo.subscription){
            //             planType = this.props.subscriptionInfo['planType']
            //             planIdx = this.props.subscriptionInfo['planIdx']
            //             userCredits = this.props.subscriptionInfo['creditsLeft']
            //
            //         }
            //     }
            // }

            const enoughCredits = userCredits >= totalCleanValues;
            const isMonthly = planIdx !== -1;

            let body1 = null;
            let body2 = null;

            // if (isMonthly){
            //     body1 = <EnoughCredits planType={planType} planIdx={planIdx} userCredits={userCredits} totalCleanValues={totalCleanValues} />
            //     body2 = <MonthlyAccountInfo planType={planType} planIdx={planIdx} userCredits={userCredits} totalCleanValues={totalCleanValues} />
            // } else {
                if (enoughCredits){
                    body1 = <EnoughCredits isPro={this.props.isPro}
                                           planType={planType}
                                           planIdx={planIdx}
                                           userCredits={userCredits}
                                           totalCleanValues={totalCleanValues}/>
                    // body2 = <UpgradeMonthly enoughCredits={enoughCredits} totalCleanValues={totalCleanValues}/>
                } else {
                    body1 = <NotEnoughCredits isPro={this.props.isPro} showType={this.state.showType} changeShowType={this.changeShowType} userCredits={userCredits} totalCleanValues={totalCleanValues} />
                    // body2 = <UpgradeMonthly changeShowType={this.changeShowType} showType={this.state.showType} enoughCredits={enoughCredits} totalCleanValues={totalCleanValues}/>
                }
            // }

            return (
                <div>
                    <div className="cleanPaymentDiv">
                        <h2 className="thinHeading" style={{textAlign: 'center'}}>This will take<br/><span className="userCredits bigNumber" style={{color: "black"}}>{addCommaToNumbers(totalCleanValues)}</span><br/>{this.props.isPro ? "Pro" : "Basic"} Credits</h2>
                        <div id="cleanPaymentActionDiv">
                            {body1}
                        </div>
                    </div>
                    <br/><br/>
                    {/*{body2}*/}
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    userCredits: state.mainState.userCredits,
    selectedColumns: state.mainState.selectedColumns,
    fileInformation: state.mainState.fileInformation,
    columnCleanTypes: state.mainState.columnCleanTypes,
    columnCleanInfo: state.mainState.columnCleanInfo,
    subscriptionInfo: state.mainState.subscriptionInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CleanPayment)