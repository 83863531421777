export const getCleanValuesForStep = (stepInfo, columnInfo) => {
    let colIndicesInStep = stepInfo['colInfoIdx']

    let maxLength = 0;

    for (let i=0; i<colIndicesInStep.length; i++){
        let colLength = columnInfo[colIndicesInStep[i]]['unique']

        if (colLength > maxLength){
            maxLength = colLength
        }
    }

    return maxLength
}