import React, {Component} from 'react'
import LandingPagePricingCard from "./LandingPagePricingCard";
import { Icon } from 'semantic-ui-react';
import LandingPagePricingIndexFreeRow from "../../SharedComponents/LandingPagePricingIndexFreeRow";

class LandingPagePricingIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="socialProofIndex">
                <div style={{textAlign: "center"}}>
                    <h1 className="thinHeading" style={{fontSize: "450%", fontFamily: "museo-sans, sans-serif", fontStyle: "normal", fontWeight: "bold"}}>Pricing</h1>
                    <LandingPagePricingIndexFreeRow/>
                    {/*<h2>Then just <b style={{color: "#0081ff"}}>$10</b> for <b style={{color: "#0081ff"}}>10,000</b> credits</h2>*/}
                    <div className="landingPagePricingIndexRow">
                        <div className="row">
                            <div className="col-sm-3">
                                <LandingPagePricingCard
                                    desc="Pay-As-You-Go Credits"
                                    img="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/CleanLanding_PricingIcons/Pricing_PayAsYouGo.svg"
                                />
                            </div>
                            <div className="col-sm-3">
                                <LandingPagePricingCard
                                    desc="Pro & Basic Credits"
                                    img="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/CleanLanding_PricingIcons/Pricing_ProVsBasic.svg"
                                />
                            </div>
                            <div className="col-sm-3">
                                <LandingPagePricingCard
                                    desc="No Monthly Subscription"
                                    img="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/CleanLanding_PricingIcons/Pricing_NoMonthlyIcon.svg"
                                />
                            </div>
                            <div className="col-sm-3">
                                <LandingPagePricingCard
                                    desc="Refer for Free Credits"
                                    img="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/CleanLanding_PricingIcons/Pricing_ReferAFriend.svg"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="landingPagePricingIndexFreeRow">
                        <span><b style={{color: "orangered"}}>PRO</b> credits provide more accurate cleaning & data enrichment vs <b style={{color: "orangered"}}>BASIC</b> credits</span>
                        <div>
                            <a href="/pricing" target="_blank" rel="noopener noreferrer">Read all about our PRO vs BASIC credit pricing <Icon name="arrow right" /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LandingPagePricingIndex