import React, {Component} from 'react'
import ExampleTable from './ExampleTable'

class SentimentDetails extends Component {
    render() {
        const defectInfo = {type: 'sentimentCleaning', rows: {
                values: ['I had an amazing meal', 'This was just an okay experience', 'I had a terrible time'],
                suggestions: ['positive', 'average', 'negative']
            }
        }

        return (
            <div>
                <h3 className="thinHeading">Convert and Standardize Currency</h3>
                <br/>
                <ExampleTable defectInfo={defectInfo}/>
            </div>
        )
    }
}

export default SentimentDetails