import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import SignUpModalBody from './SignUpModalBody'

class SignUpModal extends Component {
    render() {
        return (
            <ModalApp
                name="signupModal"
                bodyColor="#0E4D92"
                header={null}
                body={<SignUpModalBody/>}
                size="medium"
            />
        )
    }
}

export default SignUpModal