import React, {Component} from 'react'
import GuideDetailsObject from "./GuideDetailsObject";
import TutorialShare from "./TutorialShare";
import {BrowserView} from "react-device-detect";
import GuidesCard from "./GuidesCard";

class GuidesPostIndex extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        const guideInfo=GuideDetailsObject[id];

        document.title = guideInfo.title
        document.getElementsByTagName("meta")["description"].content = guideInfo.desc
    }

    render() {
        const { id } = this.props.match.params;

        return (
            <div className="guidesIndex" id="guidesIndex">
                <BrowserView>
                    <TutorialShare url={GuidesCard.url} header={GuideDetailsObject.title}/>
                </BrowserView>
                <div className="guidesDisplayIndex">
                    <div className="guidesDisplayHeader">
                <h1 className="thinHeading" style={{fontSize: '350%'}}>{GuideDetailsObject[id].header}</h1>
                <b>by</b>  {GuideDetailsObject[id].writtenBy} <b>on</b> {GuideDetailsObject[id].writtenDt}
                    </div>
                    <br/>
                    {GuideDetailsObject[id].articleComponent}

            </div>
            </div>

        )
    }
}

export default GuidesPostIndex
