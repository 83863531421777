import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyStatus} from "../../Actions/ModifyStatus";
import { Step, Icon, Popup } from 'semantic-ui-react'
import constants from "../../Constants/constants";

class DashboardProgressBar extends Component {
    constructor(props) {
        super(props);

        this.changeStatus = this.changeStatus.bind(this);
    }

    changeStatus(e, status){
        this.props.modifyStatus(status);
    }

    render() {
        return (
            <div id="dashboardProgressBar">
                <Step.Group width={4} fluid>
                    <Step onClick={(e) => this.changeStatus(e, "upload")} active={this.props.status === "upload"} completed={this.props.stepsCompleted > 0}>
                        <Icon name='file alternate outline' />
                        <Step.Content>
                            <Step.Title>Upload a File</Step.Title>
                            <Step.Description><span>Any <Popup trigger={<span style={{borderBottom: '1px dashed gray'}}>Excel, CSV or Text File</span>} content={constants.extensions.join(",")} /></span></Step.Description>
                        </Step.Content>
                    </Step>
                    <Step onClick={(e) => this.changeStatus(e, "clean")} active={this.props.status === "clean"} completed={this.props.stepsCompleted > 1}>
                        <Icon name='cogs' />
                        <Step.Content>
                            <Step.Title>Clean Data</Step.Title>
                            <Step.Description>Automatically clean your data</Step.Description>
                        </Step.Content>
                    </Step>
                    <Step onClick={(e) => this.changeStatus(e, "download")} active={this.props.status === "download"} completed={this.props.stepsCompleted > 2}>
                        <Icon name='download' />
                        <Step.Content>
                            <Step.Title>Download Data</Step.Title>
                            <Step.Description>Download your cleaned data</Step.Description>
                        </Step.Content>
                    </Step>
                </Step.Group>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    status: state.mainState.status,
    stepsCompleted: state.mainState.stepsCompleted
})

const mapActionsToProps = {
    modifyStatus: modifyStatus
}

export default connect(mapStateToProps, mapActionsToProps)(DashboardProgressBar)