import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'

class CreditCardSelection extends Component {
    constructor(props) {
        super(props);

        this.getCCText = this.getCCText.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    getCCText(ccObject){
        if (ccObject['object'] === "card"){
            return ccObject['brand'] + " ending in " + ccObject['last4'] + ", Expiry: " + ccObject['exp_month'].toString() + "/" + ccObject['exp_year']
        }
        else{
            if (ccObject['object'] === "source"){
                if ('card' in ccObject){
                    return ccObject['card']['brand'] + " ending in " + ccObject['card']['last4'] + ", Expiry: " + ccObject['card']['exp_month'].toString() + "/" + ccObject['card']['exp_year']
                }

            }
        }

    }

    handleDropdownChange(event, { value }){
        this.props.changeCardIdx(value)
    }

    render() {
        let body = null;
        if (this.props.paymentInfo !== null){
            if ('creditCards' in this.props.paymentInfo){
                if (this.props.paymentInfo['creditCards'].length > 0){
                    let dropdownOptions = this.props.paymentInfo['creditCards'].map((x, idx) => { return {key: idx, value: idx, text: this.getCCText(x)} })
                    dropdownOptions.push({key: this.props.paymentInfo.length, value: -1, text: "Add New Card", icon: 'plus circle'})
                    body = <div><Dropdown
                            selection
                            options={dropdownOptions}
                            value={this.props.cardIdx}
                            onChange={this.handleDropdownChange}
                            placeholder="Select a Credit Card"
                            fluid
                    />
                        <br/>
                    </div>
                }
            }
        }

        return (
            <div>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    paymentInfo: state.mainState.paymentInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CreditCardSelection)