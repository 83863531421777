import React, {Component} from 'react'
import { Table, Image } from 'semantic-ui-react'
import Arrow from "./Arrow.svg"

class GeocodingDifference extends Component {
    constructor(props){
        super(props);

        this.state = {
            latLong: [{lat: 40.765259, lng: -73.9887379}, {lat: 48.857415, lng: 2.3590682}, {lat: -22.9335181, lng: -43.1765181}],
            address: ["415 W 52nd Street, New York, NY 10019, USA", "34 Rue des Rosiers, 75004 Paris, France", "Rua Senador Vergueiro Flamengo 11, Flamengo, Rio de Janeiro - RJ, 22230-000, Brazil"]
        }
    }

    render() {
        const geocodingCell = {backgroundColor: 'lightgreen'};

        return (
            <div style={{marginBottom: '50px'}}>
                <h1>Forward Geocoding</h1>
                <div className="row">
                    <div className="col-sm-4">
                        <Table celled compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Address</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.address.map((x, idx) => <Table.Row key={idx}><Table.Cell key={idx}>{x}</Table.Cell></Table.Row>)}
                            </Table.Body>
                        </Table>
                    </div>
                    <div className="col-sm-2">
                        <img src={Arrow} style={{width: '100%', marginTop: '25%'}} alt="transform arrow"/>
                    </div>
                    <div className="col-sm-6">
                        <Table celled compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Address</Table.HeaderCell>
                                    <Table.HeaderCell>Latitude</Table.HeaderCell>
                                    <Table.HeaderCell>Longitude</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>{this.state.address[0]}</Table.Cell>
                                    <Table.Cell style={geocodingCell}>{this.state.latLong[0].lat}</Table.Cell>
                                    <Table.Cell style={geocodingCell}>{this.state.latLong[0].lng}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>{this.state.address[1]}</Table.Cell>
                                    <Table.Cell style={geocodingCell}>{this.state.latLong[1].lat}</Table.Cell>
                                    <Table.Cell style={geocodingCell}>{this.state.latLong[1].lng}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>{this.state.address[2]}</Table.Cell>
                                    <Table.Cell style={geocodingCell}>{this.state.latLong[2].lat}</Table.Cell>
                                    <Table.Cell style={geocodingCell}>{this.state.latLong[2].lng}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </div>
                <br/><br/>
                <h1>Reverse Geocoding</h1>
                <div className="row">
                <div className="col-sm-4">
                    <Table celled compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Latitude</Table.HeaderCell>
                                <Table.HeaderCell>Longitude</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{this.state.latLong[0].lat}</Table.Cell>
                                <Table.Cell>{this.state.latLong[0].lng}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{this.state.latLong[1].lat}</Table.Cell>
                                <Table.Cell>{this.state.latLong[1].lng}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{this.state.latLong[2].lat}</Table.Cell>
                                <Table.Cell>{this.state.latLong[2].lng}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                <div className="col-sm-2">
                    <img src={Arrow} style={{width: '100%', marginTop: '25%'}} alt="transform arrow"/>
                </div>
                <div className="col-sm-6">
                    <Table celled compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Latitude</Table.HeaderCell>
                                <Table.HeaderCell>Longitude</Table.HeaderCell>
                                <Table.HeaderCell>Address</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{this.state.latLong[0].lat}</Table.Cell>
                                <Table.Cell>{this.state.latLong[0].lng}</Table.Cell>
                                <Table.Cell style={geocodingCell}>{this.state.address[0]}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{this.state.latLong[1].lat}</Table.Cell>
                                <Table.Cell>{this.state.latLong[1].lng}</Table.Cell>
                                <Table.Cell style={geocodingCell}>{this.state.address[1]}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{this.state.latLong[2].lat}</Table.Cell>
                                <Table.Cell>{this.state.latLong[2].lng}</Table.Cell>
                                <Table.Cell style={geocodingCell}>{this.state.address[2]}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                </div>
            </div>
        )
    }
}

export default GeocodingDifference