import React, {Component} from 'react'
import DisplayBox from '../DisplayBox'
import ReactJson from 'react-json-view'
import CleanTypeURLs from "../../CleanTypeURLs";

class Request extends Component {
    render() {
        const apiKey = this.props.apiKey === null ? "YOUR_API_KEY" : this.props.apiKey;

        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <p>
                            The API ONLY accepts POST Requests over HTTPS. GET requests will be rejected with a <code>405</code> response code.
                        </p>
                        <br/>
                        <p>
                            Depending on what kind of values you want to clean, you will need to add that clean type as a parameter to the Base URL.
                        </p>
                        <br/>
                        <p>
                            For example, cleaning addresses will mean sending the data to the url <i>https://api-woyera.com/clean/address</i>.
                        </p>
                        <br/>
                        <div className="apiDocSection">
                            <h5>Request Parameters</h5>
                            <p>
                                The Request needs to include a JSON encoded Body with two required parameters: <code>apiKey</code> and <code>data</code>. An optional parameter <code>pro</code> can also be added if you want to do pro or basic cleaning
                            </p>
                            <br/>
                            <p><span className="parameterName">data</span> <span className="parameterType">array</span></p>
                            <p className="parameterSecondLine"><span className="parameterRequired">Required</span></p>
                            <p className="parameterDesc">This is an array of the values that you want to clean.</p>
                            <p className="parameterDesc">The values to clean should be in string format. If they are not, there might be some errors in cleaning.</p>
                            <br/>
                            <p><span className="parameterName">apiKey</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine"><span className="parameterRequired">Required</span></p>
                            <p className="parameterDesc">Your API Key, a 32 character alphanumeric string found in your dashboard</p>
                            <br/>
                            <p><span className="parameterName">pro</span> <span className="parameterType">boolean</span></p>
                            <p className="parameterSecondLine"><span className="parameterOptional">Optional</span> Default value: <code>true</code></p>
                            <p className="parameterDesc">Optional to include and defaults to <code>true</code>. Can switch to <code>false</code> for basic cleaning</p>
                            <br/>
                            <p><span className="parameterName">matches</span> <span className="parameterType">int</span></p>
                            <p className="parameterSecondLine"><span className="parameterOptional">Optional</span> Default value: <code>3</code></p>
                            <p className="parameterDesc">Maximum number of matches to return after cleaning each value</p>
                        </div>

                    </div>
                </div>
                <div className="col-sm-6">
                    <CleanTypeURLs header/>
                    <br/>
                    <DisplayBox header="What is an example request?"
                                desc={<div>
                                    <p>Make a POST request to <i>https://api-woyera.com/clean/date</i> to clean the following dates</p>
                                    <ReactJson src={{apiKey: apiKey, pro: false, data: ["Mar 27, 2012", "Awesome Possum"]}} name={null} theme="ocean" displayObjectSize={false}/>
                                </div>}
                                color="green"
                    />
                </div>
            </div>
        )
    }
}

export default Request