import React, {Component} from 'react'
import ColumnNameInfo from "./ColumnNameInfo"
import { Icon } from 'semantic-ui-react'

class MultipleColumnNamesSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isContentShowing: false
        }
    }

    changeSelectedCol = (idx) => {
        this.props.changeSelectedCol(idx)
    }

    showContent = () => {
        this.setState({ isContentShowing: true })
    }

    hideContent = () => {
        this.setState({ isContentShowing: false })
    }

    render() {
        let allInfoBoxes = [];

        for (let i=0; i<this.props.cleanedSourceLen; i++){
            if (i !== this.props.cleanedSourceIdx){
                allInfoBoxes.push(<ColumnNameInfo
                    cleanedSourceIdx={i}
                    isDropdown={true}
                    changeSelectedCol={this.changeSelectedCol}/>)
            }
        }

        const multipleColumNamesDropdownContentStyle = {
            position: "absolute",
            backgroundColor: "#f9f9f9",
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
            zIndex: "1",
            width: "100%"
        }

        if (this.state.isContentShowing){
            multipleColumNamesDropdownContentStyle["display"] = "block"
        } else {
            multipleColumNamesDropdownContentStyle["display"] = "none"
        }


        return (
            <div>
                <div className="multipleColumnNamesDropdown">
                    <ColumnNameInfo
                        isDropdown={false}
                        cleanedSourceIdx={this.props.cleanedSourceIdx}
                    />
                    <div className="multipleColumnNamesDropdownBtn" onMouseEnter={this.showContent} onMouseLeave={this.hideContent}>
                        <p className="multipleColumnNamesHeading"> <Icon name="caret square down" /> Select another column to view sample</p>
                    </div>
                    <div style={multipleColumNamesDropdownContentStyle} onMouseEnter={this.showContent} onMouseLeave={this.hideContent}>
                        {allInfoBoxes}
                    </div>
                </div>
            </div>
        )
    }
}

export default MultipleColumnNamesSelection