import React, {Component} from 'react'
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

class PythonCodeSnippet extends Component {
    constructor(props){
        super(props);

    }

    render() {
        const apiKey = this.props.apiKey === null ? "YOUR_API_KEY" : this.props.apiKey

        const startCode = 'from woyera import WoyeraAPI\n\napi = WoyeraAPI("' + apiKey + '")\n\n';

        const data = {
            name: '"Dr. Martin Luther King Jr.", "Stephen A. Smith", "Washington, George"',
            address: '"32 London Bridge St, London, United Kingdom","413 w 52nd Street, New York, 10019", "624 Brush St, Detroit, MI 48226"',
            date: '"Jan 23, 1981", "11/15/12 2:30 PM", "2 February, 2017 15:45:43"',
            company: '"Glaxosmithkline Pharma gmbh", "apple inc", "Infosys Ltd."',
            email: '"joecool@gmail.com", "khaleesi@got.org", "monkey bananas", "first.last@somecompany.org"'
        }
        const sampleData = 'response = api.clean(clean_type="' +
            this.props.cleanType + '", data=[' + data[this.props.cleanType] + '])\n\n';

        const successCode = 1;

        let endCode = 'if response["status_code"] == 200:\n\t# Get formatted data if there is no error. If error there will be a blank ""\n\tresults = response["cleanData"]\n\tformatted_data = [x["formatted"] if not x["error"] else "" for x in results]\n'
        endCode += 'else:\n\t# Handle Error Here\n\terror_message = response["error"]\n\n'

        return (
            <div>
                <AceEditor
                    mode="python"
                    theme="monokai"
                    name="pythonQuickstartGuide"
                    value={startCode + sampleData + endCode}
                    editorProps={{ $blockScrolling: true }}
                    width='100%'
                    height='350px'
                />
            </div>
        )
    }
}

export default PythonCodeSnippet