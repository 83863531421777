import React, {Component} from 'react'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import { Message, Table } from 'semantic-ui-react'

class DemoResponseTable extends Component {
    render() {
        if (this.props.error || isObjectEmpty(this.props.cleanResponse)){
            return (
                null
            )
        }
        else{
            let body = null;

            if (this.props.cleanResponse.error){
                body = <Message header="There was an error cleaning this value"
                                content={this.props.cleanResponse.errorMessage}
                                negative />
            } else {
                let splitTable = null;

                if ('split' in this.props.cleanResponse){
                    let tableRows = [];

                    const splitResponse = this.props.cleanResponse.split;
                    let idx = 0;

                    for (let splitName in splitResponse){
                        tableRows.push(
                            <Table.Row key={idx}>
                                <Table.Cell>{splitName}</Table.Cell>
                                <Table.Cell>{splitResponse[splitName]}</Table.Cell>
                            </Table.Row>
                        )

                        idx += 1
                    }

                    if ('lat' in this.props.cleanResponse){
                        tableRows.push(
                            <Table.Row key={idx}>
                                <Table.Cell>latitude</Table.Cell>
                                <Table.Cell>{this.props.cleanResponse['lat']}</Table.Cell>
                            </Table.Row>
                        )

                        idx += 1

                        tableRows.push(
                            <Table.Row key={idx}>
                                <Table.Cell>longitude</Table.Cell>
                                <Table.Cell>{this.props.cleanResponse['lng']}</Table.Cell>
                            </Table.Row>
                        )
                    }

                    splitTable =
                    <div>
                        <br/>
                        <Table celled compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Component</Table.HeaderCell>
                                    <Table.HeaderCell>Value</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {tableRows}
                            </Table.Body>
                        </Table>
                    </div>
                }


                if ('onlyTable' in this.props){
                    body =
                        <div>
                            {splitTable}
                        </div>
                }
                else{
                    if ('valid' in this.props.cleanResponse){
                        let validResponse;

                        if(this.props.cleanResponse.valid === "VALID"){
                            validResponse = <Message header="VALID" content="This email is real and properly formatted" positive/>
                        }
                        else if (this.props.cleanResponse.valid === "INVALID"){
                            validResponse = <Message header="INVALID" content="This email either does not exist or is improperly formatted" negative/>
                        }
                        else{
                            validResponse = <Message header="VALID (ACCEPT ALL)" content="This domain accepts emails on any username" warning/>
                        }



                        body =
                            <div>
                                <div className="row">
                                    <div className="col-sm-8">
                                        {validResponse}
                                    </div>
                                    <div className="col-sm-4" />
                                </div>
                                {splitTable}
                                <br/>
                            </div>
                    }
                    else{
                        body =
                            <div>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <Message header="Formatted Value" content={this.props.cleanResponse.formatted} positive/>
                                    </div>
                                    <div className="col-sm-4">
                                        <Message header="Confidence Score" content={this.props.cleanResponse.score.toString() + "%"} positive/>
                                    </div>
                                </div>
                                {splitTable}
                                <br/>
                            </div>
                    }
                }
            }
            return (
                <div>
                    {body}
                </div>
            )
        }
    }
}

export default DemoResponseTable