import React, {Component} from 'react'
import EditOptions from './EditOptions'
import ImportSheetsBody from './ImportSheetsBody'
import { connect } from 'react-redux'
import EditFileReadButton from './EditFileReadButton'

class EditFileBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            newSheet: this.props.sheetRead
        };

        this.updateSheetToRead = this.updateSheetToRead.bind(this);
    }

    updateSheetToRead(updateSheetName){
        this.setState({
            newSheet: updateSheetName
        })
    }


    render() {
        const isCSV = !this.props.extension.includes("xls");
        const newSheet = this.state.newSheet;

        if (isCSV)
        {
            return (
                <div style={{marginLeft: '5%'}}>
                    <EditOptions isCSV={isCSV} />
                    <br/>
                    <EditFileReadButton newSheet={newSheet} />
                    <br/><br/>
                </div>
            )
        }
        else{
            return(
                <div id="editFileModalBody">
                    <div id="xlsEditOptionsDiv">
                        <EditOptions isCSV={isCSV} />
                        <br/>
                        <EditFileReadButton newSheet={newSheet} />
                        <br/><br/>
                    </div>
                    <div>
                        <ImportSheetsBody newSheet={newSheet} updateSheetToRead={this.updateSheetToRead}/>
                        <br/>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(EditFileBody)