import React, {Component} from 'react'
import {connect} from 'react-redux'
import UpgradeButton from "./UpgradeButton"
import monthlyPricing from "../../../../Constants/monthlyPricing";
import AccountInfo from "./AccountInfo"
import {toggleModal} from "../../../../Actions/ToggleModal";

class UpgradeMonthly extends Component {
    constructor(props) {
        super(props);

        this.otherPlans = this.otherPlans.bind(this);
    }

    otherPlans(e){
        this.props.toggleModal("monthlyPricingModal")
    }

    render() {
        let pricingObjects = monthlyPricing[this.props.currency];

        let planIdx = pricingObjects.length - 1;
        let min = 0;
        for (let i=0; i<pricingObjects.length; i++){
            if (min <= this.props.totalCleanValues <= pricingObjects[i].credits){
                planIdx = i
                break;
            }
            min = pricingObjects[i].credits
        }

        let heading = <h2 className="thinHeading">Don't worry about your credits again</h2>;
        let upgradeButton = <span><br/><UpgradeButton planIdx={planIdx} /></span>;


        if ('showType' in this.props){
            if (this.props.showType === "monthly"){
                heading = null;
                upgradeButton = null;

            }
        }

        return (
            <div className="monthlyAccountInfoBox">
                {heading}
                {upgradeButton}
                <AccountInfo planIdx={planIdx} />
                <br/>
                <div className="monthlyAccountInfoSeeOtherPlans" onClick={this.otherPlans}>
                    <p>See Other Monthly Plans</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeMonthly)