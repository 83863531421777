import React, {Component} from 'react'
import ModalApp from "../../Modal"
import { Icon } from 'semantic-ui-react'
import RefillCreditsModalBody from "./RefillCreditsModalBody"
import { connect } from 'react-redux'

class RefillCreditsModal extends Component {
    render() {
        let isPro = false;

        if (this.props.modalInfo !== null){
            if ('isPro' in this.props.modalInfo){
                isPro = this.props.modalInfo.isPro
            }
        }

        const creditType = isPro ? "Pro" : "Basic"

        return (
            <ModalApp name="refillCreditsModal"
                      header={<span><Icon name="dollar sign" /> Refill {creditType} Credits</span>}
                      size="large"
                      body={<RefillCreditsModalBody />}
                      bodyColor="#F5F5F5"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

export default connect(mapStateToProps, null)(RefillCreditsModal)