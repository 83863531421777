import React, {Component} from 'react';
import withAuthorization from '../../Authentication/withAuthorization';
import CreditsIndex from "./CreditsIndex"

class AuthCreditsIndex extends Component {
    render() {
        return(
            <CreditsIndex />
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthCreditsIndex);
