import React, {Component} from 'react'
import ReactJson from 'react-json-view'
import DisplayBox from "../DisplayBox";
import APIRespBoxHeader from "./APIRespBoxHeader";
import ProLabel from "./ProLabel";

class DateResponse extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPro: true,
            height: 0
        }
    }

    componentDidMount(){
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        let height = 0;
        const div = document.getElementById("apiDateSectionIntro")

        if (div !== null && div !== undefined){
            height = div.offsetHeight;
        }

        this.setState({ height: height })
    }

    changeProToggle = (isPro) => {
        this.setState({ isPro: isPro })
    }

    render() {
        let resp = [{"split":{"year":"1981","month":"01","day":"23","hour":"03","minute":"45","second":"00", "weekdayLong": "Friday", "weekdayShort": "Fri", "monthLong": "January", "monthShort": "Jan", "AM/PM": "AM", "utcOffsetNum": "+0000", "utcOffsetCode": "UTC", "M/D/Y": "01/23/1981", "D/M/Y": "23/01/1981", "D Month Y": "23 January 1981", "Mon D, Y": "Jan 23, 1981", "D-Mon-Y": "23-Jan-1981", "Mon-D-Y": "Jan-23-1981", "HH:MM": "03:45", "HH:MM AM/PM": "03:45 AM"},"formatted":"1981-01-23 03:45:00","score":100,"error":false,"input":"Jan 23 1981, 3:45AM UTC"}]

        if (!this.state.isPro){
            const proColumnKeys = ["weekdayLong", "weekdayShort", "monthLong", "monthShort", "AM/PM", "utcOffsetNum", "utcOffsetCode", "M/D/Y", "D/M/Y", "D Month Y", "Mon D, Y", "D-Mon-Y", "Mon-D-Y", "HH:MM", "HH:MM AM/PM"]
            for (let i=0; i<proColumnKeys.length; i++){
                delete resp['split'][proColumnKeys[i]]
            }
        }

        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <div id="apiDateSectionIntro">
                            <p>Cleaning a date and time value will format it and split it into its components.
                                You can input almost any format of date and our algorithm will clean it.
                                If you input a particular format and it doesn't get cleaned, please <a href="/contact-us">Contact Us</a> and let us know.
                                Cleaning any format of date around the world is a hard task and we would love the help.
                            </p>
                            <br/>
                            <p>
                                If the value you input is not a date, e.g., Cool Beans, you will receive an error.
                            </p>
                            <br/>
                            <p>The split components and format style are listed below and examples can be seen in the sample response. The components will be different based on the <b>Pro</b> & <b>Basic</b> cleaning</p>
                            <br/>
                        </div>
                        <div className="apiDocSection">
                            <h5>Formatted String</h5>
                            <p>The formatted date is a string <i><b>YYYY-MM-DD HH:MM:SS</b></i> where <b>YYYY</b> is the 4 digit full year,
                                <b>MM</b> is the 2 digit month and <b>DD</b> is the 2 digit day. The date components are separated by a hyphen (-).
                            </p>
                            <br/>
                            <p>
                                The time components are <b>HH</b>, the 24 hour time, <b>MM</b>, minute from 00-60 and <b>SS</b>, the seconds from 00-60.
                                The time components are separated by a colon (:). Both date and time components are seperated by a space ( ).
                            </p>
                        </div>
                        <br/>
                        <div className="apiDocSection">
                            <h5>Split Object Components</h5>
                            <p><span className="parameterName">year</span> <span className="parameterType">string</span></p>
                            <p className="parameterDesc">Full 4 digit year</p>
                            <br/>
                            <p><span className="parameterName">month</span> <span className="parameterType">string</span></p>
                            <p className="parameterDesc">2 digit Month of the year with 0 prefixed. E.g., 01 for January, 11 for November</p>
                            <br/>
                            <p><span className="parameterName">day</span> <span className="parameterType">string</span></p>
                            <p className="parameterDesc">2 digit Day of the month with 0 prefixed. E.g., 02 or 21</p>
                            <br/>
                            <p><span className="parameterName">hour</span> <span className="parameterType">string</span></p>
                            <p className="parameterDesc">Hour of the day in 24 hour format. E.g., 04 or 14</p>
                            <br/>
                            <p><span className="parameterName">minute</span> <span className="parameterType">string</span></p>
                            <p className="parameterDesc">Minute of the day with 0 prefixed. E.g., 05 or 55</p>
                            <br/>
                            <p><span className="parameterName">second</span> <span className="parameterType">string</span></p>
                            <p className="parameterDesc">Second of the minute with 0 prefixed. E.g., 03 or 33</p>
                            <br/>
                            <p><span className="parameterName">weekdayLong</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Full name of the day with first letter capitalized. E.g., Sunday or Friday</p>
                            <br/>
                            <p><span className="parameterName">weekdayShort</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Short name of the day with first letter capitalized. E.g., Sun, Mon, Tue, Wed, Thu, Fri, Sat</p>
                            <br/>
                            <p><span className="parameterName">monthLong</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Full name of the month with first letter capitalized. E.g., February or November</p>
                            <br/>
                            <p><span className="parameterName">monthShort</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Short name of the month with first letter capitalized. E.g., Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec</p>
                            <br/>
                            <p><span className="parameterName">AM/PM</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">AM or PM depending on the time</p>
                            <br/>
                            <p><span className="parameterName">utcOffsetNum</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">UTC Offset Numerical code if timezone is found. E.g., +0500, -0430</p>
                            <br/>
                            <p><span className="parameterName">utcOffsetCode</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">3 Letter Timezone code if timezone is found. E.g., EST, UTC</p>
                            <br/>
                            <p><span className="parameterName">M/D/Y</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Date in the MM/DD/YYYY format</p>
                            <br/>
                            <p><span className="parameterName">D/M/Y</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Date in the DD/MM/YYYY format</p>
                            <br/>
                            <p><span className="parameterName">D Month Y</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Date in the DD Month YYYY format. E.g., 23 January 1981</p>
                            <br/>
                            <p><span className="parameterName">Mon D, Y</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Date in the Mon DD, YYYY format. E.g., Jan 23, 1981</p>
                            <br/>
                            <p><span className="parameterName">D-Mon-Y</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Date in the DD-Mon-YYYY format. E.g., 23-Jan-1981</p>
                            <br/>
                            <p><span className="parameterName">Mon-D-Y</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Date in the Mon-DD-YYYY format. E.g., Jan-23-1981</p>
                            <br/>
                            <p><span className="parameterName">HH:MM</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Time in the 24 hour format. E.g., 04:52 or 18:36</p>
                            <br/>
                            <p><span className="parameterName">HH:MM AM/PM</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterDesc">Time in the 12 hour format with AM or PM designation. E.g., 04:52 AM or 06:36 PM</p>
                            <br/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{marginTop: this.state.height}}>
                        <DisplayBox
                            header={
                                <APIRespBoxHeader
                                    header="Jan 23 1981, 3:45AM UTC"
                                    isPro={this.state.isPro}
                                    changeProToggle={this.changeProToggle}
                                />
                            }
                            desc={<div>
                                <ReactJson
                                    src={resp}
                                    name={null}
                                    theme="ocean"
                                    displayObjectSize={false}/>
                            </div>}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default DateResponse