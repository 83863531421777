import React, {Component} from 'react'
import ExampleTable from './ExampleTable'

class CompanyDetails extends Component {
    render() {
        const defectInfo = {type: 'companyDetails', rows: {
                values: ['Glaxosmithkline Pharma gmbh', 'apple incorporated', 'Infosys Ltd.', 'Your Company LLC', 'Accor S.A.', 'barclays plc', 'Deloitte touche Tohmatsu Limited', 'Bloomberg L.P.', 'Woyera, Inc.'],
                suggestions: ['Glaxosmithkline Pharma', 'Apple', 'Infosys', 'Your Company', 'Accor', 'Barclays', 'Deloitte Touche Tohmatsu', 'Bloomberg', 'Woyera'],
                splitComponents: [
                    [["name", "Apple"], ["suffix", "Inc"], ["suffixFull", "Incorporated"]],
                    [["name", "Apple"], ["suffix", "Inc"], ["suffixFull", "Incorporated"]],
                    [["name", "Bloomberg"], ["suffix", "Lp"], ["suffixFull", "Limited Partnership"]]
                ]
            }
        }

        return (
            <div>
                <h3>Standardize Worldwide Company Names</h3>
                <br/>
                <ExampleTable rowAdd={1} defectInfo={defectInfo}/>
            </div>
        )
    }
}

export default CompanyDetails