import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Form, Radio } from 'semantic-ui-react'
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyMatches} from "../../../../Actions/ModifyMatches";

class MatchesSelection extends Component {
    changeMatches = (e, { value }) => this.props.modifyMatches(value)

    openFAQModal = () => this.props.toggleModal("matchesFAQModal")

    render() {
        return (
            <div style={{padding: "0 15px 15px 15px"}}>
                <h4 className="thinHeading">Select number of <span className="linkStyle" onClick={this.openFAQModal}>matches</span> to include in cleaned data</h4>
                <div className="row">
                    <div className="col-sm-4">
                        <Radio
                          label="1 (Best Match)"
                          name="matchesSelection"
                          value={1}
                          checked={this.props.matches === 1}
                          onChange={this.changeMatches}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Radio
                            label="2"
                            name="matchesSelection"
                            value={2}
                            checked={this.props.matches === 2}
                            onChange={this.changeMatches}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Radio
                            label="3 (Max Matches)"
                            name="matchesSelection"
                            value={3}
                            checked={this.props.matches === 3}
                            onChange={this.changeMatches}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    matches: state.mainState.matches
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyMatches: modifyMatches
}

export default connect(mapStateToProps, mapActionsToProps)(MatchesSelection)