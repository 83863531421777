import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleModal} from "../../../../Actions/ToggleModal";

class ProVsBasicCopy extends Component {
    openProVsBasicModal = () => {
        this.props.toggleModal("proVsBasicModal")
    }

    render() {
        return (
            <div className="proVsBasicCopy">
                <h4>Choose between Pro Clean or Basic Clean</h4>
                <p><i>Pro Clean gives you more accuracy and a ton of data enrichment</i></p>
                <span className="linkStyle" onClick={this.openProVsBasicModal}>Read more about Pro Clean vs Basic Clean</span>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(ProVsBasicCopy)