export const MODIFY_ALL_COLUMN_INFO = 'MODIFY_ALL_COLUMN_INFO';

export function modifyAllColumnInfo(selectedColumns, columnCleanTypes, columnCleanInfo){
    let step = 3;
    if (selectedColumns.length > 0){
        step = 4;
    }

    return{
        type: MODIFY_ALL_COLUMN_INFO,
        mainState: {
            selectedColumns: selectedColumns,
            columnCleanTypes: columnCleanTypes,
            columnCleanInfo: columnCleanInfo,
            tutorialStep: step
        }
    }
}