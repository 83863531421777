import React, {Component} from 'react'
import ModalApp from "../Modal"
import ProVsBasicModalBody from "./ProVsBasicModalBody";

class ProVsBasicModal extends Component {
    render() {
        return (
            <ModalApp
                name="proVsBasicModal"
                header="Pro vs Basic Credits"
                body={<ProVsBasicModalBody/>}
                size="large"
            />
        )
    }
}

export default ProVsBasicModal