import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {resetFileState} from "../../../../Actions/ResetFileState";
import {toggleModal} from "../../../../Actions/ToggleModal";
import API_Root from "../../../../Constants/API_Root";
import {resetStateAfterUpload} from "../../../../Actions/ResetStateAfterUpload";
import {modifyTutorialStep} from "../../../../Actions/ModifyTutorialStep";
import {modifyTutorialInfo} from "../../../../Actions/ModifyTutorialInfo";

class RemoveFileButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteOpen: false
        };

        this.togglePopUp = this.togglePopUp.bind(this);
        this.removeFile = this.removeFile.bind(this);

    }

    togglePopUp(e, toggle){
        this.setState({
            deleteOpen: toggle
        })
    }

    removeFile(e){
        this.props.toggleModal("loadingModalDeleteFile");
        const pk = this.props.filePK;

        this.deleteServerFile(pk);

        this.props.resetFileState();
        this.props.resetStateAfterUpload();
        this.props.modifyTutorialInfo({})
        this.props.toggleModal(null);

        if ('full' in this.props){
            this.props.modifyTutorialStep(0)
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }
    }

    deleteServerFile(pk){
        fetch(API_Root + "api/delete-clean-file/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                pk: pk,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            })
        }).then(res => {
        }).catch();
    }

    render() {
        const isFullButton = 'full' in this.props
        return (
            <Popup trigger={<Button basic
                                    color="red"
                                    circular={!isFullButton}
                                    size={isFullButton ? "medium" : "mini"}
                                    content={isFullButton ? "Delete File" : null}
                                    icon="trash alternate outline" />}
                   content={
                       <span>
                           <h3 className="thinHeading">Are you sure?</h3>
                           <Button color="red" content="Yes, delete file" onClick={this.removeFile} />
                           <br/><br/>
                           <Button color="green" content="No, keep file" onClick={(e) => this.togglePopUp(e, false)} />
                       </span>}
                   on='click'
                   position='top right'
                   open={this.state.deleteOpen}
                   onClose={(e) => this.togglePopUp(e, false)}
                   onOpen={(e) => this.togglePopUp(e, true)}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK
})

const mapActionsToProps = {
    resetFileState: resetFileState,
    resetStateAfterUpload: resetStateAfterUpload,
    toggleModal: toggleModal,
    modifyTutorialStep: modifyTutorialStep,
    modifyTutorialInfo: modifyTutorialInfo
}

export default connect(mapStateToProps, mapActionsToProps)(RemoveFileButton)