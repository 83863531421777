import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import FilterCleanType from "./FilterCleanType"
import DetailsDownloadButton from "./DetailsDownloadButton"

class SummaryGraphHeader extends Component {
    constructor(props) {
        super(props);

        this.changeCleanType = this.changeCleanType.bind(this);
    }

    changeCleanType(name, value){
        this.props.changeCleanType(value);
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-5">
                    <h2 className="thinHeading">Your API Calls</h2>
                    <DetailsDownloadButton />
                </div>
                <div className="col-sm-7">
                    <FilterCleanType
                        cleanType={this.props.cleanType}
                        modifyFilters={this.changeCleanType}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(SummaryGraphHeader)