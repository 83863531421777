import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../../Constants/API_Root";
import { Button, Icon } from 'semantic-ui-react'
import HistoryFileSegment from "./HistoryFileSegment";
import Loader from 'react-loader-spinner'
import { push } from 'react-router-redux'

class HistoryIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cleanFiles: [],
            fetching: false
        }

        this.changeStatus = this.changeStatus.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }

    componentDidMount(){
        this.setState({ fetching: true });

        const resetFetching = () => this.setState({ fetching: false });

        fetch(API_Root + "api/get-clean-spreadsheets-files/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email")
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then(
                    (data) =>
                        {
                            this.setState({
                                cleanFiles: data.cleanFiles
                            }, () => resetFetching())
                        }).catch(err => resetFetching())
            }
            else{
                resetFetching()
            }
        }).catch(err => resetFetching())
    }

    changeStatus(e){
        this.props.navigateTo("/clean")
    }

    deleteFile(idx){
        const allFiles = JSON.parse(JSON.stringify(this.state.cleanFiles));

        allFiles.splice(idx, 1);

        this.setState({
            cleanFiles: allFiles
        })
    }

    render() {
        let body;
        if (this.state.cleanFiles.length > 0){

            let cleanFileSegments = [];
            for (let i=0; i<this.state.cleanFiles.length; i++){
                cleanFileSegments.push(
                    <HistoryFileSegment
                        key={i.toString()}
                        cleanFile={this.state.cleanFiles[i]}
                        idx={i}
                        deleteFile={this.deleteFile}
                    />
                )
            }

            body =
                <div>
                    <h2><Icon name="history" /> Your File History</h2>
                    <br/>
                    {cleanFileSegments}
                </div>
        }
        else{
            if (this.state.fetching){
                body = <div>
                    <h4>Getting your file history...</h4>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                </div>
            }
            else{
                body =
                    <div>
                        <h2><Icon name="history" /> Your File History</h2>
                        <br/>
                        <div className="historyFileSegment" style={{textAlign: 'center'}}>
                            <h2>You haven't Uploaded any Files Yet</h2>
                            <Button content="Upload a File" icon="file alternate outline" onClick={(e) => this.changeStatus(e, "upload")} color="green" size="massive"/>
                        </div>
                    </div>
            }
        }

        return(
            <div id="profileIndex" className="historyIndex">
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(HistoryIndex)