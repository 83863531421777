const capitalizeUnderscore = (underscoreString) => {
    let capitalize = true;
    let capitalizedString = "";

    for (let i=0; i<underscoreString.length; i++){
        let addedString = underscoreString.charAt(i);
        if (addedString === "-"){
            addedString = " "
            capitalize = true
        }
        else{
            if (capitalize){
                addedString = addedString.toUpperCase()
                capitalize = false
            }
        }

        capitalizedString += addedString
    }

    return capitalizedString
}

export default capitalizeUnderscore