import React, {Component} from 'react'
import ExampleTable from './ExampleTable'

class KeywordDetails extends Component {
    render() {
        const defectInfo = {type: 'keywordCleaning', rows: {
                values: ['I got this laptop yesterday. It is super fast', 'The chicken tenders here are also a worthy quarantine comfort food', 'Seinfeld and FRIENDS were the two most popular sitcoms of the \'90s'],
                suggestions: ['laptop, super fast', 'worthy quarantine comfort food, chicken tenders', 'popular sitcoms, seinfeld, friends, 90s']
                // values: ['I loved climbing up mount everest in the summertime', 'I got this laptop yesterday. It is super fast and sturdy', 'Napolean Bonaparate was the emperor of France', 'The chicken tenders here are also a worthy quarantine comfort food', 'Seinfeld and FRIENDS were the two most popular sitcoms of the \'90s'],
                // suggestions: ['loved climbing, mount everest, summertime', 'laptop, super fast, sturdy', 'napolean bonaparate, emperor, france', 'worthy quarantine comfort food, chicken tenders', 'popular sitcoms, seinfeld, friends, 90s']
            }
        }

        return (
            <div>
                <h3>Extract Relevant Keywords from Open Ended Text</h3>
                <br/>
                <ExampleTable defectInfo={defectInfo}/>
            </div>
        )
    }
}

export default KeywordDetails