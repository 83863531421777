import {TOGGLE_MODAL} from "../Actions/ToggleModal";
import {MODIFY_FILE_PK} from "../Actions/ModifyFilePK";
import {MODIFY_FILE_INFORMATION} from "../Actions/ModifyFileInformation";
import {MODIFY_STATUS} from "../Actions/ModifyStatus";
import {MODIFY_EDIT_OPTIONS} from "../Actions/ModifyEditOptions";
import {MODIFY_SHEET_READ} from "../Actions/ModifySheetRead";
import {RESET_FILE_STATE} from "../Actions/ResetFileState";
import {MODIFY_SELECTED_COLUMNS} from "../Actions/ModifySelectedColumns";
import {MODIFY_CLEAN_RESULTS} from "../Actions/ModifyCleanResults";
import {RESET_STATE_AFTER_UPLOAD} from "../Actions/ResetStateAfterUpload";
import {MODIFY_USER_CREDITS} from "../Actions/ModifyUserCredits";
import {MODIFY_STEPS_COMPLETED} from "../Actions/ModifyStepsCompleted";
import {TOGGLE_TOAST} from "../Actions/ToggleToast";
import {MODIFY_COLUMN_CLEAN_TYPES} from "../Actions/ModifyColumnCleanTypes";
import {MODIFY_FETCHED_TIME} from "../Actions/ModifyFetchedTime";
import {MODIFY_MODAL_INFO} from "../Actions/ModifyModalInfo";
import {MODIFY_CURRENCY} from "../Actions/ModifyCurrency";
import {MODIFY_PAYMENT_INFO} from "../Actions/ModifyPaymentInfo";
import {RESET_ALL_STATES} from "../Actions/ResetAllStates";
import {INIT_SAMPLE_RESULTS} from "../Actions/InitSampleResults";
import {MODIFY_CLEAN_DATA_PROGRESS} from "../Actions/ModifyCleanDataProgress";
import {INIT_SELECTED_COLUMNS} from "../Actions/InitSelectedColumns";
import {RESET_DATA_CLEANING} from "../Actions/ResetDataCleaning";
import {MODIFY_UPLOAD_DATA_TYPE} from "../Actions/ModifyUploadDataType";
import {MODIFY_CLEAN_OPTIONS} from "../Actions/ModifyCleanOptions";
import {MODIFY_ADDRESS_COLUMNS} from "../Actions/ModifyAddressColumns";
import {MODIFY_ADDRESS_DROP_IDX} from "../Actions/ModifyAddressDropIdx";
import {TOGGLE_IN_ADDRESS_DROP_ZONE} from "../Actions/ToggleInAddressDropZone";
import {MODIFY_COLUMN_BEING_DRAGGED} from "../Actions/ModifyColumnBeingDragged";
import {MODIFY_COLUMN_CLEAN_INFO} from "../Actions/ModifyColumnCleanInfo";
import {MODIFY_ALL_COLUMN_INFO} from "../Actions/ModifyAllColumnInfo";
import {MODIFY_CLEANED_SOURCE} from "../Actions/ModifyCleanedSource";
import {INIT_FULL_CLEANED_FILE_URL} from "../Actions/InitFullCleanedFileURL";
import {MODIFY_SUBSCRIPTION_INFO} from "../Actions/ModifySubscriptionInfo";
import {MODIFY_API_KEYS} from "../Actions/ModifyAPIKeys";
import {MODIFY_FULL_VIZ_INFO} from "../Actions/ModifyFullVizInfo";
import {MODIFY_IS_USER_NEW} from "../Actions/ModifyIsUserNew";
import {TOGGLE_SHOW_TUTORIAL} from "../Actions/ToggleShowTutorial";
import {MODIFY_TUTORIAL_STEP} from "../Actions/ModifyTutorialStep";
import {MODIFY_TUTORIAL_INFO} from "../Actions/ModifyTutorialInfo";
import {TOGGLE_IS_DOWNLOAD_FILE_PRO} from "../Actions/ToggleIsDownloadFilePro";
import {MODIFY_CLEANED_SOURCE_IDX} from "../Actions/ModifyCleanedSourceIdx";
import {MODIFY_MATCHES} from "../Actions/ModifyMatches";

function mainState(
    state = {
        userCredits: {pro: 0, basic: 0},
        filePK: -1,
        fileInformation: {},
        editOptions: {
            header: true,
            skipRows: "",
            startRow: "",
            skipRowsError: false,
            startRowError: false,
            delimiter: null
        },
        modalName: null,
        status: "upload",
        stepsCompleted: 0,
        sheetRead: null,
        selectedColumns: [],
        // File URL of all the columns
        cleanResults: [],
        toast: {show: false, message: "", type: ""},
        columnCleanTypes: [],
        columnCleanInfo: [],
        filesFetchedTime: null,
        profileInformationFetchedTime: null,
        modalInfo: null,
        currency: 'usd',
        paymentInfo: null,
        sampleResults: null,
        proSampleResults: null,
        cleanDataProgress: null,
        allUserFiles: [],
        uploadDataType: "",
        cleanOptions: {"addressGeocode": "forward", "addressColumn": "one"},
        addressColumns: {forward: [], reverse: {lat: null, lng: null}},
        addressDropIdx: -1,
        inAddressDropZone: false,
        columnBeingDragged: {colType: "colName", idx: -1},
        cleanedSource: [],
        fullCleanedFileURL: null,
        subscriptionInfo: {},
        apiKeys: null,
        fullVizInfo: null,
        isUserNew: false,
        showTutorial: false,
        tutorialStep: 0,
        tutorialInfo: {},
        isDownloadFilePro: false,
        cleanedSourceIdx: -1,
        matches: 3
    }, action
) {
    switch (action.type) {
        case MODIFY_MATCHES:
            return Object.assign({}, state, {
                matches: action.mainState.matches
            });
        case MODIFY_CLEANED_SOURCE_IDX:
            return Object.assign({}, state, {
                cleanedSourceIdx: action.mainState.cleanedSourceIdx
            });
        case TOGGLE_SHOW_TUTORIAL:
            return Object.assign({}, state, {
                showTutorial: action.mainState.showTutorial
            });
        case TOGGLE_IS_DOWNLOAD_FILE_PRO:
            return Object.assign({}, state, {
                isDownloadFilePro: action.mainState.isDownloadFilePro
            });
        case MODIFY_TUTORIAL_STEP:
            return Object.assign({}, state, {
                tutorialStep: action.mainState.tutorialStep
            });
        case MODIFY_TUTORIAL_INFO:
            return Object.assign({}, state, {
                tutorialInfo: action.mainState.tutorialInfo
            });
        case MODIFY_IS_USER_NEW:
            return Object.assign({}, state, {
                isUserNew: action.mainState.isUserNew
            });
        case MODIFY_API_KEYS:
            return Object.assign({}, state, {
                apiKeys: action.mainState.apiKeys
            });
        case INIT_FULL_CLEANED_FILE_URL:
            return Object.assign({}, state, {
                fullCleanedFileURL: action.mainState.fullCleanedFileURL
            });
        case MODIFY_CLEANED_SOURCE:
            return Object.assign({}, state, {
                cleanedSource: action.mainState.cleanedSource
            });
        case MODIFY_ALL_COLUMN_INFO:
            return Object.assign({}, state, {
                selectedColumns: action.mainState.selectedColumns,
                columnCleanTypes: action.mainState.columnCleanTypes,
                columnCleanInfo: action.mainState.columnCleanInfo,
                tutorialStep: action.mainState.tutorialStep
            })
        case MODIFY_COLUMN_CLEAN_INFO:
            return Object.assign({}, state, {
                columnCleanInfo: action.mainState.columnCleanInfo
            });
        case MODIFY_COLUMN_BEING_DRAGGED:
            return Object.assign({}, state, {
                columnBeingDragged: action.mainState.columnBeingDragged
            });
        case TOGGLE_IN_ADDRESS_DROP_ZONE:
            return Object.assign({}, state, {
                inAddressDropZone: action.mainState.inAddressDropZone
            });
        case MODIFY_ADDRESS_DROP_IDX:
            return Object.assign({}, state, {
                addressDropIdx: action.mainState.addressDropIdx
            });
        case MODIFY_ADDRESS_COLUMNS:
            return Object.assign({}, state, {
                addressColumns: action.mainState.addressColumns
            });
        case MODIFY_FILE_PK:
            return Object.assign({}, state, {
                filePK: action.mainState.filePK
            });
        case MODIFY_FILE_INFORMATION:
            return Object.assign({}, state, {
                fileInformation: action.mainState.fileInformation
            });
        case MODIFY_EDIT_OPTIONS:
            return Object.assign({}, state, {
                editOptions: action.mainState.editOptions
            });
        case TOGGLE_MODAL:
            return Object.assign({}, state, {
                modalName: action.mainState.modalName
            });
        case MODIFY_STATUS:
            return Object.assign({}, state, {
                status: action.mainState.status
            });
        case MODIFY_SHEET_READ:
            return Object.assign({}, state, {
                sheetRead: action.mainState.sheetRead
            });
        case RESET_FILE_STATE:
            return Object.assign({}, state, {
                filePK: action.mainState.filePK,
                fileInformation: action.mainState.fileInformation,
                editOptions: action.mainState.editOptions,
                status: action.mainState.status,
                sheetRead: action.mainState.sheetRead,
            });
        case MODIFY_UPLOAD_DATA_TYPE:
            return Object.assign({}, state, {
                uploadDataType: action.mainState.uploadDataType
            });
        case MODIFY_SELECTED_COLUMNS:
            return Object.assign({}, state, {
                selectedColumns: action.mainState.selectedColumns,
                tutorialStep: action.mainState.tutorialStep
            });
        case MODIFY_CLEAN_RESULTS:
            return Object.assign({}, state, {
                cleanResults: action.mainState.cleanResults
            });
        case RESET_STATE_AFTER_UPLOAD:
            return Object.assign({}, state, {
                editOptions: {
                    header: true,
                    skipRows: "",
                    startRow: "",
                    skipRowsError: false,
                    startRowError: false,
                    delimiter: null
                },
                sheetRead: null,
                selectedColumns: [],
                cleanResults: [],
                columnCleanTypes: [],
                columnCleanInfo: [],
                sampleResults: null,
                proSampleResults: null,
                cleanDataProgress: null,
                status: "upload",
                stepsCompleted: 0,
                uploadDataType: "",
                cleanOptions: {"addressGeocode": "forward", "addressColumn": "one"},
                addressColumns: {forward: [], reverse: {lat: null, lng: null}},
                addressDropIdx: -1,
                inAddressDropZone: false,
                columnBeingDragged: {colType: "colName", idx: -1},
                cleanedSource: [],
                cleanedSourceIdx: -1,
                matches: 3
            });
        case MODIFY_USER_CREDITS:
            return Object.assign({}, state, {
                userCredits: action.mainState.userCredits
            })
        case MODIFY_STEPS_COMPLETED:
            return Object.assign({}, state, {
                stepsCompleted: action.mainState.stepsCompleted
            })
        case TOGGLE_TOAST:
            return Object.assign({}, state, {
                toast: action.mainState.toast
            })
        case MODIFY_COLUMN_CLEAN_TYPES:
            return Object.assign({}, state, {
                columnCleanTypes: action.mainState.columnCleanTypes
            })
        case MODIFY_FETCHED_TIME:
            if (action.mainState.fetchedTimeType === "profile"){
                return Object.assign({}, state, {
                    profileInformationFetchedTime: new Date()
                })
            }
            else if (action.mainState.fetchedTimeType === "files"){
                return Object.assign({}, state, {
                    filesFetchedTime: new Date()
                })
            }
            else{
                return Object.assign({}, state, {
                    filesFetchedTime: new Date()
                })
            }
        case MODIFY_MODAL_INFO:
            return Object.assign({}, state, {
                modalInfo: action.mainState.modalInfo
            })
        case MODIFY_CURRENCY:
            return Object.assign({}, state, {
                currency: action.mainState.currency
            })
        case MODIFY_PAYMENT_INFO:
            return Object.assign({}, state, {
                paymentInfo: action.mainState.paymentInfo
            })
        case INIT_SAMPLE_RESULTS:
            return Object.assign({}, state, {
                sampleResults: action.mainState.sampleResults,
                proSampleResults: action.mainState.proSampleResults,
                cleanedSource: action.mainState.cleanedSource,
                cleanedSourceIdx: action.mainState.cleanedSourceIdx
            })
        case MODIFY_CLEAN_DATA_PROGRESS:
            return Object.assign({}, state, {
                cleanDataProgress: action.mainState.cleanDataProgress
            })
        case INIT_SELECTED_COLUMNS:
            return Object.assign({}, state, {
                selectedColumns: action.mainState.selectedColumns
            })
        case MODIFY_CLEAN_OPTIONS:
            return Object.assign({}, state, {
                cleanOptions: action.mainState.cleanOptions
            })
        case MODIFY_SUBSCRIPTION_INFO:
            return Object.assign({}, state, {
                subscriptionInfo: action.mainState.subscriptionInfo
            })
        case MODIFY_FULL_VIZ_INFO:
            return Object.assign({}, state, {
                fullVizInfo: action.mainState.fullVizInfo
            })
        case RESET_ALL_STATES:
            return Object.assign({}, state, {
                userCredits: {pro: 0, basic: 0},
                filePK: -1,
                fileInformation: {},
                editOptions: {
                    header: true,
                    skipRows: "",
                    startRow: "",
                    skipRowsError: false,
                    startRowError: false,
                    delimiter: null
                },
                modalName: null,
                status: "upload",
                stepsCompleted: 0,
                sheetRead: null,
                selectedColumns: [],
                // File URL of all the columns
                cleanResults: [],
                toast: {show: false, message: "", type: ""},
                columnCleanTypes: [],
                columnCleanInfo: [],
                filesFetchedTime: null,
                profileInformationFetchedTime: null,
                modalInfo: null,
                currency: 'usd',
                paymentInfo: null,
                sampleResults: null,
                proSampleResults: null,
                cleanDataProgress: null,
                allUserFiles: [],
                uploadDataType: "",
                cleanOptions: {"addressGeocode": "forward", "addressColumn": "one"},
                addressColumns: {forward: [], reverse: {lat: null, lng: null}},
                addressDropIdx: -1,
                inAddressDropZone: false,
                columnBeingDragged: {colType: "colName", idx: -1},
                cleanedSource: [],
                subscriptionInfo: {},
                apiKeys: null,
                fullVizInfo: null,
                tutorialStep: 0,
                cleanedSourceIdx: -1,
                matches: 3
            })
        case RESET_DATA_CLEANING:
            return Object.assign({}, state, {
                filePK: -1,
                fileInformation: {},
                editOptions: {
                    header: true,
                    skipRows: "",
                    startRow: "",
                    skipRowsError: false,
                    startRowError: false,
                    delimiter: null
                },
                modalName: null,
                status: "upload",
                stepsCompleted: 0,
                sheetRead: null,
                selectedColumns: [],
                // File URL of all the columns
                cleanResults: [],
                toast: {show: false, message: "", type: ""},
                columnCleanTypes: [],
                columnCleanInfo: [],
                modalInfo: null,
                // paymentInfo: null,
                sampleResults: null,
                proSampleResults: null,
                cleanDataProgress: null,
                uploadDataType: "",
                cleanOptions: {"addressGeocode": "forward", "addressColumn": "one"},
                addressColumns: {forward: [], reverse: {lat: null, lng: null}},
                addressDropIdx: -1,
                inAddressDropZone: false,
                columnBeingDragged: {colType: "colName", idx: -1},
                cleanedSource: [],
                tutorialStep: 0,
                cleanedSourceIdx: -1,
                matches: 3
            })
        default:
            return state
    }
}

export default mainState;

