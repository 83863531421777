import React, {Component} from 'react'

class Versioning extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="apiSectionDesc thinHeading">
                        <p>
                            We currently have 1 version live: <span style={{color: 'green'}}><b>2019-11-01</b></span>
                        </p>
                        <p>
                            As we make updates, we will notify you. When we upgrade to a different version, we will support
                            a reasonable amount of previous versions. However, if there are any breaking changes or
                            emergency updating required, we will give you at least a 72 hour notice via your email.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Versioning