import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class ReviewOptions extends Component {
    render() {
        return (
            <div>
                <br/>
                <h4 className="thinHeading"><Icon name="angle right" /> Choose one or more Data Types</h4>
                <h4 className="thinHeading"><Icon name="angle right" /> Choose one or more Columns</h4>
                <h4 className="thinHeading"><Icon name="angle right" /> Review a sample of Clean Data</h4>
            </div>
        )
    }
}

export default ReviewOptions