import React, {Component} from 'react'
import { Accordion, Icon} from 'semantic-ui-react'
import DemoResponseTable from "./DemoResponseTable";

class AddressExample extends Component {
    constructor(props){
        super(props);

        this.state = {
            address0Accordion: false,
            address1Accordion: false,
            address2Accordion: false,
        }

        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.getAccordionIcon = this.getAccordionIcon.bind(this);
    }

    toggleAccordion(e, titleProps){
        const { index } = titleProps
        const name = "address"+ index.toString()+ "Accordion";

        const currState = this.state[name];
        this.setState({ [name]: !currState })
    }

    getAccordionIcon(accordionIdx){
        const name = "address"+ accordionIdx.toString()+ "Accordion";
        const isOpen = this.state[name];

        const iconName = isOpen ? "minus circle" : "plus circle"
        return iconName
    }

    render() {
        const apiResponse = [
            {
                "formatted":"The Shard, 32 London Bridge Street, London SE1 9SG, United Kingdom",
                "score":90,
                "split":{
                    "ISO_3166-1_alpha-2":"GB",
                    "ISO_3166-1_alpha-3": "GBR",
                    "_type": "attraction",
                    "attraction": "The Shard",
                    "city": "London",
                    "continent": "Europe",
                    "country": "United Kingdom",
                    "country_code": "gb",
                    "county": "London Borough of Southwark",
                    "house_number": "32",
                    "neighbourhood": "Bermondsey Spa",
                    "political_union": "European Union",
                    "postcode": "SE1 9SG",
                    "road": "London Bridge Street",
                    "state": "England",
                    "state_code": "ENG",
                    "state_district": "Greater London",
                    "suburb":"Borough"
                },
                "lat":51.5043421,
                "lng":-0.0864459,
                "error":false,
                "input":"32 London Bridge Street, London, United Kingdom"
            },
            {
                "formatted": "413 West 52nd Street, New York, NY 10019, United States of America",
                "score": 100,
                "split": {
                    "ISO_3166-1_alpha-2": "US",
                    "ISO_3166-1_alpha-3": "USA",
                    "_type": "building",
                    "city": "New York",
                    "continent": "North America",
                    "country": "USA",
                    "country_code": "us",
                    "county": "New York County",
                    "house_number": "413",
                    "postcode": "10019",
                    "road": "West 52nd Street",
                    "state": "New York",
                    "state_code": "NY",
                    "suburb": "Manhattan"
                },
                "lat": 40.7652249,
                "lng": -73.988659,
                "error": false,
                "input": "413 West 52nd Street, New York, NY 10019, United States of America"
            },
            {
                "formatted": "624 Brush Street, Detroit, MI 48226, United States of America",
                "score": 100,
                "split": {
                    "ISO_3166-1_alpha-2": "US",
                    "ISO_3166-1_alpha-3": "USA",
                    "_type": "building",
                    "city": "Detroit",
                    "continent": "North America",
                    "country": "USA",
                    "country_code": "us",
                    "county": "Wayne County",
                    "house_number": "624",
                    "neighbourhood": "Lafayette Park",
                    "postcode": "48226",
                    "road": "Brush Street",
                    "state": "Michigan",
                    "state_code": "MI",
                    "suburb": "Midtown"
                },
                "lat": 42.3320389,
                "lng": -83.042002,
                "error": false,
                "input": "624 Brush Street, Detroit, MI 48226, United States of America"
            }
        ]

        const addressStyle = {
            color: 'blue',
            fontSize: '115%'
        }

        return (
            <div>
                <h6 className="thinHeading">
                    Since addresses vary widely, we can't give a definitive list of what components will be detected.
                    You can click on the addresses below for some examples.</h6>
                <br/>
                <Accordion>
                    <Accordion.Title
                        active={this.state.address0Accordion}
                        index={0}
                        onClick={this.toggleAccordion}>
                        <Icon name={this.getAccordionIcon(0)} />
                        <span style={addressStyle} className="thinHeading"> 32 London Bridge St, London, UK</span>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.address0Accordion}>
                        <DemoResponseTable cleanResponse={apiResponse[0]} onlyTable/>
                    </Accordion.Content>
                    <Accordion.Title
                        active={this.state.address1Accordion}
                        index={1}
                        onClick={this.toggleAccordion}>
                        <Icon name={this.getAccordionIcon(1)} />
                        <span style={addressStyle} className="thinHeading">413 w 52nd Street, New York, 10019</span>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.address1Accordion}>
                        <DemoResponseTable cleanResponse={apiResponse[1]} onlyTable/>
                    </Accordion.Content>
                    <Accordion.Title
                        active={this.state.address2Accordion}
                        index={2}
                        onClick={this.toggleAccordion}>
                        <Icon name={this.getAccordionIcon(2)} />
                        <span style={addressStyle} className="thinHeading">624 Brush St, 48226</span>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.address2Accordion}>
                        <DemoResponseTable cleanResponse={apiResponse[2]} onlyTable/>
                    </Accordion.Content>
                </Accordion>
            </div>
        )
    }
}

export default AddressExample