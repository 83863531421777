import React, {Component} from 'react'
import { Input, Button, Message, Icon } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";

class MobileLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: localStorage.getItem("email"),
            message: null
        }

        this.sendEmail = this.sendEmail.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    sendEmail(e){
        fetch(API_Root + "api/send-email/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: this.state.email,
                fromEmail: 'info@cleanspreadsheets.com'
            })
        }).then(res =>
            this.setState({
                message: <Message positive={true}
                                  header="Email sent successfully!"
                                  content="It is possible the email is sent to the spam or junk folder" />,
                email: ''
            })
        ).catch(err => this.setState({
            message: <Message negative={true}
                              header="There was an error sending your email."
                              content="You can email us at info@cleanspreadsheets.com and we will remind you" />,
            email: ''
        }));
    }

    render() {
        return (
            <div style={{marginTop: '50px', marginBottom: '100px', textAlign: 'center', marginLeft: '20px', marginRight: '20px'}}>
                <h2><Icon name="cogs" /> Clean</h2>
                <br/><br/><br/>
                <h3 className="thinHeading">You can only clean your files on a Desktop or Laptop. </h3>
                <br/>
                <h5 className="thinHeading">Email yourself a reminder!</h5>
                <Input onChange={this.handleInputChange} name="email" placeholder="Enter Email" value={this.state.email} fluid />
                <br/>
                <Button onClick={this.sendEmail} color="blue" icon="paper plane" content="Email yourself"/>
                {this.state.message}
                <br/>
            </div>
        )
    }
}

export default MobileLandingPage