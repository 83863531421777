import React, {Component} from 'react'

class DisplayBox extends Component {
    render() {
        return (
            <div className="displayBox">
                <div className="displayBoxHeader">
                    <p style={{color: this.props.color}}>{this.props.header}</p>
                </div>
                <div className="displayBoxDesc">
                    {this.props.desc}
                </div>
            </div>
        )
    }
}

export default DisplayBox