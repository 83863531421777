import React, {Component} from 'react'
import { Icon } from "semantic-ui-react"
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";

class SupportInfo extends Component {
    constructor(props){
        super(props);

        this.openSupportModal = this.openSupportModal.bind(this);
    }

    openSupportModal(e){
        this.props.toggleModal("supportModal")
    }

    render() {
        let liStyle = null;

        if ('pricingCard' in this.props){
            liStyle = {marginLeft: "-40px"}
        }

        console.log(this.props.supportType)
        return (
            <div>
                <h6 style={'headingStyle' in this.props ? this.props.headingStyle : {color: "gray"}}><i>We are live from 8AM - 8PM EST, M-F</i></h6>
                <ul style={'style' in this.props ? this.props.style : {listStyleType: "none", textAlign: "center"}}>
                    <li style={liStyle}><Icon name="computer" /> <span className={'pricingCard' in this.props ? null : "linkStyle"}  onClick={'pricingCard' in this.props ? null : this.openSupportModal}>Submit Support Ticket</span></li>
                    <li style={liStyle}><Icon name="mail" /> {'pricingCard' in this.props ? <span>Direct Email</span> : <a href="mailto:support@cleanspreadsheets.com" style={{color: "blue"}}>support@cleanspreadsheets.com</a>}</li>
                    {this.props.supportType.toLowerCase() === "priority" || this.props.supportType.toLowerCase() === "dedicated" ? <li style={liStyle}><Icon name="phone" /> {'pricingCard' in this.props ? "Call Us" : "+1-732-501-1924"}</li> : null }
                    {this.props.supportType.toLowerCase() === "dedicated" ? <li style={liStyle}><Icon name="user" /> {'pricingCard' in this.props ? <span>Personal Data Specialist</span> : <span>Adhaar Sharma, <a href="mailto:adhaar@cleanspreadsheets.com" style={{color: "blue"}}> Email Adhaar</a></span>}</li> : null}
                </ul>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(SupportInfo)