import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import PaymentForm from "../../../SharedComponents/Payment/CustomPayment/PaymentForm"
import RefillCustomCredits from "./RefillCustomCredits";
import RefillCreditsPrice from "./RefillCreditsPrice";
import CreditCardSelection from "./CreditCardSelection"
import PreviousCardPayButton from "./PreviousCardPayButton"

const dropdownOptions = [
    // {key: 1, value: 1000, text: '1,000 credits'},
    // {key: 2, value: 5000, text: '5,000 credits'},
    {key: 3, value: 10000, text: '10,000 credits'},
    {key: 4, value: 20000, text: '20,000 credits'},
    {key: 5, value: 50000, text: '50,000 credits'},
    {key: 6, value: 100000, text: '100,000 credits'},
    {key: 7, value: 250000, text: '250,000 credits'},
    {key: 8, value: 500000, text: '500,000 credits'},
    {key: 9, value: 1000000, text: '1,000,000 credits'},
    // {key: 10, value: "custom", text: 'Custom'}
]


class PaymentSegment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creditsToRefill: this.initCredits(),
            creditError: null,
            cardIdx: this.initCardIdx(),
            isCustomSelected: false
        }

        this.initCredits = this.initCredits.bind(this);
        this.initCardIdx = this.initCardIdx.bind(this);
        this.getCreditError = this.getCreditError.bind(this);
        this.handleCreditsChange = this.handleCreditsChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.changeCardIdx = this.changeCardIdx.bind(this);
    }

    initCredits(){
        let initCredits = 10000;

        if (this.props.modalInfo !== null){
            if ("creditsToRefill" in this.props.modalInfo){
                initCredits = this.props.modalInfo["creditsToRefill"]
            }
        }

        if (initCredits <= 10000){
            initCredits = 10000
        }
        else{
            for (let i=0; i<dropdownOptions.length; i++){
                if (dropdownOptions[i].value !== "custom"){
                    if (i !== dropdownOptions.length - 1){
                        if (dropdownOptions[i].value <= initCredits && dropdownOptions[i + 1].value > initCredits){
                            initCredits = dropdownOptions[i+1].value
                            break
                        }
                    } else {
                        if (initCredits <= dropdownOptions[i]){
                            initCredits = dropdownOptions[i].value
                            break
                        }
                    }
                }
            }
        }

        return initCredits
    }

    initCardIdx(){
        let cardIdx = -1;

        if (this.props.paymentInfo !== null){
            if ('creditCards' in this.props.paymentInfo){
                if (this.props.paymentInfo['creditCards'].length > 0){
                    if ('defaultCard' in this.props.paymentInfo){
                        for (let i=0; i<this.props.paymentInfo['creditCards'].length; i++){
                            if (this.props.paymentInfo['creditCards'][i]['id'] === this.props.paymentInfo['defaultCard']){
                                cardIdx = i
                                break
                            }
                        }
                    }
                    else{
                        cardIdx = 0
                    }
                }
            }
        }

        return cardIdx
    }

    getCreditError(value){
        let error = null;
        let creditsReminder = null;

        if (isNaN(value)){
            error = <span><small style={{color: 'red', display: 'block'}}>You can only input numbers for refill</small>{creditsReminder}</span>
        }
        else{
            // Check if number is a decimal
            if (value.indexOf(".") !== -1){
                error = <span><small style={{color: 'red', display: 'block'}}>You can only input whole numbers for refill</small>{creditsReminder}</span>
            }
            else{
                if (parseInt(value) < 1000){
                    error = <span><small style={{color: 'red', display: 'block'}}>Minimum 1000 credits needed to refill</small>{creditsReminder}</span>
                }
                else{
                    if (this.props.modalInfo !== null){
                        if ("creditsToRefill" in this.props.modalInfo){
                            if (this.props.modalInfo["creditsToRefill"] > parseInt(value)){
                                error = <small style={{color: 'orange', display: 'block'}}><b><u>REMINDER</u></b> You need to refill {this.props.modalInfo["creditsToRefill"]} credits to clean your current data </small>
                            }
                        }
                    }
                }
            }
        }

        return error
    }

    handleCreditsChange(e){
        let creditInput = e.target.value;

        if (creditInput.trim() === ""){
            this.setState({ creditsToRefill: "", creditError: null})
        }
        else{
            const creditError = this.getCreditError(creditInput)

            if (creditError === null){
                creditInput = parseInt(creditInput)
            }

            this.setState({ creditsToRefill: creditInput, creditError: creditError})
        }
    }

    handleDropdownChange(event, { value }){
        let credits;
        if (value === "custom"){
            credits = this.initCredits() + 1
        }
        else{
            credits = value
        }

        credits = credits.toString()

        const creditError = this.getCreditError(credits)

        if (creditError === null){
            credits = parseInt(credits)
        }

        this.setState({ creditsToRefill: credits, creditError: creditError, isCustomSelected: value === "custom"})

    }

    changeCardIdx(cardIdx){
        this.setState({
            cardIdx: cardIdx
        })
    }

    render() {
        let isPro = false;

        if ('creditsIndex' in this.props){
            isPro = this.props.isPro
        } else {
            if (this.props.modalInfo !== null){
                if ('isPro' in this.props.modalInfo){
                    isPro = this.props.modalInfo.isPro;
                }
            }
        }

        const dropdownValue = [1000, 5000, 10000, 20000, 50000, 100000, 250000, 500000, 1000000].includes(this.state.creditsToRefill) && !this.state.isCustomSelected ? this.state.creditsToRefill : "custom"

        let payMethod = <div>
            <CreditCardSelection cardIdx={this.state.cardIdx} changeCardIdx={this.changeCardIdx} />
            {
                this.state.cardIdx === -1 ?
                    <PaymentForm isPro={isPro} cardIdx={this.state.cardIdx} credits={this.state.creditError !== null ? null : this.state.creditsToRefill}/>
                    :
                    <PreviousCardPayButton isPro={isPro} cardIdx={this.state.cardIdx} credits={this.state.creditError !== null ? null : this.state.creditsToRefill} />
            }
        </div>

        if ('landing' in this.props){
            payMethod = <div>
                <hr />
                <br/>
                All Accounts start off with <h1 className="thinHeading" style={{fontSize: '350%', color: 'green'}}> 250</h1> FREE credits
            </div>
        }

        return (
            <div>
                {'landing' in this.props ? <h4>Select number of credits to see price </h4> : <h4>How many {isPro ? "Pro" : "Basic"} credits do you want to refill?</h4>}
                {this.state.creditError === null ? <br/> : this.state.creditError}
                <Dropdown
                    options={dropdownOptions}
                    value={dropdownValue}
                    onChange={this.handleDropdownChange}
                    selection
                />
                {dropdownValue === "custom" ? <RefillCustomCredits value={this.state.creditsToRefill} handleCreditsChange={this.handleCreditsChange} /> : <br/>}
                <br/>
                <RefillCreditsPrice isPro={isPro} currency={this.props.currency} credits={this.state.creditsToRefill}/>
                <br/>
                {payMethod}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    currency: state.mainState.currency,
    paymentInfo: state.mainState.paymentInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PaymentSegment)