import React, {Component} from 'react'
import { connect } from 'react-redux'
import CleanDisplayMasterMenu from "./CleanDisplayMasterMenu"
import DisplayResultsIndex from "../../Download/DisplayResults/DisplayResultsIndex"
import DownloadResultsButton from "../../Download/DownloadResultsButton"
import capitalizeHyphenated from "../../../../Constants/capitalizeHyphenated";
import images from "../../../../Constants/Images";
import { Image } from 'semantic-ui-react';
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import {toggleModal} from "../../../../Actions/ToggleModal";

class CleanDisplayMasterTable extends Component {
    constructor(props){
        super(props)

        this.state = {
            selectedColIdx: this.props.selectedColumns.length > 0 ? 0 : -1
        }

        this.changeSelectedCol = this.changeSelectedCol.bind(this);
        this.openFAQModal = this.openFAQModal.bind(this);
    }

    changeSelectedCol(idx){
        this.setState({ selectedColIdx: idx })
    }

    openFAQModal(e, cleanType){
        this.props.modifyModalInfo({"cleanTypeFAQ": cleanType});
        this.props.toggleModal("cleanTypeFAQ");
    }

    render() {
        let columnIdx = -1;
        let results = null;
        let cleanType = null;

        if (this.state.selectedColIdx !== -1){
            columnIdx = this.props.selectedColumns[this.state.selectedColIdx];
            results = this.props.sampleResults[this.state.selectedColIdx];
            cleanType = this.props.columnCleanTypes[columnIdx];
        }

        let showNum;
        let addlMessage = null;
        let downloadResults = null;

        if (this.props.isSample){
            showNum = this.props.sampleResults[this.state.selectedColIdx].length > 5 ? 5 : this.props.sampleResults[this.state.selectedColIdx].length
            addlMessage = <span>. <span className="linkStyle" onClick={(e) => this.openFAQModal(e, cleanType)}>Read FAQs about cleaning {cleanType !== null ? <span><Image src={images[cleanType + "Icon"]} avatar /> {capitalizeHyphenated(cleanType)}</span> : ""}</span> </span>
        } else {
            showNum = this.props.sampleResults[this.state.selectedColIdx].length
            addlMessage = <span>. Don't worry, the file you download will have all values <span role="img" aria-label="smile">😀</span></span>
            downloadResults = <DownloadResultsButton colIdx={this.state.selectedColIdx} />
        }

        return (
            <div className="cleanDisplayMasterTable">
                <CleanDisplayMasterMenu changeSelectedCol={this.changeSelectedCol} selectedColIdx={this.state.selectedColIdx}/>
                <div className="cleanDisplayMasterTableResults">
                    <p className="sampleDescText">We are showing only the first {showNum} values in this sample{addlMessage}</p>
                    {downloadResults}
                    <DisplayResultsIndex selectedColIdx={this.state.selectedColIdx} columnIdx={columnIdx} results={results} cleanType={cleanType} isSample={this.props.isSample}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sampleResults: state.mainState.sampleResults,
    selectedColumns: state.mainState.selectedColumns,
    fileInformation: state.mainState.fileInformation,
    columnCleanTypes: state.mainState.columnCleanTypes,
    cleanedSource: state.mainState.cleanedSource
})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(CleanDisplayMasterTable)