import React, {Component} from 'react'
import ReactJson from 'react-json-view'
import DisplayBox from "../DisplayBox";
import ProLabel from "./ProLabel";
import APIRespBoxHeader from "./APIRespBoxHeader"

class NotesResponse extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPro: true,
            height: 0
        }
    }

    componentDidMount(){
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        let height = 0;
        const div = document.getElementById("apiNotesSectionIntro")

        if (div !== null && div !== undefined){
            height = div.offsetHeight;
        }

        this.setState({ height: height })
    }

    changeProToggle = (isPro) => {
        this.setState({ isPro: isPro })
    }

    render() {
        let resp = [{"input":"I was here for business and this place was walking distance from my hotel. I found the ramen to be excellent and the broth very flavorful. The chashu was done very well, and the soft boiled egg to be the perfect texture. Service was very prompt and friendly. You don't have to wait long for food here.","formatted":"soft boiled egg, walking distance, perfect texture, wait long","split":{"secondaryKeywords":"business, place, hotel, found, ramen, excellent, broth, flavorful, chashu, service, prompt, friendly, don, food","extraKeywords":"", "textRankKeywords": "texture, boiled"},"error":false,"score":100}]

        if (!this.state.isPro){
            delete resp['split']['extraKeywords']
            delete resp['split']['textRankKeywords']
        }
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <div id="apiNotesSectionIntro">
                            <p>Cleaning an open ended text field gives back up to 30 keywords. These keywords are returned either as the <b>formatted</b> value and/or <b>secondaryKeywords</b> and/or if you did <u>PRO</u> cleaning then in <b>extraKeywords</b></p>
                            <br/>
                            <p>Let's say we find 24 keywords. We might return 4 in the <b>formatted</b> 10 in the <b>secondaryKeywords</b>. If you are doing <u>PRO</u> cleaning, then the last 10 will be in the <b>extraKeywords</b>. We could also return 10 in the <b>formatted</b> and 10 in the <b>secondaryKeywords</b>. If you are doing <u>PRO</u> cleaning, then the last 4 keywords will be in the <b>extraKeywords</b></p>
                            <br/>
                            <p>We do this to preserve High Quality Keywords in the <b>formatted</b> key.</p>
                            <br/>
                            <p>If you do <b>PRO</b> cleaning, you will also get keywords that were found by the <a href="https://dl.acm.org/doi/abs/10.1145/3321408.3326659" target="_blank" rel="noopener noreferrer"> Text Rank keywords algorithm</a> in the <b>textRankKeywords</b> key</p>
                            <br/>
                        </div>
                        <div className="apiDocSection">
                            <h5>Formatted String</h5>
                            <p>The formatted value is a string <b>keyword1,keyword2,...,keyword10</b> that contains upto 10 most important keywords/keyphrases separated by a comma</p>
                        </div>
                        <br/>
                        <div className="apiDocSection">
                            <h5>Split Object Components</h5>
                            <p><span className="parameterName">secondaryKeywords</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Upto 10 Keywords/Keyphrases separated by a comma that are not as important as the ones in <b>formatted</b>. For e.g., hello,goodbye,cool</p>
                            <br/>
                            <p><span className="parameterName">extraKeywords</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Upto 10 Keywords/Keyphrases separated by a comma that are not as important as the ones in <b>formatted</b> or <b>secondaryKeywords</b>. For e.g., omg,pizza,blaze</p>
                            <br/>
                            <p><span className="parameterName">textRankKeywords</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">As many keywords as were found by the <a href="https://dl.acm.org/doi/abs/10.1145/3321408.3326659" target="_blank" rel="noopener noreferrer"> Text Rank keywords algorithm</a> separated by a comma. For e.g., omg,pizza,blaze</p>
                            <br/>
                        </div>
                        <br/>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{marginTop: this.state.height}}>
                        <DisplayBox
                            header={
                                <APIRespBoxHeader
                                    header='a Restaurant Review from Yelp'
                                    isPro={this.state.isPro}
                                    changeProToggle={this.changeProToggle}
                                />}
                            desc={<div>
                                <ReactJson src={resp}
                                           name={null}
                                           theme="ocean"
                                           displayObjectSize={false}/>
                            </div>}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default NotesResponse