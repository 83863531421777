export const RESET_FILE_STATE = 'RESET_FILE_STATE';

export function resetFileState(){
    return{
        type: RESET_FILE_STATE,
        mainState: {
            filePK: -1,
            fileInformation: {},
            editOptions: {
                header: true,
                skipRows: "",
                startRow: "",
                skipRowsError: false,
                startRowError: false,
                delimiter: null
            },
            status: "upload",
            sheetRead: null,
        }
    }
}