import React, {Component} from 'react'
import DetailExampleRow from "./DetailExamples/DetailExampleRow";

class WhatElseCanYouDo extends Component {
    render() {
        return (
            <div id="whatElseCanYouDo">
                <DetailExampleRow/>
            </div>
        )
    }
}

export default WhatElseCanYouDo