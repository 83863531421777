import React, {Component} from 'react'
import NeedMoreInfoCard from "./NeedMoreInfoCard";
import PricingIcon from "./Illustrations/PricingIcon.svg"
import Blog from "./Illustrations/Blog.svg"
import CleanDataIcon from "./Illustrations/CleanDataIcon.svg"

class NeedMoreInfo extends Component {
    render() {
        return (
            <div className="needMoreInfoLanding">
                <h1 className="thinHeading" style={{fontSize: "450%", textAlign: "center", color: "#167dff", fontFamily: "museo-sans, sans-serif", fontStyle: "normal", fontWeight: "bold"}}>Learn More</h1>
                <div className="row" style={{marginTop: "50px"}}>
                    <div className="col-sm-4">
                        <NeedMoreInfoCard
                            imgSrc={PricingIcon}
                            header="Pricing"
                            desc="Monthly plans with awesome customer support to fit all of your needs. Or Pay-As-You-Go when you need your Data cleaned."
                            link="/pricing"
                            linkText="Check out our plans"
                        />
                    </div>
                    <div className="col-sm-4">
                        <NeedMoreInfoCard
                            imgSrc={CleanDataIcon}
                            header="Cleaning"
                            desc="We automate the data cleaning. But we know you might want to learn all about the data types and how they are cleaned."
                            link="/FAQs"
                            linkText="Look under the hood"
                        />
                    </div>
                    <div className="col-sm-4">
                        <NeedMoreInfoCard
                            imgSrc={Blog}
                            header="Guides"
                            desc="We've heard it's easy to use the tool. Even then, we have written guides on how to use the tool and Data Cleaning in general."
                            link="/guides"
                            linkText="Learn how to clean data"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default NeedMoreInfo