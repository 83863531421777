import React, {Component} from 'react'
import PageHero from "../../SharedComponents/PageHero";
import { Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import PayAsYouGoPricing from "./PayAsYouGoPricing"
import MonthlyPricing from "./MonthlyPricing";
import CurrencyChangeDropdown from "../../SharedComponents/CurrencyChangeDropdown"
import { BrowserView, MobileView } from "react-device-detect"
import FAQBlock from "../Help/FAQBlock";
import LandingPagePricingIndexFreeRow from "../../SharedComponents/LandingPagePricingIndexFreeRow";
import PricingPageBlock from "./PricingPageBlock";

const creditsFAQ = [
    // {question: "", answer: ""}
    {question: "What kind of credits do I get for free?", answer: "You get Pro credits"},
    {question: "What is the difference between Pro & Basic credits? ", answer: "Cleaning data with Pro credits gives much more data enrichment and accurate geocoding"},
    {question: "Can I convert between Pro & Basic credits?", answer: "Yes, you can convert credits in units of 1,000. The credits are converted at a 1 PRO : 5 BASIC ratio. For e.g., 5,000 PRO Credits = 25,000 BASIC Credits. 10,000 BASIC Credits = 2,000 PRO Credits. 10,000 PRO Credits = 50,000 BASIC Credits"},
    {question: "Can I mix between Pro & Basic cleaning for 1 file?", answer: "No, each file can only be cleaned with either Pro or Basic credits. You can purchase or transfer between credits if you are low on either of them."},
    {question: "What does 1 credit get me?", answer: "1 credit cleans 1 value. Whether that's 1 name (Adam Brody), 1 address (1 Penn Ave, NY) or 1 date (Apr 23, 91)."},
    {question: "What happens when a value isn't cleaned?", answer: "If there is an error, no credits are used"},
    {question: "What if I have duplicate values?", answer: "No problems! We only count unique values for the number of credits"},
]

const payAsYouGoFAQ = [
    {question: "How many free credits do I get?", answer: "250 Free Pro Credits for every account"},
    {question: "Do the credits ever expire?", answer: "Nope, your credits are there forever"},
    {question: "How can I pay?", answer: "We accept all major global credit cards. If you need another way to pay, contact us at payment@cleanspreadsheets.com"},
    {question: "Are there any additional fees?", answer: "Nope, the price you see is what you get. There are no hidden fees"},
]

const monthlyAccountFAQ = [
    {question: "What does 1 month mean?", answer: "A month begins and ends on the day you signed up. May 5, June 5 etc."},
    {question: "What if I don't need my account anymore?", answer: "Cancel anytime with no worries. Your account is valid till the end of your month."},
    {question: "How does billing work if I switch plans?", answer: "The amount is prorated. If you were on the Analyst account ($29) for half a month and upgrade to a Small ($99) account for the other half of the month. You will be billed $64 for the month. ($29*0.5 + $99*0.5)"},
    {question: "How can I pay?", answer: "We accept all major global credit cards. If you need another way to pay, contact us at support@cleanspreadsheets.com"},
    {question: "Do I get an invoice?", answer: "Yes, every month you will receive an invoice from Stripe (our payment partner)."},
    {question: "Are there any additional fees?", answer: "Nope, the price you see is what you get. There is no long-term contact, setup or hidden fees. You can cancel whenever"},
]

const accountSupportFAQ = [
    {question: "How does customer support work?", answer: "We are live Monday to Friday, 8AM - 8PM EST. Every account can submit support requests from the tool and website that get answered within 1 business day"},
]

const optionStyle = {
    fontWeight: "600",
    fontSize: "18px"
}

const dropdownOptions = [
    // {key: 1, value: 1000, text: '1,000 credits'},
    // {key: 2, value: 5000, text: '5,000 credits'},
    {key: 3, value: 10000, text: <span style={optionStyle}>10,000 credits</span>},
    {key: 4, value: 20000, text: <span style={optionStyle}>20,000 credits</span>},
    {key: 5, value: 50000, text: <span style={optionStyle}>50,000 credits</span>},
    {key: 6, value: 100000, text: <span style={optionStyle}>100,000 credits</span>},
    {key: 7, value: 250000, text: <span style={optionStyle}>250,000 credits</span>},
    {key: 8, value: 500000, text: <span style={optionStyle}>500,000 credits</span>},
    {key: 9, value: 1000000, text: <span style={optionStyle}>1,000,000 credits</span>},
    // {key: 10, value: "custom", text: 'Custom'}
]

class PricingPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            creditsToRefill: 10000,
            pricingType: "payAsYouGo"
        }

        this.openModal = this.openModal.bind(this);
        this.changePricingType = this.changePricingType.bind(this);
    }

    handleDropdownChange = (event, { value }) => {
        this.setState({ creditsToRefill: value })
    }

    changePricingType(e, pricingType){
        this.setState({ pricingType: pricingType })
    }

    openModal(e, modalName){
        this.props.toggleModal(modalName)
    }

    render() {
        return (
            <div className="pricingPage">
                <PageHero title="Pricing" desc={<span>Simple Pay-As-You-Go Pricing</span>}/>
                <div style={{paddingTop: "50px"}}>
                    <LandingPagePricingIndexFreeRow/>
                </div>
                <div className="pricingPageMainDiv">
                    <h2>Select number of credits to see price </h2>
                    <div style={{width: "25%", margin: "0 auto"}}>
                        <Dropdown
                            options={dropdownOptions}
                            value={this.state.creditsToRefill}
                            onChange={this.handleDropdownChange}
                            selection
                            fluid
                        />
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                        <div style={{borderRight: "1px solid lightgrey", height: "75px"}}>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <div className="pricingPageBlock" id="basicPricingPageBlock">
                        <PricingPageBlock pro={false} creditsToRefill={this.state.creditsToRefill}/>
                    </div>
                    <div className="pricingPageBlock" id="proPricingPageBlock">
                        <PricingPageBlock pro={true} creditsToRefill={this.state.creditsToRefill}/>
                    </div>
                </div>
                {/*{this.state.pricingType === "monthly" ? <MonthlyPricing/> : <PayAsYouGoPricing />}*/}
                <p style={{fontSize: "350%", marginTop: "75px", fontWeight: "300"}}>Pricing FAQs</p>
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{textAlign: 'left', padding: "40px"}}>
                            <FAQBlock icon="ticket" FAQs={creditsFAQ} title="Credits"/>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={{textAlign: 'left', padding: "40px"}}>
                            <FAQBlock icon="dollar" FAQs={this.state.pricingType === "monthly" ? monthlyAccountFAQ : payAsYouGoFAQ} title="Account"/>
                        </div>
                        <div style={{textAlign: 'left', padding: "40px"}}>
                            <FAQBlock icon="question circle outline" FAQs={accountSupportFAQ} title="Support"/>
                        </div>
                    </div>
                </div>
                <br/><br/><br/><br/>
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <br/><br/>
                        <div style={{textAlign: "center", padding: "10px", borderRadius: "10px"}} className="segment-raised-rounded">
                            <h4>Are you an Enterprise?</h4>
                            <h4>Do you need Manual Data Cleaning?</h4>
                            <h4>Do you need integrations to popular data storage software like Salesforce?</h4>
                            <br/>
                            <button className="scheduleDemoButton" onClick={e => this.openModal(e, "contactUsModal")}>
                                Contact Us
                            </button>
                        </div>
                        <br/><br/>
                    </div>
                    <div className="col-sm-3" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(PricingPage)