import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../Modal"
import GeocodingDifference from "./GeocodingDifference";
import GeocodingColumn from "./GeocodingColumn";
import UploadHelp from "./UploadHelp";

class HelpModal extends Component {
    render() {
        let body = null;
        let helpTopic = null;

        if (this.props.modalInfo !== null){
            if ('helpTopic' in this.props.modalInfo){
                helpTopic = this.props.modalInfo.helpTopic;

                body = {geocodingDifference: <GeocodingDifference/>, geocodingColumnType: <GeocodingColumn/>, uploadHelp: <UploadHelp/>}[helpTopic]
            }
        }

        if (helpTopic !== null){
            if (helpTopic === "uploadHelp"){
                return (
                    <ModalApp
                        name="helpModal"
                        header={null}
                        body={body}
                        size="large"
                        bodyColor="#323232"
                        noClose
                        noHeader
                    />
                )
            } else {
                return (
                    <ModalApp
                        name="helpModal"
                        header={null}
                        body={body}
                        size="large"
                    />
                )
            }
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(HelpModal)