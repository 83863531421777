import React, {Component} from 'react'
import DataSafety from "./Illustrations/DataSafety.svg"
import PermInstall from "./Illustrations/PermInstall.svg"
import Security from "./Illustrations/Security.svg"
import SecurityCard from "./SecurityCard";
import UpsideDownTriangle from "../LandingPage/Illustrations/UpsideDownTriangle.svg"
import RightAngleTriangle from "../LandingPage/Illustrations/RightAngleTriangle.svg"

class SecurityFeatures extends Component {
    render() {
        return (
            <div className="landingSecurityFeatures">
                <img src={RightAngleTriangle} style={{width: "auto", height: "100%"}} alt="LandingPageDecoration1"/>
                <h1 className="thinHeading" style={{fontSize: "450%", textAlign: "center", color: "#167dff", fontFamily: "museo-sans, sans-serif", fontStyle: "normal", fontWeight: "bold"}}>Data Security & Privacy</h1>
                <img src={UpsideDownTriangle} style={{width: "auto", height: "100%"}} alt="LandingPageDecoration2"/>
                <div className="row" style={{marginTop: "50px"}}>
                    <div className="col-sm-4">
                        <SecurityCard
                            imgSrc={Security}
                            header="Data Encryption"
                            desc="We encrypt your data at every cleaning step. In-transit using HTTPS/SSL and at rest using AWS Encryption Technology"
                        />
                    </div>
                    <div className="col-sm-4">
                        <SecurityCard
                            imgSrc={DataSafety}
                            header="GDPR Compliant"
                            desc="We are fully GDPR Compliant. We will never use your data maliciously as spelled out in our Terms of Service"
                        />
                    </div>
                    <div className="col-sm-4">
                        <SecurityCard
                            imgSrc={PermInstall}
                            header="On Prem Install"
                            desc="We offer on-prem installation if you need. We can also execute a Data Processor agreement with you easily"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SecurityFeatures