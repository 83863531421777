import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyStatus} from "../../../Actions/ModifyStatus";
import {modifyStepsCompleted} from "../../../Actions/ModifyStepsCompleted";
import {modifyCleanDataProgress} from "../../../Actions/ModifyCleanDataProgress";
import API_Root from "../../../Constants/API_Root";
import {modifyCleanResults} from "../../../Actions/ModifyCleanResults";
import {modifyUserCredits} from "../../../Actions/ModifyUserCredits";
import {getTotalCleanValues} from "../../../Constants/getTotalCleanValues";
import {createAddressSteps} from "../../../Constants/createAddressSteps";
import {initFullCleanedFileURL} from "../../../Actions/InitFullCleanedFileURL";
import {toggleIsDownloadFilePro} from "../../../Actions/ToggleIsDownloadFilePro";
import {modifyMatches} from "../../../Actions/ModifyMatches";

class CleanDataMasterButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.goToDownload = this.goToDownload.bind(this);
        this.cleanData = this.cleanData.bind(this);

    }

    goToDownload(e){
        this.props.modifyStatus("download")
        window.scrollTo({top: 0, behavior: 'smooth'});

    }

    cleanData(e){
        this.props.toggleIsDownloadFilePro(this.props.isPro)
        this.props.modifyStepsCompleted(2);
        this.props.modifyCleanDataProgress(0);
        this.goToDownload(e)


        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/clean/");

        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                isPro: this.props.isPro,
                matches: this.props.isPro ? this.props.matches : 1,
                filePK: this.props.filePK,
                selectedColumns: this.props.selectedColumns,
                columnCleanTypes: this.props.columnCleanTypes,
                columnCleanInfo: this.props.columnCleanInfo,
                email: localStorage.getItem("email"),
                totalValues: getTotalCleanValues(this.props.selectedColumns, this.props.fileInformation['columnInfo'], this.props.fileInformation.columnNames, this.props.columnCleanTypes, this.props.columnCleanInfo),
                cleanSteps: createAddressSteps(this.props.fileInformation.columnNames, this.props.columnCleanInfo, this.props.selectedColumns)
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "cleaning"){
                this.props.modifyCleanDataProgress(reply['cleanDataProgress'])
            }
            else if (status === "error"){
                this.props.modifyCleanResults(this.props.selectedColumns.map(x => null))
                this.props.modifyCleanDataProgress(100)
                socket.close()
            }
            else{
                this.props.modifyMatches(this.props.isPro ? this.props.matches : 1)
                this.props.initFullCleanedFileURL(reply['fullCleanedFileURL'])
                this.props.modifyCleanResults(reply['cleanResults'])
                this.props.modifyCleanDataProgress(100)
                this.props.modifyUserCredits(reply['userCredits'])
                socket.close()
            }
        }
    }

    render() {
        let isDisabled = false
        let warning = null;

        if (this.props.stepsCompleted > 1){
            isDisabled = true
            warning = <div style={{color: 'orange'}}><small><i>You have already cleaned your data. <span className="linkStyle" onClick={(e) => this.goToDownload(e)}>Download it now</span></i></small></div>
        }

        return (
            <div>
                <Button id={(this.props.isPro ? "pro" : "basic") + "CleanDataMasterButton"} disabled={isDisabled} loading={this.state.loading} onClick={this.cleanData} content={(this.props.isPro ? "Pro" : "Basic") + " Clean Your Data"} icon='cogs' size="massive" fluid color="green"/>
                {warning}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stepsCompleted: state.mainState.stepsCompleted,
    filePK: state.mainState.filePK,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
    cleanDataProgress: state.mainState.cleanDataProgress,
    sheetRead: state.mainState.sheetRead,
    editOptions: state.mainState.editOptions,
    fileInformation: state.mainState.fileInformation,
    columnCleanInfo: state.mainState.columnCleanInfo,
    matches: state.mainState.matches
})

const mapActionsToProps = {
    initFullCleanedFileURL: initFullCleanedFileURL,
    modifyStatus: modifyStatus,
    modifyStepsCompleted: modifyStepsCompleted,
    modifyCleanDataProgress: modifyCleanDataProgress,
    modifyCleanResults: modifyCleanResults,
    modifyUserCredits: modifyUserCredits,
    toggleIsDownloadFilePro: toggleIsDownloadFilePro,
    modifyMatches: modifyMatches
}

export default connect(mapStateToProps, mapActionsToProps)(CleanDataMasterButton)