import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import {modifyAddressColumns} from "../../../../../../Actions/ModifyAddressColumns";

class AddressReverseSelection extends Component {
    constructor(props){
        super(props)

        this.changeLatDropdown = this.changeLatDropdown.bind(this);
        this.changeLngDropdown = this.changeLngDropdown.bind(this);
    }

    changeLatDropdown(e, {value}){
        const copiedAddrColumns = JSON.parse(JSON.stringify(this.props.addressColumns))
        let colTypeAddrColumns = copiedAddrColumns.reverse;

        colTypeAddrColumns.lat = value

        copiedAddrColumns.reverse = colTypeAddrColumns

        this.props.modifyAddressColumns(copiedAddrColumns)
    }

    changeLngDropdown(e, {value}){
        const copiedAddrColumns = JSON.parse(JSON.stringify(this.props.addressColumns))
        let colTypeAddrColumns = copiedAddrColumns.reverse;

        colTypeAddrColumns.lng = value

        copiedAddrColumns.reverse = colTypeAddrColumns

        this.props.modifyAddressColumns(copiedAddrColumns)
    }

    render() {
        const addressColumns = this.props.addressColumns.reverse;
        let colNames = this.props.fileInformation.columnNames;

        let latVal = addressColumns.lat;
        let lngVal = addressColumns.lng;

        const latOptions = colNames.map((x, idx) => { return {key: idx, value: x, text: x}})
        const lngOptions = colNames.map((x, idx) => { return {key: idx, value: x, text: x}})

        return (
            <div>
                <div className="row">
                    <div className="col-sm-4">
                        <h4>Latitude</h4>
                    </div>
                    <div className="col-sm-8">
                        <Dropdown
                            placeholder="Select Latitude"
                            value={latVal}
                            options={latOptions}
                            onChange={this.changeLatDropdown}
                            selection
                            fluid
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <h4>Longitude</h4>
                    </div>
                    <div className="col-sm-8">
                        <Dropdown
                            placeholder="Select Longitude"
                            value={lngVal}
                            options={lngOptions}
                            onChange={this.changeLngDropdown}
                            selection
                            fluid
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    addressColumns: state.mainState.addressColumns,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyAddressColumns: modifyAddressColumns
}

export default connect(mapStateToProps, mapActionsToProps)(AddressReverseSelection)