import React, {Component} from 'react'
import SupportModalBody from "./SupportModalBody"
import { Icon } from 'semantic-ui-react'

class SupportIndex extends Component {
    render() {
        return (
            <div className="supportIndex">
                <h2><Icon name='envelope' /> Support</h2>
                <div><br/><SupportModalBody /></div>
            </div>
        )
    }
}

export default SupportIndex