import React, {Component} from 'react'
import CommentSection from "../CommentSection";


class UltimateGuideToGeocoding extends Component {
    render() {
        return (
            <div>
                <h1>First of all, what is geocoding?</h1>
                    <p>Good question! Well, geocoding is the process of turning an address into a coordinate that specifies its latitude and longitude values. This is called <i>Forward Geocoding.</i></p>
                    <div style={{borderLeft: '5px solid', paddingLeft: '15px'}}>
                    <p><i>There is also reverse geocoding. Which allows you to convert latitude and longitude values into an address.</i></p>
                    </div>
                    <br/>
                    <p>Geocoding allows us to easily locate addresses on a map and is often used for mapping, geographic analysis, spatial analysis, etc.</p>
                    <img src="https://miro.medium.com/max/700/1*K2TyrFklYHTe_-k0H4WQ1Q.png"
                         alt="Clean Spreadsheets Geocode"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                <h1>Hmm, ok… So what’s this article about?</h1>
                    <p>I have analyzed and prepared a list of of several no-code tools and applications that don’t require any technical knowledge to Geocode.</p>
                    <p>All you need is a list or spreadsheet of addresses.</p>
                    <p>These tools accept lists of addresses, often in spreadsheets or csv files, and either do <i> Forward Geocoding</i> or <i>Reverse Geocoding,</i> or often both</p>
                    <p>This article will let you compare and contrast between several geocoding tools!</p>
                <h1>Without further ado, in no particular order, here are the best no-code Geocoding tools</h1>
                    <h3><b><a href="https://www.geocod.io/">1.Geocodio</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*uXGA-GApZdI60kMu"
                             alt="Geocodio"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b> Free base plan, premium plans start at $0.50 for 1,000 requets</p>
                        <p><b>Coverage:</b> US and Canada only</p>
                        <p><b>Pros:</b> Easy UX, Automatic address parsing, Excel and CSV support, Robust Documentation, Good value for price</p>
                        <p><b>Cons:</b> No International coverage</p>
                        <p>This website is very similar to Clean Spreadsheets in the case that it also reads addresses in any format, cleans it up for you, and fills in missing information. The difference in this tool is that it is primarily a geocoding tool, so it doesn’t help the user to reformat any other types of data.</p>
                        <p>However, it does make up for that by providing more information for each address, such as timezones and Congressional districts. In addition, they also let you view all your addresses in a map.</p>
                        <p>The thing I don’t like about the map is that their preview of your data is only in map form, but I’d like to have a preview of the geocoded values in a spreadsheet as well before I download it.</p>
                        <p>In addition, another downside is that this site only covers locations in the US and Canada. Despite those negatives, I still think that this is a great tool with a really easy-to-use website.</p>
                        <p>There is a free subscription to this site that allows for 2,500 lookups daily and then there are paid subscriptions after that, which cost $0.50 for every 1,000 lookups or $1,000 a month for unlimited lookups.</p>

                    <h3><b><a href="https://www.cleanspreadsheets.com/">2.Clean Spreadsheets</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*I72R1M556Ebdvo1s"
                             alt="Clean Spreadsheets"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b>Free trial, pay as you go pricing starting at $1 for 1,000 addresses</p>
                        <p><b>Coverage:</b>International</p>
                        <p><b>Pros:</b>Easy UX, Automatic address parsing, International coverage, Excel and CSV support, Good value for price</p>
                        <p><b>Cons:</b>Lacking robust support documentation</p>
                        <p>Clean Spreadsheets is our tool that along with geocoding, helps you reformat and clean up names, dates, company names.</p>
                        <p>The tool will automatically arrange your address components if they are split amongst different columns (you can rearrange to form your address string too!). And also does auto parsing for incomplete addresses.</p>
                        <p>It also provides you with a pretty nice preview of your results, which include a lot of metadata, so that you can go back and make edits as necessary.</p>
                        <p>It can geocode addresses internationally and has a free trial starting with 250 credits. Like many other sites, this operates on a pay as you go pricing after the free trial starting at $1 for 1,000 addresses.</p>
                        <p>The documentation is lacking in completeness and users sometimes need to use the chat support to get answers.</p>

                    <h3><b><a href="https://smartystreets.com/products/single-address">3.Smarty Streets</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*uJ0ZzJCUo0EiEK9B"
                             alt="Smarty Streets"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b>Free trial, subscriptions starting at $50 a month for 5000 US addresses and $7 a month for 100 International addresses</p>
                        <p><b>Coverage:</b>US and/or International</p>
                        <p><b>Pros:</b>Easy UX, Automatic address parsing, International coverage, Robust documentation</p>
                        <p><b>Cons:</b>Expensive subscription, No Excel or CSV support</p>
                        <p>Smarty Streets is an extremely user-friendly tool that requires a subscription that allows single address input in both field by field inputs or as a single line (which is a feature I love in these tools).</p>
                        <p>They also help you correct and autocomplete your address which is helpful.</p>
                        <p>Speaking of which, this also has a batch geocoding option but your spreadsheet must be formatted in a specific manner, which is fine for a free tool but, like for the last geocoder, is disappointing for a paid tool.</p>
                        <p>Going into that, the price is where I believe this tool is the weakest; US geocoding is free up to 250 lookups a month and plans start at $50 a month or $460 a year after that.</p>
                        <p>They also have international geocoding plans starting at $7 a month or $70 a year.</p>

                    <h3><b><a href="https://batchgeo.com/">4.Batchgeo</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*sSF0uJrx7seTDgWP"
                             alt="batchgeo"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b> Free base plan, premium plans starting at $15 a month</p>
                        <p><b>Coverage:</b> International</p>
                        <p><b>Pros:</b> International coverage, Good value for price, Good documentation</p>
                        <p><b>Cons:</b> No Excel or CSV Support, Poor UX, No automatic address parsing</p>
                        <p>Similar to Geocodio, Batchgeo actually maps out all of your addresses for you and gives you an option to save your map. However, they don’t return you an actual spreadsheet of geocoded coordinates.</p>
                        <p>The website is relatively easy to use but I did run into some annoyances. They allow you to copy and paste spreadsheet data into their input box in any format (though it is most accurate when in the format they provided), but I really wish they just had an upload file option.</p>
                        <p>In addition, I didn’t like that they ask you to save your map to your email before you even see the final result.</p>
                        <p>They start out with a free subscription and you can upgrade into Lite for $15 a month or Pro for $99 a month to receive additional features, such as password protection and PDF exporting. Most countries are included in their map, so it is international.</p>

                    <h3><b><a href="https://csv2geo.com/">5.CSV2GEO</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*wxI0wRjqJn7Cb2PO"
                             alt="CSV2Geo"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b>Free trial, pay as you go starting at $5 for 5000 or subscription starting at $49 a month</p>
                        <p><b>Coverage:</b>International</p>
                        <p><b>Pros:</b> International coverage, Good documentation, CSV File Support, Good value for Price</p>
                        <p><b>Cons:</b> No automatic address parsing, Poor UX, No Excel file support</p>
                        <p>I would call this website a less visually appealing version of Clean Spreadsheets or Geocodio.</p>
                        <p>It’s another batch geocoding website that receives a spreadsheet in any format and returns coordinates along with a map.</p>
                        <p>The only difference about this website is that it won’t automatically clean up addresses and they ask you to select what is included (such as “City”) in each column. However, that wasn’t too big of a hassle.</p>
                        <p>For pricing, there is a free trial that provides you up to 100 conversions a day and then options to perform more geocoding. They have a pay as you go option starting at $5 for 5000 locations or $49 a month, with increasing prices as you geocode more addresses.</p>
                        <p>Like most of the batch geocoding options I listed, this is also a worldwide tool.</p>

                    <h3><b><a href="https://geocode.localfocus.nl/">6.LocalFocus Batch geocoder for journalists</a></b></h3>
                        <img
                            src="https://miro.medium.com/max/700/1*W2-8pZWQHeV3vCx5OgyFTA.png"
                             alt="LocalFocus"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b> Free</p>
                        <p><b>Coverage:</b> International</p>
                        <p><b>Pros:</b> Easy UX, Automatic Address Parsing, International coverage, Free</p>
                        <p><b>Cons:</b> No Excel or CSV Support, Lacking robust documentation, feature deficient</p>
                        <p>This batch geocoder may not have a lot of features but I really liked it, particularly because it’s completely free. Similar to Batchgeo, you paste in your addresses (both plain text and spreadsheet data works), verify the locations on a map, and then (unlike Batchgeo) you can copy and paste the final coordinates back onto a spreadsheet.</p>
                        <p>I wish there was an upload and download option but they made the copy and pasting process so clean that I didn’t miss that too much. Like most other batch geocoders here, they also help to fill in your missing address information if you are missing something (like a zip code).</p>
                        <p>Furthermore, since they read information based on a single line, you don’t need to confine your data to a specific format in regards to spreadsheet columns.</p>

                    <h3><b><a href="https://maplarge.com/geocoder">7.MapLarge</a></b></h3>
                        <img src="https://miro.medium.com/max/700/1*xXai95WDOFh_IJxN2u4qFQ.png"
                             alt="MapLarge"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b> Free trial, pay as you go pricing starting at $10 for 2,500 requests</p>
                        <p><b>Coverage:</b> International</p>
                        <p><b>Pros:</b> International coverage, Excel & CSV File Support, Good value for price</p>
                        <p><b>Cons:</b> No automatic address parsing, Poor UX, Lacking robust documentation</p>
                        <p>MapLarge is another international geocoder with really simple features. You simply upload your spreadsheet (in any format, as long as it’s one address a row) and download the final spreadsheet with added coordinates. There aren’t any previews so it’s pretty straight to the point.</p>
                        <p>The downside to this site is that, for the few amount of features it has, it is a lot more expensive than some of the other sites on this list. It does have a free trial but only for the first 100 requests that you make. Along with that, the website isn’t as clean as some of the others on this list so, like CSV2GEO, it’s another less visually appealing version of Clean Spreadsheets and Geocodio.</p>

                    <h3><b><a href="https://geocoding.geo.census.gov/">8.US Census Bureau Geocoder</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*cMmqLL5PmIguL-53"
                             alt="US Census Bureaus"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b> Free</p>
                        <p><b>Coverage:</b> US only</p>
                        <p><b>Pros:</b> Great value for price, Good documentation, Excel and CSV support</p>
                        <p><b>Cons:</b> No international coverage, Poor UX, No automatic address parsing</p>
                        <p>This is a popular choice for Geocoding US addresses.</p>
                        <p>Under the “Find Locations Using…” option, users are able to find the geocode of one address or a spreadsheet/batch of addresses (but those must be formatted in a specific manner).</p>
                        <p>For spreadsheets, up to 10,000 addresses can be processed at a time. This website uses addresses collected through the US census, so the data inside of this geocoder consists mainly of residential addresses.</p>
                        <p>This means that some US commercial areas may have limited ranges. However, this tool and relatively easy to understand and use, despite the website seeming a bit dated and confusing at first.</p>

                    <h3><b><a href="http://geoservices.tamu.edu/Services/Geocode/BatchProcess/">9. Texas A&M Geoservices</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*doVBiY4whOprq18T"
                             alt="Texas Geoservices"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b> Free trial for batch geocoding, pay as you go starting at $10 for 500 lookups</p>
                        <p><b>Coverage:</b> US only</p>
                        <p><b>Pros:</b> Good documentation, Good value for price</p>
                        <p><b>Cons:</b> Poor UX, No automatic address parsing, No international coverage, Lacking good documentation</p>
                        <p>Texas A&M Geoservices is another popular choice and, like the US Census Bureau, it uses US census data and only covers addresses within the US.</p>
                        <p>There’s a batch option that also requires data in a specific format. However, only the single address lookup is free while batch geocoding requires a subscription.</p>
                        <p>These subscriptions start out with 2500 free credits (or just 2500 address lookups) and you can buy more credits as you go, which range from $10 for 500 credits to $1200 for 1,000,000 credits.</p>
                        <p>I personally prefer using the US Census Bureau geocoder over this, especially due to the pricing and similar results.</p>

                    <h3><b><a href="https://www.mapdevelopers.com/geocode_tool.php">10. Map Developers</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*yYEvPhVxTUQQ0Cv1"
                             alt="Map Developers"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b> Free</p>
                        <p><b>Coverage:</b> International </p>
                        <p><b>Pros:</b> Good value for price, International Coverage</p>
                        <p><b>Cons:</b> No spreadsheet or CSV option, Poor UX, No automatic address parsing, Lacks good documentation</p>
                        <p>Map Developers is an international geocoding tool and uses information from Google Maps in order to retrieve data.</p>
                        <p>The great feature of this website is the batch geocode tool under “Map Tools”, which allows you to geocode several addresses by separating each address by one line.</p>
                        <p>It’s a really simple, easy-to-understand text field that works great for a small batch of locations.</p>
                        <p>Unfortunately, you can’t upload your spreadsheets to this website, but the website is completely free (though it consists of many advertisements).</p>

                    <h3><b><a href="https://geocode.xyz/">11.Geocode XYZ</a></b></h3>
                        <img src="https://miro.medium.com/max/700/0*qKBQfYJ7ZgYvuvt9"
                             alt="Frustrated Man"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p><b>Price:</b> Free single address lookup; batch geocoding starts at $2.50 for 1,000 requests or $225 a month</p>
                        <p><b>Coverage:</b> International</p>
                        <p><b>Pros:</b> Easy UX, International Coverage, CSV File Support, Decent value for price</p>
                        <p><b>Cons:</b> No Excel support, No automatic address parsing</p>
                        <p>Geocode XYZ is an international geocoder that’s extremely easy to use. The input does have to be formatted a specific way which can be a bit confusing at first (for instance, the worldwide geocode input shouldn’t include the state name) but is easy to get used to.</p>
                        <p>The single geocode option is completely free, but the batch geocode option and geocode API does require a subscription, which is $2.50 per 1,000 requests or about $225 a month for unlimited requests.</p>
                        <p>The batch geocode option also requires spreadsheet data to be in a specific format, which I think is disappointing for a paid tool.</p>
                <br/>
                <hr/>
                <h1>What about if I want to use a tool that lets you Geocode using programming?</h1>
                    <p>Geocoding using programming requires tools that have REST APIs. Here are some APIs you can use to Geocode.</p>
                <ol>
                    <li><a href="https://opencagedata.com/">Open Cage Data</a></li>
                    <li><a href="https://www.geocod.io/features/api/">Geocodio</a></li>
                    <li><a href="https://developer.mapquest.com/documentation/geocoding-api/">MapQuest</a></li>
                    <li><a href="https://locationiq.com/">LocationIQ</a></li>
                    <li><a href="https://positionstack.com/">PositionStack</a></li>
                    <li><a href="https://developer.tomtom.com/search-api/search-api-documentation-geocoding/geocode">TomTom</a></li>
                    <li><a href="https://geocode.xyz/api">Geocode.xyz</a></li>
                    <li><a href="https://geocodeapi.io/">Geocodeapi</a></li>
                    <li><a href="https://geocode.earth/cloud">Geocode Earth</a></li>
                    <li><a href="https://maplarge.com/developer/geocoderapi">MapLarge</a></li>
                    <li><a href="https://www.maptiler.com/news/2019/09/announcing-maptiler-cloud-geocoding-api/">MapTiler</a></li>
                    <li><a href="https://www.loqate.com/en-us/geocode/">Loqate</a></li>
                </ol>
                <h1>Wrapping Everything Up</h1>
                <p>Woah, you made it to the end of this article. That means that you now have a pretty good idea about the no-code geocoding tools out there!</p>
                <p>If you still have questions, don’t hesitate to reach out to us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a></p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://cleanspreadsheets.com/guides/ultimate-guide-to-geocoding-tools" data-numposts="5" data-width="" />}/>
            </div>

        )
    }
}

export default  UltimateGuideToGeocoding