import React, {Component} from 'react'
import images from "../../../Constants/Images";
import FAQBlock from "../FAQBlock";
import DateExample from "../../../LandingPage/DetailExamples/DateExample";

const dateFAQ = [
    {question: 'Can you really automatically clean and split any date?', answer: 'Yes! Any reasonable date will be automatically cleaned to the YYYY-MM-DD HH:MM:SS format..'},
    {question: 'What does reasonable mean?', answer: "There needs to something in the value that indicates a date. For example, 3-232ABC will not be read as a date."},
    {question: 'What about DD/MM/YYYY or MM/DD/YYYY formats?', answer: "We automatically figure out what format it is. However, there are some dates that can reasonably fit both formats such as 2/11 or 5/8. In those cases we default to MM/DD/YYYY because we are an American company with primarily American customers."},
    {question: 'How does the final output look like?', answer: <div>The final output from cleaning a column of dates will look like the following <br/> <br/> <DateExample faq /> </div>},
    {question: "What if my value isn't a date?", answer: "If your value isn't a date, then the Cleaned Value and the components will be completely blank."}
]

class Date extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <FAQBlock
                        title="Date" icon={images.dateIcon}
                        FAQs={dateFAQ}
                        dataType
                    />
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default Date