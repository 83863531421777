import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class APINewUserToolbar extends Component {
    constructor(props){
        super(props);

        this.closeSegment = this.closeSegment.bind(this);
        this.changeMenuItem = this.changeMenuItem.bind(this);
    }

    closeSegment(){
        this.props.toggleIsNewUser(false)
    }

    changeMenuItem(e, menuItem){
        this.props.changeMenuItem(menuItem)
    }

    render() {
        const headerStyle = {
            textAlign: 'center',
            color: 'lightblue'
        };

        const iconStyle = {
            textAlign: 'center',
            color: 'lightgray',
            fontSize: '250%'
        }

        return (
            <div className="segment-raised segment-no-margin" style={{backgroundColor: 'lightslategrey', padding: '30px'}}>
                <div style={{marginBottom: '10px'}}>
                    <div className="headerWithCloseDiv">
                        <div className="headerWithCloseText">
                            <h2 className="thinHeading" style={{color: 'white'}}>Get Started with the API</h2>
                        </div>
                        <div className="headerWithCloseButton">
                            <div className="closeIconClass" onClick={this.closeSegment}>
                                <Icon name="remove" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dashboardSegmentBody'>
                    <div className="row" style={{paddingTop: '15px'}}>
                        <div className="col-sm-6">
                            <div className="apiDocSection sectionHover" style={{cursor: 'pointer'}} onClick={(e) => this.changeMenuItem(e, 'code')}>
                                <h5 style={headerStyle}>Use Python, PHP, Ruby, JS Code Samples</h5>
                                <div style={iconStyle}><Icon name="code" /></div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="apiDocSection sectionHover" style={{cursor: 'pointer'}} onClick={(e) => this.changeMenuItem(e, 'guides')}>
                                <h5 style={headerStyle}>Quickstart Guide on How the API Works</h5>
                                <div style={iconStyle}><Icon name="file code outline" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default APINewUserToolbar