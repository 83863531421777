import React, {Component} from 'react'
import PaymentSegment from "../../../SharedComponents/Payment/RefillCreditsModal/PaymentSegment"
import PaymentInfo from "../../../SharedComponents/Payment/RefillCreditsModal/PaymentInfo"
import { connect } from 'react-redux'
import addCommaToNumbers from "../../../Constants/addCommaToNumbers";
import {shouldFetchInfo} from "../../../Constants/shouldFetchInfo";
import API_Root from "../../../Constants/API_Root";
import {modifyPaymentInfo} from "../../../Actions/ModifyPaymentInfo";
import {modifyUserCredits} from "../../../Actions/ModifyUserCredits";
import {modifyFetchedTime} from "../../../Actions/ModifyFetchedTime";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import {modifySubscriptionInfo} from "../../../Actions/ModifySubscriptionInfo";
import { Icon } from 'semantic-ui-react'
import {toggleModal} from "../../../Actions/ToggleModal";

class PayAsYouGoCredits extends Component {
    constructor(props){
        super(props);

        this.state = {
            creditType: "pro"
        }
    }

    switchCreditType = (creditType) => {
        this.setState({ creditType: creditType })
    }

    openProVsBasicModal = () => {
        this.props.toggleModal("proVsBasicModal")
    }

    openCreditConversionModal = () => {
        this.props.toggleModal("creditConversionModal")
    }

    openDownloadReceiptModal = () => {
        this.props.toggleModal("downloadReceiptsModal")
    }

    render() {
        return (
            <div id="profileIndex" className="creditsIndex">
                <div className="row">
                    <div className="creditsIndexSelection col-sm-6">
                        <h4>Choose whether to purchase Pro or Basic Credits</h4>
                        <p className="linkStyle" onClick={this.openProVsBasicModal}>Read about the difference between Pro vs Basic Credits</p>
                        <br/>
                        <div>
                            <span><span className="creditsIndexProSelection" onClick={e => this.switchCreditType("pro")} style={{backgroundColor: this.state.creditType === "pro" ? "#0081ff" : "white", color: this.state.creditType === "pro" ? "white" : "black"}}>PRO</span><span className="creditsIndexBasicSelection" onClick={e => this.switchCreditType("basic")} style={{backgroundColor: this.state.creditType === "basic" ? "#0081ff" : "white", color: this.state.creditType === "basic" ? "white" : "black"}}>BASIC</span></span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={{float: "right"}}>
                            <button onClick={this.openCreditConversionModal} className="updatedCTA" style={{backgroundColor: "orange", fontWeight: "bold"}}>
                                <Icon name="recycle" /> {"convert between pro and basic credits".toUpperCase()}
                            </button>
                            <br/><br/>
                            {
                                this.props.paymentInfo === null ? null : <button onClick={this.openDownloadReceiptModal} className="updatedCTA" style={{backgroundColor: "#0081ff", fontWeight: "bold"}}>
                                    <Icon name="file pdf outline" /> DOWNLOAD PURCHASE RECEIPTS
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <BrowserView>
                    <div style={{display: 'grid', gridTemplateColumns: '50% 50%'}}>
                        <div className="creditsSegment" style={{backgroundColor: 'white', margin: '10px', textAlign: 'center'}}>
                            <PaymentSegment creditsIndex isPro={this.state.creditType === "pro"}/>
                        </div>
                        <div className="creditsSegment" style={{backgroundColor: 'white', margin: '10px'}}>
                            <PaymentInfo isPro={this.state.creditType === "pro"}/>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="creditsSegment" style={{backgroundColor: 'white', margin: '10px', textAlign: 'center'}}>
                                <PaymentSegment creditsIndex isPro={this.state.creditType === "pro"}/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="creditsSegment" style={{backgroundColor: 'white', margin: '10px', textAlign: 'center'}}>
                                <PaymentInfo isPro={this.state.creditType === "pro"}/>
                            </div>
                        </div>
                    </div>
                </MobileView>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userCredits: state.mainState.userCredits,
    profileInformationFetchedTime: state.mainState.profileInformationFetchedTime,
    subscriptionInfo: state.mainState.subscriptionInfo,
    paymentInfo: state.mainState.paymentInfo
})

const mapActionsToProps = {
    modifyUserCredits: modifyUserCredits,
    modifyFetchedTime: modifyFetchedTime,
    modifyPaymentInfo: modifyPaymentInfo,
    modifySubscriptionInfo: modifySubscriptionInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(PayAsYouGoCredits)