import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { modifyAPIKeys } from "../Actions/ModifyAPIKeys";
import API_Root from "../Constants/API_Root";

class ResetKey extends Component {
    constructor(props) {
        super(props);

        this.closePopup = this.closePopup.bind(this);
        this.resetAPIKey = this.resetAPIKey.bind(this);
    }

    closePopup(e){
        this.props.togglePopup(e, false);
    }

    resetAPIKey(e){
        this.props.isFetchingAPIKeys(true);

        fetch(API_Root + 'api/reset-woyera-api-key/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
            })
        }).then((res) => {
            if (res.status === 201) {
                res.json().then((data) => {
                    this.props.modifyAPIKeys(data.apiKeys)
                    this.props.isFetchingAPIKeys(false);
                }).catch((err) => {
                    this.props.modifyAPIKeys(null)
                    this.props.isFetchingAPIKeys(false);
                })
            }
            else {
                this.props.modifyAPIKeys(null)
                this.props.isFetchingAPIKeys(false);
            }
        }).catch((err) => {
            this.props.modifyAPIKeys(null)
            this.props.isFetchingAPIKeys(false);
        })
    }

    render() {
        return (
            <div>
                <h6>Are you sure you want to reset your API Key?</h6>
                <div>
                    <span style={{cursor: 'pointer', float: 'left', color: 'green'}} onClick={this.closePopup}><Icon name="dont" />No, don't reset</span>
                    <span style={{cursor: "pointer", float: 'right', color: 'red'}} onClick={this.resetAPIKey}><Icon name="check circle outline"/>Yes, reset key</span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    modifyAPIKeys: modifyAPIKeys,
}

export default connect(mapStateToProps, mapActionsToProps)(ResetKey)