export const INIT_SAMPLE_RESULTS = 'INIT_SAMPLE_RESULTS';

export function initSampleResults(sampleResults, proSampleResults, cleanedSource){
    return{
        type: INIT_SAMPLE_RESULTS,
        mainState: {
            sampleResults: sampleResults,
            proSampleResults: proSampleResults,
            cleanedSource: cleanedSource,
            cleanedSourceIdx: cleanedSource !== undefined ? cleanedSource.length > 0 ? 0 : -1 : -1
        }
    }
}