import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifySelectedColumns} from "../../../Actions/ModifySelectedColumns";
import { Checkbox, Dropdown } from 'semantic-ui-react'
import {modifyColumnCleanTypes} from "../../../Actions/ModifyColumnCleanTypes";
import Images from "../../../Constants/Images"
import {modifyStepsCompleted} from "../../../Actions/ModifyStepsCompleted";
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";

class SelectColumn extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    handleInputChange(){
        if (this.props.selectedColumns.length === 1){
            if (this.props.selectedColumns[0] === this.props.colIdx){
                this.props.modifyStepsCompleted(0)
            }
        }
        this.props.modifySelectedColumns(this.props.selectedColumns, this.props.colIdx);

        const currColumnTypes = JSON.parse(JSON.stringify(this.props.columnCleanTypes));
        currColumnTypes[this.props.colIdx] = this.props.uploadDataType;

        this.props.modifyColumnCleanTypes(currColumnTypes)
    }

    handleDropdownChange(event, { value }){
        const currColumnTypes = JSON.parse(JSON.stringify(this.props.columnCleanTypes));
        currColumnTypes[this.props.colIdx] = value;

        this.props.modifyColumnCleanTypes(currColumnTypes)
    }

    render() {
        const checked = this.props.selectedColumns.includes(this.props.colIdx);
        const dropdownOptions = [
            {key: 0, value: "name", text: "Name", image: {avatar: true, src: Images.nameIcon}},
            {key: 1, value: "address", text: "Address", image: {avatar: true, src: Images.addressIcon}},
            {key: 2, value: "date", text: "Date", image: {avatar: true, src: Images.dateIcon}},
            // {key: 3, value: "email", text: "Email", image: {avatar: true, src: Images.emailIcon}},
            {key: 4, value: "company", text: "Company", image: {avatar: true, src: Images.companyIcon}},
        ]

        const cleanTypeDropdown = <Dropdown
            options={dropdownOptions}
            value={this.props.columnCleanTypes[this.props.colIdx]}
            onChange={this.handleDropdownChange}
            selection
            placeholder="Select Clean Type"
            fluid
            style={{marginTop: '10px'}}
        />;

        const columnSelectWarning = !(["name", "address", "date", "company"].includes(this.props.columnCleanTypes[this.props.colIdx])) && checked ? <div style={{color: 'red'}}><small><i>Select a clean type</i></small></div> : null;

        return (
            <span style={{textAlign: 'center'}}>
                <Checkbox toggle onChange={this.handleInputChange}
                          // label="Clean Column?"
                          checked={checked}/>
            </span>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
    uploadDataType: state.mainState.uploadDataType
})

const mapActionsToProps = {
    modifySelectedColumns: modifySelectedColumns,
    modifyColumnCleanTypes: modifyColumnCleanTypes,
    modifyStepsCompleted: modifyStepsCompleted,
    modifyTutorialStep: modifyTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(SelectColumn)