import React, {Component} from 'react'
import { connect } from 'react-redux'
import AddressColumnDropTarget from "./AddressColumnDropTarget";
import AddressColumnDropSource from "./AddressColumnDropSource";
import AddressColumnDropBar from "./AddressColumnDropBar";
import AddressReverseSelection from "./AddressReverseSelection";
import AddCleaningStepButton from "../../AddCleaningStepButton";

class AddressColumns extends Component {
    render() {
        if ('columnNames' in this.props.fileInformation){
            if (this.props.cleanOptions.addressColumn === "multiple"){
                if (this.props.cleanOptions.addressGeocode === "forward"){
                    const addressColumns = this.props.cleanOptions.addressGeocode === "forward" ? this.props.addressColumns.forward : this.props.addressColumns.reverse;

                    let addressColDivs = addressColumns.map((x, idx) => <AddressColumnDropTarget dropSource={<AddressColumnDropSource colName={x} idx={idx} colType={this.props.cleanOptions.addressGeocode}/>} key={idx} idx={idx}/>)
                    addressColDivs.push(<AddressColumnDropTarget dropSource={null} key={addressColDivs.length} idx={addressColDivs.length}/>)

                    let colNames = this.props.fileInformation.columnNames;

                    const colNameDivs = colNames.map((x, idx) => addressColumns.includes(x) ? null : <AddressColumnDropSource colName={x} key={idx} idx={idx} colType="colName"/>)

                    return (
                        <div className="addressColumnBox">
                            <h4>Arrange Your Columns to form your address</h4>
                            <AddressColumnDropBar addressColDivs={addressColDivs}/>
                            <div className="allColTags">
                                {colNameDivs}
                            </div>
                            <div>
                                <small style={{color: 'blue'}}>Drag and Drop to form your address</small>
                            </div>
                            <AddCleaningStepButton />
                        </div>
                    )
                } else if (this.props.cleanOptions.addressGeocode === "reverse"){
                    return(
                        <div className="addressColumnBox">
                            <AddressReverseSelection />
                            <AddCleaningStepButton />
                        </div>
                    )
                } else {
                    return (
                        null
                    )
                }

            }
            else{
                return(
                    null
                )
            }
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    cleanOptions: state.mainState.cleanOptions,
    addressColumns: state.mainState.addressColumns
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(AddressColumns)