import React, {Component} from 'react'
import DisplayBox from "../DisplayBox";
import ReactJson from 'react-json-view'

class Address extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <p>Cleaning an address will do three things. For an example address <i>415 w 52nd St, new york, NY</i>:
                            <ol>
                                <li>Give a consistently formatted address <i>415 West 52nd Street, New York, NY 10019, United States of America</i></li>
                                <li>Return the latitude <i>40.765259</i> and longitude <i>-73.9887379</i></li>
                                <li>Split the address into components as seen below</li>
                            </ol>
                        </p>
                    <div className="apiDocSection">
                        <h5>Formatted String</h5>
                        <p>The formatted address unfortunately does not have a strict format for each address as the location and underlying
                        data changes per location. Generally, it will have a <b>house street_number city, state zip_code, country</b> format, but it could change variably.</p>
                    </div>
                    <br/>
                    <div className="apiDocSection">
                        <h5>Split Object Components</h5>
                        <p>Similar to the formatted string, the various keys of the components vary widely. You can view a sample response
                        on the right to see what kind of things on return but shouldn't rely on a particular component being present everytime.</p>
                    </div>
                    <br/>
                    <div className="apiDocSection">
                        <h5 style={{color: 'green'}}><i>There are two more parameters in the address response object</i></h5>
                        <p><span className="parameterName">lat</span> <span className="parameterType">decimal</span></p>
                        <p className="parameterDesc">Latitude coordinates of the address</p>
                        <p><span className="parameterName">lng</span> <span className="parameterType">decimal</span></p>
                        <p className="parameterDesc">Longitude coordinates of the address</p>
                    </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <DisplayBox
                        header={<span style={{color: 'green'}}>Sample Response for <i>415 w 52nd St, new york, NY</i></span>}
                        desc={<div>
                            <ReactJson src={[{"formatted":"415 West 52nd Street, New York, NY 10019, United States of America","score":100,"split":{"ISO_3166-1_alpha-2":"US","ISO_3166-1_alpha-3":"USA","_type":"building","city":"New York","continent":"North America","country":"USA","country_code":"us","county":"New York County","house_number":"415","postcode":"10019","road":"West 52nd Street","state":"New York","state_code":"NY","suburb":"Manhattan"},"lat":40.765259,"lng":-73.9887379,"error":false,"input":"415 w 52nd St, new york, NY"}]}
                                       name={null}
                                       theme="ocean"
                                       displayObjectSize={false}/>
                        </div>}
                    />
                    <br/><br/><br/>
                </div>
            </div>
        )
    }
}

export default Address