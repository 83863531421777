import React, {Component} from 'react'
import ContactUsForm from "../../../InfoPages/ContactUs/ContactUsForm";

class Help extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <p>
                        Our goal is to provide excellent cleaning services for customer data. On a world-wide level, this task is very challenging and hard.
                        We do our absolute best in cataloging and refining our algorithms to do cleaning at the highest level. However, sometimes
                        obscure or unrecognized patterns slip through for names, addresses, company names and dates, especially for regions we get low volume of cleaning.
                    </p>
                    <p>
                        We request that you please contact us if you see certain data not being cleaned properly if it should. We frequently increase your limit based on how much you help us.
                    </p>
                    <p>
                        We have a similar ask for help for Security Issues. If you find any security holes in our API, please contact us and let us know. In these cases, we give you a 75% discount on your plan.
                    </p>
                </div>
                <div className="col-sm-6">
                    <ContactUsForm/>
                </div>
            </div>
        )
    }
}

export default Help