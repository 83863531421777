import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleInAddressDropZone} from "../../../../../../Actions/ToggleInAddressDropZone";
import {modifyAddressDropIdx} from "../../../../../../Actions/ModifyAddressDropIdx";
import {modifyAddressColumns} from "../../../../../../Actions/ModifyAddressColumns";
import isObjectEmpty from "../../../../../../Constants/isObjectEmpty";

class AddressColumnDropBar extends Component {
    constructor(props) {
        super(props);

        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    handleDragEnter(e){
        e.preventDefault();
        e.stopPropagation();

        const geocodeType = this.props.cleanOptions.addressGeocode;

        this.props.modifyAddressDropIdx(this.props.addressColumns[geocodeType].length)
        console.log("dragenter")
    }

    handleDragLeave(e){
        e.preventDefault();
        e.stopPropagation();

        this.props.toggleInAddressDropZone(false)
        this.props.modifyAddressDropIdx(-1)

        console.log("dragleave")
    }

    handleDragOver(e){
        e.preventDefault();
        e.stopPropagation();

        console.log("dragover")
        this.props.modifyAddressDropIdx(this.props.idx)
    }

    handleDrop(e) {
        console.log("drop")
        const geocodeType = this.props.cleanOptions.addressGeocode;
        const colDraggedType = this.props.columnBeingDragged.colType;
        const colDraggedIdx = this.props.columnBeingDragged.idx;

        const copiedAddrColumns = JSON.parse(JSON.stringify(this.props.addressColumns))
        let geocodeTypeAddr = copiedAddrColumns[geocodeType]

        let colName;
        if (colDraggedType === "colName") {
            colName = this.props.fileInformation.columnNames[colDraggedIdx]
        } else {
            colName = geocodeTypeAddr[colDraggedIdx]

            geocodeTypeAddr.splice(colDraggedIdx, 1)
        }

        geocodeTypeAddr.push(colName)

        copiedAddrColumns[geocodeType] = geocodeTypeAddr
        this.props.modifyAddressColumns(copiedAddrColumns)
        e.preventDefault();
        e.stopPropagation();

    }

    render() {
        return (
            <div className="addressColumnTags"
                 onDrop={this.handleDrop}
                 onDragOver={this.handleDragOver}
                 onDragEnter={this.handleDragEnter}
                 onDragLeave={this.handleDragLeave}>
                {this.props.addressColDivs.length === 1 ?  <h4 style={{color: 'red'}}>Drag and drop your column names here to form your address</h4> : this.props.addressColDivs}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    addressDropIdx: state.mainState.addressDropIdx,
    inAddressDropZone: state.mainState.inAddressDropZone,
    addressColumns: state.mainState.addressColumns,
    columnBeingDragged: state.mainState.columnBeingDragged,
    cleanOptions: state.mainState.cleanOptions,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyAddressColumns: modifyAddressColumns,
    toggleInAddressDropZone: toggleInAddressDropZone,
    modifyAddressDropIdx: modifyAddressDropIdx
}


export default connect(mapStateToProps, mapActionsToProps)(AddressColumnDropBar)