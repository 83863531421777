import React, {Component} from 'react'
import ExampleTable from './ExampleTable'
import ValidOrInvalidTag from "./EmailTags/ValidOrInvalidTag";

class EmailDetails extends Component {
    render() {
        const defectInfo = {type: 'dateFormats', rows: {
                values: ['joecool@gmail,,,com', 'first.last@somecompany.org', 'khaleesi@redkeep.c', 'monkey bananas', 'ceo@agency.biz', '!@!abc@gmail.com', 'tinafey@blahblah.a', 'az@reddit....com', 'fname@twitter.com.,.~'],
                suggestions: [<span>joecool@gmail.com <ValidOrInvalidTag valid={true}/></span>,
                    <span>first.last@somecompany.org <ValidOrInvalidTag valid={true}/></span>,
                    <span>khaleesi@redkeep.com <ValidOrInvalidTag valid={false}/></span>,
                    <span>INVALID <ValidOrInvalidTag valid={false}/></span>,
                    <span>ceo@agency.biz <ValidOrInvalidTag valid={true}/></span>,
                    <span>abc@gmail.com <ValidOrInvalidTag valid={false}/></span>,
                    <span>INVALID <ValidOrInvalidTag valid={false}/></span>,
                    <span>az@reddit.com <ValidOrInvalidTag valid={true}/></span>,
                    <span>fname@twitter.com <ValidOrInvalidTag valid={false}/></span>],
                splitComponents: [
                    [["username", "emma.watson"], ["domain", "gmail.com"]],
                    [["username", "alan.turing"], ["domain", "london.org"]],
                    [["username", "khaleesi"], ["domain", "redkeep.com"]]
                ]
            }
        }

        return (
            <div>
                <h3>Correct Formatting & Verify if Email is Real</h3>
                <br/>
                <ExampleTable defectInfo={defectInfo}/>
            </div>
        )
    }
}

export default EmailDetails