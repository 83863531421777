import React, {Component} from 'react'
import {splitKeys} from "../../../../Constants/splitKeys";
import { Label } from 'semantic-ui-react'
import { connect } from 'react-redux'

class FormattedResults extends Component {
    constructor(props) {
        super(props);

        this.getFormattedResult = this.getFormattedResult.bind(this);
        this.getNotesResultList = this.getNotesResultList.bind(this);
        this.getSplitKeys = this.getSplitKeys.bind(this);
        this.getInputValue = this.getInputValue.bind(this);
    }

    getFormattedResult(resultObject){
        if (resultObject.error){
            return ''
        }
        else{
            if (this.props.cleanType === "notes"){
                return this.getNotesResultList(resultObject.formatted)
            } else {
                return resultObject.formatted
            }
        }
    }

    getNotesResultList(formattedValue){
        if (formattedValue.trim() !== ""){
            return <div>{formattedValue.split(",").map((x, idx) => <Label key={idx} style={{margin: "5px"}}>{x.trim()}</Label>)}</div>
        } else {
            return ""
        }
    }

    getScore(resultObject){
        if (resultObject.error){
            return ''
        } else {
            return resultObject.score.toString()
        }
    }

    getSplitKeys(resultsToDisplay){
        if (this.props.cleanType === "address"){
            let addrSplitKeys = [];

            for (let i=0; i<resultsToDisplay; i++){
                if ("split" in this.props.results[i][0]){
                    for (let key in this.props.results[i][0]["split"]){
                        if (!addrSplitKeys.includes(key)){
                            addrSplitKeys.push(key)
                        }
                    }
                }
            }

            return addrSplitKeys
        }
        else{
            let tableSplitKeys = splitKeys[this.props.cleanType]

            if ("split" in this.props.results[0][0]){
                tableSplitKeys = []
                for (let key in this.props.results[0][0]["split"]){
                    tableSplitKeys.push(key)
                }
            }

            return tableSplitKeys
        }
    }

    getInputValue(inputValue){
        if (this.props.cleanedSource.type === "reverseAddress"){
            let coord = inputValue.split("|")
            return "latitude: " + coord[0] + "\nlongitude: " + coord[1] + "\n"
        } else if (this.props.cleanedSource.type === "forwardAddress"){
            let addrSplit = inputValue.split(",")
            return addrSplit.join(", ")
        } else{
            return inputValue
        }
    }

    getColumnHeaders(header, idx){
        if (this.props.isPro){
            return header + "_" + (idx+1).toString()
        } else {
            return header
        }
    }

    render() {
        let body = null;
        if (Array.isArray(this.props.results)){
            if (this.props.results.length > 0){
                // Make the Table Rows, the Header and if there is a selector or not
                const resultsToDisplay = this.props.isSample ? this.props.results.length > 5 ? 5 : this.props.results.length : this.props.results.length;
                const resultSplitKeys = this.getSplitKeys(resultsToDisplay);

                let tableRows = [];

                const maxMatches = this.props.isPro ? this.props.matches : 1;

                for (let i=0; i<resultsToDisplay; i++){
                    let row = [];
                    const resultSet = this.props.results[i];

                    for (let resultSetIdx=0; resultSetIdx < maxMatches; resultSetIdx++){
                        let results = null;
                        if (resultSetIdx < resultSet.length){
                            results = resultSet[resultSetIdx]
                        }

                        // Input value
                        if (resultSetIdx === 0){
                            row.push(<td className="formattedResultsCell errorCell" key={i.toString() + "0"}>{this.getInputValue(this.props.results[i][0].input)}</td>)
                        }

                        // Formatted Value
                        const formattedValue = results === null ? "" : this.getFormattedResult(results)
                        row.push(<td className="formattedResultsCell successCell" key={i.toString() + "1" + resultSetIdx.toString()}>{formattedValue}</td>)

                        // Score
                        const score = results === null ? "" : this.getScore(results)
                        row.push(<td className="formattedResultsCell successCell" key={i.toString() + "2" + resultSetIdx.toString()}>{score}</td>)

                        if (this.props.cleanType === "address"){
                            let lat = "";
                            let lng = "";

                            if (results !== null){
                                lat = 'lat' in results ? results['lat'] : "";
                                lng = 'lng' in results ? results['lng'] : "";
                            }

                            row.push(<td className="formattedResultsCell successCell" key={i.toString() + "-1" + resultSetIdx.toString()}>{lat}</td>)
                            row.push(<td className="formattedResultsCell successCell" key={i.toString() + "-2" + resultSetIdx.toString()}>{lng}</td>)

                        } else if (this.props.cleanType === "email" || this.props.cleanType === "phone"){
                            if (this.props.isPro){
                                if (results !== null){
                                    row.push(<td className="formattedResultsCell" key={i.toString() + "-1" + resultSetIdx.toString()}>{'valid' in results ? results['valid'] : ""}</td> )
                                } else {
                                    row.push(<td className="formattedResultsCell" key={i.toString() + "-1" + resultSetIdx.toString()}> </td> )
                                }
                            }
                        } else if (this.props.cleanType === "currency") {
                            if (this.props.isPro) {
                                if (results !== null){
                                    row.push(<td className="formattedResultsCell" key={i.toString() + "-1" + resultSetIdx.toString()}>{'usd' in results ? results['usd'] : ""}</td>)
                                } else {
                                    row.push(<td className="formattedResultsCell" key={i.toString() + "-1" + resultSetIdx.toString()}> </td>)
                                }

                            }
                        }

                        for (let j=0; j<resultSplitKeys.length; j++){
                            let val = "";

                            if (results !== null){
                                if ("split" in results){
                                    if (resultSplitKeys[j] in results["split"]){
                                        val = results["split"][resultSplitKeys[j]]
                                    }
                                }
                            }

                            if (this.props.cleanType === "notes"){
                                row.push(<td className="formattedResultsCell" key={i.toString() + (j + 3).toString() + resultSetIdx.toString()}>{this.getNotesResultList(val)}</td>)
                            } else {
                                row.push(<td className="formattedResultsCell" key={i.toString() + (j + 3).toString() + resultSetIdx.toString()}>{val}</td>)
                            }
                        }
                    }

                    tableRows.push(<tr key={i.toString()}>{row}</tr>)
                }

                let tableHeaderValues = []

                // Input Value
                tableHeaderValues.push(<th className="formattedResultsHeader leftHeaderCell" key="0">Your Value</th>)
                let colCount = 1;

                for (let matchIdx=0; matchIdx < maxMatches; matchIdx++){
                    let tableHeaderClassName = "formattedResultsHeader";

                    tableHeaderValues.push(<th className="formattedResultsHeader" key={"1" + matchIdx.toString()}>{this.getColumnHeaders("Cleaned Value", matchIdx)}</th>)
                    colCount += 1

                    tableHeaderValues.push(<th className="formattedResultsHeader" key={"2" + matchIdx.toString()}>{this.getColumnHeaders("Match Score", matchIdx)}</th>)
                    colCount += 1

                    if (this.props.cleanType === "address"){
                        tableHeaderValues.push(<th className="formattedResultsHeader" key={"-1" + matchIdx.toString()}>{this.getColumnHeaders("Latitude", matchIdx)}</th>)
                        tableHeaderValues.push(<th className="formattedResultsHeader" key={"-2" + matchIdx.toString()}>{this.getColumnHeaders("Longitude", matchIdx)}</th>)

                        colCount += 2;
                    } else if (this.props.cleanType === "email" || this.props.cleanType === "phone"){
                        if (this.props.isPro){
                            tableHeaderValues.push(<th className="formattedResultsHeader" key={"-1" + matchIdx.toString()}>{this.getColumnHeaders("Valid", matchIdx)}</th> )
                            colCount += 1;
                        }
                    } else if (this.props.cleanType === "currency") {
                        if (this.props.isPro){
                            tableHeaderValues.push(<th className="formattedResultsHeader" key={"-1" + matchIdx.toString()}>{this.getColumnHeaders("USD", matchIdx)}</th> )
                            colCount += 1;
                        }
                    }

                    for (let i=0; i<resultSplitKeys.length; i++){
                        if (i===resultSplitKeys.length - 1){
                            tableHeaderClassName += " rightHeaderCell"
                        }
                        tableHeaderValues.push(<th key={(i + 3).toString() + matchIdx.toString()} className={tableHeaderClassName}>{this.getColumnHeaders(resultSplitKeys[i], matchIdx)}</th>)
                        colCount += 1;
                    }
                }

                let cleaningStyleHeader;
                let cleaningStyleHeaderClassName = "cleaningStyleDisclaimer";

                if (this.props.isPro){
                    cleaningStyleHeader = "Pro Clean"
                    cleaningStyleHeaderClassName += " proDisclaimer"
                } else {
                    cleaningStyleHeader = "Basic Clean"
                    cleaningStyleHeaderClassName += " basicDisclaimer"
                }

                body =
                    <div>
                        <table className="formattedResultsTable">
                            <thead>
                                {
                                    this.props.isSample ? null : <tr>
                                        <th colSpan={colCount} className={cleaningStyleHeaderClassName}>{cleaningStyleHeader}</th>
                                    </tr>
                                }
                                <tr>
                                    {tableHeaderValues}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>

            }
        }

        return (
            <div className="formattedResultsDiv">
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    matches: state.mainState.matches
})

export default connect(mapStateToProps, null)(FormattedResults)