import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../../../../../Constants/API_Root";
import {modifyStatus} from "../../../../../Actions/ModifyStatus";
import {modifyFileInformation} from "../../../../../Actions/ModifyFileInformation";
import {toggleModal} from "../../../../../Actions/ToggleModal";
import {modifyEditOptions} from "../../../../../Actions/ModifyEditOptions";
import {modifySheetRead} from "../../../../../Actions/ModifySheetRead";
import {modifyColumnCleanTypes} from "../../../../../Actions/ModifyColumnCleanTypes";
import {toggleToast} from "../../../../../Actions/ToggleToast";
import {modifyColumnCleanInfo} from "../../../../../Actions/ModifyColumnCleanInfo";
import {resetStateAfterUpload} from "../../../../../Actions/ResetStateAfterUpload";
import {modifyTutorialInfo} from "../../../../../Actions/ModifyTutorialInfo";

class EditFileReadButton extends Component {
    constructor(props) {
        super(props);

        this.editFile = this.editFile.bind(this)
    }

    editFile(){
        const editOptions = this.props.editOptions;

        // Check if there is any error
        const skipRowsError = editOptions.skipRowsError;
        const startRowError = editOptions.startRowError;

        if (!skipRowsError && !startRowError){
            // Open up WebSockets
            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
            const socket = new WebSocket(websocketRoute + "ws/api/read-clean-file/");

            const filePK = this.props.filePK;

            const props = this.props;

            // Send the PKs and if any column names
            socket.onopen = (event) => {
                socket.send(JSON.stringify({
                    filePK: filePK,
                    editOptions: props.editOptions,
                    sheetRead: props.newSheet
                    // sheetRead: props.sheetRead,
                }));
            };

            props.toggleModal(null);
            props.toggleModal("loadingModalEditFile");

            socket.onmessage = (event) => {
                const reply = JSON.parse(event.data);
                const status = reply['status'];

                if (status === "read"){
                    let fileInformation = reply.fileInformation;
                    let sheetRead = reply.sheetRead;

                    props.resetStateAfterUpload()
                    props.modifyColumnCleanInfo(fileInformation.columnNames.map((x, idx) => null))
                    props.modifyColumnCleanTypes(fileInformation.columnNames.map((x, idx) => ""))
                    props.modifyFileInformation(fileInformation);
                    props.modifySheetRead(sheetRead);

                    socket.close();
                    props.toggleModal(null);
                }

                if (status === "error"){
                    this.props.toggleToast({show: true, message: "There was a problem re-reading your file. Please try again. Contact our support if the error persists!", type: "error"})

                    socket.close();
                    props.toggleModal(null);
                }

                props.modifyTutorialInfo({})
            };
        }
    }

    render() {
        return (
            <Button color="purple" icon="edit outline" content="Read File With Options" onClick={this.editFile}/>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    editOptions: state.mainState.editOptions,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    modifyFileInformation: modifyFileInformation,
    modifyEditOptions: modifyEditOptions,
    toggleModal: toggleModal,
    modifySheetRead: modifySheetRead,
    modifyColumnCleanTypes: modifyColumnCleanTypes,
    toggleToast: toggleToast,
    modifyColumnCleanInfo: modifyColumnCleanInfo,
    resetStateAfterUpload: resetStateAfterUpload,
    modifyTutorialInfo: modifyTutorialInfo
}

export default connect(mapStateToProps, mapActionsToProps)(EditFileReadButton)