import React, {Component} from 'react'
import {connect} from 'react-redux'
import APIQuickStart from "./APIQuickstart";
import { Icon } from 'semantic-ui-react'
import APIGuidesBody from "./APIGuidesBody";

class APIGuidesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            guide: 'quick'
        }

        this.changeGuide = this.changeGuide.bind(this);
    }

    changeGuide(e, guide){
        this.setState({ guide: guide })
    }

    render() {
        let quickStyle = {cursor: 'pointer'}
        let detailStyle = {cursor: 'pointer'}

        if (this.state.guide === "quick"){
            quickStyle['backgroundColor'] = "lightgray"
            quickStyle['boxShadow'] = "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
            detailStyle['backgroundColor'] = "white"
            detailStyle['boxShadow'] = ""
        }
        else{
            quickStyle['backgroundColor'] = "white"
            quickStyle['boxShadow'] = ""
            detailStyle['backgroundColor'] = "lightgray"
            detailStyle['boxShadow'] = "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
        }

        const guideHeaderStyle = {textAlign: 'center'}
        const iconStyle = {fontSize: '200%', textAlign: 'center', color: 'gray'}

        const apiKey = this.props.apiKeys === null ? "YOUR_API_KEY" : this.props.apiKeys.accessKey

        return (
            <div>
                <div className="row">
                    <div className="col-sm-2" />
                    <div className="col-sm-4">
                        <div className="segment-no-margin segment-raised">
                            <div className="apiDocSection sectionHover"
                                 style={quickStyle}
                                 onClick={(e)=> this.changeGuide(e, "quick")}>
                                <h4 className="thinHeading" style={guideHeaderStyle}>
                                    Quick Start Guide
                                </h4>
                                <div style={iconStyle}>
                                    <Icon name="clock" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="segment-no-margin segment-raised">
                            <div className="apiDocSection sectionHover"
                                 style={detailStyle}
                                 onClick={(e)=> this.changeGuide(e, "detail")}>
                                <h4 className="thinHeading" style={guideHeaderStyle}>
                                    Detailed Guide
                                </h4>
                                <div style={iconStyle}>
                                    <Icon name="book" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2" />
                </div>
                <br/>
                {this.state.guide === "quick" ?
                    <div className="segment-no-margin segment-raised"><APIGuidesBody apiKey={apiKey}/></div> : <APIQuickStart apiKey={apiKey}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    apiKeys: state.mainState.apiKeys
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APIGuidesIndex)