import React, {Component} from 'react'
import ExampleTable from './ExampleTable'
import ValidOrInvalidTag from "./EmailTags/ValidOrInvalidTag"

class PhoneDetails extends Component {
    render() {
        const defectInfo = {type: 'dateFormats', rows: {
                values: ['(212) 555 1924', '+91-98-55-155555', '82 045-888-6666', '+45 33 15 32 86', '1 404 404 4044 (salon)', '(34) 6455 6677', '+54 (01135) 24-5566', '+81 022 344-5566', '+32 0423/67.78.98'],
                suggestions: [
                    <span>212-5551924 <ValidOrInvalidTag valid={false}/></span>,
                    <span>+91-98-55155555 <ValidOrInvalidTag valid={false}/></span>,
                    <span>+82-45-8886666 <ValidOrInvalidTag valid={true}/></span>,
                    <span>+45-33-153286 <ValidOrInvalidTag valid={true}/></span>,
                    <span>+1-404-4044044 <ValidOrInvalidTag valid={true}/></span>,
                    <span>34-64556677 <ValidOrInvalidTag valid={false}/></span>,
                    <span>+54-1135-245566 <ValidOrInvalidTag valid={true}/></span>,
                    <span>+81-22-3445566 <ValidOrInvalidTag valid={false}/></span>,
                    <span>+32-423-677898 <ValidOrInvalidTag valid={true}/></span>],
                splitComponents: [
                    [["username", "emma.watson"], ["domain", "gmail.com"]],
                    [["username", "alan.turing"], ["domain", "london.org"]],
                    [["username", "khaleesi"], ["domain", "redkeep.com"]]
                ]
            }
        }

        return (
            <div>
                <h3>Standardize & Verify if International Phone Numbers are Real</h3>
                <br/>
                <ExampleTable defectInfo={defectInfo}/>
            </div>
        )
    }
}

export default PhoneDetails