import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class NeedMoreInfoCard extends Component {
    render() {
        return (
            <div className="moreInfoCard">
                <h1 className="thinHeading"><img src={this.props.imgSrc} style={{width: "20%"}} alt="moreInfoCardLogo"/> {this.props.header}</h1>
                <p>{this.props.desc}</p>
                <a href={this.props.link}>{this.props.linkText} <Icon name="arrow right" /></a>
            </div>
        )
    }
}

export default NeedMoreInfoCard