import React, {Component} from 'react'
import ContactUsForm from './ContactUsForm'
import { Icon } from 'semantic-ui-react'

class ContactUsIndex extends Component {
    render() {
        return (
            <div className="contactUsIndex">
                <h1><Icon name="paper plane outline" /> Contact Us</h1>
                <ContactUsForm/>
            </div>
        )
    }
}

export default ContactUsIndex