const currencySelection = [
    {'code': 'usd', 'name': 'United States Dollar', 'symbol': '$', 'flag': 'us'},
    {'code': 'cad', 'name': 'Canadian Dollar', 'symbol': 'CAD$', 'flag': 'ca'},
    {'code': 'eur', 'name': 'Euro', 'symbol': '€', 'flag': 'eu'},
    {'code': 'gbp', 'name': 'Pound Sterling', 'symbol': '£', 'flag': 'gb'},
    {'code': 'sgd', 'name': 'Singapore Dollar', 'symbol': 'SGD$', 'flag': 'sg'},
    {'code': 'aud', 'name': 'Australian Dollar', 'symbol': 'AUD$', 'flag': 'au'},
    {'code': 'hkd', 'name': 'Hong Kong Dollar', 'symbol': 'HK$', 'flag': 'hk'},
    {'code': 'isk', 'name': 'Icelandic Crown', 'symbol': 'kr', 'flag': 'is'},
    {'code': 'php', 'name': 'Philippine Peso', 'symbol': '₱', 'flag': 'ph'},
    {'code': 'dkk', 'name': 'Danish Krone', 'symbol': 'kr', 'flag': 'dk'},
    {'code': 'huf', 'name': 'Hungarian Forint', 'symbol': 'Ft', 'flag': 'hu'},
    {'code': 'czk', 'name': 'Czech Koruna', 'symbol': 'Kč', 'flag': 'cz'},
    {'code': 'ron', 'name': 'Romanian Leu', 'symbol': 'L', 'flag': 'ro'},
    {'code': 'sek', 'name': 'Swedish Krona', 'symbol': 'kr', 'flag': 'se'},
    {'code': 'idr', 'name': 'Indonesian Rupiah', 'symbol': 'Rp', 'flag': 'id'},
    {'code': 'inr', 'name': 'Indian Rupee', 'symbol': '₹', 'flag': 'in'},
    {'code': 'brl', 'name': 'Brazilian Real', 'symbol': 'R$', 'flag': 'br'},
    {'code': 'rub', 'name': 'Russian Ruble', 'symbol': '₽', 'flag': 'ru'},
    {'code': 'hrk', 'name': 'Croatian Kuna', 'symbol': 'kn', 'flag': 'hr'},
    {'code': 'jpy', 'name': 'Japanese Yen', 'symbol': '¥', 'flag': 'jp'},
    {'code': 'thb', 'name': 'Thai Baht', 'symbol': '฿', 'flag': 'th'},
    {'code': 'chf', 'name': 'Swiss Franc', 'symbol': 'fr.', 'flag': 'ch'},
    {'code': 'myr', 'name': 'Malaysian Ringgit', 'symbol': 'RM', 'flag': 'my'},
    {'code': 'bgn', 'name': 'Bulgarian Lev', 'symbol': 'лв', 'flag': 'bg'},
    {'code': 'try', 'name': 'Turkish Lira', 'symbol': '₺', 'flag': 'tr'},
    {'code': 'cny', 'name': 'Chinese Yuan', 'symbol': 'RMB 元', 'flag': 'cn'},
    {'code': 'nok', 'name': 'Norwegian Krone', 'symbol': 'kr', 'flag': 'no'},
    {'code': 'nzd', 'name': 'New Zealand Dollar', 'symbol': '$', 'flag': 'nz'},
    {'code': 'zar', 'name': 'South African Rand', 'symbol': 'R', 'flag': 'za'},
    {'code': 'mxn', 'name': 'Mexican Peso', 'symbol': '$', 'flag': 'mx'},
    {'code': 'ils', 'name': 'Israeli Shekel', 'symbol': '₪', 'flag': 'il'},
    {'code': 'krw', 'name': 'South Korean Won', 'symbol': '₩', 'flag': 'kr'},
    {'code': 'pln', 'name': 'Polish Złoty', 'symbol': 'zł', 'flag': 'pl'}
]

export default currencySelection