import React, {Component} from 'react'
import {connect} from 'react-redux'
import FormattedResults from "./FormattedResults"

class DisplayResultsIndex extends Component {
    render() {
        return (
            <FormattedResults selectedColIdx={this.props.selectedColIdx} isSample={this.props.isSample} results={this.props.results} cleanType={this.props.cleanType} />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DisplayResultsIndex)