import React, {Component} from 'react'
import DashboardProgressBar from './UpdatedDashboard/DashboardProgressBar'
import { connect } from 'react-redux'
import UploadFilesIndex from './DashboardDisplay/UploadFiles/UploadFilesIndex'
import CleanIndex from './UpdatedDashboard/Clean/CleanIndex'
import DownloadIndex from './UpdatedDashboard/Download/DownloadIndex'
import API_Root from "../Constants/API_Root";
import {shouldFetchInfo} from "../Constants/shouldFetchInfo";
import {modifyUserCredits} from "../Actions/ModifyUserCredits";
import {modifyFetchedTime} from "../Actions/ModifyFetchedTime";
import {modifyPaymentInfo} from "../Actions/ModifyPaymentInfo";
import ResetSteps from "./ResetSteps"
import {modifySubscriptionInfo} from "../Actions/ModifySubscriptionInfo";
import {modifyAPIKeys} from "../Actions/ModifyAPIKeys";
import {modifyIsUserNew} from "../Actions/ModifyIsUserNew";

class DashboardIndex extends Component {
    componentDidMount(){
        //   Do any pre-fetching here
        if (shouldFetchInfo(this.props.profileInformationFetchedTime, 5)){
            fetch(API_Root + "api/get-clean-spreadsheets-profile-info/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email")
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(
                        (data) =>
                        {
                            this.props.modifyIsUserNew(true)
                            this.props.modifyAPIKeys(data.apiKeys)
                            this.props.modifySubscriptionInfo(data.subscriptionInfo)
                            this.props.modifyUserCredits(data.totalCredits)
                            this.props.modifyFetchedTime("profile")
                            this.props.modifyPaymentInfo(data.paymentInfo)
                        }).catch(err => console.log(err))
                }
            }).catch(err => console.log(err))
        }
    }

    render() {
        const dashboardDisplay = {
            upload: <UploadFilesIndex />,
            clean: <CleanIndex />,
            download: <DownloadIndex/>
        }

        let progressBar = null;

        if (this.props.stepsCompleted > 0){
            progressBar = <DashboardProgressBar/>
        }

        return (
            <div id="dashboardIndex">
                <ResetSteps />
                {progressBar}
                {dashboardDisplay[this.props.status]}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    status: state.mainState.status,
    profileInformationFetchedTime: state.mainState.profileInformationFetchedTime,
    stepsCompleted: state.mainState.stepsCompleted
})

const mapActionsToProps = {
    modifyAPIKeys: modifyAPIKeys,
    modifyUserCredits: modifyUserCredits,
    modifyFetchedTime: modifyFetchedTime,
    modifyPaymentInfo: modifyPaymentInfo,
    modifySubscriptionInfo: modifySubscriptionInfo,
    modifyIsUserNew: modifyIsUserNew
}

export default connect(mapStateToProps, mapActionsToProps)(DashboardIndex)