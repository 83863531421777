import React, {Component} from 'react'
import {connect} from 'react-redux'
import CurrencyTypeDropdown from "./CurrencyTypeDropdown";
import CurrencyNumberFormat from "./CurrencyNumberFormat";

class CurrencyOptions extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                <h4>Choose Currency Type and Number Format</h4>
                <div className="row">
                    <div className="col-sm-6">
                        <CurrencyTypeDropdown/>
                    </div>
                    <div className="col-sm-6">
                        <CurrencyNumberFormat />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CurrencyOptions)