import React, {Component} from 'react'
import PageHero from '../../SharedComponents/PageHero'
import LiveDemoIndex from "./LiveDemoIndex"
import ScheduleDemoButton from "../../LandingPage/ScheduleDemoButton";
import UpdatedCTA from "../../LandingPage/UpdatedCTA"

class DemoIndex extends Component {
    render(){
        return(
            <div style={{minHeight: "100vh", marginBottom: "250px"}}>
                <PageHero title="Demo" desc={<span>Try out our software with your values</span>}/>
                <LiveDemoIndex/>
                <div className="row" style={{marginTop: "100px"}}>
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <div className="segment-raised-rounded" style={{padding: "20px", textAlign: "center"}}>
                            <h2 className="thinHeading">Want us to demo the tool for you?</h2>
                            <ScheduleDemoButton/>
                            {/*<a href="https://www.youtube.com/watch?v=LClqBdaxPcU" target="_blank" rel="noopener noreferrer" style={{display: "block", marginTop: "15px", fontSize: "110%"}}>*/}
                                {/*Or Watch a 70 Second Demo Video*/}
                            {/*</a>*/}
                        </div>
                    </div>
                    <div className="col-sm-3" />
                </div>
                <div style={{textAlign: "center", marginTop: "150px"}}>
                    <UpdatedCTA style={{fontSize: "36px", padding: "20px 50px 20px 50px", fontWeight: "400"}}/>
                </div>
            </div>
        )
    }
}

export default DemoIndex