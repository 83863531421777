const dataTypeInfo = {
    "address" : {desc: "Clean and Geocode Worldwide Addresses", sampleValues: ["32 London Bridge St, London, UK", "413 w 52nd Street, New York, 10019"]},
    "name": {desc: "Format and Split Any Latin Character Name", sampleValues: ["Dr. Marie Skłodowska Curie", "Smith, Joe"]},
    "company": {desc: "Standardize Worldwide Company Names", sampleValues: ["ThyssenKrupp AG", "apple incorporated"]},
    "date": {desc: "Standardize Dates to a Common Format", sampleValues: ["January 10th, 2010", "12/25/2015 3:45PM"]},
    "email": {desc: "Correct Badly Formatted Emails", sampleValues: ["joecool@gmail,,,com", "monkey bananas"]},
    "phone": {desc: "Standardize Phone Numbers", sampleValues: ["(212) 5551924", "82 045-555-6666"]},
    "currency": {desc: "Convert and Standardize Currency", sampleValues: ["€1.343,76", "10,322 Pound Sterling"]},
    "notes": {desc: "Extract Relevant Keywords from Open Ended Text", sampleValues: []}
}

export default dataTypeInfo