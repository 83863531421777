import React, { Component } from 'react';
import { auth } from '../../firebase';
import API_ROOT from "../../../Constants/API_Root";
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Header, Input, Button, Message } from 'semantic-ui-react'
import {toggleModal} from "../../../Actions/ToggleModal";
import {toggleProBlock} from "../../../Actions/ToggleProBlock";

class LoginForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            error: null,
            loading: false
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.login = this.login.bind(this);
        this.forgetPassword = this.forgetPassword.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    handleKeyPress(e){
        const {
            email,
            password,
            error,
        } = this.state;

        const isInvalid =
            password === '' ||
            email === '';

        if (e.charCode === 13) {
            if (!isInvalid){
                // Prevent the default action to stop scrolling when space is pressed
                e.preventDefault()
                this.login(e)
            }
        }
    }

    dismissMessage(e){
        this.setState({ error: null });
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    login(e){
        e.preventDefault();

        this.setState({
            loading: true
        })

        const {
            email,
            password,
        } = this.state;

        const API = API_ROOT + "api/login/"

        const errorMessage = () => this.setState({
            loading: false,
            error: {message: 'There was a problem logging in with your account. Contact us at support@cleanspreadsheets.com if the problem persists'}
        })

        auth.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState(() => ({
                    email:'',
                    password:'',
                    error: null,
                }));
                localStorage.setItem("email", email.toLowerCase().trim());

                fetch(API, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password
                    })
                }).then(res => res.json())
                    .then((data) => {
                        localStorage.setItem("token", data.token);

                        this.props.toggleModal(null);

                        this.setState({
                            loading: false
                        })

                        this.props.navigateTo("/clean")
                    })
                    .catch((err) => {
                        errorMessage();
                    }).catch((err) => errorMessage());
            })
            .catch(error => {
                this.setState({error: error, loading: false});
            });
    };

    forgetPassword(e){
        this.props.toggleModal(null);
        this.props.toggleModal("forgetPasswordModal")
    }

    render(){
        const {
            email,
            password,
            error,
        } = this.state;

        const isInvalid =
            password === '' ||
            email === '';

        return(
            <div onKeyPress={this.handleKeyPress}>
                <Header as='h1'>
                    Log In
                    <Header.Subheader>Log In to your account</Header.Subheader>
                </Header>
                <br/>
                <Input icon="at"
                       placeholder="Your Email"
                       iconPosition='left'
                       type="text" name="email" value={email}
                       onChange={this.handleInputChange}
                       fluid
                />
                <br/>
                <Input icon="lock"
                       placeholder="Your Password"
                       iconPosition='left'
                       type="password" name="password" value={password}
                       onChange={this.handleInputChange}
                       fluid
                />
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading} basic color="blue" disabled={isInvalid}
                            onKeyPress={this.handleKeyPress}
                            type="submit"
                            onClick={this.login} style={{fontSize: '120%'}}>Log In</Button>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <p className="linkStyle" onClick={this.forgetPassword}>Forgot password?</p>
                </div>

                { error && <Message error
                                    onDismiss={this.dismissMessage}
                                    header={error.message} /> }
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    toggleProBlock: toggleProBlock
}

export default connect(null,mapActionsToProps)(LoginForm);
