import React, {Component} from 'react'

class ProOrBasicTag extends Component {
    render() {
        let style = null;
        if (this.props.pro){
            style = {
                backgroundColor: "#fbb03b"
            }
        } else {
            style = {
                backgroundColor: "#a2d5fc"
            }
        }

        return (
            <span className="proOrBasicTag" style={style}>
                {this.props.pro ? "Pro" : "Basic"}
            </span>
        )
    }
}

export default ProOrBasicTag