import React, {Component} from 'react'
import {connect} from 'react-redux'
import isObjectEmpty from "../../../Constants/isObjectEmpty";
import monthlyPricing from "../../../Constants/monthlyPricing";
import {isMobile} from "react-device-detect";
import PricingCard from "../../../InfoPages/Pricing/PricingCard"
import Loader from 'react-loader-spinner'
import formatDateToDisplay from "../../../Constants/formatDateToDisplay";
import {toggleModal} from "../../../Actions/ToggleModal";
import { Icon } from "semantic-ui-react"
import supportIconNames from "../../../Constants/supportIconNames";
import SupportInfo from "../../../InfoPages/Pricing/SupportInfo"

class MonthlyCredits extends Component {
    constructor(props){
        super(props);

        this.openCreditExplanation = this.openCreditExplanation.bind(this);
    }

    openCreditExplanation(e){
        this.props.toggleModal("monthlyCreditsExplanationModal")
    }

    render() {
        if (this.props.fetching){
            return(
                <div style={{margin: "50px 50px 0 50px", textAlign: "center"}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                </div>
            )
        } else {
            let planType = "free"
            let planIdx = -1

            if (!isObjectEmpty(this.props.subscriptionInfo)){
                if ('subscription' in this.props.subscriptionInfo){
                    if (this.props.subscriptionInfo.subscription){
                        planType = this.props.subscriptionInfo['planType']
                        planIdx = this.props.subscriptionInfo['planIdx']
                    }
                }
            }

            if (planType === "free"){
                return (
                    <div style={{margin: "50px 50px 0 50px", textAlign: "center"}}>
                        <div className="row">
                            <div className="col-sm-4">
                                <PricingCard profile={true} priceIdx={0}/>
                                {isMobile ? <br/> : null}
                            </div>
                            <div className="col-sm-4">
                                <PricingCard profile={true} priceIdx={1}/>
                                {isMobile ? <br/> : null}
                            </div>
                            <div className="col-sm-4">
                                <PricingCard profile={true} priceIdx={2}/>
                            </div>
                        </div>
                        <br/><br/>
                        <div className="row">
                            <div className="col-sm-4">
                                <PricingCard profile={true} priceIdx={3}/>
                                {isMobile ? <br/> : null}
                            </div>
                            <div className="col-sm-4">
                                <PricingCard profile={true} priceIdx={4}/>
                                {isMobile ? <br/> : null}
                            </div>
                            <div className="col-sm-4">
                                <PricingCard profile={true} priceIdx={5}/>
                            </div>
                        </div>
                    </div>
                )
            } else {
                let creditsLeft = monthlyPricing[this.props.currency][planIdx].credits;

                if ('subscription' in this.props.subscriptionInfo){
                    if (this.props.subscriptionInfo.subscription){
                        creditsLeft = this.props.subscriptionInfo['creditsLeft']
                    }
                }

                const currPeriodStart = new Date(parseInt(this.props.subscriptionInfo.currentPeriodStart, 10) * 1000)
                const currPeriodEnd = new Date(parseInt(this.props.subscriptionInfo.currentPeriodEnd, 10) * 1000)

                const totalCredits = monthlyPricing[this.props.currency][planIdx].credits;
                const customerSupport = monthlyPricing[this.props.currency][planIdx].support;

                return (
                    <div>
                        <div style={{margin: "0 auto", width: "60%", backgroundColor: "white", border: "1px solid lightgrey", marginTop: "70px", padding: "20px 10px 20px 10px"}}>
                            <h4 style={{textAlign: "center"}}><span className="thinHeading">You have <span className="userCredits bigNumber" style={{color: creditsLeft < 2500 ? "red" : creditsLeft < 5000 ? "orange" : "green"}}>{creditsLeft.toLocaleString()}</span> out of {totalCredits.toLocaleString()} credits left this month</span></h4>
                            <p className="linkStyle" onClick={this.openCreditExplanation} style={{textAlign: "center"}}>Our monthly credits are soft limits. Learn More <span role="img" aria-label="smile">😀</span></p>
                            <br/><br/>
                            <h5 style={{textAlign: "center"}}><Icon name="calendar check outline" /> <b>Month Start</b> <span className="thinHeading">{formatDateToDisplay(currPeriodStart)}</span></h5>
                            <h5 style={{textAlign: "center"}}><Icon name="calendar times outline" /> <b>Month End</b> <span className="thinHeading">{formatDateToDisplay(currPeriodEnd)}</span></h5>
                            <br/><br/>
                            <h4 style={{textAlign: "center"}}>{customerSupport} Customer Support</h4>
                            <SupportInfo headingStyle={{textAlign: 'center', color: 'gray'}} supportType={customerSupport}/>
                        </div>
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    subscriptionInfo: state.mainState.subscriptionInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(MonthlyCredits)