import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Image } from 'semantic-ui-react'
import images from "../../../Constants/Images";
import capitalizeHyphenated from "../../../Constants/capitalizeHyphenated";
import { Icon } from 'semantic-ui-react';
import {modifyColumnCleanTypes} from "../../../Actions/ModifyColumnCleanTypes";
import {modifySelectedColumns} from "../../../Actions/ModifySelectedColumns";
import {modifyColumnCleanInfo} from "../../../Actions/ModifyColumnCleanInfo";
import {modifyAllColumnInfo} from "../../../Actions/ModifyAllColumnInfo";

class CleaningStep extends Component {
    constructor(props) {
        super(props);

        this.removeCleaningStep = this.removeCleaningStep.bind(this);
    }

    removeCleaningStep(){
        const copiedSelectedColumns = JSON.parse(JSON.stringify(this.props.selectedColumns));
        const copiedColumnCleanTypes = JSON.parse(JSON.stringify(this.props.columnCleanTypes));

        copiedSelectedColumns.splice(copiedSelectedColumns.indexOf(this.props.selectedColIdx), 1)
        copiedColumnCleanTypes[this.props.selectedColIdx] = ""

        this.props.modifyAllColumnInfo(copiedSelectedColumns, copiedColumnCleanTypes, this.props.columnCleanInfo);
    }

    render() {
        const colNames = this.props.fileInformation.columnNames;
        let addlInfo = null;

        if (this.props.cleanDataType === "address"){
            addlInfo = <i> {this.props.cleanOptions.addressGeocode} geocoding</i>
        }

        if (this.props.cleanDataScreen){
            return(
                <tr>
                    <td>
                        {colNames[this.props.selectedColIdx]}
                    </td>
                    <td>
                        <Image src={images[this.props.cleanDataType + "Icon"]} avatar/><b>{capitalizeHyphenated(this.props.cleanDataType)}</b>
                    </td>
                    <td>
                        {this.props.fileInformation['columnInfo'][this.props.selectedColIdx]['unique']}
                    </td>
                </tr>
            )
        } else {
            return(
                <li className="cleaningStep"><Image src={images[this.props.cleanDataType + "Icon"]} avatar/><b>{capitalizeHyphenated(this.props.cleanDataType)}</b> {colNames[this.props.selectedColIdx]} {addlInfo} <Icon onClick={this.removeCleaningStep} className="removeStepIcon" name="remove circle" /></li>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    uploadDataType: state.mainState.uploadDataType,
    cleanOptions: state.mainState.cleanOptions,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
    columnCleanInfo: state.mainState.columnCleanInfo
})

const mapActionsToProps = {
    modifyColumnCleanTypes: modifyColumnCleanTypes,
    modifySelectedColumns: modifySelectedColumns,
    modifyColumnCleanInfo: modifyColumnCleanInfo,
    modifyAllColumnInfo: modifyAllColumnInfo
}

export default connect(mapStateToProps, mapActionsToProps)(CleaningStep)
