import React, {Component} from 'react'
import { Segment, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import PaymentBodyModal from "../Pricing/PaymentBodyModal"
import isObjectEmpty from "../../Constants/isObjectEmpty";
import { push } from 'react-router-redux'

class SignUpCleanPaymentIndex extends Component {
    constructor(props){
        super(props)
        this.goToCredits = this.goToCredits.bind(this);
    }

    goToCredits(){
        this.props.navigateTo("/credits")
    }

    componentWillMount(){
        let pricingIdx = 0;

        let param = window.location.href.charAt(window.location.href.length-1)

        if (!isNaN(param)) {
            pricingIdx = parseInt(param, 10)
        }

        this.props.modifyModalInfo({
            priceIdx: pricingIdx,
        })
    }

    render() {
        let isSubscribed = false;
        let planType = "";
        if (!isObjectEmpty(this.props.subscriptionInfo)) {
            if ('subscription' in this.props.subscriptionInfo) {
                if (this.props.subscriptionInfo.subscription) {
                    isSubscribed = true
                    planType = this.props.subscriptionInfo['planType']
                }
            }
        }

        const alreadySubscribed = <div style={{textAlign: 'center'}}>
            <h4>You are already subscribed to the {planType.toUpperCase()} plan</h4>
            <Button color="green" icon="dollar" content="View your Plan Info" onClick={this.goToCredits}/>
        </div>

        return (
            <div className="row" style={{backgroundColor: '#0E4D92', minHeight: '100vh'}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <br/><br/><br/>
                    <Segment raised>
                        <div>
                            <br/>
                            {isSubscribed ? alreadySubscribed : <PaymentBodyModal signUp/>}
                            <br/>
                        </div>
                    </Segment>
                </div>
                <div className="col-sm-3" />
            </div>
        )
    }
}

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    navigateTo: push
}

export default connect(null, mapActionsToProps)(SignUpCleanPaymentIndex)