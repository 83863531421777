import React, {Component} from 'react'
import capitalizeUnderscore from "../../../Constants/capitalizeUnderscore";
import { Divider } from 'semantic-ui-react';

class APIDisplaySegment extends Component {
    render() {
        return (
            <section id={this.props.name + "-api-docs"}>
                <div className={this.props.name + "-docs-segment"} id={this.props.name + "-docs-segment"}>
                    {this.props.name !== "introduction" ?  <span><br/><br/></span> : null}
                    <h3 className="thinHeading apiDisplaySegmentHeader">
                        {capitalizeUnderscore(this.props.name)}
                    </h3>
                    <div className="apiDisplaySegmentBody">
                        {this.props.body}
                    </div>
                    <Divider/>
                </div>
            </section>
        )
    }
}

export default APIDisplaySegment