import React, {Component} from 'react'
import DisplayBox from "../DisplayBox";
import ReactJson from 'react-json-view'
import ProLabel from "./ProLabel";
import APIRespBoxHeader from "./APIRespBoxHeader";

class EmailResponse extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPro: true,
            height: 0
        }
    }

    componentDidMount(){
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        let height = 0;
        const div = document.getElementById("apiEmailSectionIntro")

        if (div !== null && div !== undefined){
            height = div.offsetHeight;
        }

        this.setState({ height: height })
    }

    changeProToggle = (isPro) => {
        this.setState({ isPro: isPro })
    }

    render() {
        let validResp = [{"formatted":"joecool@gmail.com","split":{"username":"joecool","domain":"gmail.com"},"error": false, "score": 100, "input":"joecool@gmail,,,com"}]
        let invalidResp = [{"formatted":"INVALID","split":{"username":"","domain":""},"input":"monkey bananas"}];

        if (this.state.isPro){
            validResp['valid'] = "VALID"
            invalidResp['valid'] = "INVALID"
        }

        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <div id="apiEmailSectionIntro">
                            <p>Cleaning an email will let you know if the email is properly formatted or not, sometimes suggest corrections and split the email. If the format is not valid, e.g., monkey bananas, then the split will contain blanks ("") for all the components.</p>
                            <br/>
                            <p>
                                For example:
                                <ul>
                                    <li><i>joecool@gmail,,,com</i> will be formatted to <span style={{color: 'green'}}>joecool@gmail.com</span> and split into <i>joecool</i> for the username and <i>gmail.com</i> as its domain</li>
                                    <li><i>khaleesi@got.org</i> will keep its formatting. It will split into <i>khaleesi</i> for the username and <i>got.org</i> as its domain</li>
                                    <li><i>monkey bananas</i> is <span style={{color: 'red'}}>INVALID</span> because it is not properly formatted. It will split into <i>""</i> for the username and <i>""</i> as its domain</li>
                                </ul>
                            </p>
                            <br/>
                            <p>If you are doing <ProLabel/> cleaning, you will also see whether the Email is real or not</p>
                            <br/>
                        </div>
                        <div className="apiDocSection">
                            <h5>Formatted String</h5>
                            <p>The formatted email is a string <b>username@domain.extension</b> when the email is valid in format. Otherwise it is the value <i><b>INVALID</b></i></p>
                        </div>
                        <br/>
                        <div className="apiDocSection">
                            <h5>Split Object Components</h5>
                            <p><span className="parameterName">username</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Username from the email. <i>For e.g., <b>joecool</b> from joecool@gmail.com</i></p>
                            <br/>
                            <p><span className="parameterName">domain</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Domain from the email. <i>For e.g., <b>gmail.com</b> from joecool@gmail.com</i></p>
                        </div>
                        <br/>
                        <div className="apiDocSection">
                            <h5 style={{color: 'green'}}>There is one more parameter in the <b>PRO</b> Email response object</h5>
                            <p><span className="parameterName">valid</span> <span className="parameterType">string </span> <ProLabel/></p>
                            <p className="parameterDesc">Value showing if email is real or not. There are 4 possible values:</p>
                            <ul style={{paddingLeft: "1"}}>
                                <li><b>VALID</b> if the email is real and can accept emails</li>
                                <li><b>INVALID</b> if the email is <u>not</u> real and can <u>not</u> accept emails</li>
                                <li><b>UNKNOWN</b> we cannot determine anything about this email</li>
                                <li><b>VALID (ACCEPT ALL)</b> if the domain of the email can accept emails on ANY username. It is impossible to tell whether the email is real or not</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{marginTop: this.state.height}}>
                        <DisplayBox
                            header={
                                <APIRespBoxHeader
                                    header='joecool@gmail,,,com'
                                    isPro={this.state.isPro}
                                    changeProToggle={this.changeProToggle}
                                />}
                            desc={<div><ReactJson src={validResp} name={null} theme="ocean" displayObjectSize={false}/></div>}
                        />
                        <br/><br/><br/><br/>
                        <DisplayBox
                            header={
                                <APIRespBoxHeader
                                    header='monkey bananas'
                                    isPro={this.state.isPro}
                                    changeProToggle={this.changeProToggle}
                                />}
                            desc={<div><ReactJson src={invalidResp} name={null} theme="ocean" displayObjectSize={false}/></div>}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default EmailResponse