import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'
import images from "../../../../Constants/Images";
import capitalizeHyphenated from "../../../../Constants/capitalizeHyphenated";
import { connect } from 'react-redux'
import {createAddressSteps} from "../../../../Constants/createAddressSteps";

class CleanDisplayMasterMenuUpdated extends Component {
    constructor(props) {
        super(props);

        this.toggleMenuItem = this.toggleMenuItem.bind(this);
    }

    toggleMenuItem(e, idx){
        this.props.changeSelectedCol(idx)
    }

    render(){
        if (this.props.cleanedSource.length === 0){
            return(
                null
            )
        } else {
            const columnNames = 'columnNames' in this.props.fileInformation ? this.props.fileInformation['columnNames'] : null;
            const columnInfo = 'columnInfo' in this.props.fileInformation ? this.props.fileInformation['columnInfo'] : null;

            if (columnNames !== null && columnInfo !== null){
                let cleanDisplayMenuItems = [];
                let addressSteps = createAddressSteps(columnNames, this.props.columnCleanInfo, this.props.selectedColumns);

                for (let i=0; i<this.props.cleanedSource.length; i++){
                    const colSource = this.props.cleanedSource[i];
                    let menuClassName = "cleanDisplayMenuItem";

                    if (this.props.cleanedSourceIdx === i){
                        menuClassName += " cleanDisplayMenuItemSelected";
                    }

                    let columnType;
                    let columnText;

                    if (colSource.type === "selectedColumn"){
                        columnType = this.props.columnCleanTypes[colSource.id]
                        columnText = columnNames[colSource.id]
                    } else {
                        columnType = "address"
                        let colStep = addressSteps[colSource.id];
                        let colNamesStep = colStep.colNames;

                        if (colStep.geocode === "forward"){
                            const colIndices = Object.keys(colNamesStep)
                            let colNamesString = "";

                            for (let i=0; i<colIndices.length; i++){
                                colNamesString += colNamesStep[colIndices[i]] + ", "
                            }

                            columnText = colNamesString.substring(0, colNamesString.length - 2) + ", forward geocoding"
                        } else {
                            columnText = "latitude: " + colNamesStep.lat + ",longitude:" + colNamesStep.lng + ", reverse geocoding"
                        }

                    }

                    cleanDisplayMenuItems.push(
                        <div className={menuClassName} onClick={(e) => this.toggleMenuItem(e, i)}>
                            <span className="cleanDisplayMenuItemColInfo">{capitalizeHyphenated(columnType)} <Image src={images[columnType + "Icon"]} avatar /></span>
                            <br/>
                            <span className="cleanDisplayMenuItemColName">{columnText}</span>
                        </div>
                    )

                }

                return(
                    <div className="cleanDisplayMasterMenu">
                        <p style={{padding: "5px 0 5px 5px"}}>Data Type<br/><b>Column Name(s)</b></p>
                        {cleanDisplayMenuItems}
                    </div>
                )
            } else {
                return(
                    null
                )
            }
        }
    }

}

const mapStateToProps = (state) => ({
    selectedColumns: state.mainState.selectedColumns,
    fileInformation: state.mainState.fileInformation,
    columnCleanTypes: state.mainState.columnCleanTypes,
    cleanedSource: state.mainState.cleanedSource,
    columnCleanInfo: state.mainState.columnCleanInfo
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(CleanDisplayMasterMenuUpdated)