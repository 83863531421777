import React, {Component} from 'react'
import {connect} from 'react-redux'
import CleanDisplayMasterTable from "../Clean/CleanDisplayPreview/CleanDisplayMasterTable"
import DownloadDisplayIndex from "../Download/DownloadDisplayIndex";

class DownloadDisplay extends Component {
    render() {
        // Deal with errors by looking for nulls in the clean results
        let table = <CleanDisplayMasterTable isSample={false}/>;

        if (this.props.cleanedSource !== undefined){
            if (this.props.cleanedSource.length > 0){
                table = <DownloadDisplayIndex />;
            }
        }
        return (
            <div>
                {table}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cleanResults: state.mainState.cleanResults,
    cleanedSource: state.mainState.cleanedSource
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DownloadDisplay)