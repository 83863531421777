import React, {Component} from 'react'
import { Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux'
import URLBox from './Docs/URLBox'
import "ace-builds/src-noconflict/mode-powershell";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import ReactJson from 'react-json-view'
import {getCodeSnippet} from "../Constants/getCodeSnippet";

class APIGuidesBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cleanType: "name"
        }

        this.changeCleanType = this.changeCleanType.bind(this);
    }

    changeCleanType(e, {value}){
        this.setState({ cleanType: value })
    }

    render() {
        const apiKey = this.props.apiKey;

        const requestBody = {
            company: {"apiKey": apiKey, "data": ["apple inc", "Infosys ltd."]},
            name: {"apiKey": apiKey, "data": ["Dr. james M. mcavoy Jr.", "barack Obama"]},
            address: {"apiKey": apiKey, "data": ["415 w 52nd st, new york", "32 London Bridge St, London, UK"]},
            date: {"apiKey": apiKey, "data": ["Jan 23 1981, 3:45PM", "1/15/19"]},
            email: {"apiKey": apiKey, "data": ["admin@woyera.com", "boo"]},
            phone: {"apiKey": apiKey, "data": ["1 404 404 4044 (salon)", "+32 0423/67.78.98"]},
            notes: {"apiKey": apiKey, "data": ["I got this laptop yesterday. It is super fast", "The chicken tenders here are also a worthy quarantine comfort food"]},
            currency: {"apiKey": apiKey, "data": ["€1.343,76", "10,322 Pound Sterling"]}
        }

        const responseBody = {
            company: {"cleanData":[{"formatted":"Apple","split":{"name":"Apple","suffix":"INC","suffixFull":"INCORPORATED"},"score":100,"error":false,"input":"apple inc"},{"formatted":"Infosys","split":{"name":"Infosys","suffix":"LTD.","suffixFull":"PUBLIC LIMITED COMPANY"},"score":100,"error":false,"input":"Infosys ltd."}]},
            name: {"cleanData":[{"formatted":"James Mcavoy","split":{"prefix":"Dr.","suffix":"Jr.","first":"James","middle":"M","last":"Mcavoy","otherNames":""},"error":false,"score":100,"input":"Dr. james M. mcavoy Jr."},{"formatted":"Barack Obama","split":{"prefix":"","suffix":"","first":"Barack","middle":"","last":"Obama","otherNames":""},"error":false,"score":100,"input":"barack Obama"}]},
            address: {"cleanData":[{"formatted":"415 West 52nd Street, New York, NY 10019, United States of America","score":100,"split":{"ISO_3166-1_alpha-2":"US","ISO_3166-1_alpha-3":"USA","_category":"building","_type":"building","city":"New York","continent":"North America","country":"United States of America","country_code":"us","county":"New York County","house_number":"415","postcode":"10019","road":"West 52nd Street","state":"New York","state_code":"NY","suburb":"Manhattan"},"lat":40.765259,"lng":-73.9887379,"error":false,"input":"415 w 52nd st, new york"},{"formatted":"The Shard, 32 London Bridge Street, London SE1 9SG, United Kingdom","score":90,"split":{"ISO_3166-1_alpha-2":"GB","ISO_3166-1_alpha-3":"GBR","_category":"travel/tourism","_type":"attraction","attraction":"The Shard","city":"London","continent":"Europe","country":"United Kingdom","country_code":"gb","county":"London Borough of Southwark","house_number":"32","neighbourhood":"Bermondsey Spa","political_union":"European Union","postcode":"SE1 9SG","road":"London Bridge Street","state":"England","state_code":"ENG","state_district":"Greater London","suburb":"Borough"},"lat":51.5043421,"lng":-0.0864459,"error":false,"input":"32 London Bridge St, London, UK"}]},
            date: {"cleanData":[{"split":{"year":"1981","month":"01","day":"23","hour":"15","minute":"45","second":"00"},"formatted":"1981-01-23 15:45:00","score":100,"error":false,"input":"Jan 23 1981, 3:45PM"},{"split":{"year":"2019","month":"01","day":"15","hour":"00","minute":"00","second":"00"},"formatted":"2019-01-15 00:00:00","score":100,"error":false,"input":"1/15/19"}]},
            email: {"cleanData": [{"formatted":"admin@woyera.com","split":{"username":"admin","domain":"woyera.com"},"score":100,"error":false,"input":"admin@woyera.com"},{"formatted":"INVALID","split":{"username":"","domain":""},"score":100,"error":false,"input":"boo"}]},
            phone: {"cleanData":[{"formatted":"+1-404-4044044","split":{"countryCode":"1","areaCode":"404","number":"4044044"},"error":false,"score":100,"input":"1 404 404 4044 (salon)"},{"formatted":"+32-423-677898","split":{"countryCode":"32","areaCode":"0423","number":"677898"},"error":false,"score":100,"input":"+32 0423/67.78.98"}]},
            notes: {"cleanData":[{"formatted":"laptop yesterday,super fast","split":{"secondaryKeywords":"","extraKeywords":""},"error":false,"score":100,"input":"I got this laptop yesterday. It is super fast"},{"formatted":"worthy quarantine comfort food,chicken tenders","split":{"secondaryKeywords":"","extraKeywords":""},"error":false,"score":100,"input":"The chicken tenders here are also a worthy quarantine comfort food"}]},
            currency: {"cleanData":[{"formatted":"1343.76","split":{"numberValue":"1343.76","symbol":"\u20ac","name":"Euro","code":"EUR","usd":"1609.82"},"error":false,"score":100,"input":"\u20ac1.343,76"},{"formatted":"10322.00","split":{"numberValue":"10322.00","symbol":"\xa3","name":"Pound Sterling","code":"GBP","usd":"13763.43"},"error":false,"score":100,"input":"10,322 Pound Sterling"}]}
        }

        const responseText = {
            company: "Get Formatted Company Name and Components: Short and Full Company Suffix",
            name: "Get Formatted Name and Components: First Name, Last Name, Middle Name, Prefix, Suffix and Other Names",
            address: "Get Formatted Address, Latitude, Longitude and Components",
            date: "Get Formatted Date and Components: Day, Month, Year, Hour, Minute, Second",
            email: 'Get Corrected Email Format',
            phone: 'Get Validated Phone for G20 Countries',
            notes: 'Get Keywords and Tags from Open Ended Text',
            currency: 'Get Numerical Currency and USD Conversion'
        }

        const options = [
            {key: 0, text: 'name', value:'name'},
            {key: 1, text: 'address', value:'address'},
            {key: 2, text: 'company', value:'company'},
            {key: 3, text: 'date', value:'date'},
            {key: 4, text: 'email', value:'email'},
            {key: 5, text: 'phone', value:'phone'},
            {key: 6, text: 'notes', value:'notes'},
            {key: 7, text: 'currency', value:'currency'},
        ]

        const curlCode = getCodeSnippet('cURL', apiKey, this.state.cleanType)

        // const curlCode = 'curl --header "Content-Type: application/json" \\\n --request POST \\\n --data ' +
        //     '\'{"apiKey": "' + this.props.apiKeys.accessKey + '", "data": ["' + requestBody[this.state.cleanType]["data"][0]
        //     + '", "' + requestBody[this.state.cleanType]["data"][1] + '"]}\' \\\n' + API_Root + "clean/" + this.state.cleanType + "/"

        return (
            <div>
                <div className="apiDocSection" style={{textAlign: 'center', color: 'gray'}}>
                    <h3 className="thinHeading">
                        <Icon name='cogs' style={{paddingRight: '30px'}}/>
                        I want to clean {' '}
                        <Dropdown
                            inline
                            options={options}
                            value={this.state.cleanType}
                            onChange={this.changeCleanType}
                        />
                    </h3>
                </div>
                <br/>
                <div className="apiDocSection">
                    <h4>Request</h4>
                    <br/>
                    <h5 className="thinHeading">Make POST Request to URL</h5>
                    <URLBox
                        urlType={"Base URL for Cleaning " + this.state.cleanType.charAt(0).toUpperCase() + this.state.cleanType.substring(1, this.state.cleanType.length)}
                        url={"https://api-woyera.com/clean/" + this.state.cleanType + "/"}
                    />
                    <br/>
                    <h5 className="thinHeading">Request Data must be JSON</h5>
                    <ReactJson
                        src={requestBody[this.state.cleanType]}
                        name={null} theme="ocean" displayObjectSize={false}/>
                    <br/>
                    <h5 className="thinHeading">cURL code to make request</h5>
                    <AceEditor
                        mode="powershell"
                        theme="monokai"
                        name=""
                        value={curlCode}
                        editorProps={{ $blockScrolling: true }}
                        width='100%'
                        height="125px"
                    />
                </div>
                <br/><br/>
                <div className="apiDocSection">
                    <h4>Response</h4>
                    <br/>
                    <h5 className="thinHeading">{responseText[this.state.cleanType]}</h5>
                    <br/>
                    <ReactJson
                        src={responseBody[this.state.cleanType]}
                        name={null} theme="ocean" displayObjectSize={false}/>
                    <br/>
                </div>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, null)(APIGuidesBody)