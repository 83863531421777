import React, {Component} from 'react'
import PaymentSegment from "../../SharedComponents/Payment/RefillCreditsModal/PaymentSegment"
import PaymentInfo from "../../SharedComponents/Payment/RefillCreditsModal/PaymentInfo"
import { connect } from 'react-redux'
import addCommaToNumbers from "../../Constants/addCommaToNumbers";
import {shouldFetchInfo} from "../../Constants/shouldFetchInfo";
import API_Root from "../../Constants/API_Root";
import {modifyPaymentInfo} from "../../Actions/ModifyPaymentInfo";
import {modifyUserCredits} from "../../Actions/ModifyUserCredits";
import {modifyFetchedTime} from "../../Actions/ModifyFetchedTime";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import {modifySubscriptionInfo} from "../../Actions/ModifySubscriptionInfo";
import SubscriptionInfo from "./Credits/SubscriptionInfo"
import CurrencyChangeDropdown from "../../SharedComponents/CurrencyChangeDropdown"
import MonthlyPricing from "../Pricing/MonthlyPricing";
import MonthlyCredits from "./Credits/MonthlyCredits"
import PayAsYouGoCredits from "./Credits/PayAsYouGoCredits"
import isObjectEmpty from "../../Constants/isObjectEmpty";
import {modifyAPIKeys} from "../../Actions/ModifyAPIKeys";
import {modifyIsUserNew} from "../../Actions/ModifyIsUserNew";

class CreditsIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            pricingType: "payAsYouGo",
            fetching: false
        }

        this.changePricingType = this.changePricingType.bind(this);
    }

    changePricingType(e, pricingType){
        this.setState({ pricingType: pricingType })
    }

    componentDidMount(){
        const stopFetch = () => this.setState({ fetching : false })
        if (shouldFetchInfo(this.props.profileInformationFetchedTime, 5)){
            this.setState({ fetching: true })
            fetch(API_Root + "api/get-clean-spreadsheets-profile-info/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email")
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(
                        (data) =>
                        {
                            this.props.modifyIsUserNew(true)
                            this.props.modifyAPIKeys(data.apiKeys)
                            this.props.modifySubscriptionInfo(data.subscriptionInfo)
                            this.props.modifyUserCredits(data.totalCredits)
                            this.props.modifyFetchedTime("profile")
                            this.props.modifyPaymentInfo(data.paymentInfo)
                            stopFetch()
                        }).catch((err) => {
                            stopFetch();
                    })
                }
            }).catch((err) => stopFetch())
        }
    }

    render() {
        let toolbar = null;
        // let toolbar = <div>
        //     <BrowserView>
        //         <div className="row" style={{marginTop: '30px', marginBottom: '35px'}}>
        //             <div className="col-sm-4">
        //                 <span style={{fontSize: '16px', color: 'grey', fontWeight: 'bold'}}>*1 credit = 1 cleaned value</span>
        //             </div>
        //             <div className="col-sm-4">
        //                 {/*<span onClick={(e) => this.changePricingType(e, "monthly")} className="monthlyPricingSelector" style={this.state.pricingType === "monthly" ? {backgroundColor: "#0081ff", color: "white"} : null}>Monthly</span>*/}
        //                 {/*<span onClick={(e) => this.changePricingType(e, "payAsYouGo")} className="payAsYouGoSelector" style={this.state.pricingType === "payAsYouGo" ? {backgroundColor: "#0081ff", color: "white"} : null}>Pay As You Go</span>*/}
        //             </div>
        //             <div className="col-sm-4">
        //                 <div style={{float: "right", backgroundColor: "white", borderRadius: "10px", padding: "10px", marginTop: "-10px", boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)',}}>
        //                     <b>Currency</b> <CurrencyChangeDropdown />
        //                 </div>
        //             </div>
        //         </div>
        //     </BrowserView>
        //     <MobileView>
        //         <div style={{marginTop: "30px", marginBottom: "40px"}}>
        //             <div style={{fontSize: '16px', color: 'grey', fontWeight: 'bold'}}>*1 credit = 1 cleaned value</div>
        //             <div className="pricingTypeSelector" style={{marginTop: "15px"}}>
        //                 {/*<span onClick={(e) => this.changePricingType(e, "monthly")} className="monthlyPricingSelector" style={this.state.pricingType === "monthly" ? {backgroundColor: "#0081ff", color: "white"} : null}>Monthly</span>*/}
        //                 {/*<span onClick={(e) => this.changePricingType(e, "payAsYouGo")} className="payAsYouGoSelector" style={this.state.pricingType === "payAsYouGo" ? {backgroundColor: "#0081ff", color: "white"} : null}>Pay As You Go</span>*/}
        //             </div>
        //             <div style={{marginTop: "25px"}}>
        //                 <b>Currency</b> <CurrencyChangeDropdown />
        //             </div>
        //         </div>
        //     </MobileView>
        // </div>

        if (!isObjectEmpty(this.props.subscriptionInfo)){
            if ('subscription' in this.props.subscriptionInfo){
                if (this.props.subscriptionInfo.subscription){
                    toolbar = null
                }
            }
        }

        return (
            <div id="profileIndex" className="creditsIndex">
                {toolbar}
                <SubscriptionInfo fetching={this.state.fetching}
                                  userCredits={this.props.userCredits} />
                {this.state.pricingType === "monthly" ? <MonthlyCredits fetching={this.state.fetching} /> : <PayAsYouGoCredits fetching={this.state.fetching} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userCredits: state.mainState.userCredits,
    profileInformationFetchedTime: state.mainState.profileInformationFetchedTime,
    subscriptionInfo: state.mainState.subscriptionInfo
})

const mapActionsToProps = {
    modifyUserCredits: modifyUserCredits,
    modifyFetchedTime: modifyFetchedTime,
    modifyPaymentInfo: modifyPaymentInfo,
    modifySubscriptionInfo: modifySubscriptionInfo,
    modifyAPIKeys: modifyAPIKeys,
    modifyIsUserNew: modifyIsUserNew
}

export default connect(mapStateToProps, mapActionsToProps)(CreditsIndex)