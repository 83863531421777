import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {push} from "react-router-redux";
import { isMobile } from 'react-device-detect';

class NavbarLogIn extends Component {
    constructor(props) {
        super(props);

        this.logIn = this.logIn.bind(this);
    }

    logIn(e){
        if (isMobile){
            this.props.navigateTo("/login")
        }
        else{
            // this.props.toggleModal("matchesFAQModal")
            this.props.toggleModal("loginModal")
        }
    }

    render() {
        return (
            <Button basic color="blue" onClick={this.logIn} style={{marginTop: '2px', borderColor: "#0081ff", borderRadius: "2px"}}>
                <span style={{color: "#0081ff"}}>LOG IN</span>
            </Button>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(NavbarLogIn);