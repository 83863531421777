import React, {Component} from 'react'
import URLBox from "../URLBox";
import { connect } from 'react-redux'
import {toggleModal} from "../../../Actions/ToggleModal";

class Introduction extends Component {
    constructor(props){
        super(props);

        this.openGuideModal = this.openGuideModal.bind(this);
    }

    openGuideModal(){
        this.props.toggleModal("apiGuideModal")
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <p>
                            The API is based around <a href="https://en.wikipedia.org/wiki/Representational_state_transfer" >REST</a> principles.
                            The API has 8 endpoints for each of the cleaning types. All endpoints receive a POST request with a JSON encoded body containing the data.
                            The Response from each endpoint is a JSON encoded response body with a predictable response code.
                        </p>
                        <br/>
                        <p>
                            This is the full API documentation. If you want examples or want to get started right away, check out our <span className="linkStyle" onClick={this.openGuideModal}>guides</span>.
                        </p>
                        <br/>
                        <p>
                            The documentation assumes basic proficiency in programming. It will contain your actual API Key if you are logged in where it's applicable.
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <URLBox urlType="BASE URL" url="https://api-woyera.com/clean/<data_cleaning_type>"/>
                    <br/>
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(Introduction)