import React, {Component} from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux';
import {toggleModal} from "../Actions/ToggleModal";
import { isMobile } from 'react-device-detect';

class CTA extends Component {
    constructor(props) {
        super(props);

        this.signUp = this.signUp.bind(this);
    }

    signUp(e){
        if (isMobile){
            this.signupButton.click()
        }
        else{
            this.props.toggleModal("signupModal")
        }
    }

    render() {
        let divStyle = {textAlign: 'center'};

        if (isMobile){
            divStyle['marginLeft'] = '30px';
            divStyle['marginRight'] = '30px';
        }
        return (
            <div style={divStyle}>
                <a style={{display: 'none'}}
                   ref={signupButton => this.signupButton = signupButton}
                   href="/signup">Download Hidden</a>
                <Button color="blue" onClick={this.signUp} style={{marginTop: '2px', fontSize: '150%'}}>
                    Sign up free and start cleaning <Icon name="right arrow"/>
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal
}


export default connect(mapStateToProps, mapActionsToProps)(CTA)