import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table } from 'semantic-ui-react'
import FileSampleHeaderRow from './FileSampleHeaderRow'

class FileSampleTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            dummyWidth: '100%'
        }

        this.uploadFilesSampleTable = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!('samples' in prevProps.fileInformation) && 'samples' in this.props.fileInformation){
            console.log(this.uploadFilesSampleTable.clientWidth)
        }
    }

    componentDidMount(){
        if ('samples' in this.props.fileInformation) {
            console.log(this.uploadFilesSampleTable.clientWidth)
        }
        // this.setState({dummyWidth: document.getElementById("uploadFilesSampleTable").clientWidth.toString() + "px"})
    }

    render() {
        if ('samples' in this.props.fileInformation){
            const fileSamples = this.props.fileInformation.samples;
            const rowCount = this.props.rowCount <= fileSamples.length ? this.props.rowCount : fileSamples.length;

            // Check whether the viz or select column headers need to be displayed
            const vizHeaderRow = this.props.viz ? <FileSampleHeaderRow info="viz" /> : null;
            let selectColumnHeaderRow = this.props.selectColumn ? <FileSampleHeaderRow info="selectColumn" /> : null;

            if (this.props.uploadDataType === "address" && this.props.cleanOptions.addressColumn === "multiple"){
                selectColumnHeaderRow = null
            }

            // Create the rows for the table
            let rows = [];

            for (let rowIdx=0; rowIdx<rowCount; rowIdx++){
                const rowArray = fileSamples[rowIdx];

                let columns = [];
                for (let colIdx=0; colIdx<rowArray.length; colIdx++){
                    columns.push(
                        <Table.Cell key={rowIdx.toString() + colIdx.toString()}>
                            {rowArray[colIdx]}
                        </Table.Cell>
                    )
                }

                rows.push(
                    <Table.Row key={rowIdx.toString()}>
                        {columns}
                    </Table.Row>
                )
            }

            return(
                <div id="uploadFilesSampleTableContainer">
                    <div id="uploadFilesSampleTable" ref={this.uploadFilesSampleTable}>
                        <Table celled>
                            <Table.Header>
                                {selectColumnHeaderRow}
                                <FileSampleHeaderRow info="name" />
                                {vizHeaderRow}
                            </Table.Header>
                            <Table.Body>
                                {rows}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            )

        }
        else{
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    cleanOptions: state.mainState.cleanOptions,
    uploadDataType: state.mainState.uploadDataType
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileSampleTable)