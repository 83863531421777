import cleanDataTypes from "./cleanDataTypes"

export const canColumnsBeCleaned = (selectedColumns, columnCleanTypes) => {
    let validCleanTypeCount = 0;

    for (let i=0; i<selectedColumns.length; i++){
        if ((cleanDataTypes.includes(columnCleanTypes[selectedColumns[i]]))){
            validCleanTypeCount += 1
        }
    }

    return validCleanTypeCount === selectedColumns.length
}