import React, {Component} from 'react'
import DisplayBox from "../DisplayBox";
import ReactJson from 'react-json-view'
import ProLabel from "./ProLabel"
import APIRespBoxHeader from "./APIRespBoxHeader";

class PhoneResponse extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPro: true,
            height: 0
        }
    }

    componentDidMount(){
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        let height = 0;
        const div = document.getElementById("apiPhoneSectionIntro")

        if (div !== null && div !== undefined){
            height = div.offsetHeight;
        }

        this.setState({ height: height })
    }

    changeProToggle = (isPro) => {
        this.setState({ isPro: isPro })
    }

    render() {
        let resp = [{"formatted":"+32-423-677898","split":{"countryCode":"32","areaCode":"0423","number":"677898"},"error":false,"score":100,"input":"+32 0423/67.78.98"}];

        if (this.state.isPro){
            resp['valid'] = "VALID"
        }

        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <div id="apiPhoneSectionIntro">
                            <p>Cleaning a phone number will standardize it to the widely accepted <a href="https://en.wikipedia.org/wiki/E.164" target="_blank" rel="noopener noreferrer">E.164 format</a>: +[CountryCode]-[AreaCode]-[Number]</p>
                            <br/>
                            <p>
                                For example:
                                <ul>
                                    <li><i>(212) 555 1924</i> will be cleaned to <i>212-5551924</i></li>
                                    <li><i>82 045-888-6666</i> will be cleaned to <i>+82-45-8886666</i></li>
                                    <li><i>+54 (01135) 24-5566</i> will be cleaned to <i>+54-1135-245566</i></li>
                                </ul>
                            </p>
                            <br/>
                            <p>If you are doing <ProLabel/> cleaning, you will also see whether the Phone is real or not</p>
                            <br/>
                        </div>
                        <div className="apiDocSection">
                            <h5>Formatted String</h5>
                            <p>The formatted phone number is a string that can be one of three following formats:</p>
                            <ol>
                                <li><b>+countryCode-areaCode-number</b></li>
                                <li><b>areaCode-number</b></li>
                                <li><b>number</b></li>
                            </ol>
                            <p>These are different because we may not be able to determine the <b>countryCode</b> and/or the <b>areaCode</b></p>
                        </div>
                        <br/>
                        <div className="apiDocSection">
                            <h5>Split Object Components</h5>
                            <p><span className="parameterName">countryCode</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Country Code of the phone number <i>For e.g., <b>32</b>. No + is added to this value unlike the formatted. Blank ("") if not found.</i></p>
                            <br/>
                            <p><span className="parameterName">areaCode</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Area Code of the phone number. We remove leading zeros following the E.164 format. Blank ("") if not found.</p>
                            <br/>
                            <p><span className="parameterName">number</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">The rest of the phone number</p>
                        </div>
                        <br/>
                        <div className="apiDocSection">
                            <h5 style={{color: 'green'}}>There is one more parameter in the <b>PRO</b> Phone response object</h5>
                            <p><span className="parameterName">valid</span> <span className="parameterType">string </span> <ProLabel/></p>
                            <p className="parameterDesc">Value showing if phone number is real or not. There are 2 possible values:</p>
                            <ul style={{paddingLeft: "1"}}>
                                <li><b>VALID</b> if the phone is real and can accept calls and/or SMS</li>
                                <li><b>INVALID</b> if the phone is <u>not</u> real and can <u>not</u> accept calls and SMS</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{marginTop: this.state.height}}>
                        <DisplayBox
                            header={<APIRespBoxHeader
                                header="+32 0423/67.78.98"
                                isPro={this.state.isPro}
                                changeProToggle={this.changeProToggle}
                            />}
                            desc={<div>
                                <ReactJson src={resp}
                                           name={null}
                                           theme="ocean"
                                           displayObjectSize={false}/>
                            </div>}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PhoneResponse