import React, {Component} from 'react';
import {FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton, EmailIcon,  TwitterShareButton, TwitterIcon, FacebookIcon, LinkedinIcon, RedditIcon} from "react-share";
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";

class SocialMediaButtons extends  Component {
    constructor(props){
        super(props);

        this.openPhoneModal = this.openPhoneModal.bind(this);
    }

    openPhoneModal(e){
        this.props.toggleModal("phoneModal")
    }

    render() {
        return (
            <div className="footerShareGrid">
                <div className="socialMediaButton" id="twitterButton">
                    <TwitterShareButton
                        url={"twitter.com"}
                        title={"I saved so much time with cleanspreadsheets.com! Check them out for an easy to use service to clean your spreadsheets."} >
                        <TwitterIcon
                            size={32}
                            round />
                    </TwitterShareButton>
                </div>
                <div className="socialMediaButton" id="facebookButton">
                    <FacebookShareButton
                        url={"fb.com"}
                        title={"I saved so much time with cleanspreadsheets.com! Check them out for an easy to use service to clean your spreadsheets."} >
                        <FacebookIcon
                            size={32}
                            round />
                    </FacebookShareButton>
                </div>
                <div className="socialMediaButton" id="redditButton">
                    <RedditShareButton
                        url={"reddit.com"}
                        title={"I saved so much time with cleanspreadsheets.com! Check them out for an easy to use service to clean your spreadsheets."} >
                        <RedditIcon
                            size={32}
                            round />
                    </RedditShareButton>
                </div>
                <div className="socialMediaButton" id="linkedinButton">
                    <LinkedinShareButton
                        url={"linkedin.com"}
                        title={"I saved so much time with cleanspreadsheets.com! Check them out for an easy to use service to clean your spreadsheets."} >
                        <LinkedinIcon
                            size={32}
                            round />
                    </LinkedinShareButton>
                </div>
                <div className="socialMediaButton" id="emailButton">
                    <a href="mailto:info@cleanspreadsheets.com?Subject=I%20Want%20To%20Clean%20Spreadsheets">
                        <EmailIcon size={32} round />
                    </a>
                </div>
                <div className="socialMediaButton" id="callButton">
                    <Button color="green" circular icon="phone" style={{color: 'white', fontSize: '95%'}} onClick={this.openPhoneModal}/>
                </div>
            </div>
        );
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(SocialMediaButtons)