import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'

class PhoneModal extends Component {
    render() {
        return (
            <ModalApp name="phoneModal"
                      header="Call Us"
                      size="small"
                      body={<div>
                          <br/>
                          <a href="tel:14159159398">+1-415-915-9398</a>
                          <br/>
                      </div>}
            />
        )
    }
}

export default PhoneModal