import React, {Component} from 'react'
import ModalApp from '../../../../SharedComponents/Modal'
import { connect } from 'react-redux'
import Name from "../../../../InfoPages/Help/FAQSection/Name";
import Address from "../../../../InfoPages/Help/FAQSection/Address";
import Company from "../../../../InfoPages/Help/FAQSection/Company";
import Date from "../../../../InfoPages/Help/FAQSection/Date";
import Email from "../../../../InfoPages/Help/FAQSection/Email";
import cleanDataTypes from "../../../../Constants/cleanDataTypes";
import Currency from "../../../../InfoPages/Help/FAQSection/Currency";
import Keyword from "../../../../InfoPages/Help/FAQSection/Keyword";
import Phone from "../../../../InfoPages/Help/FAQSection/Phone";

class CleanTypeFAQ extends Component {
    render() {
        let modal = null;

        if (this.props.modalInfo !== null){
            if ("cleanTypeFAQ" in this.props.modalInfo){
                let cleanType = this.props.modalInfo.cleanTypeFAQ

                if (cleanDataTypes.includes(cleanType)){
                    let faqBody = {
                        name: <Name/>,
                        address: <Address/>,
                        company: <Company/>,
                        date: <Date/>,
                        email: <Email/>,
                        currency: <Currency/>,
                        notes: <Keyword/>,
                        phone: <Phone/>

                    }

                    modal = <ModalApp name="cleanTypeFAQ"
                                      header="FAQ"
                                      size="medium"
                                      body={faqBody[cleanType]}
                    />
                }
            }
        }

        return (
            modal
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

export default connect(mapStateToProps, null)(CleanTypeFAQ)