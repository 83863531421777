import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import images from "../../Constants/Images";
import HelpSectionButton from "../../InfoPages/Help/HelpSectionButton";
import PricingPageBlockFeatureModalBody from "../../InfoPages/Pricing/PricingPageBlockFeatureModal/PricingPageBlockFeatureModalBody"

class ProVsBasicModalBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            helpSection: null
        }

        this.exampleRef = React.createRef()
    }

    changeHelpSection = (helpSection) => {
        let scroll = false;
        if (this.state.helpSection === null){
            scroll = true;
        }

        this.setState({
            helpSection: helpSection
        }, () =>  scroll ? this.exampleRef.current.scrollIntoView() : {})
    }

    render() {
        const pStyle = {fontSize: "20px", marginBottom: "10px"};

        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <div>
                            <div style={{backgroundColor: "#ffa543", color: "white", textAlign: "center", padding: "15px"}}>
                                <h2>PRO</h2>
                            </div>
                            <div style={{backgroundColor: "rgba(255, 165, 67, 0.48)", padding: "15px"}}>
                                <p style={pStyle}><Icon name="briefcase" /> Perfect for your <b>business</b> needs</p>
                                <p style={pStyle}><Icon name="plus square outline" /> <b>Extensive</b> data enrichment</p>
                                <p style={pStyle}><Icon name="copy outline" /><b>Multiple</b> matches returned</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div>
                            <div style={{backgroundColor: "#8fd0f9", color: "white", textAlign: "center", padding: "15px"}}>
                                <h2>BASIC</h2>
                            </div>
                            <div style={{backgroundColor: "rgba(143, 208, 249, 0.48)", padding: "15px"}}>
                                <p style={pStyle}><Icon name="user" /> Good for your <b>analyst</b> needs</p>
                                <p style={pStyle}><Icon name="minus square outline" /> <b>Minimum</b> data enrichment</p>
                                <p style={pStyle}><Icon name="file outline" /> <b>One</b> best match returned</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div>
                    {
                        this.state.helpSection === null ? <h4 style={{color: 'green', textAlign: "center"}}>Click on Data Type below to see examples</h4> : null
                    }
                </div>
                <br/>
                <div className="row" style={{marginLeft: '10px', marginRight: '10px', marginBottom: '10px'}}>
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.nameIcon}
                            name="name"
                            changeHelpSection={this.changeHelpSection}
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.dateIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="date"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.addressIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="address"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.companyIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="company"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2" />
                </div>
                <div className="row" style={{marginLeft: '10px', marginRight: '10px', marginBottom: '40px'}}>
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.emailIcon}
                            name="email"
                            changeHelpSection={this.changeHelpSection}
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.phoneIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="phone"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.currencyIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="currency"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.keywordIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="notes"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2" />
                </div>
                <div>
                    {
                        this.state.helpSection === null ? null :
                           <div ref={this.exampleRef}>
                               <PricingPageBlockFeatureModalBody dataType={this.state.helpSection} />
                           </div>
                    }
                </div>
            </div>
        )
    }
}

export default ProVsBasicModalBody