import React, {Component} from 'react'

class CompanyExample extends Component {
    render() {
        let pro = 'pro' in this.props;

        if (pro){
            pro = this.props.pro
        }

        const faq = 'faq' in this.props;

        return (
            <div>
                <table className="table table-sm table-bordered">
                    <thead>
                    <tr>
                        {'faq' in this.props ? <th>Your Value</th> : <th>Input</th> }
                        {'faq' in this.props ? <th>Cleaned Value</th> : null}
                        <th>name</th>
                        <th>suffix</th>
                        <th>suffix full</th>
                        {pro ? <th>domain</th> : null}
                        {pro ? <th>country</th> : null}
                        {pro ? <th>locality</th> : null}
                        {pro ? <th>employees count</th> : null}
                        {pro ? <th>industry</th> : null}
                        {pro ? <th>year founded</th> : null}
                        {pro ? <th>linkedin URL</th> : null}
                        <th>other names</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>ThyssenKrupp AG</td>
                        {faq ?  <td>ThyssenKrupp</td> : null}
                        <td>ThyssenKrupp</td>
                        <td>AG</td>
                        <td>AKTIENGESELLSCHAFT</td>
                        {pro ? <td>thyssenkrupp.com</td> : null}
                        {pro ? <td>Germany</td> : null}
                        {pro ? <td>Essen</td> : null}
                        {pro ? <td>29513</td> : null}
                        {pro ? <td>Mechanical or industrial engineering</td> : null}
                        {pro ? <td>1811</td> : null}
                        {pro ? <td>linkedin.com/company/thyssenkrupp</td> : null}
                        <td> </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>apple incorporated</td>
                        {faq ? <td style={{backgroundColor: 'lightgreen'}}>Apple</td> : null}
                        <td>Apple</td>
                        <td>INC</td>
                        <td>INCORPORATED</td>
                        {pro ? <td>apple.com</td> : null}
                        {pro ? <td>United States</td> : null}
                        {pro ? <td>Cupertino</td> : null}
                        {pro ? <td>218112</td> : null}
                        {pro ? <td>Consumer electronics</td> : null}
                        {pro ? <td>1976</td> : null}
                        {pro ? <td>linkedin.com/company/apple</td> : null}
                        <td> </td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>Infosys Ltd.</td>
                        {faq ? <td style={{backgroundColor: 'lightgreen'}}>Infosys</td> : null}
                        <td>Infosys</td>
                        <td>LTD</td>
                        <td>PUBLIC LIMITED COMPANY</td>
                        {pro ? <td>infosys.com</td> : null}
                        {pro ? <td>India</td> : null}
                        {pro ? <td>Bangalore</td> : null}
                        {pro ? <td>217514</td> : null}
                        {pro ? <td>Information technology and services</td> : null}
                        {pro ? <td>1981</td> : null}
                        {pro ? <td>linkedin.com/company/infosys</td> : null}
                        <td> </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default CompanyExample