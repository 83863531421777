import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyColumnCleanTypes} from "../../../../Actions/ModifyColumnCleanTypes";
import {modifySelectedColumns} from "../../../../Actions/ModifySelectedColumns";
import {modifyColumnCleanInfo} from "../../../../Actions/ModifyColumnCleanInfo";
import {modifyAllColumnInfo} from "../../../../Actions/ModifyAllColumnInfo";
import {modifyTutorialInfo} from "../../../../Actions/ModifyTutorialInfo";
import {modifyTutorialStep} from "../../../../Actions/ModifyTutorialStep";

class AddCleaningStepButton extends Component {
    constructor(props) {
        super(props);

        this.addCleaningStep = this.addCleaningStep.bind(this);
        this.isBtnDisabled = this.isBtnDisabled.bind(this);
    }

    addCleaningStep(e){
        if (this.props.uploadDataType === "address"){
            if (this.props.cleanOptions.addressColumn === "multiple"){
                let addrColumns = [];
                if (this.props.cleanOptions.addressGeocode === "forward"){
                    addrColumns = this.props.addressColumns.forward;
                } else {
                    addrColumns = [this.props.addressColumns.reverse.lat, this.props.addressColumns.reverse.lng];
                }

                let selectedColumns = JSON.parse(JSON.stringify(this.props.selectedColumns));
                let columnTypes = JSON.parse(JSON.stringify(this.props.columnCleanTypes));
                let columnInfo = JSON.parse(JSON.stringify(this.props.columnCleanInfo));
                let columnNames = this.props.fileInformation.columnNames;

                const time = (new Date()).getTime();
                for (let i=0; i<addrColumns.length; i++){
                    let colIdx = columnNames.indexOf(addrColumns[i])

                    if (!selectedColumns.includes(colIdx)){
                        selectedColumns.push(colIdx)
                    }

                    columnTypes[colIdx] = "address"
                    const position = this.props.cleanOptions.addressGeocode === "reverse" ? (i === 0 ? "lat" : "lng") : i;

                    if (columnInfo[colIdx] === null){
                        columnInfo[colIdx] = [{geocode: this.props.cleanOptions.addressGeocode, position: position, id: time}]
                    } else {
                        const currColInfo = columnInfo[colIdx];
                        currColInfo.push({geocode: this.props.cleanOptions.addressGeocode, position: position, id: time})
                        columnInfo[colIdx] = currColInfo
                    }
                }

                this.props.modifyAllColumnInfo(selectedColumns, columnTypes, columnInfo);
                this.props.modifyTutorialStep(4)
            }
        }
    }

    isBtnDisabled(){
        let btnDisabled = false;

        if (this.props.cleanOptions.addressGeocode === "forward"){
            if (this.props.addressColumns.forward.length === 0){
                btnDisabled = true;
            }
        }  else if (this.props.cleanOptions.addressGeocode === "reverse"){
            if (this.props.addressColumns.reverse.lat === null || this.props.addressColumns.reverse.lng === null){
                btnDisabled = true;
            }
        }

        return btnDisabled
    }

    render() {
        if ('samples' in this.props.fileInformation){
            const isDisabled = this.isBtnDisabled();
            let errorMessage = null;

            if (isDisabled){
                let message;
                if (this.props.cleanOptions.addressGeocode === "forward"){
                    message = "At least 1 column needs to form your address"
                } else {
                    message = "Select both the Latitude and Longitude columns"
                }

                errorMessage = <div style={{color: 'red'}}><small><i>{message}</i></small></div>
            }

          return(
            <div className="addCleaningStepsButtonDiv">
              <Button disabled={isDisabled} size="massive" className="addCleaningStepsButton" content="Confirm Columns" icon="plus" color="green" onClick={this.addCleaningStep} />
                {errorMessage}
            </div>
          )
        } else {
          return(
            null
          )
        }
  }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    addressColumns: state.mainState.addressColumns,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
    cleanOptions: state.mainState.cleanOptions,
    uploadDataType: state.mainState.uploadDataType,
    columnCleanInfo: state.mainState.columnCleanInfo
})

const mapActionsToProps = {
    modifyAllColumnInfo: modifyAllColumnInfo,
    modifyColumnCleanTypes: modifyColumnCleanTypes,
    modifySelectedColumns: modifySelectedColumns,
    modifyColumnCleanInfo: modifyColumnCleanInfo,
    modifyTutorialStep: modifyTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(AddCleaningStepButton)
