import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Button, Message } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {isEmailValid} from "../../Constants/isEmailValid";

class EmailPromoModalBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            friendEmail: '',
            message: null,
            sending: false
        }

        this.sendFriendEmail = this.sendFriendEmail.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e){
        this.setState({ [e.target.name]: e.target.value })
    }

    sendFriendEmail(e){
        this.setState({ sending: true })

        const errorState = () => this.setState({ sending: false, message: <Message negative={true} header="There was a problem emailing :(" content={<span>Please click <a href={"mailto:" + this.state.friendEmail.trim() + "?Subject=Promo%20Clean%20Spreadsheets"}>here</a> to email from your email account</span>} onDismiss={this.dismissMessage}/> })

        fetch(API_Root + "api/send-admin-email/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                fromEmail: 'info@cleanspreadsheets.com',
                toEmail: this.state.friendEmail.trim(),
                message: "Hi\n\nYour friend " + localStorage.getItem("email") + " has invited you to make an account on Clean Spreadsheets, a cloud based tool to clean addresses, names, company names and dates (and geocode).\n\nIf you sign up using this referral link, you will both get 1500 credits! You also get 250 FREE credits just for signing up.\n\nSign up link: " + this.props.promoLink + "\n\nIf you have any questions, just reply to this email!\n\nThanks,\nAdhaar from Clean Spreadsheets" ,
                subject: "Your friend has invited you to use a new tool, Clean Spreadsheets!",
                s3URL: ''
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({ sending: false, message: <Message positive={true} header="Your email was sent successfully!" onDismiss={this.dismissMessage}/> })
            } else {
                errorState()
            }
        }).catch(err => errorState());

    }

    dismissMessage(){
        this.setState({ message: null })
    }

    render() {
        return (
            <div className="emailPromoModalBody">
                <h4>Enter Your Friend's Email to send the Referral Link & Instructions</h4>
                <Input value={this.state.friendEmail} onChange={this.handleInputChange} name="friendEmail" fluid placeholder="Enter Your Friend's Email"/>
                <br/>
                <Button disabled={!isEmailValid(this.state.friendEmail.trim())} loading={this.state.sending} color="green" icon="paper plane outline" content="Email Link" size="large" onClick={this.sendFriendEmail} />
                {!isEmailValid(this.state.friendEmail.trim()) ? <small style={{color: 'red'}}><br/>Enter a valid email</small> : null}
                {this.state.message}
                <br/><br/>
                <h6>If your friend signs up using this link, you both get 1500 credits!</h6>
                <a href={this.props.promoLink} target="_blank" rel="noopener noreferrer">{this.props.promoLink}</a>
                <br/><br/>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EmailPromoModalBody)