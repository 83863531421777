import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'

class DownloadResultsButton extends Component {
    constructor(props) {
        super(props);

        this.downloadResults = this.downloadResults.bind(this);
    }

    downloadResults(e){
        this.downloadResultsRef.click()
    }

    render() {
        let downloadURL = this.props.cleanResults[this.props.colIdx];

        if (downloadURL === null){
            return(
                <div className="downloadResultsErrorMessage">
                    <h2>There was an error cleaning your data. Please email us at <a href="mailto:support@cleanspreadsheets.com?Subject=Download%20Results%20Error">support@cleanspreadsheets.com</a> to fix this</h2>
                </div>
            )
        }
        else{
            return (
                <span>
                    <a
                        style={{display: 'none'}}
                        ref={downloadResultsRef => this.downloadResultsRef = downloadResultsRef}
                        href={downloadURL}
                    >Download hidden</a>
                    <Button content="Download Your Cleaned Column" icon="columns" onClick={this.downloadResults} color="violet"/>
                    <br/><br/>
                </span>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    cleanResults: state.mainState.cleanResults
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DownloadResultsButton)