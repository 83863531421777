import React, {Component} from 'react'
import PageHero from '../../SharedComponents/PageHero'
import {connect} from "react-redux";
import {toggleModal} from "../../Actions/ToggleModal";
import General from "./FAQSection/General";
import HelpSectionButton from "./HelpSectionButton";
import images from "../../Constants/Images";
import Name from "./FAQSection/Name";
import Date from "./FAQSection/Date";
import Company from "./FAQSection/Company";
import Address from "./FAQSection/Address";
import Email from "./FAQSection/Email";
import Keyword from "./FAQSection/Keyword";
import Currency from "./FAQSection/Currency";
import Phone from "./FAQSection/Phone";

const faqDisplay = {
    general: <General/>,
    name: <Name/>,
    date: <Date/>,
    company: <Company/>,
    address: <Address/>,
    currency: <Currency/>,
    notes: <Keyword/>,
    phone: <Phone />,
    email: <Email/>
}

class HelpIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            helpSection: 'general'
        }

        this.changeHelpSection = this.changeHelpSection.bind(this);
    }

    changeHelpSection(helpSection){
        this.setState({
            helpSection: helpSection
        })
    }

    render() {
        return (
            <div>
                <PageHero title="FAQs" desc={<span>Some of our most asked questions</span>}/>
                <br/>
                <div className="row" style={{marginLeft: '10px', marginRight: '10px', marginBottom: '10px'}}>
                    <div className="col-sm-1" />
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.customIcon}
                            name="general"
                            changeHelpSection={this.changeHelpSection}
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.nameIcon}
                            name="name"
                            changeHelpSection={this.changeHelpSection}
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.dateIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="date"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.addressIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="address"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.companyIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="company"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-1" />
                    {/*<div className="col-sm-2">*/}
                        {/*<HelpSectionButton*/}
                            {/*icon={images.emailIcon}*/}
                            {/*changeHelpSection={this.changeHelpSection}*/}
                            {/*name="email"*/}
                            {/*currHelpSection={this.state.helpSection}*/}
                        {/*/>*/}
                    {/*</div>*/}
                </div>
                <div className="row" style={{marginLeft: '10px', marginRight: '10px', marginBottom: '40px'}}>
                    <div className="col-sm-1" />
                    <div className="col-sm-2">
                        {/*<HelpSectionButton*/}
                            {/*icon={images.customIcon}*/}
                            {/*name="general"*/}
                            {/*changeHelpSection={this.changeHelpSection}*/}
                            {/*currHelpSection={this.state.helpSection}*/}
                        {/*/>*/}
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.emailIcon}
                            name="email"
                            changeHelpSection={this.changeHelpSection}
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.phoneIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="phone"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.currencyIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="currency"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-2">
                        <HelpSectionButton
                            icon={images.keywordIcon}
                            changeHelpSection={this.changeHelpSection}
                            name="notes"
                            currHelpSection={this.state.helpSection}
                        />
                    </div>
                    <div className="col-sm-1" />
                </div>
                <div className="helpIndexDisplay">
                    {faqDisplay[this.state.helpSection]}
                </div>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});


const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(HelpIndex)