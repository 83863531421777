import React, {Component} from 'react'
import UpdatedCTA from "./UpdatedCTA"
import ScheduleDemoButton from "./ScheduleDemoButton";
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";

class BottomSignUp extends Component {
    constructor(props){
        super(props);

        this.contactUsModal = this.contactUsModal.bind(this);
    }

    contactUsModal(e){
        this.props.toggleModal("contactUsModal")
    }

    render() {
        return (
            <div className="landingPageBottomSignUp">
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <div style={{textAlign: "center", margin: "100px 0 100px 0"}}>
                            <h4><i>Want to see Clean Spreadsheets in action?</i></h4>
                            <ScheduleDemoButton/>
                            <br/><br/><br/><br/>
                            <h4>More Questions? <span className="linkStyle" onClick={this.contactUsModal}>CONTACT US</span></h4>
                            <br/><br/><br/><br/><br/><br/>
                        </div>
                    </div>
                    <div className="col-sm-3" />
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(BottomSignUp)