import React from 'react'
import UltimateGuideToGeocoding from "./Posts/UltimateGuideToGeocoding";
import QuickstartGuide from "./Posts/QuickstartGuide";
import ultimate from "./Posts/ultimate.svg";
import clean from "./Posts/clean.svg";
import GeocodeQuickstart from "./Posts/GeocodeQuickstart";
import geocode from "./Posts/geocode.svg"
import UltimateDateTimeGuide from "./Posts/UltimateDateTimeGuide.svg"
import marketing from "./Posts/marketing.svg"
import phone from "./Posts/phone.svg"
import name from "./Posts/name.svg"
import business from "./Posts/business.svg"
import CleanCurrencyPic from "./Posts/CleanCurrencyPic.svg"


const GuideDetailsObject={
    "ultimate-guide-to-geocoding-tools":{
        url:"ultimate-guide-to-geocoding-tools",
        header:"The Ultimate Guide to Geocoding Tools",
        desc:"Compare and contrast the best geocoding tools on the market",
        title:"Ultimate Guide to Geocoding Tools",
        writtenDt:"2020-08-19",
        writtenBy:"Clarissa Ng",
        illustration:ultimate,
        articleComponent:<UltimateGuideToGeocoding/>
    },
    "geocode-quickstart": {
        url: "geocode-quickstart",
        header: "Get Started With Geocoding",
        desc: "An easy quickstart guide for geocoding",
        title: "Geocode Quickstart",
        writtenDt:"2020-08-20",
        writtenBy:"Astha Sharma",
        illustration:geocode,
        articleComponent:<GeocodeQuickstart/>
    },
    "clean-spreadsheets-quickstart":{
        url:"clean-spreadsheets-quickstart",
        header:"Get Started Quickly With Clean Spreadsheets",
        desc:"An easy quickstart guide for the tool",
        title:"Clean Spreadsheets Quickstart",
        writtenDt:"2020-08-19",
        writtenBy:"Adhaar Sharma",
        illustration:clean,
        articleComponent: <QuickstartGuide/>
    },
    "5-reasons-marketing-effort-clean-data": {
        url:"5-reasons-marketing-effort-clean-data",
        header:"Improve Your Marketing Effort",
        desc:"5 reasons your marketing effort needs clean data",
        title:"5 Reasons Your Marketing Effort Needs Clean Data",
        writtenDt:"2020-10-16",
        writtenBy:"Adhaar Sharma",
        illustration: marketing,
        articleComponent: <QuickstartGuide/>
    },
    "ultimate-guide-to-clean-date-time": {
        url:"ultimate-guide-to-clean-date-time",
        header:"Ultimate Guide to Cleaning Dates and Time",
        desc:"Clean, standardize and format any Date or Time",
        title:"Ultimate Guide to Clean Dates and Time",
        writtenDt:"2020-10-09",
        writtenBy:"Adhaar Sharma",
        illustration: UltimateDateTimeGuide,
        articleComponent: <QuickstartGuide/>
    },
    "ultimate-guide-cleaning-phone-numbers": {
        url:"ultimate-guide-cleaning-phone-numbers",
        header:"Ultimate Guide to Cleaning Phone Numbers",
        desc:"Clean, standardize and format any Phone Number",
        title:"Ultimate Guide to Cleaning Phone Numbers",
        writtenDt:"2020-11-24",
        writtenBy:"Astha Sharma",
        illustration: phone,
        articleComponent: <QuickstartGuide/>
    },
    "quick-guide-clean-names-spreadsheets": {
        url:"quick-guide-clean-names-spreadsheets",
        header:"Cleaning Names Quick Start Guide",
        desc:"A Quick Guide on Cleaning People’s Names from Your Data",
        title:"Ultimate Guide to Cleaning Phone Numbers",
        writtenDt:"2020-11-03",
        writtenBy:"Astha Sharma",
        illustration: name,
        articleComponent: <QuickstartGuide/>
    },
    "clean-company-business-names-from-spreadsheets": {
        url:"clean-company-business-names-from-spreadsheets",
        header:"Clean Company Names from Spreadsheets",
        desc:"High level algorithm to clean company names",
        title:"Clean Company Names from Spreadsheets",
        writtenDt:"2021-04-24",
        writtenBy:"Adhaar Sharma",
        illustration: business,
        articleComponent: <QuickstartGuide/>
    },
    "ultimate-guide-to-clean-money-currency": {
        url:"ultimate-guide-to-clean-money-currency",
        header:"Ultimate Guide to Clean Money and Currency Amounts in Your Data",
        desc:"Learn all about cleaning, standardizing and parsing money data",
        title:"Ultimate Guide to Clean Money and Currency in your Data",
        writtenDt:"2021-05-31",
        writtenBy:"Adhaar Sharma",
        illustration: CleanCurrencyPic,
        articleComponent: <QuickstartGuide/>
    },

    "when-why-clean-your-spreadsheet-data":{
        url:"when-why-clean-your-spreadsheet-data",
        header:"Why You Should Clean Your Spreadsheet Data",
        desc:"Learn why cleaning your spreadsheet data is beneficial for your small business ",
        title:"Why You Should Clean Your Spreadsheet Data",
        writtenDt:"2021-07-18",
        writtenBy:"Astha Sharma",
        illustration: CleanCurrencyPic,
        articleComponent: <QuickstartGuide/>
    }


}
export default GuideDetailsObject