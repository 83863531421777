import React, {Component} from 'react'
import capitalizeUnderscore from '../../Constants/capitalizeUnderscore'

class APIMenuItem extends Component {
    constructor(props) {
        super(props);

        this.changeMenuItem = this.changeMenuItem.bind(this)
    }

    changeMenuItem(){
        this.props.changeMenuItem(this.props.name)

    }

    render() {
        let styleName = "apiDocsMenuItem";

        if (this.props.name === this.props.apiSection){
            styleName = "apiDocsMenuItemSelected"
        }

        if ('subMenuItem' in this.props){
            styleName += " apiDocsSubMenuItem"
        }

        return (
            <p className={styleName} onClick={this.changeMenuItem}>{capitalizeUnderscore(this.props.name)}</p>
        )
    }
}

export default APIMenuItem