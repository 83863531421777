import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import CleanDisplayMasterTable from "./CleanDisplayMasterTable";
import DummyCleanData from "./DummyCleanData";
import {toggleModal} from "../../../../Actions/ToggleModal";
import CleanDisplayMasterTableUpdated from "./CleanDisplayMasterTableUpdated";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import CleanDisplayMasterMenuUpdated from "./CleanDisplayMasterMenuUpdated";
import ColumnResultsInformation from "./ColumnResultsInformation";
import { Icon } from 'semantic-ui-react'
import FormattedResults from "../../Download/DisplayResults/FormattedResults";
import {modifyCleanedSourceIdx} from "../../../../Actions/ModifyCleanedSourceIdx";
import CleanPayment from "../CleanPayment/CleanPayment"

class CleanDisplayPreview extends Component {
    constructor(props){
        super(props);

        // this.state = {
        //     cleanedSourceIdx: this.props.cleanedSource.length > 0 ? 0 : -1
        // }

        this.openSupport = this.openSupport.bind(this);
        this.changeSelectedCol = this.changeSelectedCol.bind(this);
        this.openFAQModal = this.openFAQModal.bind(this);
    }

    openSupport(e){
        this.props.toggleModal("supportModal")
    }

    changeSelectedCol(idx){
        this.props.modifyCleanedSourceIdx(idx)
        // this.setState({ cleanedSourceIdx: idx })
    }

    openFAQModal(e, cleanType){
        this.props.modifyModalInfo({"cleanTypeFAQ": cleanType});
        this.props.toggleModal("cleanTypeFAQ");
    }

    render() {
        if (this.props.sampleResults === null){
            return(
                <div>
                    <h4>There was an error. Please <span className="linkStyle" onClick={this.openSupport}>Contact Our Support</span> to get help!</h4>
                    <DummyCleanData/>
                </div>
            )
        }
        else {
            let body;
            if (this.props.sampleResults.length === 0){
                body = <div>
                    <h4>Your input values were either blank or there was an error. Either way, see a sample of the cleaned data below</h4>
                    <DummyCleanData/>
                </div>
            }
            else if (this.props.sampleResults === "fetching"){
                body =
                    <div>
                        <h4>A sample of cleaned data will be shown here shortly...</h4>
                        <Loader
                            type="TailSpin"
                            color="black"
                            height="50"
                            width="50"
                        />
                    </div>
            }
            else {
                let table = <CleanDisplayMasterTable isSample={true}/>;
                let tablePro = null;
                let columnResultsInformation = null;

                if (this.props.cleanedSource !== undefined){
                    if (this.props.cleanedSourceIdx !== -1){
                        const showNum = this.props.sampleResults[this.props.cleanedSourceIdx].length > 5 ? 5 : this.props.sampleResults[this.props.cleanedSourceIdx].length
                        const addlMessage = <span><span className="sampleDescText">When you <Icon name="cogs" /> <b>Clean Your Data</b> all rows will be cleaned</span></span>

                        columnResultsInformation =  <ColumnResultsInformation
                                                        isSample={true}
                                                        changeSelectedCol={this.changeSelectedCol}
                                                        cleanedSourceIdx={this.props.cleanedSourceIdx}
                                                        showNum={showNum}
                                                        addlMessage={addlMessage}
                                                    />

                        let cleanedSource = this.props.cleanedSource[this.props.cleanedSourceIdx];
                        let cleanType;

                        if (cleanedSource.type === "selectedColumn"){
                            cleanType = this.props.columnCleanTypes[cleanedSource.id]
                        } else {
                            cleanType = "address"
                        }

                        if (this.props.proSampleResults.length !== null){
                            if (this.props.proSampleResults.length > 0){
                                tablePro =
                                    <div style={{marginBottom: "40px", border: "1px solid lightgrey"}}>
                                        <div className="cleanDisplayPreviewDisclaimer proDisclaimer">
                                            <h2>Pro Clean</h2>
                                        </div>
                                        <div id="cleanDisplay">
                                            <div className="cleanDisplayMasterTableResults">
                                                <FormattedResults
                                                    cleanType={cleanType}
                                                    cleanedSource={this.props.cleanedSource[this.props.cleanedSourceIdx]}
                                                    results={this.props.proSampleResults[this.props.cleanedSourceIdx]}
                                                    isSample={true}
                                                    isPro={true}
                                                />
                                            </div>
                                            <div id="cleanDisplayPayment">
                                                <CleanPayment isPro={true}/>
                                            </div>
                                        </div>
                                    </div>
                            }
                        }

                        table =
                            <div>
                                <div className="cleanDisplayPreviewDisclaimer basicDisclaimer">
                                    <h2>Basic Clean</h2>
                                </div>
                                <div id="cleanDisplay">
                                    <div className="cleanDisplayMasterTableResults">
                                        <FormattedResults
                                            cleanType={cleanType}
                                            cleanedSource={this.props.cleanedSource[this.props.cleanedSourceIdx]}
                                            results={this.props.sampleResults[this.props.cleanedSourceIdx]}
                                            isSample={true}
                                            isPro={false}
                                        />
                                    </div>
                                    <div id="cleanDisplayPayment">
                                        <CleanPayment isPro={false}/>
                                    </div>
                                </div>
                            </div>
                    }
                }

                let tableClassName = null;
                let cleanMenu = null;

                // if (this.props.cleanedSource.length > 1){
                //     // if (this.props.isSample){
                //     //     if ((this.))
                //     // }
                //     tableClassName = "cleanDisplayMasterTable"
                //     cleanMenu = <CleanDisplayMasterMenuUpdated
                //         changeSelectedCol={this.changeSelectedCol}
                //         cleanedSourceIdx={this.props.cleanedSourceIdx}/>
                // }

                body =
                    <div>
                        <h2>This is a sample of what your cleaned data will look like</h2>
                            <div className={tableClassName}>
                                {cleanMenu}
                                {columnResultsInformation}
                                {tablePro}
                                {table}
                            </div>
                    </div>
            }

            return (
                <div style={{paddingRight: "50px", border: "1px solid lightgrey", borderRadius: "15px", padding: "15px"}}>
                    {body}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    sampleResults: state.mainState.sampleResults,
    selectedColumns: state.mainState.selectedColumns,
    cleanedSource: state.mainState.cleanedSource,
    proSampleResults: state.mainState.proSampleResults,
    cleanedSourceIdx: state.mainState.cleanedSourceIdx,
    columnCleanTypes: state.mainState.columnCleanTypes
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyCleanedSourceIdx: modifyCleanedSourceIdx
}

export default connect(mapStateToProps, mapActionsToProps)(CleanDisplayPreview)