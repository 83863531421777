import React, {Component} from 'react'
import ExampleTable from './ExampleTable'

class DateFormatDetails extends Component {
    render() {
        const defectInfo = {type: 'dateFormats', rows: {
                values: ["January 10th, 2010", "12/25/2015 3:45PM", "Aug 1 2019", "1997-10-09 21:19", "2/17/88", "24/08/1957", "01.01.2021", "Mar 27 1991 10:45 AM", "14 February 71"],
                suggestions: ['2010-01-10 00:00:00', '2015-12-25 15:45:00', '2019-08-01 00:00:00', "1997-10-09 21:19:00", "1988-02-17 00:00:00", "1957-08-24 00:00:00", "2021-01-01 00:00:00", "1991-03-27 10:45:00", "1971-02-14 00:00:00"],
                splitComponents: [
                    [["year", "2010"], ["month", "1"], ["day", "10"]],
                    [["year", "2015"], ["month", "12"], ["day", "25"], ["hour", "15"], ["minute", "45"], ["second", "0"]],
                    [["year", "2019"], ["month", "8"], ["day", "1"]],
                ]
            }
        }

        return (
            <div>
                <h3>Standardize Dates to a Common Format</h3>
                <br/>
                <ExampleTable rowAdd={2} defectInfo={defectInfo}/>
            </div>
        )
    }
}

export default DateFormatDetails