import React, {Component} from 'react'
import DisplayBox from "../DisplayBox";
import ReactJson from 'react-json-view'
import {Divider} from 'semantic-ui-react'

class Response extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <div className="apiDocSection">
                            <h5 style={{color: 'red'}}>Error Response</h5>
                            <p>If the request was not successful, then a JSON encoded Response will be returned with only 1 parameter <code>error</code> that contains the error message.
                                </p>
                            <br/>
                            <p>You can tell a request was not successful if the following response codes are returned: <code>400, 402, 403, 404, 405 and 500</code>.</p>
                            <br/>
                            <small>
                                <i>WE DO NOT RAISE ERRORS TO ENSURE YOUR CODE DOES NOT BREAK. IT IS UP TO YOU TO PROPERLY HANDLE ERRORS IN YOUR CODE.</i>
                            </small>
                        </div>
                        <br/><br/><br/>
                        <div className="apiDocSection">
                            <h5 style={{color: 'green'}}>Success Response</h5>
                            <p>If the request was a success, then a JSON encoded Response will be returned with only 1 parameter <code>cleanData</code> that contains an array equal to the length of the <code>data</code> array that was sent with the request</p>
                            <br/>
                            <p>Each item in the <code>cleanData</code> array is another array containing anywhere from 1 up to 3 results from cleaning the value in the corresponding input index.<br/><br/>We call this the <code>matches</code> array. Each item in the <code>matches</code> array is a JSON object containing the details of the data cleaning. We call this JSON object the <code>results</code>. The <code>results</code> in the <code>matches</code> array are sorted in order of decreasing <code>score</code> which reflects how accurate we think the results are.</p>
                            <br/>
                            <p><u>Every <code>result</code> JSON object will always have the following 3 keys</u></p>
                            <p><span className="parameterName">input</span> <span className="parameterType">type of Input</span></p>
                            <p className="parameterDesc">This is the input that was submitted. It is the same type as the input value.</p>
                            <p className="parameterDesc"><i>Note: if you have sent an input that was not a string, it will most likely result an error.</i></p>
                            <br/>
                            <p><span className="parameterName">error</span> <span className="parameterType">boolean</span></p>
                            <p className="parameterDesc">A boolean stating whether there was an error cleaning this value or not.</p>
                            <br/>
                            <p><span className="parameterName">score</span> <span className="parameterType">integer</span></p>
                            <p className="parameterDesc">A confidence score out of 100 on how confident our cleaning algorithm were. If there was a problem or an error the score will be 0.</p>

                        <br/>
                        <Divider />
                        <h6 style={{color: 'red'}}><i>If there is an error cleaning the value</i></h6>
                            <p>The following parameters will be present in the <code>result</code> object</p>
                        <p><span className="parameterName">errorMessage</span> <span className="parameterType">string</span></p>
                        <p className="parameterDesc">Message containing why the value could not be cleaned</p>
                        <br/>
                        <Divider />
                        <h6 style={{color: 'green'}}><i>If there is no error cleaning the value</i></h6>
                            <p>The following parameters will be present in ALL of the <code>result</code> objects. Depending what kind of <b>data type</b> you are cleaning and whether it is <b>pro</b> or <b>basic</b>, additional parameters may be present in the object as you will read below. The <code>split</code> parameter will contain different components as well based on the <b>data type</b> and <b>pro</b> or <b>basic</b> cleaning.</p>
                        <br/>
                        <p><span className="parameterName">formatted</span> <span className="parameterType">string</span></p>
                        <p className="parameterDesc">The cleaned value. The exact format of the clean value will depend on the clean type as you will read below.</p>
                        <br/>
                        <p><span className="parameterName">split</span> <span className="parameterType">JSON Object</span></p>
                        <p className="parameterDesc">A JSON Object containing a split of the different components.
                            The components contained in the object will depend on the clean type as you will read below.</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <DisplayBox
                        color="red"
                        header="Sample Error Response"
                        desc={<div>
                            <ReactJson src={{error: "The API Key is either not valid or not specified in the body with 'apiKey'"}} name={null} theme="ocean" displayObjectSize={false}/>
                        </div>}
                    />
                    <br/><br/><br/><br/><br/><br/>
                    <DisplayBox
                        color="green"
                        header="Sample Success Response"
                        desc={<div>
                            <ReactJson src={{cleanData: [[{"split":{"year":2012,"month":3,"day":27,"hour":0,"minute":0,"second":0},"formatted":"2012-03-27 00:00:00","score":100,"error":false,"input":"Mar 27, 2012"}],[{"error":true,"errorMessage":"Value could not be converted to a date","score":0,"input":"Awesome Possum"}]]}} name={null} theme="ocean" displayObjectSize={false}/>
                        </div>}
                    />
                </div>
            </div>
        )
    }
}

export default Response