import React, {Component} from 'react'
import CleanDisplayMasterTableUpdated from "../Clean/CleanDisplayPreview/CleanDisplayMasterTableUpdated"
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import ProOrBasicTag from "../../UpdatedDashboard/Clean/CleanDisplayPreview/ProOrBasicTag"

class DownloadDisplayIndex extends Component {
    constructor(props) {
        super(props);

        this.downloadResults = this.downloadResults.bind(this);
    }

    downloadResults(e){
        this.downloadFullResultsRef.click()
    }

    render() {
        let downloadURL = this.props.fullCleanedFileURL;

        if (downloadURL === null){
            return(
                null
            )
        } else {
            return (
                <div className="downloadDisplayIndex">
                    <a
                        style={{display: 'none'}}
                        ref={downloadFullResultsRef => this.downloadFullResultsRef = downloadFullResultsRef}
                        href={downloadURL}
                    >Download hidden</a>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}><ProOrBasicTag pro={this.props.isDownloadFilePro}/></div>
                    <Button content="Download Your Cleaned File" icon="download" onClick={this.downloadResults} size="huge" color="green"/>
                    <br/><br/>
                    <div className="downloadDisplayIndexMasterTable">
                        <CleanDisplayMasterTableUpdated pro={this.props.isDownloadFilePro} isSample={false} />
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fullCleanedFileURL: state.mainState.fullCleanedFileURL,
    isDownloadFilePro: state.mainState.isDownloadFilePro
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(DownloadDisplayIndex)