import React, {Component} from 'react'

class NameExample extends Component {
    render() {
        let pro = 'pro' in this.props;

        if (pro){
            pro = this.props.pro
        }

        return (
            <div>
                <table className="table table-sm table-bordered">
                    <thead>
                    <tr>
                        {'faq' in this.props ? <th>Your Value</th> : <th>Input</th> }
                        {'faq' in this.props ? <th>Cleaned Value</th> : null }
                        {pro ? <th>prefix</th> : null}
                        <th>first</th>
                        {pro ? <th>middle</th> : null}
                        <th>last</th>
                        {pro ? <th>suffix</th> : null}
                        {pro ? <th>nicknames</th> : null}
                        {pro ? <th>other names</th> : null}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>dr. martin luther king Jr.</td>
                        {'faq' in this.props ? <td>Martin King</td> : null }
                        {pro ? <td>Dr</td> : null}
                        <td>Martin</td>
                        {pro ? <td>Luther</td> : null}
                        <td>King</td>
                        {pro ? <td>Jr</td> : null}
                        {pro ? <td> </td> : null}
                        {pro ? <td> </td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>Emilia r. Clarke</td>
                        {'faq' in this.props ? <td>Emilia Clarke</td> : null }
                        {pro ? <td> </td> : null}
                        <td>Emilia</td>
                        {pro ? <td>R</td> : null}
                        <td>Clarke</td>
                        {pro ? <td> </td> : null}
                        {pro ? <td> </td> : null}
                        {pro ? <td> </td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>Roosevelt, Franklin delano</td>
                        {'faq' in this.props ? <td>Franklin Roosevelt</td> : null }
                        {pro ? <td> </td> : null}
                        <td>Franklin</td>
                        {pro ? <td>Delano</td> : null}
                        <td>Roosevelt</td>
                        {pro ? <td> </td> : null}
                        {pro ? <td> </td> : null}
                        {pro ? <td> </td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>Mister Benjamin M. Franklin (Inventor)</td>
                        {'faq' in this.props ? <td>Benjamin Franklin</td> : null }
                        {pro ? <td>Mister</td> : null}
                        <td>Benjamin</td>
                        {pro ? <td>M</td> : null }
                        <td>Franklin</td>
                        {pro ? <td> </td> : null}
                        {pro ? <td> </td> : null}
                        {pro ? <td>(Inventor)</td> : null}
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default NameExample