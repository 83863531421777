import React, {Component} from 'react'
import SignOutButton from '../../Authentication/UserPages/SignOut/SignOutButton'
import { Menu, Image, Icon } from 'semantic-ui-react'
import images from "../../Constants/Images";
import NavbarItem from './NavbarItem'
import { push } from 'react-router-redux'
import {connect} from "react-redux";
import {BrowserView, isBrowser} from 'react-device-detect'
import NavbarFeedback from "./NavbarFeedback";

class NavbarAuth extends Component {
    constructor(props){
        super(props);

        this.state = {
            dashboard: false,
            urlEnd: window.location.pathname.replace("/", ""),
            mobileView: false
        }

        this.goToURL = this.goToURL.bind(this);
        this.changeURLEnd = this.changeURLEnd.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    goToURL(e){
        this.setState({
            urlEnd: 'clean'
        })
        e.preventDefault();
        this.props.navigateTo("/clean")
    }

    changeURLEnd(changedURLEnd){
        this.setState({
            urlEnd: changedURLEnd
        })
    }

    toggle(e, state){
        this.setState({
            mobileView: state
        })
    }

    render() {
        return (
            <div className="navbarAuth">
                <Menu secondary stackable>
                    <Menu.Menu position="left">
                        <BrowserView>
                            <Menu.Item>
                                <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                    <Image
                                            style={{cursor: 'pointer'}}
                                           src={images.logoImage}
                                           onClick={(e) => this.goToURL(e)}
                                           size="mini" />
                                </div>
                            </Menu.Item>
                        </BrowserView>
                        <Menu.Item>
                            <NavbarItem to="clean"
                                        name="Clean"
                                        icon="cogs"
                                        color="black"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="history"
                                        name="History"
                                        color="black"
                                        icon="history"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="credits"
                                        name="Credits"
                                        color="black"
                                        icon="dollar sign"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="profile"
                                        name="Profile"
                                        color="black"
                                        icon="user"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="api"
                                        name="API"
                                        color="black"
                                        icon="code"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <NavbarItem to="guides"
                                        name="Guides"
                                        color="gray"
                                        icon="book"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="FAQs"
                                        name="FAQs"
                                        color="gray"
                                        icon="question"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                        <Menu.Item>
                            {isBrowser ? <NavbarItem to="supportModal" name="Support" color="gray" urlEnd={this.state.urlEnd}
                                                     changeURLEnd={this.changeURLEnd} icon="envelope outline" modal /> : <NavbarItem to="support" name="Support" color="gray" urlEnd={this.state.urlEnd}
                                                                                                                                     changeURLEnd={this.changeURLEnd} icon="envelope outline" /> }
                        </Menu.Item>
                        {/*<Menu.Item>*/}
                        {/*    <NavbarFeedback/>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item>
                            <SignOutButton style={{marginTop: '5px'}} />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarAuth)