export const shouldFetchInfo = (fetchDate, minuteThreshold) => {
    let shouldFetch = true;
    if (fetchDate !== null){
        const currDate = new Date()
        let minutesElapsed = Math.round(((currDate - fetchDate % 86400000) % 3600000) / 60000); // minutes

        if (minutesElapsed <= minuteThreshold){
            shouldFetch = false;
        }
    }

    return shouldFetch
}