import React, {Component} from 'react'
import {connect} from 'react-redux'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import monthlyPricing from "../../Constants/monthlyPricing";
import PaymentForm from "../../SharedComponents/Payment/CustomPayment/PaymentForm"
import CreditCardSelection from "../../SharedComponents/Payment/RefillCreditsModal/CreditCardSelection"
import PreviousCardPayButton from "../../SharedComponents/Payment/RefillCreditsModal/PreviousCardPayButton"

class PaymentBodyModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardIdx: this.initCardIdx()
        }

        this.initCardIdx = this.initCardIdx.bind(this);
        this.changeCardIdx = this.changeCardIdx.bind(this);
    }

    changeCardIdx(cardIdx){
        this.setState({
            cardIdx: cardIdx
        })
    }

    initCardIdx(){
        let cardIdx = -1;

        if (this.props.paymentInfo !== null){
            if ('creditCards' in this.props.paymentInfo){
                if (this.props.paymentInfo['creditCards'].length > 0){
                    if ('defaultCard' in this.props.paymentInfo){
                        for (let i=0; i<this.props.paymentInfo['creditCards'].length; i++){
                            if (this.props.paymentInfo['creditCards'][i]['id'] === this.props.paymentInfo['defaultCard']){
                                cardIdx = i
                                break
                            }
                        }
                    }
                    else{
                        cardIdx = 0
                    }
                }
            }
        }

        return cardIdx
    }

    render() {
        if (!isObjectEmpty(this.props.modalInfo)){
            const planType = monthlyPricing[this.props.currency][this.props.modalInfo.priceIdx].name.toUpperCase()

            return (
                <div>
                    <h2 style={{color: 'signUp' in this.props ? 'black' : "white"}}>Upgrade to {["A", "E", "I", "O", "U"].includes(planType.charAt(0)) ? "an" : "a"} {planType} Account</h2>
                    <h5 className="thinHeading" style={{color: 'signUp' in this.props ? 'black' : "white"}}>{monthlyPricing[this.props.currency][this.props.modalInfo.priceIdx].credits.toLocaleString() + " Credits per Month & Awesome " + monthlyPricing[this.props.currency][this.props.modalInfo.priceIdx].support + " Customer Support"}</h5>
                    <div className="segment-raised-rounded" style={{backgroundColor: "white", marginTop: "25px"}}>
                        <CreditCardSelection cardIdx={this.state.cardIdx} changeCardIdx={this.changeCardIdx} />
                        {
                            this.state.cardIdx === -1 ?
                                <PaymentForm planIdx={this.props.modalInfo.priceIdx} cardIdx={this.state.cardIdx} credits={0} signUp={'signUp' in this.props}/>
                                :
                                <PreviousCardPayButton planIdx={this.props.modalInfo.priceIdx} cardIdx={this.state.cardIdx} credits={0} />
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{color: "white"}}>
                    <h1 className="thinHeading">Upgrade Account</h1>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    currency: state.mainState.currency,
    paymentInfo: state.mainState.paymentInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PaymentBodyModal)