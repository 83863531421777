import React, {Component} from 'react'
import AccountIndex from "./AccountIndex"

class ProfileIndex extends Component {
    render() {
        return (
            <div id="profileIndex" className="accountIndex">
                <AccountIndex/>
            </div>
        )
    }
}

export default ProfileIndex