import React,{Component} from 'react'
import {authorDetails} from "./authorDetails";
import {Image} from "semantic-ui-react";
import GuideDetailsObject from "./GuideDetailsObject";

class GuidesCard extends Component {
    render() {
        const cardInfo=GuideDetailsObject[this.props.url];
        const writtenBy = <span><Image src={authorDetails[cardInfo.writtenBy]['pic']} avatar /> <a href={authorDetails[cardInfo.writtenBy]['social']} target="_blank" rel="noopener noreferrer">{cardInfo.writtenBy}</a></span>
        const writtenDt = <span><i>{cardInfo.writtenDt}</i></span>


        return (

            <div className="guidesCard">
                <a style={{textDecoration:"none",color:"black"}} href={"/guides/" + this.props.url + ".html" }>
                    <div>
                        <h4> {cardInfo.header} </h4>
                        <img className="guidesCardImg" src={cardInfo.illustration} alt="merged files"/>
                        <br/><br/>
                        {cardInfo.desc}
                        <br/><br/>
                        <div style={{paddingTop: '10px'}}>{writtenBy} {writtenDt}</div>
                    </div>
                </a>
            </div>

        )
    }

}


export default GuidesCard