import React, {Component} from 'react'
import images from "../../../Constants/Images";
import { Icon, Image, Button } from 'semantic-ui-react'
import {modifyUploadDataType} from "../../../Actions/ModifyUploadDataType";
import { connect } from 'react-redux'
import cleanDataTypes from "../../../Constants/cleanDataTypes";
import DataTypeButton from "./DataTypeButton";

class DataTypeToolbar extends Component {
    constructor(props) {
        super(props);

        this.changeDataType = this.changeDataType.bind(this);
        this.getClassName = this.getClassName.bind(this);
    }

    // changeDataType(e, {value}){
    //     this.props.modifyUploadDataType(value)
    // }

    changeDataType(value){
        this.props.modifyUploadDataType(value)
    }

    getClassName(dataType){
      if (this.props.uploadDataType === dataType){
        return "segment-raised-rounded helpSectionButton helpSectionButtonSelected"
      }
      else{
        return "helpSectionButton"
      }
    }

    render() {
        if ('samples' in this.props.fileInformation){
            console.log('samples' in this.props.fileInformation)
            const cleanDataTypesOptions = cleanDataTypes.map((x, idx) => { return { key: idx, value: x, text: <span><Image src={images[x + "Icon"]} avatar /> {x.charAt(0).toUpperCase() + x.substring(1, x.length)}</span> } })
            return (
                <div>
                    <h2><Icon name="cogs" /> I want to clean...</h2>
                    <div className="row">
                        <div className="col-sm-2">
                            <DataTypeButton
                                idx={0}
                            />
                        </div>
                        <div className="col-sm-2">
                            <DataTypeButton
                                idx={1}
                            />
                        </div>
                        <div className="col-sm-2">
                            <DataTypeButton
                                idx={2}
                            />
                        </div>
                        <div className="col-sm-2">
                            <DataTypeButton
                                idx={3}
                            />
                        </div>
                        <div className="col-sm-4" />
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-2">
                            <DataTypeButton
                                idx={4}
                            />
                        </div>
                        <div className="col-sm-2">
                            <DataTypeButton
                                idx={5}
                            />
                        </div>
                        <div className="col-sm-2">
                            <DataTypeButton
                                idx={6}
                            />
                        </div>
                        <div className="col-sm-2">
                            <DataTypeButton
                                idx={7}
                            />
                        </div>
                        <div className="col-sm-4" />
                    </div>
                    {/*<div className="row dataTypeToolbar" style={{marginLeft: '50px', marginRight: '25px'}}>*/}
                        {/**/}
                    {/*</div>*/}
                </div>
            )
        }
        else{
            return(
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    uploadDataType: state.mainState.uploadDataType
})

const mapActionsToProps = {
    modifyUploadDataType: modifyUploadDataType
}

export default connect(mapStateToProps, mapActionsToProps)(DataTypeToolbar)
