import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Image } from 'semantic-ui-react'
import images from "../../../Constants/Images";
import capitalizeHyphenated from "../../../Constants/capitalizeHyphenated";
import { Icon } from 'semantic-ui-react';
import {modifyAllColumnInfo} from "../../../Actions/ModifyAllColumnInfo";
import {getCleanValuesForStep} from "../../../Constants/getCleanValuesForStep";

class AddressCleaningStep extends Component {
    constructor(props) {
        super(props);

        this.removeCleaningStep = this.removeCleaningStep.bind(this);
    }

    removeCleaningStep(e, colInfoIndices, infoIndices){
        const copiedColInfo = JSON.parse(JSON.stringify(this.props.columnCleanInfo));
        const copiedColTypes = JSON.parse(JSON.stringify(this.props.columnCleanTypes));
        let selectedColumns = JSON.parse(JSON.stringify(this.props.selectedColumns));

        for (let i=0; i<colInfoIndices.length; i++){
            let colIdx = colInfoIndices[i];

            let colInfo = copiedColInfo[colIdx];

            if (colInfo !== null){
                if (colInfo.length === 1){
                    copiedColInfo[colIdx] = null
                    copiedColTypes[colIdx] = ""
                    selectedColumns.splice(selectedColumns.indexOf(colIdx), 1)
                } else {
                    colInfo.splice(infoIndices[i], 1)
                    copiedColInfo[colIdx] = colInfo
                }
            }
        }

        this.props.modifyAllColumnInfo(selectedColumns, copiedColTypes, copiedColInfo)
    }

    render() {
        const fileColNames = this.props.fileInformation.columnNames;
        const colInfo = this.props.columnCleanInfo;
        const colIdx = this.props.colInfoIdx;

        let stepID = [];

        for (let i=0; i<colIdx.length; i++){
            let idx = colIdx[i];
            let info = colInfo[idx];

            if (info !== null){
                for (let j=0; j<info.length; j++){
                    if (!(stepID.includes(info[j].id))){
                        stepID.push(info[j].id)
                    }
                }
            }
        }

        let messages = {};

        for (let i=0; i<stepID.length; i++) {
            let id = stepID[i];
            messages[id] = {colNames: {}, geocode: "", colInfoIdx: [], infoIdx: []}
        }

        for (let i=0; i<stepID.length; i++){
            let id = stepID[i];

            for (let j=0; j<colInfo.length; j++){
                let info = colInfo[j];

                if (info !== null){
                    for (let k=0; k<info.length; k++){
                        if (info[k].id === id){
                            if (info[k].geocode === "reverse"){
                                let messageInfo = messages[id];
                                messageInfo['geocode'] = "reverse"
                                let colNames = messageInfo.colNames;

                                if (info[k].position === "lat"){
                                    colNames['lat'] = fileColNames[j]
                                } else {
                                    colNames['lng'] = fileColNames[j]
                                }

                                messageInfo['colNames'] = colNames

                                let colInfoIdx = messageInfo['colInfoIdx'];
                                let infoIdx = messageInfo['infoIdx'];

                                colInfoIdx.push(j)
                                infoIdx.push(k)

                                messageInfo['colInfoIdx'] = colInfoIdx
                                messageInfo['infoIdx'] = infoIdx

                                messages[id] = messageInfo

                            } else {
                                let messageInfo = messages[id];
                                messageInfo['geocode'] = "forward"

                                let colNames = messageInfo.colNames;
                                colNames[info[k].position] = fileColNames[j]

                                let colInfoIdx = messageInfo['colInfoIdx'];
                                let infoIdx = messageInfo['infoIdx'];

                                colInfoIdx.push(j)
                                infoIdx.push(k)

                                messageInfo['colInfoIdx'] = colInfoIdx
                                messageInfo['infoIdx'] = infoIdx

                                messageInfo['colNames'] = colNames
                                messages[id] = messageInfo
                            }
                        }
                    }
                }
            }
        }

        stepID.sort()

        let addressDescStep = [];

        for (let i=0; i<stepID.length; i++){
            let geocodeType = messages[stepID[i]].geocode;
            let colNamesStep = messages[stepID[i]].colNames;

            if (geocodeType === "reverse"){
                if (this.props.cleanDataScreen){
                    addressDescStep.push(<tr key={i.toString()}>
                        <td>
                            latitude: {colNamesStep.lat}, longitude: {colNamesStep.lng} <i>reverse geocoding</i>
                        </td>
                        <td>
                            <Image src={images.addressIcon} avatar/><b>Address</b>
                        </td>
                        <td>
                            {getCleanValuesForStep(messages[stepID[i]], this.props.fileInformation['columnInfo'])}
                        </td>
                    </tr>)
                } else {
                    addressDescStep.push(<li className="cleaningStep" key={i.toString()}><Image src={images.addressIcon} avatar/><b>Address</b> latitude: {colNamesStep.lat}, longitude: {colNamesStep.lng} <i>reverse geocoding</i> <Icon onClick={(e) => this.removeCleaningStep(e, messages[stepID[i]].colInfoIdx, messages[stepID[i]].infoIdx)} className="removeStepIcon" name="remove circle" /></li>)
                }

            } else {
                const colIndices = Object.keys(colNamesStep)
                let colNamesString = "";

                for (let i=0; i<colIndices.length; i++){
                    colNamesString += colNamesStep[colIndices[i]] + ", "
                }

                colNamesString = colNamesString.substring(0, colNamesString.length - 2)

                if (this.props.cleanDataScreen){
                    addressDescStep.push(<tr key={i.toString()}>
                        <td>
                            {colNamesString} <i>forward geocoding</i>
                        </td>
                        <td>
                            <Image src={images.addressIcon} avatar/><b>Address</b>
                        </td>
                        <td>
                            {getCleanValuesForStep(messages[stepID[i]], this.props.fileInformation['columnInfo'])}
                        </td>
                    </tr>)
                } else {
                    addressDescStep.push(<li className="cleaningStep" key={i.toString()}><Image src={images.addressIcon} avatar/><b>Address</b> {colNamesString} <i>forward geocoding</i> <Icon onClick={(e) => this.removeCleaningStep(e, messages[stepID[i]].colInfoIdx, messages[stepID[i]].infoIdx)} className="removeStepIcon" name="remove circle" /></li>)
                }


            }
        }


        return(
            <React.Fragment>{addressDescStep}</React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    uploadDataType: state.mainState.uploadDataType,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
    cleanOptions: state.mainState.cleanOptions,
    addressColumns: state.mainState.addressColumns,
    columnCleanInfo: state.mainState.columnCleanInfo
})

const mapActionsToProps = {
    modifyAllColumnInfo: modifyAllColumnInfo
}

export default connect(mapStateToProps, mapActionsToProps)(AddressCleaningStep)
