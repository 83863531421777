import React, {Component} from 'react'
import { Checkbox } from 'semantic-ui-react'
import color from "../../../Constants/colors";

class APIRespBoxHeader extends Component {
    changeProToggle = () => {
        this.props.changeProToggle(!this.props.isPro)
    }

    render() {
        const proOrBasicStyle = {
            backgroundColor: color[this.props.isPro ? "pro" : "basic"],
            color: (this.props.isPro ? "white" : "black"),
            borderRadius: "5px",
            padding: "2px 5px 2px 5px"
        }

        return (
            <span>
                <Checkbox toggle label="Pro?" checked={this.props.isPro} onChange={this.changeProToggle}/>
                <br/>
                <h5>Sample <span style={proOrBasicStyle}>{this.props.isPro ? "PRO" : "BASIC"}</span> Response for <br/><b style={{fontSize: "120%"}}>{this.props.header}</b></h5>
            </span>
        )
    }
}

export default APIRespBoxHeader