import React, {Component} from 'react'
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-r";
import "ace-builds/src-noconflict/theme-monokai";

class RCodeSnippet extends Component {
    render() {
        const apiKey = this.props.apiKey === null ? "YOUR_API_KEY" : this.props.apiKey;

        const data = {
            name: '"Dr. Martin Luther King Jr.", "Stephen A. Smith", "Washington, George"',
            address: '"32 London Bridge St, London, United Kingdom","413 w 52nd Street, New York, 10019", "624 Brush St, Detroit, MI 48226"',
            date: '"Jan 23, 1981", "11/15/12 2:30 PM", "2 February, 2017 15:45:43"',
            company: '"Glaxosmithkline Pharma gmbh", "apple inc", "Infosys Ltd."',
            email: '"joecool@gmail.com", "khaleesi@got.org", "monkey bananas", "first.last@somecompany.org"'
        }

        let code = 'library(woyera)\n\nclean_data <- woyera::clean(api_key="' + apiKey + '", clean_type="' + this.props.cleanType + '", data=c(' + data[this.props.cleanType] + '))\n\n';
        code += 'formatted_data <- clean_data["formatted"]'

        return (
            <div>
                <AceEditor
                    mode="r"
                    theme="monokai"
                    name="rQuickstartGuide"
                    value={code}
                    editorProps={{ $blockScrolling: true }}
                    width='100%'
                    height='350px'
                />
            </div>
        )
    }
}

export default RCodeSnippet