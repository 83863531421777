import React, {Component} from 'react'
import {connect} from 'react-redux'
import CleanActionNotTaken from "./CleanActionNotTaken";
import {modifyStepsCompleted} from "../../../Actions/ModifyStepsCompleted";
import {canColumnsBeCleaned} from "../../../Constants/canColumnsBeCleaned";
import CleanDisplay from "./CleanDisplay"
import cleanDataTypes from "../../../Constants/cleanDataTypes";

class CleanIndex extends Component {
    componentDidMount(){
        window.scroll(0, 0);
        if (this.props.stepsCompleted === 0 && this.props.selectedColumns.length > 0){

            let validCleanTypeCount = 0;

            for (let i=0; i<this.props.selectedColumns.length; i++){
                if ((cleanDataTypes.includes(this.props.columnCleanTypes[this.props.selectedColumns[i]]))){
                    validCleanTypeCount += 1
                }
            }

            if (validCleanTypeCount === this.props.selectedColumns.length){
                this.props.modifyStepsCompleted(1)
            }
            else{
                this.props.modifyStepsCompleted(0)
            }
        }
    }

    render() {
        let body = <CleanActionNotTaken/>;

        if (this.props.stepsCompleted > 0){
            if (canColumnsBeCleaned(this.props.selectedColumns, this.props.columnCleanTypes)) {
                body = <div> <CleanDisplay /> </div>
            }
        }

        return (
            <div id="cleanIndex">
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stepsCompleted: state.mainState.stepsCompleted,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes
})

const mapActionsToProps = {
    modifyStepsCompleted: modifyStepsCompleted
}


export default connect(mapStateToProps, mapActionsToProps)(CleanIndex)