import React, {Component} from 'react'

class NewAddressExample extends Component {
    render() {
        let pro = 'pro' in this.props;

        if (pro){
            pro = this.props.pro
        }

        const faq = 'faq' in this.props;

        const tdStyle = {backgroundColor: "mistyrose", whiteSpace: 'nowrap'}
        // const tdStyle = null;

        return (
                <table className="intelligent-parsing-address-table">
                    <thead>
                    <tr>
                        { faq ? <th>Your Value</th> : <th>Input</th> }
                        {'faq' in this.props ? <th>Cleaned Value</th> : null}
                        <th>lat</th>
                        <th>lng</th>
                        <th>street number</th>
                        <th>route short</th>
                        <th>route long</th>
                        <th>neighborhood</th>
                        <th>political</th>
                        <th>locality</th>
                        <th>administrative area level 2</th>
                        <th>administrative area level 1 short</th>
                        <th>administrative area level 1 long</th>
                        <th>country short</th>
                        <th>country long</th>
                        <th>postal code</th>
                        <th>postal code suffix</th>
                        <th>addr type</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={tdStyle}>624 Brush St, 48226</td>
                        { faq ? pro ? <td>624 Brush St, Detroit, MI 48226, USA</td> : <td>624 Brush Street, Detroit, MI 48226, United States of America</td> : null}
                        { pro ? <td>42.33226579</td> : <td>42.3320389</td>}
                        { pro ? <td>-83.0421731</td>: <td>-83.042002</td>}
                        <td>624</td>
                        { pro ? <td>Brush St</td> : <td> </td>}
                        <td>Brush Street</td>
                        {pro ? <td>Downtown Detroit</td> : <td> </td>}
                        {pro ? <td> </td> : <td> </td>}
                        <td>Detroit</td>
                        {pro ? <td>Wayne County</td> : <td> </td>}
                        <td>MI</td>
                        <td>Michigan</td>
                        {pro ? <td>US</td> : <td>us</td>}
                        <td>United States</td>
                        <td>48226</td>
                        {pro ? <td>2909</td> : <td> </td>}
                        {pro ? <td>street_address</td> : <td>building</td>}
                    </tr>
                    <tr>
                        <td style={tdStyle}>413 w 52nd Street, New York, 10019</td>
                        { faq ? pro ? <td>413 W 52nd St, New York, NY 10019, USA</td> : <td>413 West 52nd Street, New York, NY 10019, United States of America</td> : null}
                        { pro ? <td>40.7652307</td> : <td>40.7652248</td>}
                        { pro ? <td>-73.988682</td>: <td>-73.988659</td>}
                        <td>413</td>
                        { pro ? <td>W 52nd St</td> : <td> </td>}
                        <td>West 52nd Street</td>
                        {pro ? <td> </td> : <td> </td>}
                        {pro ? <td>Manhattan</td> : <td> </td>}
                        <td>New York</td>
                        {pro ? <td>New York County</td> : <td> </td>}
                        <td>NY</td>
                        <td>New York</td>
                        {pro ? <td>US</td> : <td>us</td>}
                        <td>United States</td>
                        <td>10019</td>
                        {pro ? <td>5628</td> : <td> </td>}
                        {pro ? <td>premise</td> : <td>building</td>}
                    </tr>
                    <tr>
                        <td style={tdStyle}>32 London Bridge St, London, UK</td>
                        { faq ? pro ? <td>32 London Bridge St, London SE1 9SG, UK</td> : <td>The Shard, 32 London Bridge Street, London, SE1 9SG, United Kingdom</td> : null}
                        { pro ? <td>51.5042997</td> : <td>51.5043421</td>}
                        { pro ? <td>-0.0863703</td>: <td>-0.0864459</td>}
                        <td>32</td>
                        { pro ? <td>London Bridge St</td> : <td> </td>}
                        <td>London Bridge Street</td>
                        {pro ? <td> </td> : <td> </td>}
                        {pro ? <td> </td> : <td>London Borough of Southwark</td>}
                        <td>London</td>
                        {pro ? <td>Greater London</td> : <td> </td>}
                        {pro ? <td> </td> : <td>ENG</td>}
                        <td>England</td>
                        {pro ? <td>GB</td> : <td>gb</td>}
                        <td>United Kingdom</td>
                        <td>SE1 9SG</td>
                        {pro ? <td> </td> : <td> </td>}
                        {pro ? <td>premise</td> : <td>travel/tourism</td>}
                    </tr>
                    </tbody>
                </table>
        )
    }
}

export default NewAddressExample