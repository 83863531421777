import React, {Component} from 'react'
import { Input } from 'semantic-ui-react'

class RefillCustomCredits extends Component {
    constructor(props) {
        super(props);

        this.handleCreditsChange = this.handleCreditsChange.bind(this);
    }

    handleCreditsChange(e){
        this.props.handleCreditsChange(e)
    }

    render() {
        return (
            <div>
                <Input value={this.props.value} onChange={this.handleCreditsChange} name="creditsToRefill" label="Credits" labelPosition="right"/>
            </div>
        )
    }
}

export default RefillCustomCredits