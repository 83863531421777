import React, {Component} from 'react'
import {connect} from 'react-redux'
import addCommaToNumbers from "../../../Constants/addCommaToNumbers";
import { Button, Message } from 'semantic-ui-react'
import {modifyUserCredits} from "../../../Actions/ModifyUserCredits";
import {getCardInfoFromCardIdx} from "../../../Constants/getCardInfofromCardIdx";
import API_Root from "../../../Constants/API_Root";
import {getTotalPriceForCredits} from "../../../Constants/getTotalPriceForCredits";
import {toggleModal} from "../../../Actions/ToggleModal";
import currencyInfo from "../../../Constants/currencyInfo";
import monthlyPricing from "../../../Constants/monthlyPricing";
import { Icon } from 'semantic-ui-react'
import {modifyModalInfo} from "../../../Actions/ModifyModalInfo";

class PreviousCardPayButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            cardSuccess: null
        }

        this.payWithPreviousCard = this.payWithPreviousCard.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }
    openCongratsModal = () => {
        this.props.modifyModalInfo({refillCredits:this.props.credits, pro: this.props.isPro})
        this.props.toggleModal("congratsPaymentModal")
    }

    async payWithPreviousCard(e){
        this.setState({
            loading: true
        })

        let cardInfo = null;
        if (this.props.cardIdx !== -1){
            cardInfo = getCardInfoFromCardIdx(this.props.paymentInfo, this.props.cardIdx)
        }

        const failedMessage = "Your Payment Failed!";

        let response = await fetch(API_Root + "api/charge-clean-spreadsheets/", {
            method: "POST",
            headers: {Accept: 'application/json, text/plain, */*',},
            body: JSON.stringify({
                stripeToken: null,
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
                currency: this.props.currency,
                credits: this.props.credits,
                totalPrice: getTotalPriceForCredits(this.props.currency, this.props.credits, this.props.isPro),
                cardInfo: cardInfo,
                isPro: this.props.isPro
            })
        }).then().catch(err =>
            this.setState({
                loading: false,
                cardSuccess: <Message error
                                      onDismiss={this.dismissMessage}
                                      header={failedMessage}
                                      content="Please try again!"
                />
            })
        );

        if (response !== undefined){
            if (response.ok){
                this.setState({
                    loading: false,
                    cardSuccess: <Message positive={true}
                                          onDismiss={this.dismissMessage}
                                          header="Your payment was a success!"
                    />
                }, () => {
                    this.props.isPro ? this.props.modifyUserCredits({pro: this.props.credits + this.props.userCredits.pro, basic: this.props.userCredits.basic }) :
                        this.props.modifyUserCredits({pro: this.props.userCredits.pro, basic: this.props.credits + this.props.userCredits.basic });
                    this.props.toggleModal(null)
                })

                this.openCongratsModal()
            }
            else{
                response.json().then(data => {
                    const errorMsg = "errorMsg" in data ? data['errorMsg'] : "Please try again!"

                    this.setState({
                        loading: false,
                        cardSuccess: <Message error
                                              onDismiss={this.dismissMessage}
                                              header={failedMessage}
                                              content={errorMsg}
                        />
                    })
                }).catch(err =>
                    this.setState({
                        loading: false,
                        cardSuccess: <Message error
                                              onDismiss={this.dismissMessage}
                                              header={failedMessage}
                                              content="Please try again!"
                        />
                    })
                )
            }
        }
    }

    dismissMessage(){
        this.setState({ cardSuccess: null })
    }

    render() {
        return (
            <div style={{textAlign: 'center'}}>
                <Button disabled={this.props.credits === null} color="green" loading={this.state.loading} onClick={this.payWithPreviousCard} style={{textAlign: 'center'}} size="big">
                    <Icon name="dollar" /> {"Refill " + (this.props.credits === null ? "" : addCommaToNumbers(this.props.credits)) + (this.props.isPro ? " Pro " : " Basic ") + " Credits"}
                </Button>
                <br/>
                {this.state.cardSuccess}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    userCredits: state.mainState.userCredits,
    paymentInfo: state.mainState.paymentInfo
})

const mapActionsToProps = {
    modifyUserCredits: modifyUserCredits,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(PreviousCardPayButton)