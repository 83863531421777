import React, {Component} from 'react'
import CleanTypeDisplay from "./CleanTypeDisplay";
import NameExample from "./NameExample";
import AddressExample from "./AddressExample";
import DateExample from "./DateExample";
import CompanyExample from "./CompanyExample";
import EmailExample from "./EmailExample";
import CurrencyExample from "./CurrencyExample";
import { Icon } from "semantic-ui-react";
import PhoneExample from "./PhoneExample"
import KeywordExample from "./KeywordExample";
import { BrowserView } from "react-device-detect"
import NewAddressExample from "./NewAddressExample";

class DetailExampleIndex extends Component {
    render() {
        return (
            <div className="segment-raised-rounded" style={{backgroundColor: 'whitesmoke', borderRadius: "2px", marginTop: "50px"}}>
                <div style={{textAlign: 'center'}}>
                    <br/>
                    <h5 className="thinHeading">
                        Clean Spreadsheets intelligently identifies key components of your data and provides data enrichment
                    </h5>
                </div>
                <BrowserView>
                    <br/><br/>
                    <CleanTypeDisplay display={
                        {
                            address: <NewAddressExample pro={true}/>,
                            // address: <AddressExample pro={true}/>,
                            name: <NameExample pro={true} />,
                            company: <CompanyExample pro={true} />,
                            date: <DateExample pro={true} />,
                            email: <EmailExample pro={true} />,
                            phone: <PhoneExample pro={true} />,
                            currency: <CurrencyExample pro={true} />,
                            notes: <KeywordExample pro={true} />
                        }}
                    />
                </BrowserView>
                <br/><br/>
                <div style={{textAlign: "center", fontSize: "145%"}}>
                    <a href="/demo">Try out your own values with our live demo <Icon name="arrow right" /></a>
                </div>
                <br/><br/>
            </div>
        )
    }
}

export default DetailExampleIndex