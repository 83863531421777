import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'

class KeywordExample extends Component {
    render() {
        let pro = 'pro' in this.props;

        if (pro){
            pro = this.props.pro
        }

        return (
            <div>
                <table className="table table-sm table-bordered">
                    <thead>
                    <tr>
                        {'faq' in this.props ? <th>Your Value</th> : <th>Input</th> }
                        {'faq' in this.props ? <th>Cleaned Value</th> : <th>keywords</th> }
                        <th>secondary keywords</th>
                        {pro ? <th>extra keywords</th> : null}
                        {pro ? <th>text rank keywords</th> : null}
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor: 'mistyrose'}}>I was here for business and this place was walking distance from my hotel. I found the ramen to be excellent and the broth very flavorful. The chashu was done very well, and the soft boiled egg to be the perfect texture. Service was very prompt and friendly. You don't have to wait long for food here.</td>
                            <td><div>{"soft boiled egg, walking distance, perfect texture, wait long".split(",").map((x, idx) => <Label key={idx} style={{margin: "5px"}}>{x.trim()}</Label>)}</div></td>
                            <td><div>{"business, place, hotel, found, ramen, excellent, broth, flavorful, chashu, service, prompt, friendly, don, food".split(",").map((x, idx) => <Label key={idx} style={{margin: "5px"}}>{x.trim()}</Label>)}</div></td>
                            {pro ? <td> </td> : null}
                            {pro ? <td><div>{"texture, boiled".split(",").map((x, idx) => <Label key={idx} style={{margin: "5px"}}>{x.trim()}</Label>)}</div></td> : null}
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default KeywordExample