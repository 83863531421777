import React, {Component} from 'react'
import CreditCardDetails from './CreditCardDetails'
import {Elements, StripeProvider} from 'react-stripe-elements';
import { connect } from 'react-redux'
import {modifyUserCredits} from "../../../Actions/ModifyUserCredits";
import {getCardInfoFromCardIdx} from "../../../Constants/getCardInfofromCardIdx";
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifySubscriptionInfo} from "../../../Actions/ModifySubscriptionInfo";
import monthlyPricing from "../../../Constants/monthlyPricing";
import API_Root from "../../../Constants/API_Root";
import {modifyModalInfo} from "../../../Actions/ModifyModalInfo";

class PaymentForm extends Component {
    constructor(props){
        super(props)

        this.updateUserCredits = this.updateUserCredits.bind(this);
        this.updateUserSubscription = this.updateUserSubscription.bind(this);
        this.getCurrentPeriodEnd = this.getCurrentPeriodEnd.bind(this);
    }

    updateUserCredits(){
        if (this.props.isPro){
            this.props.modifyUserCredits({pro: this.props.userCredits.pro + this.props.credits, basic: this.props.userCredits.basic})
        } else {
            this.props.modifyUserCredits({pro: this.props.userCredits.pro, basic: this.props.userCredits.basic + this.props.credits})
        }

        this.props.toggleModal(null)
    }

    getCurrentPeriodEnd(){
        let b = new Date()
        let a;

        // Get day
        let periodEndDay = b.getDate();


        if ([1, 3, 5, 8, 10].includes(b.getMonth() + 1)){
            if (b.getMonth() === 1){
                if (b.getFullYear() % 4 === 0){
                    if (periodEndDay > 29){
                        periodEndDay = 29
                    }
                } else {
                    if (periodEndDay > 28) {
                        periodEndDay = 28
                    }
                }
            } else {
                if (periodEndDay > 30) {
                    periodEndDay = 30
                }
            }
        }

        if (b.getMonth() === 11){
            a = new Date(b.getFullYear() + 1, 0, periodEndDay)
        } else{
            a = new Date(b.getFullYear(), b.getMonth() + 1, periodEndDay)
        }

        return + a
    }

    updateUserSubscription(){
        this.props.modifySubscriptionInfo({
            subscription: true,
            planIdx: this.props.planIdx,
            planType: monthlyPricing[this.props.currency][this.props.planIdx].name,
            creditsLeft: monthlyPricing[this.props.currency][this.props.planIdx].credits,
            currentPeriodStart: Math.floor(+ new Date() / 1000 ),
            currentPeriodEnd: Math.floor(this.getCurrentPeriodEnd() / 1000),
            currency: this.props.currency
        })
    }

    openCongratsModal = () => {
        this.props.modifyModalInfo({refillCredits:this.props.credits, pro: this.props.isPro})
        this.props.toggleModal("congratsPaymentModal")
    }

    render() {
        let cardInfo = null;
        if (this.props.cardIdx !== -1){
            cardInfo = getCardInfoFromCardIdx(this.props.paymentInfo, this.props.cardIdx)
        }

        return (
            <div>
                {/*<StripeProvider apiKey="pk_live_nmeaYWsNdAbSbOTRd8MgnGLL">*/}
                <StripeProvider apiKey={API_Root.includes("127.0.0.1:8000") ? "pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0" : "pk_live_nmeaYWsNdAbSbOTRd8MgnGLL"}>
                    <div className="example">
                        <Elements>
                            <CreditCardDetails currency={this.props.currency}
                                               credits={this.props.credits}
                                               updateUserCredits={this.updateUserCredits}
                                               otherCardsExist={this.props.paymentInfo !== null}
                                               cardInfo={cardInfo}
                                               planIdx={'planIdx' in this.props ? this.props.planIdx : -1}
                                               updateUserSubscription={this.updateUserSubscription}
                                               signUp={this.props.signUp}
                                               isPro={this.props.isPro}
                                               openCongratsModal={this.openCongratsModal}
                            />
                        </Elements>
                    </div>
                </StripeProvider>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    userCredits: state.mainState.userCredits,
    paymentInfo: state.mainState.paymentInfo
});

const mapActionsToProps = {
    modifyUserCredits: modifyUserCredits,
    toggleModal: toggleModal,
    modifySubscriptionInfo: modifySubscriptionInfo,
    modifyModalInfo: modifyModalInfo

};

export default connect(mapStateToProps, mapActionsToProps)(PaymentForm)
