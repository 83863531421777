import React, {Component} from 'react'
import FAQBlock from "../FAQBlock";

class General extends Component {
    render() {
        const uploadFAQs = [
            {question: "What kind of spreadsheets can I clean?", answer: "You can clean spreadsheets with any of the following extensions: .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .csv, .txt"},
            {question: "Can I upload a file of any size?", answer: "You can upload up to a 1 GB file. If your files are bigger, please contact us"},
            {question: "How do I upload the files?", answer: "You simply click on the BIG Upload File box and get started :)"},
            {question: "Can you clean files with non-latin based scripts, like arabic?", answer: "Not at the time unfortunately :( We can only clean files with latin based scripts"}
        ];

        const cleanFAQs = [
            {question: "How do I get started cleaning the file?", answer: "You upload your file, select which column(s) you want to clean and how, and then click clean!"},
            {question: "What is the result of the cleaning?", answer: "Each column you clean will be available to download in a separate Excel file. You can see the exact outputs on the respective data type page."},
            {question: "How long does it take to clean the files?", answer: "It depends on the amount of values and the data types. On average it can take anywhere from 5 seconds to 5 minutes."},
            {question: "Do I have to sit around and wait?", answer: "Nope, you get an email alert when the cleaning is complete."},
            {question: "How can I download the file?", answer: "It is available in your History tab for Download"},
            {question: "How do you clean the different data types?", answer: "You can read about each individual data type in its respective FAQ section"}
        ];

        const securityFAQs = [
            {question: 'How is my data secured?', answer: 'We use encryption at every step of the process. HTTPS/SSL is used when transmitting data and S3 Encryption is used on AWS when the data is at rest.'},
            {question: 'Do you guys store my data?', answer: "We do. We experimented with not storing the data but it's a very poor user experience. However, you can always delete your data after you're done cleaning and it's forever gone."},
            {question: 'I really want to use this but my data is super confidential', answer: "Our Terms and Privacy Policy cover that you totally own your data." +
                "We can also sign a Data Confidentiality agreement on an ad-hoc basis that keeps us liable. Finally, we can install the app on your servers!"},
        ];

        const aboutFAQs = [
            {question: "I have some ideas for improvement", answer: "Great! Please let us know via the Feedback button."},
            {question: "I want to reach out to you guys", answer: "Great! You can use the Support button in your dashboard. Or you can email us at info@cleanspreadsheets.com"},
        ];

        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-5">
                    <br/>
                    <FAQBlock title="Clean" icon="cogs" FAQs={cleanFAQs}/>
                    <br/>
                    <FAQBlock title="Security" icon="lock" FAQs={securityFAQs}/>
                    <br/>
                </div>
                <div className="col-sm-5">
                    <br/>
                    <FAQBlock title="Upload" icon="upload" FAQs={uploadFAQs} />
                    <br/>
                    <FAQBlock title="About" icon="user" FAQs={aboutFAQs}/>
                    <br/>
                </div>
                <div className="col-sm-1" />
                <br/>
            </div>
        )
    }
}

export default General