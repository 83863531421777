import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";

class UpdatedCTA extends Component {
    constructor(props) {
        super(props);

        this.openSignUp = this.openSignUp.bind(this);
    }

    openSignUp(e){
        this.props.toggleModal("signupModal")
    }

    render() {
        return (
            <button onClick={this.openSignUp} className="updatedCTA" style={'style' in this.props ? this.props.style : null}>
                {"Sign Up Free".toUpperCase()}
            </button>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(UpdatedCTA)