import { modifyTutorialStep } from "./ModifyTutorialStep";

export const MODIFY_SELECTED_COLUMNS = 'MODIFY_SELECTED_COLUMNS';

export function modifySelectedColumns(currentSelected, modifiedColIdx){
    const copiedCurrentSelected = JSON.parse(JSON.stringify(currentSelected));

    if (copiedCurrentSelected.includes(modifiedColIdx)){
        const modifiedColIdxIndex = copiedCurrentSelected.indexOf(modifiedColIdx);
        copiedCurrentSelected.splice(modifiedColIdxIndex, 1);
    }
    else{
        copiedCurrentSelected.push(modifiedColIdx);
    }

    let step = 3;
    if (copiedCurrentSelected.length > 0){
        step = 4;
    }

    return{
        type: MODIFY_SELECTED_COLUMNS,
        mainState: {
            selectedColumns: copiedCurrentSelected,
            tutorialStep: step
        }
    }
}