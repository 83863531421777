import React, {Component} from 'react'
import DisplayBox from '../DisplayBox'
import ReactJson from 'react-json-view'

class RateLimiting extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <h5>Per Second Rate Limit</h5>
                        <p>
                            We currently do not have a per second rate limit on our web app as we have currently not experienced any issues. If we ever start to experience regular problems, we will impose a per second rate limit depending on your plan.
                        </p>
                        <p>
                            For an enterprise edition, we can impose a limit on your copy of the software.
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    {/*<DisplayBox header="What is the response when per day limit is exceeded?"*/}
                                {/*desc={<div>*/}
                                    {/*<p>The following response body is returned with a <code>402</code> response code.</p>*/}
                                    {/*<ReactJson src={{error: "Your daily limit has been reached. Please go to your dashboard to upgrade your plan."}} name={null} theme="ocean" displayObjectSize={false}/>*/}
                                {/*</div>}*/}
                                {/*color="red"*/}
                    {/*/>*/}
                </div>
            </div>
        )
    }
}

export default RateLimiting