import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon, Popup, Button } from 'semantic-ui-react';
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";

class DeleteHistoryFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteOpen: false
        };

        this.togglePopUp = this.togglePopUp.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

    togglePopUp(e, toggle){
        this.setState({
            deleteOpen: toggle
        })
    }

    removeFile(e){
        this.props.deleteFile()
        const removePopup = () => {
            this.togglePopUp(e, false);
            this.props.toggleToast({show: true, message: "File Deleted!", type: "success"})
        }


        fetch(API_Root + "api/delete-clean-file/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                pk: this.props.pk,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            })
        }).then(res => { removePopup() }).catch(err => removePopup());
    }

    render() {
        return (
            <Popup trigger={
                        <div className="deleteHistoryFileText">
                            <span><Icon name="trash alternate outline" /> Delete File</span>
                        </div>}
                   content={
                       <span>
                           <h3 className="thinHeading">Are you sure?</h3>
                           <Button color="red" content="Yes, delete file" onClick={this.removeFile} />
                           <br/><br/>
                           <Button color="green" content="No, keep file" onClick={(e) => this.togglePopUp(e, false)} />
                       </span>}
                   on='click'
                   position='top'
                   open={this.state.deleteOpen}
                   onClose={(e) => this.togglePopUp(e, false)}
                   onOpen={(e) => this.togglePopUp(e, true)}
               />

        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteHistoryFile)