import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {resetDataCleaning} from "../Actions/ResetDataCleaning";

class ResetSteps extends Component {
    constructor(props) {
        super(props);

        this.resetDataCleaning = this.resetDataCleaning.bind(this);
    }

    resetDataCleaning(e){
        this.props.resetDataCleaning()
    }

    render() {
        if (this.props.filePK !== -1){
            return (
                <div className="resetStepsButton">
                    <Button content="Reset Data Cleaning" color="blue" basic icon="undo" onClick={this.resetDataCleaning}/>
                </div>
            )
        }
        else{
            return (
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,

})

const mapActionsToProps = {
    resetDataCleaning: resetDataCleaning
}

export default connect(mapStateToProps, mapActionsToProps)(ResetSteps)