import React, {Component} from 'react'
import ModalApp from "../../SharedComponents/Modal"

class MonthlyCreditsExplanationModal extends Component {
    render() {
        const body = <div>
            <br/>
            <h4>OUR MONTHLY CREDITS ARE SOFT LIMITS</h4>
            <br/>
            <h4><b><i>What does that mean?</i></b></h4>
            <h5>It means you can comfortably use the tool without fear of running out of credits.</h5>
            <h5>Once you exceed your monthly credits, nothing happens.</h5>
            <h5>We want to be your long term partner in data cleaning. And not running the meter is part of that.</h5>
            <br/>
            <h5 style={{color: "red"}}>However, if you consistently exceed your monthly limits, we will ask you to upgrade. Or put a hard limit on your account.</h5>
            <br/>
        </div>

        return (
            <ModalApp
                name="monthlyCreditsExplanationModal"
                header="How do monthly credits work?"
                body={body}
                size="medium"
            />
        )
    }
}

export default MonthlyCreditsExplanationModal