export const createAddressSteps = (fileColNames, colInfo, selectedColumns) => {
    let colIdx = [];

    for (let i=0; i<selectedColumns.length; i++){
        let selectedColIdx = selectedColumns[i];
        if (colInfo[selectedColIdx] !== null){
            colIdx.push(selectedColIdx)
        }
    }

    let stepID = [];

    for (let i=0; i<colIdx.length; i++){
        let idx = colIdx[i];
        let info = colInfo[idx];

        if (info !== null){
            for (let j=0; j<info.length; j++){
                if (!(stepID.includes(info[j].id))){
                    stepID.push(info[j].id)
                }
            }
        }
    }

    let messages = {};

    for (let i=0; i<stepID.length; i++) {
        let id = stepID[i];
        messages[id] = {colNames: {}, geocode: "", colInfoIdx: [], infoIdx: []}
    }

    for (let i=0; i<stepID.length; i++){
        let id = stepID[i];

        for (let j=0; j<colInfo.length; j++){
            let info = colInfo[j];

            if (info !== null){
                for (let k=0; k<info.length; k++){
                    if (info[k].id === id){
                        if (info[k].geocode === "reverse"){
                            let messageInfo = messages[id];
                            messageInfo['geocode'] = "reverse"
                            let colNames = messageInfo.colNames;

                            if (info[k].position === "lat"){
                                colNames['lat'] = fileColNames[j]
                            } else {
                                colNames['lng'] = fileColNames[j]
                            }

                            messageInfo['colNames'] = colNames

                            let colInfoIdx = messageInfo['colInfoIdx'];
                            let infoIdx = messageInfo['infoIdx'];

                            colInfoIdx.push(j)
                            infoIdx.push(k)

                            messageInfo['colInfoIdx'] = colInfoIdx
                            messageInfo['infoIdx'] = infoIdx

                            messages[id] = messageInfo

                        } else {
                            let messageInfo = messages[id];
                            messageInfo['geocode'] = "forward"

                            let colNames = messageInfo.colNames;
                            colNames[info[k].position] = fileColNames[j]

                            let colInfoIdx = messageInfo['colInfoIdx'];
                            let infoIdx = messageInfo['infoIdx'];

                            colInfoIdx.push(j)
                            infoIdx.push(k)

                            messageInfo['colInfoIdx'] = colInfoIdx
                            messageInfo['infoIdx'] = infoIdx

                            messageInfo['colNames'] = colNames
                            messages[id] = messageInfo
                        }
                    }
                }
            }
        }
    }

    return messages
}