import React, {Component} from 'react'
import images from "../../../Constants/Images";
import FAQBlock from "../FAQBlock";
import KeywordExample from "../../../LandingPage/DetailExamples/KeywordExample";

const keywordFAQ = [
    {question: 'What does Keyword cleaning mean?', answer: 'Keyword cleaning take open ended text and extracts relevant keywords.'},
    {question: 'How does the tool find those keywords?', answer: <span>We use a combination of Machine Learning and statistics. The tool returns keywords found through both approaches like the following <KeywordExample faq/></span>},
    {question: "How many keywords are found?", answer: "We find up to 30 keywords for your note value. If you need more keywords returned, contact us and we can enable that for your account."},
    {question: 'What does the secondaryKeywords and extraKeywords columns represent?', answer: "secondaryKeywords and extraKeywords are determined by importance of the keyword and cutoff of 10. Say we found 23 keywords, 10 of the most important and relevant keywords would be in the Cleaned Value column. The next 10 most important will be under the secondaryKeywords column and the final 3 will be in the extraKeywords column"},
]

class Keyword extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <FAQBlock
                        title="Notes" icon={images.keywordIcon}
                        FAQs={keywordFAQ}
                        dataType
                    />
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default Keyword