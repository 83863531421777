import React, {Component} from 'react'
import DisplayBox from "../DisplayBox";

class ResponseCodes extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <div className="apiDocSection">
                            <h5 style={{color: 'red'}}>Error Response Codes</h5>
                            <div>
                                <p>For different errors encountered, these will be the response codes returned. We
                                do not raise errors when an error is encountered. It is up to you to deal with
                                an error if one of the following codes is returned.</p>
                                <table className="table table-sm">
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>What it means?</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/*400, 402, 403, 404, 405 and 500.*/}
                                    <tr>
                                        <td><code>400</code></td>
                                        <td><b>BAD REQUEST</b> The request body is not formatted properly such as whether
                                            there is no data present or it is in the wrong format.</td>
                                    </tr>
                                    <tr>
                                        <td><code>402</code></td>
                                        <td><b>PAYMENT REQUIRED</b> The daily limit for your plan has been reached.</td>
                                    </tr>
                                    <tr>
                                        <td><code>403</code></td>
                                        <td><b>FORBIDDEN</b> The apiKey is not valid or not present.</td>
                                    </tr>
                                    <tr>
                                        <td><code>404</code></td>
                                        <td><b>NOT FOUND</b> The clean type parameter in the URL was not valid such as /clean/booyeah.</td>
                                    </tr>
                                    <tr>
                                        <td><code>405</code></td>
                                        <td><b>METHOD NOT ALLOWED</b> Any thing other than a POST request was made.</td>
                                    </tr>
                                    <tr>
                                        <td><code>500</code></td>
                                        <td><b>INTERNAL SERVER ERROR</b> There was an error somewhere on our server.</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <div className="apiDocSection">
                            <h5 style={{color: 'green'}}>Success Response Code</h5>
                                <div>
                                    <p>There is only one response code that defines success</p>
                                    <table className="table table-sm">
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>What it means?</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td><code>200</code></td>
                                            <td><b>OK</b> Request was successful and values were able to be parsed.</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResponseCodes