import React, {Component} from 'react'

class MatchesExampleTable extends Component {
    getMatchValue = (idx, matchKey) => {
        if (idx < this.props.output.length){
            return this.props.output[idx][matchKey]
        } else {
            return ""
        }
    }

    render() {
        const match2Style = {backgroundColor: "#c3f6c3"}
        const match3Style = {backgroundColor: "#e5fbe5"}
        return (
            <div>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th className="formattedResultsHeader leftHeaderCell" key="0">Your Value</th>
                            <th className="formattedResultsHeader">Cleaned Value_1</th>
                            <th className="formattedResultsHeader">Score_1</th>
                            <th className="formattedResultsHeader">Cleaned Value_2</th>
                            <th className="formattedResultsHeader">Score_2</th>
                            <th className="formattedResultsHeader">Cleaned Value_3</th>
                            <th className="formattedResultsHeader">Score_3</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="formattedResultsCell errorCell">{this.props.input}</td>
                            <td className="formattedResultsCell successCell">{this.getMatchValue(0, "formatted")}</td>
                            <td className="formattedResultsCell successCell">{this.getMatchValue(0, "score")}</td>
                            <td className="formattedResultsCell" style={match2Style}>{this.getMatchValue(1, "formatted")}</td>
                            <td className="formattedResultsCell" style={match2Style}>{this.getMatchValue(1, "score")}</td>
                            <td className="formattedResultsCell" style={match3Style}>{this.getMatchValue(2, "formatted")}</td>
                            <td className="formattedResultsCell" style={match3Style}>{this.getMatchValue(2, "score")}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default MatchesExampleTable