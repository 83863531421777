import React, {Component} from 'react'
import {isBrowser} from 'react-device-detect'
import { Label } from "semantic-ui-react"

class ExampleTable extends Component {
    render() {
        const defectInfo = this.props.defectInfo;

        let table = null;

        if ('rows' in defectInfo){
            const values = defectInfo['rows']['values'];
            const suggestions = defectInfo['rows']['suggestions'];
            // const splitComponents = defectInfo['rows']['splitComponents'];

            let tableRows = [];
            let headerRow;

            if (isBrowser){
                for (let i=0; i<values.length; i++){
                    if ('geocode' in defectInfo['rows']){
                        tableRows.push(
                            <tr key={i}>
                                <td style={{backgroundColor: '#556B2F'}}>{values[i].toString()}</td>
                                <td style={{backgroundColor: '#6B8E23'}}>{suggestions[i]}</td>
                                <td style={{backgroundColor: '#6B8E23'}}>{defectInfo['rows']['geocode'][i]}</td>
                            </tr>
                        )
                    }
                    else{
                        let bgColor = '#556B2F';
                        let errorColor = '#FFA07A';

                        let textColor = 'white';

                        if (suggestions[i] === "INVALID"){
                            bgColor = '#ff6262'
                            textColor = 'white'
                        } else if (suggestions[i] === "VALID (ACCEPT ALL)"){
                            bgColor = '#ffcf76'
                        }

                        if (defectInfo['type'] === "keywordCleaning"){
                            tableRows.push(
                                <tr key={i}>
                                    <td style={{backgroundColor: errorColor}}>{values[i].toString()}</td>
                                    <td style={{backgroundColor: bgColor, color: textColor}}>{suggestions[i].split(",").map((x, idx) => <Label key={idx} style={{margin: "5px"}}>{x.trim()}</Label>)}</td>
                                    {/*<td><SplitComponents splitComponents={splitComponents[i]}/></td>*/}
                                </tr>
                            )
                        } else {
                            tableRows.push(
                                <tr key={i}>
                                    <td style={{backgroundColor: errorColor}}>{values[i].toString()}</td>
                                    <td style={{backgroundColor: bgColor, color: textColor}}>{suggestions[i]}</td>
                                    {/*<td><SplitComponents splitComponents={splitComponents[i]}/></td>*/}
                                </tr>
                            )
                        }
                    }
                }

                headerRow =
                    <tr>
                        <th>Input</th>
                        <th>Formatted</th>
                        {/*<th>Components</th>*/}
                    </tr>

                if ('geocode' in defectInfo['rows']){
                    headerRow =
                        <tr>
                            <th>Input</th>
                            <th>Formatted</th>
                            <th>Geocode</th>
                        </tr>
                }

                table =
                    <table className="table table-bordered table-sm table-hover">
                        <thead className="thead-dark">
                        {headerRow}
                        </thead>
                        <tbody>
                        {tableRows}
                        </tbody>
                    </table>
            }
            else{

                let tableRows1 = [];
                let tableRows2 = [];

                let table3 = null;

                for (let i=0; i<values.length; i++){
                    tableRows1.push(
                        <tr key={i}>
                            <td style={{backgroundColor: 'mistyrose'}}>{values[i].toString()}</td>
                        </tr>
                    )

                    tableRows2.push(
                        <tr key={i}>
                            <td style={{backgroundColor: 'lightgreen'}}>{suggestions[i]}</td>
                        </tr>
                    )


                }

                let headerRow1 =
                    <tr>
                        <th>Input</th>
                    </tr>

                let headerRow2 =
                    <tr>
                        <th>Formatted</th>
                    </tr>



                let table1 = <table className="table table-bordered table-sm table-hover">
                    <thead className="thead-dark">
                    {headerRow1}
                    </thead>
                    <tbody>
                    {tableRows1}
                    </tbody>
                </table>

                let table2 = <table className="table table-bordered table-sm table-hover">
                    <thead className="thead-dark">
                    {headerRow2}
                    </thead>
                    <tbody>
                    {tableRows2}
                    </tbody>
                </table>

                if ('geocode' in defectInfo['rows']){
                    let tableRows3 = [];

                    for (let i=0; i<values.length; i++){
                        tableRows3.push(
                            <tr key={i}>
                                <td>
                                    <td style={{backgroundColor: 'lightgreen'}}>{defectInfo['rows']['geocode'][i]}</td>
                                </td>
                            </tr>
                        )
                    }

                    let headerRow3 =
                        <tr>
                            <th>Geocode</th>
                        </tr>

                    table3 = <table className="table table-bordered table-sm table-hover">
                        <thead className="thead-dark">
                        {headerRow3}
                        </thead>
                        <tbody>
                        {tableRows3}
                        </tbody>
                    </table>


                }



                table =
                    <div>
                        {table1}
                        <br/>
                        {table2}
                        <br/>
                        {table3}
                        {/*<br/>*/}
                    </div>
            }



        }

        return (
            <div>
                {table}
            </div>
        )
    }
}

export default ExampleTable