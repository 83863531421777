import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table } from 'semantic-ui-react'
import ColumnInfo from './ColumnInfo'
import SelectColumn from './SelectColumn'

class FileSampleHeaderRow extends Component {
    render() {
        const columnNames = this.props.fileInformation.columnNames;

        const info = this.props.info;

        let headerCells = [];

        for (let i=0; i<columnNames.length; i++){
            let cellInfo = null;

            if (info === "name"){
                cellInfo = <h2 className="thinHeading" style={{textAlign: 'center'}}>{columnNames[i]}</h2>;
            }
            else if (info === "viz"){
                cellInfo = <ColumnInfo colIdx={i} />
            }
            else if (info === "selectColumn"){
                cellInfo = <SelectColumn colIdx={i} />
            }

            headerCells.push(
                <Table.HeaderCell key={i.toString()}>
                    {cellInfo}
                </Table.HeaderCell>
            )
        }

        return (
            <Table.Row>
                {headerCells}
            </Table.Row>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileSampleHeaderRow)