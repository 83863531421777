import React, {Component} from 'react'
import {CreditPricing} from "../../../Constants/CreditPricing";
import addCommaToNumbers from "../../../Constants/addCommaToNumbers";
import currencyInfo from "../../../Constants/currencyInfo";
import CurrencyChangeDropdown from "../../../SharedComponents/CurrencyChangeDropdown"
import {connect} from "react-redux";

class PaymentInfo extends Component {
    render() {
        let isPro = false;

        if (this.props.modalInfo !== null){
            if ('isPro' in this.props.modalInfo){
                isPro = this.props.modalInfo.isPro
            }
        }

        if ('isPro' in this.props){
            isPro = this.props.isPro
        }

        const proMultiplier = isPro ? 5 : 1;
        const pricingRows = CreditPricing.map((x, idx) => <tr key={idx.toString()} className="creditPricingTableRow"><td className="creditPricingTableRowCreditValue">Up to <b>{addCommaToNumbers(x.values)}</b> credits</td> <td className="creditPricingTableRowPrice">{currencyInfo[this.props.currency].symbol} {(currencyInfo[this.props.currency].multiplier * x.price * proMultiplier).toFixed(4)} per credit</td></tr>)

        return (
            <div>
                <span style={{display: 'block', fontSize: '24px'}} className="thinHeading">Simple Pay As You Go Pricing</span>
                {"landing" in this.props ? null : <span style={{display: 'block', fontSize: '14px', color: 'grey', fontWeight: 'bold'}}>1 credit = 1 cleaned value</span>}
                <br/>
                <table id="creditPricingTable">
                    <tbody>
                    {pricingRows}
                    </tbody>
                </table>
                {'landing' in this.props ? null :
                <div>
                    <br/>
                    <hr />
                    <b>Pay in your currency</b>
                    <br/>
                    <CurrencyChangeDropdown />
                </div>}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    currency: state.mainState.currency
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PaymentInfo)