import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyStatus} from "../../../Actions/ModifyStatus";
import {canColumnsBeCleaned} from "../../../Constants/canColumnsBeCleaned";
import {modifyStepsCompleted} from "../../../Actions/ModifyStepsCompleted";

class CleanActionNotTaken extends Component {
    constructor(props){
        super(props);

        this.goToUpload = this.goToUpload.bind(this);
    }

    goToUpload(){
        this.props.modifyStatus("upload")
    }

    render() {
        let body = null;

        if (this.props.filePK === -1){
            body =
                <div>
                    <h4>You need to Upload a File before you can Clean</h4>
                    <Button content="Upload a File" icon="file alternate outline" size="massive" onClick={this.goToUpload}/>
                </div>
        } else {
            if (this.props.selectedColumns.length === 0){
                body =
                    <div>
                        <h4>You need to Select some Columns before you can Clean</h4>
                        <Button content="Select Columns" icon="columns" size="massive" onClick={this.goToUpload}/>
                    </div>
            }
            else{
                if (!canColumnsBeCleaned(this.props.selectedColumns, this.props.columnCleanTypes)) {
                    this.props.modifyStepsCompleted(0);

                    body =
                        <div>
                            <h4>You need to Select valid Clean Types</h4>
                            <Button content="Select Columns" icon="columns" size="massive" onClick={this.goToUpload}/>
                        </div>
                }
            }

        }

        return (
            <div id="fileInfoDiv" className="cleanActionNotTaken">
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes
})

const mapActionToProps = {
    modifyStatus: modifyStatus,
    modifyStepsCompleted: modifyStepsCompleted
}

export default connect(mapStateToProps, mapActionToProps)(CleanActionNotTaken)