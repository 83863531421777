import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import EmailPromoModalBody from "./EmailPromoModalBody"

class EmailPromoModal extends Component {
    render() {
        let modal = null;

        if (this.props.modalInfo !== null){
            if ('promoLink' in this.props.modalInfo){
                modal = <ModalApp name="emailPromoModal"
                                  header="Email Referral Link"
                                  body={<EmailPromoModalBody promoLink={this.props.modalInfo.promoLink}/>}
                                  size="medium"
                />
            }
        }

        return (
            <React.Fragment>
                {modal}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EmailPromoModal)