import React, {Component} from 'react'
import images from "../../../../Constants/Images";
import {startCase} from "lodash";
import { Image, Segment, Grid, Menu } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'
import NameExample from "../../../../LandingPage/DetailExamples/NameExample"
import AddressExample from "../../../../LandingPage/DetailExamples/AddressExample"
import DateExample from "../../../../LandingPage/DetailExamples/DateExample"
import CompanyExample from "../../../../LandingPage/DetailExamples/CompanyExample"
import EmailExample from "../../../../LandingPage/DetailExamples/EmailExample"

class DummyCleanData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeFunction: "address"
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, {name}){
        this.setState({activeFunction: name})
    }

    render() {
        const functionInfo = {
            "name": {icon: images.nameIcon, desc: <NameExample faq/>},
            "address": {icon: images.addressIcon, desc: <AddressExample faq/>},
            "date": {icon: images.dateIcon, desc: <DateExample faq/>},
            "company": {icon: images.companyIcon, desc: <CompanyExample faq/>},
            "email": {icon: images.emailIcon, desc: <EmailExample faq/>},
        };

        const firstRow = ["address", "date", "name", "company", "email"];

        const menuItems = firstRow.map((x, idx) =>
            <Menu.Item name={x} active={this.state.activeFunction === x} key={idx} onClick={this.handleClick}>
                <Image src={functionInfo[x].icon} avatar />
                <span>{startCase(x)}</span>
            </Menu.Item>);

        const cleanWidth = isMobile ? 5 : 4;
        const exampleWidth = isMobile ? 11 : 12;

        return (
            <div>
                <Segment raised>
                    <Grid>
                        <Grid.Column width={cleanWidth}>
                            <Menu fluid vertical tabular>
                                {menuItems}
                            </Menu>
                        </Grid.Column>
                        <Grid.Column stretched width={exampleWidth}>
                            <Segment>
                                {functionInfo[this.state.activeFunction].desc}
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </div>
        )
    }
}

export default DummyCleanData