export const getCardInfoFromCardIdx = (paymentInfo, cardIdx) => {
    let cardInfo = null;
    if ("creditCards" in paymentInfo){
        if (paymentInfo['creditCards'].length > cardIdx){
            if ("id" in paymentInfo['creditCards'][cardIdx]){
                cardInfo = {"cardID": paymentInfo["creditCards"][cardIdx]["id"]}
            }
        }
    }

    return cardInfo
}