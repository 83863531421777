import React, {Component} from 'react'
import {connect} from 'react-redux'
import capitalizeHyphenated from "../../../../Constants/capitalizeHyphenated";
import images from "../../../../Constants/Images";
import { Image, Icon } from 'semantic-ui-react';
import CleanDisplayMasterMenuUpdated from "./CleanDisplayMasterMenuUpdated"
import FormattedResults from "../../Download/DisplayResults/FormattedResults";
import DownloadResultsButton from "../../Download/DownloadResultsButton"
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import {toggleModal} from "../../../../Actions/ToggleModal";
import ColumnResultsInformation from "./ColumnResultsInformation";

class CleanDisplayMasterTableUpdated extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cleanedSourceIdx: this.props.cleanedSource.length > 0 ? 0 : -1
        }

        this.changeSelectedCol = this.changeSelectedCol.bind(this);
        this.openFAQModal = this.openFAQModal.bind(this);
    }

    changeSelectedCol(idx){
        this.setState({ cleanedSourceIdx: idx })
    }

    openFAQModal(e, cleanType){
        this.props.modifyModalInfo({"cleanTypeFAQ": cleanType});
        this.props.toggleModal("cleanTypeFAQ");
    }

    render() {
        let columnIdx = -1;
        let results = null;
        let cleanType = null;
        let isPro = false;
        let showNum;
        let addlMessage = null;
        let downloadResults = null;
        let columnResultsInformation = null;

        if (this.state.cleanedSourceIdx !== -1){
            let cleanedSource = this.props.cleanedSource[this.state.cleanedSourceIdx];

            if (cleanedSource.type === "selectedColumn"){
                cleanType = this.props.columnCleanTypes[cleanedSource.id]
            } else {
                cleanType = "address"
            }

            // columnIdx = this.props.selectedColumns[this.state.selectedColIdx];
            // if ('pro' in this.props){
            if (this.props.pro){
                results = this.props.proSampleResults[this.state.cleanedSourceIdx];
                isPro = true;
            } else {
                results = this.props.sampleResults[this.state.cleanedSourceIdx];
                isPro = false;
            }
            // } else {
            //     results = this.props.sampleResults[this.state.cleanedSourceIdx];
            //     isPro = false;
            // }

            if (this.props.isSample){
                if ('doesProExist' in this.props){
                    if (this.props.doesProExist){
                        if (this.props.pro){
                            showNum = this.props.sampleResults[this.state.cleanedSourceIdx].length > 5 ? 5 : this.props.sampleResults[this.state.cleanedSourceIdx].length
                            addlMessage = <span><span className="sampleDescText">When you <Icon name="cogs" /> <b>Clean Your Data</b> all rows will be cleaned</span></span>
                            columnResultsInformation = <ColumnResultsInformation changeSelectedCol={this.changeSelectedCol} isSample={true} pro={isPro} cleanedSourceIdx={this.state.cleanedSourceIdx} showNum={showNum} addlMessage={addlMessage}/>
                        }
                    } else {
                        showNum = this.props.sampleResults[this.state.cleanedSourceIdx].length > 5 ? 5 : this.props.sampleResults[this.state.cleanedSourceIdx].length
                        addlMessage = <span><span className="sampleDescText">When you <Icon name="cogs" /> <b>Clean Your Data</b> all rows will be cleaned</span></span>
                        columnResultsInformation = <ColumnResultsInformation changeSelectedCol={this.changeSelectedCol} isSample={true} pro={isPro} cleanedSourceIdx={this.state.cleanedSourceIdx} showNum={showNum} addlMessage={addlMessage}/>
                    }
                }
            } else {
                showNum = this.props.sampleResults[this.state.cleanedSourceIdx].length
                addlMessage = <span>Don't worry, the file you download will have all values <span role="img" aria-label="smile">😀</span></span>
                downloadResults = <DownloadResultsButton colIdx={this.state.cleanedSourceIdx} />
                columnResultsInformation = <ColumnResultsInformation changeSelectedCol={this.changeSelectedCol} isSample={false} pro={isPro} cleanedSourceIdx={this.state.cleanedSourceIdx} showNum={showNum} addlMessage={addlMessage}/>
            }


        }

        let tableClassName = null;
        let cleanMenu = null;

        if (this.props.cleanedSource.length > 1){
            // if (this.props.isSample){
            //     if ((this.))
            // }
            // tableClassName = "cleanDisplayMasterTable"
            // cleanMenu = <CleanDisplayMasterMenuUpdated
            //     changeSelectedCol={this.changeSelectedCol}
            //     cleanedSourceIdx={this.state.cleanedSourceIdx}/>
        }

        return (
            <div className={tableClassName}>
                {cleanMenu}
                <div className="cleanDisplayMasterTableResults">
                    {columnResultsInformation}
                    {downloadResults}
                    <FormattedResults
                        cleanType={cleanType}
                        cleanedSource={this.props.cleanedSource[this.state.cleanedSourceIdx]}
                        results={results}
                        isSample={this.props.isSample}
                        isPro={isPro}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sampleResults: state.mainState.sampleResults,
    selectedColumns: state.mainState.selectedColumns,
    fileInformation: state.mainState.fileInformation,
    columnCleanTypes: state.mainState.columnCleanTypes,
    cleanedSource: state.mainState.cleanedSource,
    proSampleResults: state.mainState.proSampleResults
})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(CleanDisplayMasterTableUpdated)