import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import RemoveFileButton from './EditFileToolbar/RemoveFileButton';
import { Button, Popup } from 'semantic-ui-react';
import FileSampleTable from "../FileSamples/FileSampleTable"
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";
import {modifyTutorialInfo} from "../../../Actions/ModifyTutorialInfo";

class FileInfoBox extends Component {
    constructor(props) {
        super(props);

        this.openEditModal = this.openEditModal.bind(this);
    }

    openEditModal(e){
        this.props.toggleModal("editModal");
    }

    fileReadCorrectly = (e) => {
        this.props.modifyTutorialStep(2)
        this.props.scrollToDataType()
    }

    fileNotReadCorrectly = (e) => {
        this.props.modifyTutorialInfo({"fileReadCorrectly": false})
    }

    render() {
        if ('samples' in this.props.fileInformation) {
            const fileName = this.props.fileInformation.name;
            const sheetRead = this.props.sheetRead !== null ? <span><i>{this.props.sheetRead}</i><br/></span> : null;

            const fileSampleLength = this.props.fileInformation.samples.length;
            const rowCount =  this.props.rowCount <= fileSampleLength ? this.props.rowCount : fileSampleLength;

            let totalLengthMessage = null;

            if ('columnInfo' in this.props.fileInformation){
                if (this.props.fileInformation.columnInfo.length > 0){
                    totalLengthMessage = <span> out of <b>{this.props.fileInformation.columnInfo[0]['total']}</b> total.</span>
                }
            }

            let isUserNewAndOnStep = false;

            if (this.props.isUserNew && this.props.tutorialStep === 1){
                isUserNewAndOnStep = true;
            }

            let confirmFileRead = null;

            if (isUserNewAndOnStep){
                const confirmDivStyle = {backgroundColor: "#E8E8E8", padding: "10px", textAlign: "center", borderRadius: "8px", boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"};

                let fileReadCorrectly = null;

                if ('fileReadCorrectly' in this.props.tutorialInfo){
                    fileReadCorrectly = this.props.tutorialInfo.fileReadCorrectly
                }

                if (fileReadCorrectly === null){
                    confirmFileRead =
                    <div style={confirmDivStyle}>
                        <h2>Were columns read correctly?</h2>
                        <Button positive onClick={this.fileReadCorrectly} id="yesColumnsReadCorrectly">Yes</Button><Button id="noColumnsReadCorrectly" negative onClick={this.fileNotReadCorrectly}>No</Button>
                    </div>
                } else if (fileReadCorrectly === false){
                    confirmFileRead =
                        <div style={confirmDivStyle}>
                            <h2>Read file properly</h2>
                            <Button basic icon="edit outline" color="purple" content="Edit Read Settings" onClick={this.openEditModal} />
                            <RemoveFileButton full/>
                        </div>
                }
            }

            return (
                <div id="fileInfoBoxDiv">
                    <div className="row" style={{marginBottom: '40px'}}>
                        <div className={isUserNewAndOnStep ? "col-sm-6" : "col-sm-8"}>
                            <div id="fileInfoDiv">
                                <h2>{fileName}
                                    {
                                        isUserNewAndOnStep ? null :
                                        <span style={{marginLeft: '15px', marginTop: '-5px', float: 'right'}}>
                                            <Popup trigger={<Button basic icon="edit outline" color="purple" circular size="mini"
                                                                    onClick={this.openEditModal}/>}
                                                   content="Edit File Read Settings" />
                                            <Popup trigger={<RemoveFileButton/>}
                                                   content="Delete File" />
                                        </span>
                                    }
                                </h2>
                                {sheetRead}
                                <small><i>{"Showing first " + rowCount + " rows"} {totalLengthMessage}</i></small>
                            </div>
                        </div>
                        <div className={isUserNewAndOnStep ? "col-sm-6" : "col-sm-4"}>
                            {
                                isUserNewAndOnStep ?
                               confirmFileRead : null
                            }
                        </div>
                    </div>
                    {/*<FileSampleTable rowCount={this.props.rowCount} viz={true} selectColumn={false} />*/}
                </div>
            )
        }
        else{
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    sheetRead: state.mainState.sheetRead,
    tutorialStep: state.mainState.tutorialStep,
    tutorialInfo: state.mainState.tutorialInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyTutorialStep: modifyTutorialStep,
    modifyTutorialInfo: modifyTutorialInfo
}

export default connect(mapStateToProps, mapActionsToProps)(FileInfoBox)