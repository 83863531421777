import React from 'react'
import { Route, Switch } from "react-router";
import LandingPageIndex from './LandingPage/LandingPageIndex'
import NotFound from './InfoPages/NotFound'
import PrivacyPolicy from './InfoPages/PrivacyPolicy'
import TermsAndConditions from './InfoPages/TermsAndConditions'
import LoginIndex from './Authentication/UserPages/LogIn/LoginIndex'
import SignUpIndex from './Authentication/UserPages/SignUp/SignUpIndex'
import ResetPasswordIndex from './Authentication/UserPages/Password/ResetPasswordIndex'
import PricingPage from './InfoPages/Pricing/PricingPage'
import HelpIndex from "./InfoPages/Help/HelpIndex";
import AuthDashboard from './Dashboard/AuthDashboard';
import AuthProfileIndex from "./InfoPages/Profile/AuthProfileIndex";
import AuthHistoryIndex from "./InfoPages/Profile/AuthHistoryIndex";
import AuthCreditsIndex from "./InfoPages/Profile/AuthCreditsIndex";
import ContactUsIndex from "./InfoPages/ContactUs/ContactUsIndex";
import SupportIndex from "./SharedComponents/Support/SupportIndex";
import EmailUnsubscribeIndex from "./SharedComponents/EmailUnsubscribeIndex";
import GuidesIndex from "./InfoPages/Guides/GuidesIndex";
import GuidesPostIndex from "./InfoPages/Guides/GuidesPostIndex";
import DemoIndex from "./InfoPages/Demo/DemoIndex"
import AuthCleanPaymentIndex from "./InfoPages/Pricing/AuthCleanPaymentIndex"
import AuthAPIIndex from "./API/AuthAPIIndex";
import APIDocsIndex from "./API/Docs/APIDocsIndex"

export default ({ childProps }) =>
    <Switch>
        <Route exact path='/' component={LandingPageIndex} />
        <Route exact path="/clean-payment/:id" component={AuthCleanPaymentIndex} />
        <Route exact path='/contact' component={ContactUsIndex} />
        <Route exact path='/demo' component={DemoIndex} />
        <Route exact path='/support' component={SupportIndex} />
        <Route exact path='/FAQs' component={HelpIndex} />
        <Route exact path='/terms' component={TermsAndConditions} />
        <Route exact path='/guides' component={GuidesIndex}/>
        {/*<Route exact path='/guides/:id' component={GuidesPostIndex}/>*/}
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route exact path="/login" component={LoginIndex} />
        <Route exact path="/signup" component={SignUpIndex} />
        <Route exact path='/reset-password/:id' component={ResetPasswordIndex} />
        <Route exact path='/pricing' component={PricingPage} />
        <Route exact path='/clean' component={AuthDashboard} />
        <Route exact path="/profile" component={AuthProfileIndex} />
        <Route exact path="/history" component={AuthHistoryIndex} />
        <Route exact path="/credits" component={AuthCreditsIndex} />
        <Route exact path="/email/unsubscribe/:id" component={EmailUnsubscribeIndex} />
        <Route exact path="/api" component={AuthAPIIndex} />
        <Route exact path="/api-docs" component={APIDocsIndex} />
        <Route component={NotFound}/>
    </Switch>;