import React, {Component} from 'react'
import { Label, Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {modifyColumnCleanTypes} from "../../Actions/ModifyColumnCleanTypes";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {modifySheetRead} from "../../Actions/ModifySheetRead";
import {resetStateAfterUpload} from "../../Actions/ResetStateAfterUpload";
import {modifyStatus} from "../../Actions/ModifyStatus";
import {modifyStepsCompleted} from "../../Actions/ModifyStepsCompleted";
import { push } from 'react-router-redux'
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import {initSampleResults} from "../../Actions/InitSampleResults";
import {modifySelectedColumns} from "../../Actions/ModifySelectedColumns";
import {initSelectedColumns} from "../../Actions/InitSelectedColumns";
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";
import { wait } from '../../Constants/wait'
import DeleteHistoryFile from "./DeleteHistoryFile";
import { isMobile } from 'react-device-detect';
import {modifyColumnCleanInfo} from "../../Actions/ModifyColumnCleanInfo";
import {modifyCleanedSource} from "../../Actions/ModifyCleanedSource";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyTutorialStep} from "../../Actions/ModifyTutorialStep";
import {toggleShowTutorial} from "../../Actions/ToggleShowTutorial";

class HistoryFileSegment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadURL: '',
            fetching: false
        }

        this.getFileStage = this.getFileStage.bind(this);
        this.getButtonMessage = this.getButtonMessage.bind(this);
        this.continueOperation = this.continueOperation.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.openDownloadEachFileModal = this.openDownloadEachFileModal.bind(this);
    }

    getFileStage(e){
        let fileStage = "upload";

        // "cleanStatus": "currentlyCleaning"
        if ("cleanStatus" in this.props.cleanFile.cleanInformation){
            if (this.props.cleanFile.cleanInformation["cleanStatus"] === "uploaded"){
                return "clean"
            }
            else if (this.props.cleanFile.cleanInformation["cleanStatus"] === "currentlyCleaning"){
                return "currentlyCleaning"
            }
            else {
                return "download"
            }
        }

        return fileStage
    }

    getButtonMessage(e){
        const fileStage = this.getFileStage();
        let buttonContent;
        let buttonDisplayText;
        let buttonIcon;

        if (fileStage === "upload"){
            buttonDisplayText = "You uploaded a file but didn't select any columns"
            buttonContent = "Select Columns to Clean"
            buttonIcon = "columns"
        }
        else if (fileStage === "clean"){
            buttonDisplayText = "You selected columns to clean but didn't finish"
            buttonContent = "Clean Your Data"
            buttonIcon = "cogs"
        }
        else if (fileStage === "currentlyCleaning"){
            buttonDisplayText = "Your file is currently cleaning. You will get an email when it is finished. You will also be able to download it here."
            buttonContent = null
            buttonIcon = null
        }
        else{
            buttonDisplayText = <span>Congrats! You successfully cleaned <span className="userCredits bigNumber">{this.props.cleanFile.cleanInformation['cleanInfo']['cleanValues']}</span> values</span>
            buttonContent = "Download Your Cleaned File"
            buttonIcon = "download"
        }

        return {buttonContent: buttonContent, buttonDisplayText: <span style={{color: "whitesmoke"}}>{buttonDisplayText}</span>, buttonIcon: buttonIcon}
    }

    continueOperation(downloadFull=true){
        const fileStage = this.getFileStage();
        if (fileStage === "upload" || fileStage === "clean"){
            this.props.resetStateAfterUpload()
            this.props.modifyFilePK(this.props.cleanFile.pk)
            this.props.modifyColumnCleanInfo(this.props.cleanFile.fileInformation.columnNames.map((x, idx) => null))
            this.props.modifyColumnCleanTypes(this.props.cleanFile.fileInformation.columnNames.map((x, idx) => ""))
            this.props.modifyFileInformation(this.props.cleanFile.fileInformation);
            this.props.modifySheetRead('sheetRead' in this.props.cleanFile.fileInformation ? this.props.cleanFile.fileInformation['sheetRead'] : null);

            if (fileStage === "upload"){
                this.props.modifyStatus("upload")
                this.props.navigateTo("/clean")
                this.props.modifyTutorialStep(2)
                this.props.toggleShowTutorial(true)
                window.scrollTo({top: 400, behavior: 'smooth'});
            }
            else{
                this.props.modifyStepsCompleted(1);
                this.props.modifyStatus("clean")
                const cleanedSource = 'cleanedSource' in this.props.cleanFile.cleanInformation.cleanInfo ? this.props.cleanFile.cleanInformation.cleanInfo.cleanedSource : [];
                const columnCleanInfo = 'columnCleanInfo' in this.props.cleanFile.cleanInformation.cleanInfo ? this.props.cleanFile.cleanInformation.cleanInfo.columnCleanInfo : this.props.cleanFile.cleanInformation.cleanInfo.columnCleanTypes.map(x => "");
                this.props.modifyColumnCleanInfo(columnCleanInfo)
                let proCleanedValues = null;

                if ('proCleanedValues' in this.props.cleanFile.cleanInformation.cleanInfo){
                    proCleanedValues = this.props.cleanFile.cleanInformation.cleanInfo.proCleanedValues
                }

                this.props.initSampleResults(this.props.cleanFile.cleanInformation.cleanInfo.cleanedValues, proCleanedValues, cleanedSource)
                this.props.initSelectedColumns(this.props.cleanFile.cleanInformation.cleanInfo.selectedColumns);
                this.props.modifyColumnCleanTypes(this.props.cleanFile.cleanInformation.cleanInfo.columnCleanTypes)
                this.props.navigateTo("/clean")
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        }
        else if (fileStage === "download"){
            this.setState({ fetching: true })
            let fileURLs = this.props.cleanFile.cleanInformation.cleanInfo.cleanResults;

            if (downloadFull){
                if ("fullCleanFile" in this.props.cleanFile.cleanInformation.cleanInfo){
                    fileURLs = [this.props.cleanFile.cleanInformation.cleanInfo.fullCleanFile]
                }
            }

            const stopFetching = () => this.setState({ fetching: false })

            fetch(API_Root + "api/swap-expired-download-urls-with-current/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    fileURLs: fileURLs
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(
                        (data) =>
                        {
                            for (let i=0; i<data.downloadURLs.length; i++){
                                console.log(data.downloadURLs[i]);
                                this.setState({ downloadURL: data.downloadURLs[i] }, () => this.downloadResultsHistory.click())
                                wait(1500)
                            }
                            stopFetching()
                        }).catch(err =>
                            {
                                this.props.toggleToast({show: true, message: "There was an error downloading your results. Please contact us at support@cleanspreadsheets.com if the error persists", type: "error"})
                                stopFetching()
                            }
                    )
                }
                else{
                    this.props.toggleToast({show: true, message: "There was an error downloading your results. Please contact us at support@cleanspreadsheets.com if the error persists", type: "error"})
                    stopFetching()
                }
            }).catch(err =>
                {
                    this.props.toggleToast({show: true, message: "There was an error downloading your results. Please contact us at support@cleanspreadsheets.com if the error persists", type: "error"})
                    stopFetching()
                }
            )
        }
    }

    deleteFile(){
        this.props.deleteFile(this.props.idx)
    }

    openDownloadEachFileModal(){
        let downloadFileInfo = {
            cleanResults: this.props.cleanFile.cleanInformation.cleanInfo.cleanResults,
            cleanedSource: 'cleanedSource' in this.props.cleanFile.cleanInformation.cleanInfo ? this.props.cleanFile.cleanInformation.cleanInfo.cleanedSource : null,

        }

    }

    render() {
        const fileStage = this.getFileStage();

        const fileStageColor = {"upload": "orange", "clean": "green", "download": "green", "currentlyCleaning": "red"}[fileStage]
        const buttonMessage = this.getButtonMessage();

        const downloadURL = this.state.downloadURL;

        let downloadAll = null;

        if (fileStage === "download"){
            downloadAll = <span className="downloadAllColumnLinks" onClick={(e) => this.continueOperation(false)}><br/><Icon name="columns" /> Download Each Cleaned Column Separately</span>
        }

        return (
            <div className="historyFileSegment">
                <a
                    style={{display: 'none'}}
                    ref={downloadResultsHistory => this.downloadResultsHistory = downloadResultsHistory}
                    href={downloadURL}
                >Download hidden</a>
                <div className={isMobile ? "row" : "historyFileSegmentGrid"}>
                    <div className={isMobile ? "col-sm-7" : "historyFileSegmentInfo"}>
                        <Label color={fileStageColor} ribbon>
                            {fileStage}
                        </Label>
                        <h4>{this.props.cleanFile.fileInformation["name"]}</h4>
                        <span><b>Uploaded: </b><i>{this.props.cleanFile.createdDt}</i></span>
                        <p><b>Last Modified: </b><i>{this.props.cleanFile.updatedDt}</i></p>
                    </div>
                    <div className={isMobile ? "col-sm-5" : "historyFileSegmentCTA"} style={isMobile ? {marginTop: '25px'} : null}>
                        {buttonMessage.buttonDisplayText} {downloadAll}
                        <Button loading={this.state.fetching || fileStage === "currentlyCleaning"} icon={buttonMessage.buttonIcon} content={buttonMessage.buttonContent} color={fileStageColor} size="huge" fluid onClick={this.continueOperation}/>
                        {fileStage === "currentlyCleaning" ? null : <DeleteHistoryFile pk={this.props.cleanFile.pk} deleteFile={this.deleteFile}/>}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    modifyColumnCleanTypes: modifyColumnCleanTypes,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    resetStateAfterUpload: resetStateAfterUpload,
    modifyStatus: modifyStatus,
    modifyStepsCompleted: modifyStepsCompleted,
    navigateTo: push,
    initSampleResults: initSampleResults,
    modifySelectedColumns: modifySelectedColumns,
    initSelectedColumns: initSelectedColumns,
    toggleToast: toggleToast,
    modifyColumnCleanInfo: modifyColumnCleanInfo,
    modifyCleanedSource: modifyCleanedSource,
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal,
    modifyTutorialStep: modifyTutorialStep,
    toggleShowTutorial: toggleShowTutorial
}

export default connect(mapStateToProps, mapActionsToProps)(HistoryFileSegment)