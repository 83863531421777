import React, {Component} from 'react'
import {connect} from 'react-redux'

class ImportSheetsBody extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.props.updateSheetToRead(value);
    }

    render() {
        if ('sheetNames' in this.props.fileInformation){
            const sheetNames = this.props.fileInformation['sheetNames'];

            let sheetOptions = [];

            for (let i=0; i<sheetNames.length; i++){
                let sheetName = sheetNames[i];

                sheetOptions.push(
                    <div className="importSheetOptions" key={i.toString()}>
                        <input style={{fontSize: '150%'}}
                               type="radio"
                               name="sheetPicked"
                               value={sheetName}
                               id={sheetName}
                               onChange={this.handleInputChange}
                               checked={this.props.newSheet === sheetName} />
                        <label htmlFor={sheetName}>
                            <span style={{cursor: 'pointer', fontSize: '125%', paddingTop: '2px', paddingLeft: '7px'}}>{sheetName}</span>
                        </label>
                    </div>
                )
            }

            return (
                <div id="importSheetsDiv">
                    <h4 className="thinHeading">Sheet Names</h4>
                    {sheetOptions}
                </div>
            )
        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsBody)