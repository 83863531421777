import React, {Component} from 'react'
import {connect} from 'react-redux'
import ColumnNameTable from "./ColumnNameTable"

class OtherOptions extends Component {
    render() {
        return (
            <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px', marginTop: this.props.uploadDataType === "address" ? '15px' : null}}>
                <h2>Pick the columns to clean</h2>
                <h6 style={{color: '#FF4500'}}>You can select multiple columns at one time</h6>
                <ColumnNameTable />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    uploadDataType: state.mainState.uploadDataType
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(OtherOptions)