import React, {Component} from 'react'
import {connect} from 'react-redux'
import images from "../../../../Constants/Images";
import capitalizeHyphenated from "../../../../Constants/capitalizeHyphenated";
import {createAddressSteps} from "../../../../Constants/createAddressSteps";
import {getCleanValuesForStep} from "../../../../Constants/getCleanValuesForStep";
import { Image, Icon } from 'semantic-ui-react';
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import {toggleModal} from "../../../../Actions/ToggleModal";

class ColumnNameInfo extends Component {
    constructor(props) {
        super(props);
    }

    openFAQModal = (e, cleanType) => {
        this.props.modifyModalInfo({"cleanTypeFAQ": cleanType});
        this.props.toggleModal("cleanTypeFAQ");
    }

    changeSelectedCol = () => {
        this.props.changeSelectedCol(this.props.cleanedSourceIdx)
    }

    render() {
        const idx = this.props.cleanedSourceIdx;
        let cleanType;
        let cleanText;
        let uniqueValues;

        let cleanedSource = this.props.cleanedSource[idx];
        let cleanIdx = cleanedSource.id;

        const columnNames = this.props.fileInformation['columnNames']
        const columnInfo = this.props.fileInformation['columnInfo']

        if (cleanedSource.type === "selectedColumn") {
            cleanType = this.props.columnCleanTypes[cleanIdx]
            cleanText = columnNames[cleanIdx]
            uniqueValues = columnInfo[cleanIdx]['unique']
        } else {
            cleanType = "address"

            let addressSteps = createAddressSteps(columnNames, this.props.columnCleanInfo, this.props.selectedColumns);
            let colStep = addressSteps[cleanIdx];
            let colNamesStep = colStep.colNames;

            if (colStep.geocode === "forward"){
                const colIndices = Object.keys(colNamesStep)
                let colNamesString = "";

                for (let i=0; i<colIndices.length; i++){
                    colNamesString += colNamesStep[colIndices[i]] + ", "
                }

                cleanText = colNamesString.substring(0, colNamesString.length - 2) + ", forward geocoding"
            } else {
                cleanText = "latitude: " + colNamesStep.lat + ",longitude:" + colNamesStep.lng + ", reverse geocoding"
            }

            uniqueValues = getCleanValuesForStep(colStep, columnInfo)
        }

        let divClassName = "columnResultsNameInfo";
        let divOnClick = null;

        if (this.props.isDropdown){
            divOnClick = this.changeSelectedCol;
            divClassName += " columnResultsNameInfoHover"
        }

        return (
            <div className={divClassName} onClick={divOnClick}>
                <span>{capitalizeHyphenated(cleanType)} <Image src={images[cleanType + "Icon"]} avatar />
                        <span className="linkStyle" style={{fontSize: "85%"}} onClick={(e) => this.openFAQModal(e, cleanType)}> {capitalizeHyphenated(cleanType)} FAQs <Icon name="question circle outline" /></span>
                    </span>
                <br/>
                <span><span style={{fontSize: "150%", fontWeight: "bold"}}>{cleanText}</span> <span style={{float: "right"}}><span style={{color: "green", fontSize: "16px", fontWeight: "bold"}}>{uniqueValues}</span> unique values</span></span>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cleanedSource: state.mainState.cleanedSource,
    fileInformation: state.mainState.fileInformation,
    columnCleanInfo: state.mainState.columnCleanInfo,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnNameInfo)