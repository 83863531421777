import React, {Component} from 'react'
import ExampleTable from './ExampleTable'

class NameExampleDetails extends Component {
    render() {
        const defectInfo = {type: 'name', rows: {
                values: ['dr. martin luther king Jr.', 'Emilia r. Clarke', 'Roosevelt, Franklin delano', 'Tony Edward Starkz', 'A.B. Kalama', 'George Washington (General)', 'Rosa Diaz III', 'Trevor Noah', 'Mister Benjamin M. Franklin (Inventor)'],
                suggestions: ['Martin King', 'Emilia Clarke', 'Franklin Roosevelt', 'Tony Stark', 'AB Kalama', 'George Washington', 'Rosa Diaz', 'Trevor Noah', 'Benjamin Franklin'],
                // splitComponents: [
                //     [['first', 'Lionel'], ['last', 'Messi']],
                //     [['first', 'Chris'], ['middle', 'M'], ['last', 'Pratt']],
                //     [['first', 'Martin'], ['middle', 'Luther'], ['last', 'King'], ['prefix', 'Dr'], ['suffix', 'Jr']]
                // ]
            }
        }

        return (
            <div>
                <h3>Format and Split Any Latin Character Name</h3>
                <br/>
                <ExampleTable defectInfo={defectInfo}/>
                <br/>
            </div>
        )
    }
}

export default NameExampleDetails