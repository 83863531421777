import React, {Component} from 'react'
import {connect} from 'react-redux'

class NotEnoughCreditsSubscription extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <h1>Not Enough Credits Subscribe</h1>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(NotEnoughCreditsSubscription)