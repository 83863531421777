import {createAddressSteps} from "./createAddressSteps";
import isObjectEmpty from "./isObjectEmpty";
import {getCleanValuesForStep} from "./getCleanValuesForStep";

export const getTotalCleanValues = (selectedColumns, columnInfo, colNames, colTypes, colCleanInfo) => {
    const addressSteps = createAddressSteps(colNames, colCleanInfo, selectedColumns)
    let total = 0;

    let colsToRemove = [];
    if (!isObjectEmpty(addressSteps)){
        for (let stepID in addressSteps){
            colsToRemove = colsToRemove.concat(addressSteps[stepID]['colInfoIdx'])
        }
    }

    let nonStepSelectedCols = [];
    if (colsToRemove.length > 0){
        for (let i=0; i<selectedColumns.length; i++){
            if (!colsToRemove.includes(selectedColumns[i])){
                nonStepSelectedCols.push(selectedColumns[i])
            }
        }
    } else {
        nonStepSelectedCols = selectedColumns
    }


    // This is the counting for all the non step selected columns
    for (let i=0; i<nonStepSelectedCols.length; i++){
        const col = nonStepSelectedCols[i];

        const colAllInfo = columnInfo[col]

        // let missing = 0 ;
        // if (colAllInfo['missing'] > 0){
        //     missing = 1
        // }

        // total += colAllInfo['unique'] - missing
        total += colAllInfo['unique']

    }

    // Count the step column lengths
    if (!isObjectEmpty(addressSteps)){
        for (let stepID in addressSteps){
            total += getCleanValuesForStep(addressSteps[stepID], columnInfo)
        }
    }

    return total
}