import React, {Component} from 'react'
import { Dropdown } from 'semantic-ui-react'
import currencySelection from "../../../../../../Constants/currencySelection";

class CurrencyTypeDropdown extends Component {
    constructor(props) {
        super(props);

        this.updateCurrencyCode = this.updateCurrencyCode.bind(this);
    }

    updateCurrencyCode(event, {value}){
        this.props.updateCurrencyCode(value)
    }

    render() {
        const currencyOptions = currencySelection.map((x, idx) => { return { key: idx, value: x.code, text: x.name + " (" + x.symbol + ")", flag: x.flag } })

        return (
            <div>
                <Dropdown
                    placeholder="Select Currency"
                    name="CurrencyTypeDropdown"
                    value={this.props.currency}
                    options={currencyOptions}
                    onChange={this.updateCurrencyCode}
                    // fluid
                    selection
                />
            </div>
        )
    }
}

export default CurrencyTypeDropdown