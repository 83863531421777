import React, {Component} from 'react'
import AddressExample from "../../../LandingPage/DetailExamples/AddressExample"
import NameExample from "../../../LandingPage/DetailExamples/NameExample"
import CompanyExample from "../../../LandingPage/DetailExamples/CompanyExample"
import DateExample from "../../../LandingPage/DetailExamples/DateExample"
import EmailExample from "../../../LandingPage/DetailExamples/EmailExample"
import PhoneExample from "../../../LandingPage/DetailExamples/PhoneExample"
import CurrencyExample from "../../../LandingPage/DetailExamples/CurrencyExample"
import KeywordExample from "../../../LandingPage/DetailExamples/KeywordExample"
import pricingPageBlockDesc from "../../../Constants/pricingPageBlockDesc";

class PricingPageBlockFeatureModalBody extends Component {
    getDataTypeTable = (dataType, pro) => {
        return {
            address: <AddressExample pro={pro}/>,
            name: <NameExample pro={pro} />,
            company: <CompanyExample pro={pro} />,
            date: <DateExample pro={pro} />,
            email: <EmailExample pro={pro} />,
            phone: <PhoneExample pro={pro} />,
            currency: <CurrencyExample pro={pro} />,
            notes: <KeywordExample pro={pro} />}[dataType]
    }

    render() {
        const descColor = "white";

        return (
            <div>
                <div style={{marginBottom: "50px"}}>
                    <div className="cleanDisplayPreviewDisclaimer proDisclaimer">
                        <h2>Pro Clean</h2>
                        <h4 style={{color: descColor}}>{pricingPageBlockDesc["pro"][this.props.dataType]}</h4>
                    </div>
                    {/*<div id="cleanDisplay">*/}
                        <div className="cleanDisplayMasterTableResults">
                            {this.getDataTypeTable(this.props.dataType, true)}
                        </div>
                        {/*<div id="cleanDisplayPayment">*/}

                        {/*</div>*/}
                    {/*</div>*/}
                </div>
                <div className="cleanDisplayPreviewDisclaimer basicDisclaimer">
                    <h2>Basic Clean</h2>
                    <h4 style={{color: descColor}}>{pricingPageBlockDesc["basic"][this.props.dataType]}</h4>
                </div>
                {/*<div id="cleanDisplay">*/}
                    <div className="cleanDisplayMasterTableResults">
                        {this.getDataTypeTable(this.props.dataType, false)}
                    {/*</div>*/}
                    {/*<div id="cleanDisplayPayment">*/}

                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

export default PricingPageBlockFeatureModalBody