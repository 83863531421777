import React, { Component } from 'react';
import NavbarIndex from './SharedComponents/Navbar/NavbarIndex';
import Routes from './Routes';
import withAuthentication from './Authentication/withAuthentication';
import FooterIndex from './SharedComponents/Footer/FooterIndex'
import LoadingModal from './SharedComponents/LoadingModal'
import EditModal from './Dashboard/DashboardDisplay/UploadFiles/EditFileToolbar/EditFile/EditModal'
import SignUpModal from "./Authentication/UserPages/SignUp/SignUpModal";
import LoginModal from "./Authentication/UserPages/LogIn/LoginModal";
import ForgetPasswordModal from "./Authentication/UserPages/Password/ForgetPasswordModal";
import ContactUsModal from "./InfoPages/ContactUs/ContactUsModal";
import { isBrowser } from 'react-device-detect';
import PhoneModal from "./InfoPages/PhoneModal";
import CustomToastContainer from "./SharedComponents/CustomToastContainer"
import RefillCreditsModal from "./SharedComponents/Payment/RefillCreditsModal/RefillCreditsModal";
import SupportModal from "./SharedComponents/Support/SupportModal";
import CleanTypeFAQ from "./Dashboard/UpdatedDashboard/Clean/CleanTypeFAQ/CleanTypeFAQ";
import HelpModal from "./SharedComponents/Help/HelpModal"
import EmailPromoModal from "./InfoPages/Profile/EmailPromoModal"
import PaymentModal from "./InfoPages/Pricing/PaymentModal"
import MonthlyCreditsExplanationModal from "./InfoPages/Pricing/MonthlyCreditsExplanationModal";
import MonthlyPricingModal from "./InfoPages/Pricing/MonthlyPricingModal"
import ModalApp from "./SharedComponents/Modal"
import APIGuidesIndex from "./API/APIGuidesIndex"
import PricingPageBlockFeatureModal from "./InfoPages/Pricing/PricingPageBlockFeatureModal/PricingPageBlockFeatureModal";
import ProVsBasicModal from "./SharedComponents/ProVsBasicModal/ProVsBasicModal";
import CreditConversionModal from "./InfoPages/Profile/Credits/CreditConversionModal/CreditConversionModal";
import MatchesFAQModal from "./SharedComponents/MatchesFAQ/MatchesFAQModal";
import DownloadReceiptsModal from "./InfoPages/Profile/Credits/CreditConversionModal/Receipts/DownloadReceiptsModal";
import CongratsPaymentModal from "./SharedComponents/Payment/RefillCreditsModal/CongratsPaymentModal";
import LovePromoBanner from "./SharedComponents/LovePromoBanner";

class AppAuthentication extends Component {
    componentWillMount(){
        if (isBrowser){
            (function(){
                const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/5caeb7ccc1fe2560f3fe3ea1/default';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
            })();
        }

        let source = "";
        let url = window.location.href;

        if (url.includes("source=")){
            let srcSubstr = url.substring(url.indexOf("source="), url.length)

            let endIdx = srcSubstr.length;

            if (srcSubstr.includes("&")){
                endIdx = srcSubstr.indexOf("&")
            }

            source = srcSubstr.substring(srcSubstr.indexOf("=") + 1, endIdx)
        } else {
            source = document.referrer
        }

        localStorage.setItem("source", source)
    }

    render() {
        const url = window.location.href;
        const isAPIDocs = url.substring(url.length - 11, url.length).replace(/\//g, "").includes("api-docs")

        return (
            <div>
                <ModalApp
                    name="apiGuideModal"
                    header="API Guide"
                    body={<APIGuidesIndex />}
                    size="large"
                />
                <CreditConversionModal/>
                <MonthlyCreditsExplanationModal/>
                <EditModal />
                <PricingPageBlockFeatureModal />
                <EmailPromoModal />
                <PaymentModal />
                <ProVsBasicModal/>
                <HelpModal />
                <MatchesFAQModal/>
                <DownloadReceiptsModal/>
                <CleanTypeFAQ />
                <LoadingModal name="loadingModalEditFile" header="Re-reading your file..." message="Re-Reading Files with Options" noClose/>
                <LoadingModal name="loadingModalDeleteFile" header="Deleting your file..." message="Deleting Your File..." noClose/>
                <SignUpModal/>
                <CongratsPaymentModal/>
                <LoginModal/>
                <ForgetPasswordModal/>
                <ContactUsModal/>
                <PhoneModal/>
                <SupportModal/>
                <RefillCreditsModal/>
                <MonthlyPricingModal />
                <div id="appAuthentication">
                    <LovePromoBanner/>
                    <NavbarIndex/>
                    <CustomToastContainer />
                    <Routes/>
                    {isAPIDocs ? null : <FooterIndex/>}
                </div>
            </div>
        );
    }
}


export default withAuthentication(AppAuthentication)
