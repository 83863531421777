import stringToDate from "../../Constants/stringToDate";

const filterAPICalls = (props, apiCalls) => {
    let endDate;
    let startDate;

    endDate = new Date();
    startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);

    if ('filters' in props){
        if ('date' in props.filters){
            endDate = stringToDate(props.filters.date.endDate);
            startDate = stringToDate(props.filters.date.startDate);
        }
    }

    const cleanTypeList = createFilterList("cleanType", props);
    const statusCodeList = createFilterList("statusCode", props);

    let filteredAPICalls = [];

    for (let i=0; i<apiCalls.length; i++){
        let apiCall = apiCalls[i];

        if (filterAPICall(apiCall, cleanTypeList, statusCodeList, startDate, endDate)){
            filteredAPICalls.push(apiCall)
        }
    }

    return filteredAPICalls

}

const createFilterList = (filterType, props) => {
    let filterList = [];

    if (filterType === "cleanType"){
        filterList = ['name', 'address', 'company', 'email', 'date', 'phone', 'notes', 'currency']
    }
    else if (filterType === "statusCode"){
        filterList = [200, 201, 400, 403, 404, 405, 500]
    }

    if ('filters' in props){
        if (filterType in props.filters){
            if (props.filters[filterType].length > 0){
                filterList = props.filters[filterType]
            }
        }
    }

    return filterList
}

const filterAPICall = (apiCall, cleanTypeList, statusCodeList, startDate, endDate) => {
    let filter = false;

    const requestDt = stringToDate(apiCall.request_dt)

    if (requestDt >= startDate && requestDt <= endDate){
        if (statusCodeList.includes(apiCall.status_code) &&
            cleanTypeList.includes(apiCall.clean_type))
        {
            filter = true
        }
    }

    return filter
}

export default filterAPICalls