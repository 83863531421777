import React, {Component} from 'react'
import {connect} from 'react-redux'
import SubscriptionInfo from "../SubscriptionInfo"
import CreditConversionModalBodyIndex from "./CreditConversionModalBodyIndex"
import ProOrBasicTag from "../../../../Dashboard/UpdatedDashboard/Clean/CleanDisplayPreview/ProOrBasicTag";

const thereAreUserCredits = (userCredits) => {
    return userCredits.pro !== 0 || userCredits.basic !== 0
}

class CreditConversionModalBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (thereAreUserCredits(this.props.userCredits)){
            const enoughUserCredits = this.props.userCredits.pro >= 1000 || this.props.userCredits.basic >= 1000;

            return (
                <div>
                    <SubscriptionInfo fetching={false}
                                      userCredits={this.props.userCredits} />
                    {
                        enoughUserCredits ? <CreditConversionModalBodyIndex /> : <div style={{marginTop: "20px", color: "red"}}><h2>You need at least 1,000 <ProOrBasicTag pro={true}/> credits or 1,000 <ProOrBasicTag pro={false}/> credits to convert</h2></div>
                    }
                </div>
            )
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    userCredits: state.mainState.userCredits
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CreditConversionModalBody)