import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Image } from 'semantic-ui-react'
import images from "../../../Constants/Images";
import capitalizeHyphenated from "../../../Constants/capitalizeHyphenated";
import {getTotalCleanValues} from "../../../Constants/getTotalCleanValues";

class CleanColumnsSelected extends Component {
    constructor(props) {
        super(props);

        this.getTotalValue = this.getTotalValue.bind(this);
    }

    getTotalValue(colInfo, colType){
        if (colType === "column"){
            // let missing = 0 ;
            // if (colInfo['missing'] > 0){
            //     missing = 1
            // }

            return colInfo['unique']
        }
        else{
            return getTotalCleanValues(this.props.selectedColumns, colInfo, this.props.fileInformation['columnNames'], this.props.columnCleanTypes, this.props.columnCleanInfo)
        }

    }

    render() {
        if (this.props.selectedColumns.length === 0){
            return(
                null
            )
        }
        else{
            const columnNames = 'columnNames' in this.props.fileInformation ? this.props.fileInformation['columnNames'] : null;
            const columnInfo = 'columnInfo' in this.props.fileInformation ? this.props.fileInformation['columnInfo'] : null;

            if (columnNames !== null && columnInfo !== null){
                let tableRows = [];

                for (let i=0; i<this.props.selectedColumns.length; i++){
                    let columnRow = [];

                    const colIdx = this.props.selectedColumns[i];

                    columnRow.push(<td key={(0).toString() + i.toString()}>{columnNames[colIdx]}</td>)
                    columnRow.push(<td key={(1).toString() + i.toString()}> <Image src={images[this.props.columnCleanTypes[colIdx] + "Icon"]} avatar /> {capitalizeHyphenated(this.props.columnCleanTypes[colIdx])}</td>)
                    columnRow.push(<td key={(2).toString() + i.toString()}><span style={{textAlign: 'center'}}>{this.getTotalValue(columnInfo[colIdx], "column")}</span></td>)

                    tableRows.push(<tr key={i.toString()}>{columnRow}</tr>)
                }

                return (
                    <div>
                        <div id="fileInfoDiv">
                            <h4 style={{textAlign: 'center', paddingTop:'30px'}}>You wish to clean <span className="cleaningTotal bigNumber">{this.getTotalValue(columnInfo, "total")}</span> values</h4>
                            <table id="columnSelectedTable">
                                <thead>
                                <tr>
                                    <th>Column Name</th>
                                    <th>Clean Type</th>
                                    <th>Values Cleaning</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
            else{
                return(
                    null
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    selectedColumns: state.mainState.selectedColumns,
    fileInformation: state.mainState.fileInformation,
    columnCleanTypes: state.mainState.columnCleanTypes,
    columnCleanInfo: state.mainState.columnCleanInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CleanColumnsSelected)