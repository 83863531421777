import React, {Component} from 'react'

class SecurityCard extends Component {
    render() {
        return (
            <div style={{textAlign: "center", width: "75%", margin: "0 auto", padding: "25px 25px 25px 25px", border: "1px solid lightgrey", borderRadius: "2px"}}>
                <img src={this.props.imgSrc} style={{width: "30%", marginTop: this.props.header === "On Prem Install" ? "-20px" : null}} alt={this.props.header + "Icon"}/>
                <h4 style={{paddingTop: "20px"}}><b>{this.props.header}</b></h4>
                <p>{this.props.desc}</p>
            </div>
        )
    }
}

export default SecurityCard