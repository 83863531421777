import React, {Component} from 'react'
import currencyInfo from "../../../Constants/currencyInfo";
import {getTotalPriceForCredits} from "../../../Constants/getTotalPriceForCredits";
import addCommaToNumbers from "../../../Constants/addCommaToNumbers";

// currency={this.props.currency} credits={this.state.creditsToRefill}

class RefillCreditsPrice extends Component {
    render() {
        const totalPrice = getTotalPriceForCredits(this.props.currency, this.props.credits, this.props.isPro);
        let totalPriceDisplay = addCommaToNumbers(totalPrice);

        let display;
        if (isNaN(totalPrice)){
            display = <h1 style={{color: 'red'}}>ERROR</h1>
        }
        else{
            display = <h1 style={{fontSize: '350%', textAlign: "center"}}> <span style={{color: 'grey', fontSize: '50%'}}>{currencyInfo[this.props.currency].symbol}</span> {totalPriceDisplay}</h1>
        }

        return (
            <div>
                {display}
            </div>
        )
    }
}

export default RefillCreditsPrice