import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import monthlyPricing from "../../../../Constants/monthlyPricing";
import UpgradeButton from "./UpgradeButton"
import AccountInfo from "./AccountInfo"

class NotEnoughCredits extends Component {
    constructor(props) {
        super(props);

        this.openRefillModal = this.openRefillModal.bind(this);
        this.changeShowType = this.changeShowType.bind(this);
    }

    openRefillModal(e){
        this.props.modifyModalInfo({creditsToRefill: this.props.totalCleanValues - this.props.userCredits, isPro: this.props.isPro})
        this.props.toggleModal("refillCreditsModal")
    }

    changeShowType(){
        let currShowType = "monthly";

        if ('showType' in this.props){
            currShowType = this.props.showType
        }

        this.props.changeShowType(currShowType !== "monthly" ? "monthly" : "payAsYouGo")
    }

    render() {
        const creditsNeeded = this.props.totalCleanValues - this.props.userCredits;

        let pricingObjects = monthlyPricing[this.props.currency];

        let planIdx = pricingObjects.length - 1;
        let min = 0;
        for (let i=0; i<pricingObjects.length; i++){
            if (min <= this.props.totalCleanValues <= pricingObjects[i].credits){
                planIdx = i
                break;
            }
            min = pricingObjects[i].credits
        }

        let upgradeButton;
        let switchType = null;
        let credits;

        // if (this.props.showType === "monthly"){
        //     upgradeButton = <UpgradeButton planIdx={planIdx} size="large"/>
        //     switchType = <h6 className="linkStyle" style={{marginTop: "25px", marginBottom: "25px", textAlign: "center"}} onClick={this.changeShowType}>I WANT TO PAY AS I GO</h6>
        //     credits = <AccountInfo planIdx={planIdx} lite/>
        // } else {
        const refillText = this.props.isPro ? "Pro" : "Basic";
        upgradeButton = <Button id={refillText.toLowerCase() + "UpgradeButton"} content={"Refill " + creditsNeeded.toString() + " " + refillText + " Credits"} icon="dollar sign" color="purple" size="massive" onClick={this.openRefillModal}/>
        credits = <h6 style={{marginTop: "15px"}}>You need <span className="userCredits bigNumber" style={{color: "green"}}>{creditsNeeded}</span> more {refillText} credits to clean all your values<br/><br/>You currently have <span className="userCredits bigNumber" style={{color: "red"}}>{this.props.userCredits}</span> {refillText} credits</h6>
        // }

        return (
            <div>
                {upgradeButton}
                {credits}
                {switchType}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(NotEnoughCredits)