import React, {Component} from 'react'
import API_Root from '../../../Constants/API_Root'
import { auth } from '../../firebase';
import { Header, Input, Button, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import constants from "../../../Constants/constants";
import { Icon } from 'semantic-ui-react'
import {modifyUserCredits} from "../../../Actions/ModifyUserCredits";
import {isEmailValid} from "../../../Constants/isEmailValid";
import monthlyPricing from "../../../Constants/monthlyPricing";
import currencyInfo from "../../../Constants/currencyInfo";


const isPricing = window.location.href.includes("?pricing=")
let pricingIdx = 0;

if (isPricing){
    let param = window.location.href.substring(window.location.href.indexOf("?pricing=") + "?pricing=".length, window.location.href.indexOf("?pricing=") + "?pricing=".length + window.location.href.length)

    if (!isNaN(param)) {
        pricingIdx = parseInt(param, 10)
    }
}

class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            passwordOne:'',
            passwordTwo:'',
            tandc: false,
            error: null,
            loading: false
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.signUp = this.signUp.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    handleKeyPress(e){
        const {
            email,
            passwordOne,
            passwordTwo,
            tandc,
            error
        } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || passwordOne.length < 8 ||email === ''|| tandc === false;

        if (e.charCode === 13) {
            if (!isInvalid){
                // Prevent the default action to stop scrolling when space is pressed
                e.preventDefault()
                this.signUp(e)
            }
        }
    }

    dismissMessage(e){
        this.setState({ error: null });
    }

    handleInputChange(e){
        if (e.target.name === "tandc"){
            this.setState({
                tandc: !this.state.tandc
            })
        }
        else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    signUp(e){
        this.setState({
            loading: true
        })

        const email = this.state.email;
        const passwordOne = this.state.passwordOne;

        const API = API_Root + 'api/save-user/'; /*save user email to our db*/
        const formData = {
            "email": email,
            "username": email,
            "password": passwordOne,
            "source": localStorage.getItem("source"),
            "promo": window.location.href.includes("?promo=") ? isEmailValid(window.location.href.substring(window.location.href.indexOf("?promo=") + 7, window.location.href.length).replace("/", "")) ? window.location.href.substring(window.location.href.indexOf("?promo=") + 7, window.location.href.length).replace("/", "") : "" : ""
        };

        const errorMessage = () => this.setState({
            loading: false,
            error: {message: 'There was a problem signing up. Contact us at support@cleanspreadsheets.com if the problem persists'}
        })


        auth.doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                this.setState(() => ({
                    username: '',
                    email: '',
                    passwordOne: '',
                    passwordTwo: '',
                    tandc: false,
                    error: null,
                }));
                fetch(API, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                })
                    .then(res => res.json())
                    .then((data) => {
                            localStorage.setItem("email", email.toLowerCase().trim());
                            localStorage.setItem("token", data.token);
                            this.props.modifyUserCredits({"pro": 250, "basic": 0});
                            this.setState({
                                loading: false
                            });
                            this.props.toggleModal(null);


                            if (isPricing){
                                this.props.navigateTo("/clean-payment/" + pricingIdx);
                            } else {
                                this.props.navigateTo("/clean");
                            }
                        }
                    ).catch(err => errorMessage()).catch(err => errorMessage());
            })
            .catch(error => {
                this.setState({error:  error, loading: false});
            });
    }

    render() {
        const {
            email,
            passwordOne,
            passwordTwo,
            tandc,
            error
        } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || passwordOne.length < 8 ||email === ''|| tandc === false;

        const passwordLengthError = passwordOne.length < 8 ? <div><small style={{color: 'red'}}>Password must be at least 8 characters</small></div> : null;
        const passwordMatchError = passwordOne !== passwordTwo ? <div><small style={{color: 'red'}}>Passwords Do Not Match</small></div> : null;



        return (
            <div onKeyPress={this.handleKeyPress}>
                <Header as='h1'>
                    Sign Up
                    {/*<Header.Subheader>*/}
                        {/*Start cleaning your spreadsheets*/}
                    {/*</Header.Subheader>*/}
                    <Header.Subheader style={{marginTop: "5px"}}>
                    {
                        isPricing ?
                            <div>
                                <span style={{fontSize: "16px"}}>{monthlyPricing[this.props.currency][pricingIdx].name.toUpperCase()} Account, {currencyInfo[this.props.currency].symbol + monthlyPricing[this.props.currency][pricingIdx].price.toString() + " per month"}</span>
                                <br/>
                                <span style={{fontSize: "16px"}}><Icon name="ticket"/> {monthlyPricing[this.props.currency][pricingIdx].credits.toLocaleString()} credits per month</span>
                                <br/>
                                <span style={{fontSize: "16px"}}><Icon name="user"/> {monthlyPricing[this.props.currency][pricingIdx].support} Support</span>
                            </div> :
                            <span><span style={{fontWeight: "bold", fontSize: "110%", color: "green"}}>250</span> FREE Credits with each account</span>
                    }
                    </Header.Subheader>
                </Header>
                <br/>
                <Input icon="at"
                       id="signupEmail"
                       iconPosition='left'
                       placeholder="Email" type="email" name="email"
                       onChange={this.handleInputChange}
                       value={email} fluid/>
                <Input  icon="lock"
                        id="signupPassword1"
                        iconPosition="left"
                        placeholder="Password (at least 8 characters)" type="password" name="passwordOne"
                        onChange={this.handleInputChange}
                        value={passwordOne} error={passwordOne.length < 8} fluid/>
                <Input  icon="lock"
                        id="signupPassword2"
                        iconPosition="left"
                        placeholder="Confirm Password" type="password" name="passwordTwo"
                        onChange={this.handleInputChange}
                        value={passwordTwo} error={passwordTwo.length < 8} fluid/>
                <br/>
                <input type="checkbox" name="tandc" id="tandc" onChange={this.handleInputChange} checked={tandc} />
                <label style={{cursor: 'pointer'}} htmlFor="tandc">
                    <span style={{paddingLeft: '7px'}}>{"I agree with " + constants.productName + "'s"}</span>
                    <a href="/terms" target="_blank"> Terms and Conditions</a>
                </label>
                {passwordLengthError}
                {passwordMatchError}
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button id="signupButton" loading={this.state.loading} color="blue" disabled={isInvalid} onClick={this.signUp} onKeyPress={this.handleKeyPress} style={{fontSize: '120%'}}>
                        {isPricing ? <span>Sign up and go to payment <Icon name="right arrow" /></span> : <span>Sign up and go to your dashboard <Icon name="right arrow" /></span>}
                    </Button>
                </div>
                { error && <Message error
                                    onDismiss={this.dismissMessage}
                                    header={error.message} /> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    modifyUserCredits: modifyUserCredits
}

export default connect(mapStateToProps,mapActionsToProps)(SignUpForm);
