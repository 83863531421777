import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../../../Actions/ToggleModal";
import monthlyPricing from "../../../../Constants/monthlyPricing";
import supportIconNames from "../../../../Constants/supportIconNames";
import SupportInfo from "../../../../InfoPages/Pricing/SupportInfo";
import { Icon } from "semantic-ui-react"

class AccountInfo extends Component {
    constructor(props) {
        super(props);

        this.openCreditExplanation = this.openCreditExplanation.bind(this);
    }

    openCreditExplanation(e){
        this.props.toggleModal("monthlyCreditsExplanationModal")
    }

    render() {
        const planIdx = this.props.planIdx;

        const totalCredits = monthlyPricing[this.props.currency][planIdx].credits;
        const customerSupport = monthlyPricing[this.props.currency][planIdx].support;

        return (
            <div className="monthlyAccountInfoDetailsBox">
                <div className="monthlyAccountInfoFeatures">
                    <span><Icon name="check" style={{color: "darkblue"}}/> {totalCredits.toLocaleString()} Credits per Month</span>
                    <span>Our monthly limits are soft limits. <span className="linkStyle" onClick={this.openCreditExplanation}>Read more</span> </span>
                    <span><Icon name={supportIconNames[customerSupport]} style={{color: 'darkblue'}}/> {customerSupport} Support</span>
                </div>
                {'lite' in this.props ? null : <SupportInfo supportType={customerSupport} style={{listStyleType: "none", textAlign: "left", marginLeft: "-25px", fontSize: "90%"}}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    subscriptionInfo: state.mainState.subscriptionInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(AccountInfo)