import React, {Component} from 'react';
import DashboardIndex from './DashboardIndex';
import withAuthorization from '../Authentication/withAuthorization';
import MobileLandingPage from "../LandingPage/MobileLandingPage"
import { isMobile } from 'react-device-detect';

class AuthDashboard extends Component {
    render() {
        if (isMobile){
            return(
                <MobileLandingPage />
            )
        } else {
            return(
                <DashboardIndex/>
            )
        }
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthDashboard);
