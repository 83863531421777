import React, {Component} from 'react'
import {connect} from 'react-redux'
import RCodeSnippet from './RQuickstart/RCodeSnippet'
import PythonCodeSnippet from './PythonQuickstart/PythonCodeSnippet'
import JavascriptCodeSnippet from "./JavascriptQuickstart/JavascriptCodeSnippet";
import URLBox from "../URLBox";
import {getCodeSnippet} from "../../../Constants/getCodeSnippet";
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-powershell";
import "ace-builds/src-noconflict/theme-monokai";

class CodeSnippetBody extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        const code = getCodeSnippet(this.props.guide, this.props.apiKey, this.props.cleanType)

        const codeLibrary = {
            r: <URLBox
                urlType="Install R Library"
                url='devtools::install_github("asharma327/woyera-r-library")'
            />,
            python: <URLBox
                urlType="Install Requests Library (if not already installed)"
                url="$ pip install requests"
            />,
            javascript: <p>The <code>fetch</code> function is default in Javascript</p>,
            php: <p>Code uses all default libraries</p>,
            ruby: <p>Code uses all default libraries</p>,
            cURL: <p>You can execute cURL commands on the <code>$ terminal</code></p>
        }

        return (
            <div>
                {codeLibrary[this.props.guide]}
                <br/>
                <AceEditor
                    mode={this.props.guide === 'cURL' ? "powershell" : this.props.guide}
                    theme="monokai"
                    name="pythonQuickstartGuide"
                    value={code}
                    editorProps={{ $blockScrolling: true }}
                    width='100%'
                    height='350px'
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CodeSnippetBody)