import React, {Component} from 'react'
import SocialLogo from "./SocialLogo";
import Leadaro from "./Leadaro.svg";
import MaoHome from "./MaoHome.svg";
import MyGPS from "./MyGPS.svg";
import Lightyear from "./Lightyear.svg";
import GenoaUniversity from "./GenoaUniversity.svg";

class SocialProofIndex extends Component {
    render() {
        let headingStyle = {color: 'gray', textAlign: 'center', paddingLeft: "10px"};

        return (
            <div id="socialProofIndex" className="socialProofIndexBackgroundColor">
                <h2 style={{textAlign: "center", marginBottom: "30px"}}>Trusted by Universities, Agencies, Startups & Small Businesses</h2>
                <div className="row">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <SocialLogo
                            src={GenoaUniversity}
                            link="https://economia.unige.it/about-us"
                        />
                    </div>
                    <div className="col-sm-2">
                        <SocialLogo
                            src={Lightyear}
                            link='http://www.lightyearstrategies.com/'
                        />
                    </div>
                    <div className="col-sm-2">
                        <SocialLogo
                            src={MaoHome}
                            link='https://www.maohomesolutions.com/'
                        />
                    </div>
                    <div className="col-sm-2">
                        <SocialLogo
                            src={MyGPS}
                            link='https://www.mygps.mu/'
                        />
                    </div>
                    <div className="col-sm-2" />
                </div>
            </div>
        )
    }
}

export default SocialProofIndex