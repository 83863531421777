import React, {Component} from 'react'
import DetailExampleIndex from './DetailExampleIndex'
import Bubbles from "../Illustrations/Bubbles.svg"

class DetailExampleRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            height: null
        }
    }

    componentDidMount(){
        const height = this.detailDiv.clientHeight;
        this.setState({
            height: height
        })
    }

    render() {
        return (
            <div className="row">
                {/*<LaunchDate/>*/}
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <div ref={(detailDiv) => this.detailDiv = detailDiv} style={{marginTop: "50px"}}>
                        <h1 className="thinHeading" style={{fontSize: "450%", textAlign: "center", color: "#167dff", fontFamily: "museo-sans, sans-serif", fontStyle: "normal", fontWeight: "bold"}}>Intelligent Parsing</h1>
                        <DetailExampleIndex/>
                    </div>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

export default DetailExampleRow