import React, {Component} from 'react'
import ModalApp from "../../../../../SharedComponents/Modal"
import { Icon } from 'semantic-ui-react'
import DownloadReceiptsModalBody from "./DownloadReceiptsModalBody"

class DownloadReceiptsModal extends Component {
    render() {
        return (
            <ModalApp
                name="downloadReceiptsModal"
                header={<span><Icon name="file pdf outline" /> Download Your Receipts</span>}
                body={<DownloadReceiptsModalBody />}
                size="large"
            />
        )
    }
}

export default DownloadReceiptsModal