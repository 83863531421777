import React, {Component} from 'react'
import DisplayBox from '../DisplayBox'
import ReactJson from 'react-json-view'

class Authentication extends Component {
    render() {
        const apiKey = this.props.apiKey === null ? "YOUR_API_KEY" : this.props.apiKey;

        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <p>We authenticate requests based on your <b>API Key</b>. It is a 32 character alphanumeric key that can be found in your dashboard.</p>
                        <br/>
                        <p>Your API Keys should be kept secret otherwise someone else can use up your request quota.</p>
                        <br/>
                        <p>You can request a new API Key in your dashboard. Make sure to change the key everywhere in your code.</p>
                        <br/>
                        <p>You need to include your API Key using the <code>apiKey</code> parameter in the request body.
                            And POST the request over HTTPS otherwise it will not authenticate.</p>
                        <br/>
                        <p>Response code <code>403</code> will be returned if the request does not authenticate.</p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <DisplayBox header="How to include the API Key in the request?"
                                desc={<div>
                                    <p>Include the API Key in the <code>JSON</code> encoded body with the <code>apiKey</code> parameter</p>
                                    <ReactJson src={{apiKey: apiKey}} name={null} theme="ocean" displayObjectSize={false}/>
                                </div>}
                                color="green"
                    />
                </div>
            </div>
        )
    }
}

export default Authentication