import React, {Component} from 'react'

class CurrencyExample extends Component {
    render() {
        let pro = 'pro' in this.props;

        if (pro){
            pro = this.props.pro
        }

        return (
            <div>
                <table className="table table-sm table-bordered">
                    <thead>
                    <tr>
                        {'faq' in this.props ? <th>Your Value</th> : <th>Input</th> }
                        {'faq' in this.props ? <th>Cleaned Value</th> : <th>number value</th> }
                        <th>symbol</th>
                        <th>code</th>
                        {pro ? <th>usd</th> : null}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>$232.32000231</td>
                        <td>232.32</td>
                        <td>$</td>
                        <td>USD</td>
                        {pro ? <td>232.32</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>€1.343,76</td>
                        <td>1343.76</td>
                        <td>€</td>
                        <td>EUR</td>
                        {pro ? <td>1575.58</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>10,322 Pound Sterling</td>
                        <td>10322.00</td>
                        <td>£</td>
                        <td>GBP</td>
                        {pro ? <td>13275.12</td> : null}
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default CurrencyExample