import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCleanOptions} from "../../../../../../Actions/ModifyCleanOptions";
import isObjectEmpty from "../../../../../../Constants/isObjectEmpty";
import AddressColumns from "../Address/AddressColumns"
import {modifyAddressColumns} from "../../../../../../Actions/ModifyAddressColumns";
import {toggleModal} from "../../../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../../../Actions/ModifyModalInfo";
import OtherOptions from "../Other/OtherOptions"

class AddressOptions extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.isRadioChecked = this.isRadioChecked.bind(this);
        this.initAddressColumns = this.initAddressColumns.bind(this);
        this.findAndAddColNames = this.findAndAddColNames.bind(this);
        this.openHelpModal = this.openHelpModal.bind(this);
    }

    handleInputChange(e){
        if (this.props.cleanOptions !== null){
            if (!isObjectEmpty(this.props.cleanOptions)){
                const copiedCleanOptions = JSON.parse(JSON.stringify(this.props.cleanOptions))
                copiedCleanOptions[e.target.name] = e.target.value
                this.props.modifyCleanOptions(copiedCleanOptions)
            }
            else{
                this.props.modifyCleanOptions({ [e.target.name]: e.target.value })
            }
        } else {
            this.props.modifyCleanOptions({ [e.target.name]: e.target.value })
        }

        let geocodeType;
        if (e.target.name === "addressColumn"){
            geocodeType = this.props.cleanOptions.addressGeocode
        } else {
            geocodeType = e.target.value
        }

        if (geocodeType === "forward"){
            if (this.props.addressColumns[geocodeType].length === 0){
                const currAddrColumns = JSON.parse(JSON.stringify(this.props.addressColumns));
                currAddrColumns[geocodeType] = this.initAddressColumns(geocodeType)
                this.props.modifyAddressColumns(currAddrColumns)
            }
        } else {
            const currAddrColumns = JSON.parse(JSON.stringify(this.props.addressColumns));
            currAddrColumns[geocodeType] = this.initAddressColumns(geocodeType)
            this.props.modifyAddressColumns(currAddrColumns)
        }
    }

    isRadioChecked(radioKey, radioName){
        let radioChecked = false;
        if (this.props.cleanOptions !== null){
            if (!isObjectEmpty(this.props.cleanOptions)){
                if (radioKey in this.props.cleanOptions){
                    if (this.props.cleanOptions[radioKey] === radioName){
                        radioChecked = true;
                    }
                }
            }
        }

        return radioChecked
    }

    findAndAddColNames(colNames, lowerColNames, colToFind){
        for (let i=0; i<colToFind.length; i++){
            if (lowerColNames.includes(colToFind[i])){
                colNames.push(this.props.fileInformation.columnNames[lowerColNames.indexOf(colToFind[i])])
                break
            }
        }

        return colNames
    }

    initAddressColumns(geocodeType){
        let colNames = []

        if ('columnNames' in this.props.fileInformation){
            let allColNames = this.props.fileInformation.columnNames.map(x => x.toLowerCase().replace(" ", ""));

            if (geocodeType === "reverse"){
                let latlng = {lat: null, lng: null};
                let reverseLatNames = ['lat', 'latitude']
                let reverseLngNames = ['long', 'lng', 'longitude']

                colNames = this.findAndAddColNames(colNames, allColNames, reverseLatNames)

                if (colNames.length === 1){
                    latlng.lat = colNames[0]
                }

                colNames = this.findAndAddColNames(colNames, allColNames, reverseLngNames)

                if (colNames.length === 1 && latlng.lat === null){
                    latlng.lng = colNames[0]
                } else if (colNames.length === 2){
                    latlng.lng = colNames[1]
                }

                colNames = latlng

            } else {
                let beginner = ['number', 'num', '#', 'address', 'addr1', 'address1', "addr"]
                colNames = this.findAndAddColNames(colNames, allColNames, beginner)

                if (colNames.includes("addr1") || colNames.includes("address1")){
                    colNames = this.findAndAddColNames(colNames, allColNames, ["addr2", "address2"])
                } else if (colNames.includes("number") || colNames.includes("num") || colNames.includes("#")){
                    colNames = this.findAndAddColNames(colNames, allColNames, ['street'])
                }

                colNames = this.findAndAddColNames(colNames, allColNames, ['city'])
                colNames = this.findAndAddColNames(colNames, allColNames, ['state', 'province'])
                colNames = this.findAndAddColNames(colNames, allColNames, ['zipcode', 'zip', 'postal', 'postalcode'])
                colNames = this.findAndAddColNames(colNames, allColNames, ['country'])
            }
        }

        return colNames
    }

    openHelpModal(e, helpTopic){
        this.props.modifyModalInfo({helpTopic: helpTopic})
        this.props.toggleModal("helpModal")
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-5">
                        <div className="addressOptionsBox">
                            <h4>I also want to do</h4>
                            <input type="radio" name="addressGeocode" value="forward" onChange={this.handleInputChange} checked={this.isRadioChecked("addressGeocode", "forward")} id="addressGeocodeRadio1" />
                            <label htmlFor="addressGeocodeRadio1">Forward Geocoding</label>
                            <br/>
                            <input type="radio" name="addressGeocode" value="reverse" onChange={this.handleInputChange} checked={this.isRadioChecked("addressGeocode", "reverse")} id="addressGeocodeRadio2" />
                            <label htmlFor="addressGeocodeRadio2">Reverse Geocoding</label>
                        </div>
                        <span className="linkStyle" onClick={(e) => this.openHelpModal(e, "geocodingDifference")}>What is the difference?</span>
                    </div>
                    <div className="col-sm-7">
                        <div className="addressOptionsBox">
                            <h4>My addresses components are...</h4>
                            <input type="radio" name="addressColumn" value="one" onChange={this.handleInputChange} checked={this.isRadioChecked("addressColumn", "one")} id="addressColumnRadio1" />
                            <label htmlFor="addressColumnRadio1">...entirely in one column</label>
                            <br/>
                            <input type="radio" name="addressColumn" value="multiple" onChange={this.handleInputChange} checked={this.isRadioChecked("addressColumn", "multiple")} id="addressColumnRadio2" />
                            <label htmlFor="addressColumnRadio2">...split across columns</label>
                        </div>
                        <span className="linkStyle" onClick={(e) => this.openHelpModal(e, "geocodingColumnType")}>See example showing difference</span>
                    </div>
                </div>
                {
                    this.isRadioChecked("addressColumn", "one") ? <OtherOptions /> : null
                }
                <div className="row">
                    <div className="col-sm-12">
                        <AddressColumns />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cleanOptions: state.mainState.cleanOptions,
    fileInformation: state.mainState.fileInformation,
    addressColumns: state.mainState.addressColumns,
})

const mapActionsToProps = {
    modifyCleanOptions: modifyCleanOptions,
    modifyAddressColumns: modifyAddressColumns,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(AddressOptions)