import React, {Component} from 'react'
import { connect } from 'react-redux'
import cleanDataTypes from "../../../Constants/cleanDataTypes";
import { Image } from 'semantic-ui-react'
import images from "../../../Constants/Images";
import {modifyUploadDataType} from "../../../Actions/ModifyUploadDataType";
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";
import {modifySelectedColumns} from "../../../Actions/ModifySelectedColumns";
import {modifyColumnCleanTypes} from "../../../Actions/ModifyColumnCleanTypes";
import {modifyAllColumnInfo} from "../../../Actions/ModifyAllColumnInfo";

class DataTypeButton extends Component {
    changeDataType(dataType){
        this.props.modifyUploadDataType(dataType)
        this.props.modifyTutorialStep(3)
        const columnCleanTypes = this.props.fileInformation.columnNames.map((x, idx) => "")
        const columnCleanInfo = this.props.fileInformation.columnNames.map((x, idx) => null)
        // selectedColumns, columnCleanTypes (""), columnCleanInfo (null)
        this.props.modifyAllColumnInfo([], columnCleanTypes, columnCleanInfo)
    }

    render() {
        const dataType = cleanDataTypes[this.props.idx];
        const cName = this.props.uploadDataType === dataType ? "segment-raised-rounded helpSectionButton helpSectionButtonSelected" : "helpSectionButton";

        return (
            <div className={cName} onClick={e => this.changeDataType(dataType)} id={dataType + "DataTypeButton"}>
                <Image src={images[dataType + "Icon"]} size='mini' centered />
                <h4 style={{textAlign: "center", textTransform: "capitalize"}}>{dataType}</h4>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    uploadDataType: state.mainState.uploadDataType,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyUploadDataType: modifyUploadDataType,
    modifyTutorialStep: modifyTutorialStep,
    modifyAllColumnInfo: modifyAllColumnInfo
}

export default connect(mapStateToProps, mapActionsToProps)(DataTypeButton)