import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'
import images from "../../../../Constants/Images";
import capitalizeHyphenated from "../../../../Constants/capitalizeHyphenated";
import { connect } from 'react-redux'


class CleanDisplayMasterMenu extends Component {
    constructor(props) {
        super(props);

        this.toggleMenuItem = this.toggleMenuItem.bind(this);
    }

    toggleMenuItem(e, idx){
        this.props.changeSelectedCol(idx)
    }

    render() {
        if (this.props.selectedColumns.length === 0){
            return(
                null
            )
        }
        else{
            const columnNames = 'columnNames' in this.props.fileInformation ? this.props.fileInformation['columnNames'] : null;
            const columnInfo = 'columnInfo' in this.props.fileInformation ? this.props.fileInformation['columnInfo'] : null;

            if (columnNames !== null && columnInfo !== null){
                let cleanDisplayMenuItems = [];

                for (let i=0; i<this.props.selectedColumns.length; i++){

                    const colIdx = this.props.selectedColumns[i];
                    let menuClassName = "cleanDisplayMenuItem";

                    if (this.props.selectedColIdx === i){
                        menuClassName += " cleanDisplayMenuItemSelected"
                    }

                    cleanDisplayMenuItems.push(
                        <div className={menuClassName} onClick={(e) => this.toggleMenuItem(e, i)} style={i === 0 ? {borderRadius: "5px 0px 0px 0px"} : null}>
                            <span className="cleanDisplayMenuItemColInfo"><Image src={images[this.props.columnCleanTypes[colIdx] + "Icon"]} avatar /> {capitalizeHyphenated(this.props.columnCleanTypes[colIdx])}</span>
                            <span className="cleanDisplayMenuItemColName">{columnNames[colIdx]}</span>
                        </div>
                    )

                }

                return (
                    <div className="cleanDisplayMasterMenu">
                        <p>Select Cleaning Steps</p>
                        {cleanDisplayMenuItems}
                    </div>
                )
            }
            else{
                return(
                    null
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    selectedColumns: state.mainState.selectedColumns,
    fileInformation: state.mainState.fileInformation,
    columnCleanTypes: state.mainState.columnCleanTypes
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(CleanDisplayMasterMenu)