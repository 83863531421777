import React, {Component} from 'react'
import images from "../../../Constants/Images";
import EmailExample from "../../../LandingPage/DetailExamples/EmailExample"
import FAQBlock from "../FAQBlock";
import PhoneExample from "../../../LandingPage/DetailExamples/PhoneExample"

const phoneFAQ = [
    {question: 'Can you really clean all phone numbers?', answer: <span>We can definitely clean phone number for all <a href="https://en.wikipedia.org/wiki/G20" target="_blank" rel="noopener noreferrer">G20</a> countries. Other than that, most phone numbers will still work. Try it out on our <a href="/demo">Demo</a></span>},
    {question: 'How does cleaning phone numbers work?', answer: <span>We standardize each number into the <a href="https://support.twilio.com/hc/en-us/articles/223183008-Formatting-International-Phone-Numbers" target="_blank" rel="noopener noreferrer">E.164 format</a>, the internationally recognized format for dialing</span>},
    {question: "Do you validate if the phone number is real?", answer: "No, we CURRENTLY do not validate whether the phone number is real or not. We are working on the capability and it will be released very soon."},
    {question: 'What is the output for phone number cleaning?', answer: <span>The final output from cleaning a column of phone numbers will look like the following <br/> <br/> <PhoneExample faq/></span>},
    {question: "What if my value isn't a phone number?", answer: "If your value hit an error. Then the Cleaned Value and the components will be completely blank."}
]

class Phone extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <FAQBlock
                        title="Phone" icon={images.phoneIcon}
                        FAQs={phoneFAQ}
                        dataType
                    />
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default Phone