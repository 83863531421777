import React, {Component} from 'react'
import { connect } from 'react-redux'
import CleanDataUploadButton from "../../UpdatedDashboard/CleanDataUploadButton";
import CleaningStep from "./CleaningStep";
import AddressCleaningStep from "./AddressCleaningStep";
import {getTotalCleanValues} from "../../../Constants/getTotalCleanValues";

class CleaningSteps extends Component {
    render() {
        if ('samples' in this.props.fileInformation){
            let cleaningSteps = null;

            if (this.props.selectedColumns.length > 0){
                let allCleaningSteps = [];
                let colInfoIdx = [];
                for (let i=0; i<this.props.selectedColumns.length; i++){
                    const selectedColIdx = this.props.selectedColumns[i];
                    if (this.props.columnCleanInfo[selectedColIdx] === null){
                        allCleaningSteps.push(<CleaningStep
                            key={i.toString()}
                            selectedColIdx={selectedColIdx}
                            cleanDataType={this.props.columnCleanTypes[selectedColIdx]}
                            cleanDataScreen={'cleanData' in this.props}
                        />)
                    }
                    else{
                        colInfoIdx.push(selectedColIdx)
                    }
                }

                if (colInfoIdx.length > 0){
                    allCleaningSteps.push(<AddressCleaningStep key="-1"
                                                               cleanDataScreen={'cleanData' in this.props}
                                                               colInfoIdx={colInfoIdx}/>)
                }

                let header = <h4>Your Cleaning Selection</h4>;
                let allCleaningStepsRows = allCleaningSteps;

                if ('cleanData' in this.props){
                    header = <h4 style={{textAlign: 'center', paddingTop:'30px'}}>You wish to clean <span className="cleaningTotal bigNumber">{getTotalCleanValues(this.props.selectedColumns, this.props.fileInformation['columnInfo'], this.props.fileInformation['columnNames'], this.props.columnCleanTypes, this.props.columnCleanInfo)}</span> values</h4>
                    allCleaningStepsRows = <table id="columnSelectedTable">
                        <thead>
                        <tr>
                            <th>Column Name</th>
                            <th>Clean Type</th>
                            <th>Values Cleaning</th>
                        </tr>
                        </thead>
                        <tbody>
                        {allCleaningSteps}
                        </tbody>
                    </table>
                }

                cleaningSteps = <div className="cleaningStepsBox">
                    {header}
                    {allCleaningStepsRows}
                </div>
            }


            return(
                <div>
                    {cleaningSteps}
                    {!('cleanData' in this.props) ? <CleanDataUploadButton/> : null }
                </div>
          )
        } else {
              return(
                null
              )
        }
  }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    uploadDataType: state.mainState.uploadDataType,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
    cleanOptions: state.mainState.cleanOptions,
    columnCleanInfo: state.mainState.columnCleanInfo
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(CleaningSteps)
