import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createAddressSteps} from "../../../../Constants/createAddressSteps";
import {getCleanValuesForStep} from "../../../../Constants/getCleanValuesForStep";
import images from "../../../../Constants/Images";
import capitalizeHyphenated from "../../../../Constants/capitalizeHyphenated";
import { Image, Icon } from 'semantic-ui-react';
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import {toggleModal} from "../../../../Actions/ToggleModal";
import ProVsBasicCopy from "./ProVsBasicCopy";
import ProOrBasicTag from "./ProOrBasicTag";
import MultipleColumnNamesSelection from "./MultipleColumnNamesSelection";
import ColumnNameInfo from "./ColumnNameInfo"

class ColumnResultsInformation extends Component {
    constructor(props) {
        super(props);

        this.openFAQModal = this.openFAQModal.bind(this);
    }

    openFAQModal(e, cleanType){
        this.props.modifyModalInfo({"cleanTypeFAQ": cleanType});
        this.props.toggleModal("cleanTypeFAQ");
    }

    changeSelectedCol = (idx) => {
        this.props.changeSelectedCol(idx)
    }

    render() {
        let columnNameInfo = null;
        let columnInfoSegment = null;

        const idx = this.props.cleanedSourceIdx;

        if (idx !== -1){
            const columnNames = 'columnNames' in this.props.fileInformation ? this.props.fileInformation['columnNames'] : null;
            const columnInfo = 'columnInfo' in this.props.fileInformation ? this.props.fileInformation['columnInfo'] : null;

            if (columnNames !== null && columnInfo !== null) {
                // let cleanType;
                // let cleanText;
                // let uniqueValues;
                //
                // let cleanedSource = this.props.cleanedSource[idx];
                // let cleanIdx = cleanedSource.id;
                //
                // if (cleanedSource.type === "selectedColumn") {
                //     cleanType = this.props.columnCleanTypes[cleanIdx]
                //     cleanText = columnNames[cleanIdx]
                //     uniqueValues = columnInfo[cleanIdx]['unique']
                // } else {
                //     cleanType = "address"
                //
                //     let addressSteps = createAddressSteps(columnNames, this.props.columnCleanInfo, this.props.selectedColumns);
                //     let colStep = addressSteps[cleanIdx];
                //     let colNamesStep = colStep.colNames;
                //
                //     if (colStep.geocode === "forward"){
                //         const colIndices = Object.keys(colNamesStep)
                //         let colNamesString = "";
                //
                //         for (let i=0; i<colIndices.length; i++){
                //             colNamesString += colNamesStep[colIndices[i]] + ", "
                //         }
                //
                //         cleanText = colNamesString.substring(0, colNamesString.length - 2) + ", forward geocoding"
                //     } else {
                //         cleanText = "latitude: " + colNamesStep.lat + ",longitude:" + colNamesStep.lng + ", reverse geocoding"
                //     }
                //
                //     uniqueValues = getCleanValuesForStep(colStep, columnInfo)
                // }

                // columnNameInfo = <div className="columnResultsNameInfo">
                //     <span>{capitalizeHyphenated(cleanType)} <Image src={images[cleanType + "Icon"]} avatar />
                //         <span className="linkStyle" style={{fontSize: "85%"}} onClick={(e) => this.openFAQModal(e, cleanType)}> {capitalizeHyphenated(cleanType)} FAQs <Icon name="question circle outline" /></span>
                //     </span>
                //     <br/>
                //     <span><span style={{fontSize: "150%", fontWeight: "bold"}}>{cleanText}</span> <span style={{float: "right"}}><span style={{color: "green", fontSize: "16px", fontWeight: "bold"}}>{uniqueValues}</span> unique values</span></span>
                // </div>

                if (this.props.cleanedSource.length > 1){
                    columnInfoSegment = <MultipleColumnNamesSelection
                        changeSelectedCol={this.changeSelectedCol}
                        cleanedSourceIdx={idx}
                        cleanedSourceLen={this.props.cleanedSource.length}
                    />
                } else {
                    columnInfoSegment = <ColumnNameInfo
                        cleanedSourceIdx={idx}
                        isDropdown={false}
                    />
                }
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <div>
                            {
                                this.props.isSample ? <ProVsBasicCopy/> : null
                            }
                        </div>
                    </div>
                    <div className="col-sm-6">
                        { this.props.isSample ? <div style={{fontSize: "110%", textAlign: "right"}}>We are showing only the first <b>{this.props.showNum} rows</b> in this sample. <br/>{this.props.addlMessage}</div> : null }
                    </div>
                </div>
                <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col-sm-6">
                        {columnInfoSegment}
                        { this.props.isSample ? null : <span style={{fontSize: "110%"}}>We are showing only the first <b>{this.props.showNum} rows</b> in this sample. </span> }
                        { this.props.isSample ? null : this.props.addlMessage }
                    </div>
                    <div className="col-sm-6">

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    uploadDataType: state.mainState.uploadDataType,
    selectedColumns: state.mainState.selectedColumns,
    columnCleanTypes: state.mainState.columnCleanTypes,
    cleanOptions: state.mainState.cleanOptions,
    cleanedSource: state.mainState.cleanedSource,
    columnCleanInfo: state.mainState.columnCleanInfo
})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnResultsInformation)