import React, {Component} from 'react'
import ProOrBasicTag from "../../Dashboard/UpdatedDashboard/Clean/CleanDisplayPreview/ProOrBasicTag";
import MatchesExampleTable from "./MatchesExampleTable";

class MatchesFAQModalBody extends Component {
    render() {
        return (
            <div>
                <div style={{marginLeft: "10px"}}>
                    <p className="thinHeading"><li>We return up to 3 possible matches for <i>some</i> values</li></p>
                    <p className="thinHeading"><li>The matches are ordered by the <b>Score</b></li></p>
                    <p className="thinHeading"><li>Each match's results will have <b>_1</b>, <b>_2</b> or <b>_3</b> as their suffixes</li></p>
                    <p className="thinHeading"><li>The suffix are ordered from high to low <b>Score</b></li></p>
                    <p className="thinHeading"><li>The Data Cleaning might only result in 1 or 2 matches. In that case, the other columns will be empty</li></p>
                    <small>*Multiple matches are only available when you do <b>Pro</b> cleaning</small>
                </div>
                <br/>
                {/*<small>The examples only contain a subset of data we return. Clean your values to see everything</small>*/}
                <h4>Example 1</h4>
                <MatchesExampleTable input="205 Cinder Road"
                 output={[
                    {"formatted": "205 Cinder Rd, New Providence, PA 17560, USA", "score": "100"},
                    {"formatted": "205 Cinder Rd, Lutherville-Timonium, MD 21093, USA", "score": "90"},
                    {"formatted": "205 Cinder Rd, Edison, NJ 08820, USA", "score": "90"},
                ]}/>
                <br/>
                <h4>Example 2</h4>
                <MatchesExampleTable input="10/12/2021"
                                     output={[
                                         {"formatted": "October 12th, 2021", "score": "100"},
                                         {"formatted": "December 10th, 2021", "score": "90"}
                                     ]}

                />
                <br/>
                <h4>Example 3</h4>
                <MatchesExampleTable input="1600 Pennsylvania Avenue, USA"
                                     output={[
                                         {"formatted": "1600 Pennsylvania Avenue NW, Washington, DC 20500, USA", "score": "100"},
                                     ]}

                />
                <br/><br/>
            </div>
        )
    }
}

export default MatchesFAQModalBody