import React, {Component} from 'react'
import ModalApp from "../../../../SharedComponents/Modal"
import CreditConversionModalBody from "./CreditConversionModalBody"

class CreditConversionModal extends Component {
    render() {
        return (
            <div>
                <ModalApp
                    name="creditConversionModal"
                    header="Convert between Pro & Basic Credits"
                    body={<CreditConversionModalBody />}
                    size="large"
                />
            </div>
        )
    }
}

export default CreditConversionModal