import React, {Component} from 'react'
import { Menu, Segment, Image } from 'semantic-ui-react'
import images from "../../Constants/Images";
import {startCase} from "lodash";
import cleanDataTypes from "../../Constants/cleanDataTypes";

class CleanTypeDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cleanType: "name"
        }

        this.changeMenuItem = this.changeMenuItem.bind(this);
    }

    changeMenuItem(e, {name}){
        this.setState({
            cleanType: name
        })
    }

    render() {
        const menuIcon = {
            "name": images.nameIcon,
            "address": images.addressIcon,
            "date": images.dateIcon,
            "company": images.companyIcon,
            "currency": images.currencyIcon,
            "email": images.emailIcon,
            "notes": images.notesIcon,
            "phone": images.phoneIcon
        };

        const firstRow = cleanDataTypes;

        const menuItems = firstRow.map((x, idx) =>
            <Menu.Item name={x} active={this.state.cleanType === x} key={idx} onClick={this.changeMenuItem}>
                <Image src={menuIcon[x]} avatar />
                <span>{startCase(x)}</span>
            </Menu.Item>);

        let cleanTypeDisplay = null;

        if ('display' in this.props){
            if (this.state.cleanType in this.props.display){
                cleanTypeDisplay = this.props.display[this.state.cleanType]
            }
        }

        return (
            <div>
                <Menu attached='top' tabular>
                    {menuItems}
                </Menu>
                <Segment attached='bottom'>
                    <div style={{overflowX: "scroll"}}>
                        {cleanTypeDisplay}
                    </div>
                </Segment>
            </div>
        )
    }
}

export default CleanTypeDisplay