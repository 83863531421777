import React, {Component} from 'react'
import images from "../../../Constants/Images";
import FAQBlock from "../FAQBlock";
import CurrencyExample from "../../../LandingPage/DetailExamples/CurrencyExample"
import { Flag } from 'semantic-ui-react'
import currencySelection from "../../../Constants/currencySelection";

const currencyDetails = currencySelection.map((x, idx) => <div key={idx}><Flag name={x.flag} /> {x.name + " (" + x.symbol + ")"}</div>)

const currencyFAQ = [
    {question: 'What does cleaning currency mean?', answer: <span>Let's say we are cleaning <b>$1,323.23 USD</b>. We do a few things:<ol><li>Remove any symbols or words</li><li>Remove thousands separator</li><li>Convert to number</li></ol>The final cleaned value will be <b>1323.23</b></span>},
    {question: 'Nice, does cleaning do anything else?', answer: <div>Yes! We split the currency symbol, currency code, and conversion to USD. The final result will look as follows <br/> <br/> <CurrencyExample faq/> </div>},
    {question: 'Can you guys clean any currency?', answer: <span>No, currently we support the following currencies. We are constantly adding more currency support. <br/>{currencyDetails}</span>},
    {question: "What if my value isn't a currency?", answer: "If your value hit an error. Then the Cleaned Value and the components will be completely blank."},
]

class Currency extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <FAQBlock
                        title="Currency" icon={images.currencyIcon}
                        FAQs={currencyFAQ}
                        dataType
                    />
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default Currency