import React, {Component} from 'react'

class UploadBody extends Component {
    render() {
        return (
            <div>
                <br/>
                <h4 className="thinHeading">You can upload and clean any of these formats</h4>
                <br/>
                <table style={{marginBottom: "10px"}}>
                    <tbody>
                        <tr>
                            <td>
                                XLSX
                            </td>
                            <td>
                                XLS
                            </td>
                            <td>
                                CSV
                            </td>
                        </tr>
                        <tr>
                            <td>
                                TSV
                            </td>
                            <td>
                                TXT
                            </td>
                            <td>
                                XLSB
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default UploadBody