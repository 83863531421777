import React, {Component} from 'react'

class EmailExample extends Component {
    render() {
        let pro = 'pro' in this.props;

        if (pro){
            pro = this.props.pro
        }

        return (
            <div>
                <table className="table table-sm table-bordered">
                    <thead>
                    <tr>
                        {'faq' in this.props ? <th>Your Value</th> : <th>Input</th> }
                        {'faq' in this.props ? <th>Cleaned Value</th> : null }
                        <th>username</th>
                        <th>domain</th>
                        {pro ? <th>valid</th> : null}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>joecool@gmail,,,com</td>
                        {'faq' in this.props ? <th>joecool@gmail.com</th> : null }
                        <td>joecool</td>
                        <td>gmail.com</td>
                        {pro ? <td>VALID</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>first.last@somecompany.org</td>
                        {'faq' in this.props ? <th>first.last@somecompany.org</th> : null }
                        <td>first.last</td>
                        <td>somecompany.org</td>
                        {pro ? <td>VALID (ACCEPT ALL)</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>khaleesi@redkeep.c</td>
                        {'faq' in this.props ? <th>khaleesi@redkeep.com</th> : null }
                        <td>khaleesi</td>
                        <td>redkeep.com</td>
                        {pro ? <td>INVALID</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>monkey bananas</td>
                        {'faq' in this.props ? <th>INVALID</th> : null }
                        <td> </td>
                        <td> </td>
                        {pro ? <td>INVALID</td> : null}
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default EmailExample