import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Dropdown } from 'semantic-ui-react'
import {modifyEditOptions} from "../../../../../Actions/ModifyEditOptions";

class EditOptions extends Component {
    constructor(props) {
        super(props);

        this.changeReduxEditOptions = this.changeReduxEditOptions.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.error = this.error.bind(this);
        this.changeDelimiter = this.changeDelimiter.bind(this);
    }

    changeReduxEditOptions(state, value){
        const copyEditOptions = JSON.parse(JSON.stringify(this.props.editOptions));

        if (typeof state === "string"){
            copyEditOptions[state] = value;
        }
        else{
            for(let i=0; i<state.length; i++){
                const name = state[i];
                copyEditOptions[name] = value[i]
            }
        }

        this.props.modifyEditOptions(copyEditOptions);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "startRow" || name === "skipRows"){
            const error = this.error(name, value);

            if (error){
                this.changeReduxEditOptions([name, name + "Error"], [value, true]);
            }
            else{
                this.changeReduxEditOptions([name, name + "Error"], [value, false]);
            }
        }
        else{
            this.changeReduxEditOptions(name, value);
        }

    }

    error(name, value){
        let error = true;

        if (name === "startRow"){
            if (value.trim() === ""){
                error = false
            }
            else if (!isNaN(value)){
                if (parseInt(value) >= 0){
                    error = false;
                }
            }
        }

        if (name === "skipRows"){
            const strip = value.split(',');
            const trimmed = strip.map(x=> x.trim());

            let numberCount = 0;

            for (let i=0; i<trimmed.length; i++){
                let num = trimmed[i];

                if (!isNaN(num)){
                    if (parseInt(num) >= 0){
                        numberCount += 1
                    }
                }
            }

            if (numberCount === trimmed.length){
                error = false
            }

            if (value.trim() === ""){
                error = false
            }
        }

        return error
    }

    changeDelimiter(event, {value}){
        this.changeReduxEditOptions("delimiter", value)
    }

    render() {
        const editOptions = this.props.editOptions;
        const delimiterValue = editOptions.delimiter;

        const delimiterOptions = [
            {key: 1, text: 'Comma (,)', value: ','},
            {key: 2, text: 'Semi-Colon (;)', value: ';'},
            {key: 3, text: 'Pipe (|)', value: '|'},
            {key: 4, text: 'Tab', value: 'tab'},
            {key: 5, text: 'Space ( )', value: 'space'},
        ];

        const delimiterInput = this.props.isCSV ?
            <div>
                <h5 className="thinHeading">Delimiter</h5>
                <Dropdown
                    placeholder="Select Delimiter"
                    name="delimiter"
                    value={delimiterValue}
                    options={delimiterOptions}
                    onChange={this.changeDelimiter}
                    selection
                />
            </div> : null;

        const startRowError = editOptions.startRowError;
        const startRowErrorMessage = startRowError ?
            <small style={{color: 'red'}}>Please enter only a single number</small> : null;

        const headerValue = editOptions.header;
        const startRowValue = editOptions.startRow;

        return (
            <div id="editOptionsDiv">
                <div className="editOptionDiv">
                    <input style={{fontSize: '150%'}} type="checkbox" name="header" id="header" onChange={this.handleInputChange} checked={headerValue} />
                    <label htmlFor="header">
                        <span className="thinHeading" style={{cursor: 'pointer', fontSize: '125%', paddingTop: '2px', paddingLeft: '7px'}}>Does File Have a Header?</span>
                    </label>
                </div>
                <div className="editOptionDiv">
                    <h5 className="thinHeading">Start at Row</h5>
                    <Input onChange={this.handleInputChange} name="startRow" error={startRowError} placeholder="3" value={startRowValue} />
                    <br/>
                    {startRowErrorMessage}
                </div>
                <div className="editOptionDiv">
                    {delimiterInput}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    editOptions: state.mainState.editOptions
})

const mapActionsToProps = {
    modifyEditOptions: modifyEditOptions
}

export default connect(mapStateToProps, mapActionsToProps)(EditOptions)