import React, {Component} from 'react'
import APIMenu from "./APIMenu";
import APIDisplayIndex from './APIDisplay/APIDisplayIndex'
import API_Root from "../../Constants/API_Root";

const Scroll = require('react-scroll');
const scroller = Scroll.animateScroll;

class APIDocsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiSection: 'introduction',
            clicked: false,
            apiKey: null
        }

        this.changeAPISection = this.changeAPISection.bind(this);
        this.changeAPISectionScroll = this.changeAPISectionScroll.bind(this);
        this.scrollWhenMenuClicked = this.scrollWhenMenuClicked.bind(this);
    }

    componentDidMount(){
        // document.title = "API Docs - Clean Spreadsheets"

        if (localStorage.getItem("email") !== null){
            fetch(API_Root + 'api/get-api-keys-woyera/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                })
            }).then(res => res.json())
                .then((data) => {
                    if (data.apiKeys !== null){
                        this.setState({
                            apiKey: data.apiKeys.accessKey
                        });
                    }
                }).catch(err => console.log(err))
        }
    }

    changeAPISection(apiSection){
        this.setState({
            clicked: true
        }, () => {
            const d = document.getElementById(apiSection + "-docs-segment");

            if (d !== null && d !== undefined){

                const scrollWhenClicked = this.scrollWhenMenuClicked(d.offsetTop);
                scrollWhenClicked.then(
                    (success) => {
                        if (success){
                            this.setState({ clicked: false, apiSection: apiSection })
                        }
                    }
                )
            }
        })
    }

    changeAPISectionScroll(apiSection){
        this.setState({
            apiSection: apiSection
        })
    }

    scrollWhenMenuClicked(scrollHeight){
        return new Promise((resolve, reject) => {
            let updated;
            try{
                scroller.scrollTo(scrollHeight)
                updated = true;
            }
            catch(error){
                updated = false;
            }

            resolve(updated)
        })
    }

    render() {
        return (
            <div className="apiDocsIndex">
                <APIMenu
                    apiSection={this.state.apiSection}
                    changeAPISection={this.changeAPISection}
                />
                <APIDisplayIndex
                    apiSection={this.state.apiSection}
                    changeAPISection={this.changeAPISection}
                    changeAPISectionScroll={this.changeAPISectionScroll}
                    clicked={this.state.clicked}
                    apiKey={this.state.apiKey}
                />
            </div>
        )
    }
}

export default APIDocsIndex