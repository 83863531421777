import React, {Component} from 'react'
import { connect } from 'react-redux'
import API_Root from "../../../Constants/API_Root";
import constants from "../../../Constants/constants";
import UploadIndicator from "./Indicators/UploadIndicator";
import {modifyFilePK} from "../../../Actions/ModifyFilePK";
import {modifyStatus} from "../../../Actions/ModifyStatus";
import ReadingIndicator from './Indicators/ReadingIndicator'
import {modifyFileInformation} from "../../../Actions/ModifyFileInformation";
import {modifySheetRead} from "../../../Actions/ModifySheetRead";
import FileSampleTable from '../FileSamples/FileSampleTable';
import EditFileToolbar from './EditFileToolbar/EditFileToolbar';
import {resetStateAfterUpload} from "../../../Actions/ResetStateAfterUpload";
import {modifyStepsCompleted} from "../../../Actions/ModifyStepsCompleted";
import {toggleToast} from "../../../Actions/ToggleToast";
import {modifyColumnCleanTypes} from "../../../Actions/ModifyColumnCleanTypes";
import DataTypeToolbar from "./DataTypeToolbar";
import FileInfoBox from "./FileInfoBox"
import {modifyColumnCleanInfo} from "../../../Actions/ModifyColumnCleanInfo";
import {resetDataCleaning} from "../../../Actions/ResetDataCleaning";
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../Actions/ModifyModalInfo";
import WelcomeMessage from "./WelcomeMessage";
import TutorialPopup from "./Tutorial/TutorialPopup";
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";
import {toggleShowTutorial} from "../../../Actions/ToggleShowTutorial";

class UploadFilesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            readingStatus: "upload"
        };

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);

        this.fileInfoRef = React.createRef()
        this.dataTypeToolbarRef = React.createRef()
        this.editFileToolbarRef = React.createRef()
    }

    handleFileUpload(e){
        this.setState({readingStatus: "reading"})
        const files = e.target.files;

        const formData = new FormData();

        const errorToast = () => this.setState({readingStatus: "upload"}, () => this.props.toggleToast({show: true, message: "There was a problem reading your file. Please try again. Contact our Support if the error persists!", type: "error"}))

        if (files.length > 0){
            for (let i=0; i<files.length; i++){
                formData.append("file" + i.toString(), files[i]);
            }

            formData.append("email", localStorage.getItem("email"));
            formData.append("token", localStorage.getItem("token"));

            fetch(API_Root + 'api/save-clean-file/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: formData
            }).then(res => res.json())
                .then(data => {
                    if (!data.error){
                        // Modify the File Primary Key
                        let filePK = data.filePK;

                        this.setState({readingStatus: "saved"})
                        this.props.modifyFilePK(filePK);

                        // Open up WebSocket when files are saved
                        this.getStatusAndResults(data.filePK);
                    }
                    else{
                        errorToast()
                    }
                }).catch(err => errorToast()).catch(err => errorToast());
        }
    }

    executeScroll = (ref) => ref.current.scrollIntoView()

    scrollToDataType = () => {
        this.executeScroll(this.dataTypeToolbarRef)
    }

    getStatusAndResults(filePK){
        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-clean-file/");

        const props = this.props;

        // Send the PKs and if any column names
        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                filePK: filePK,
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "read"){
                let fileInformation = reply.fileInformation;
                let sheetRead = reply.sheetRead;

                this.setState({readingStatus: "read"})

                props.resetStateAfterUpload();
                // props.resetDataCleaning();
                props.modifyColumnCleanInfo(fileInformation.columnNames.map((x, idx) => null))
                props.modifyColumnCleanTypes(fileInformation.columnNames.map((x, idx) => ""))
                props.modifyFileInformation(fileInformation);
                props.modifySheetRead(sheetRead);

                try {
                    props.modifyTutorialStep(1)
                    props.toggleShowTutorial(true)
                    this.executeScroll(this.fileInfoRef)
                } catch {

                }

                socket.close()
            }

            if (status === "error"){
                this.setState({readingStatus: "upload"}, () => this.props.toggleToast({show: true, message: "There was a problem reading your file. Please try again. Contact our Support if the error persists!", type: "error"}))
                socket.close()
            }
        };
    }

    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        // Components for what status the job is in
        const indicators = {
            upload: <UploadIndicator type="start"/>,
            reading: <ReadingIndicator percentage={0} />,
            saved: <ReadingIndicator percentage={45} />,
            read: <UploadIndicator type="end"/>
        };

        const rowCount = 100;

        const uploadBody =
            <div id="uploadFilesBlock">
                <input
                    type="file"
                    name="inputFileUpload"
                    id="inputFileUpload"
                    className="form-control-file"
                    onChange={this.handleFileUpload}
                    style={inputFileStyle}
                    multiple={false}
                    accept={acceptExtensions}
                />
                <div className="uploadOuterSection">
                    <label className="uploadInnerSection" htmlFor="inputFileUpload">
                        {indicators[this.state.readingStatus]}
                    </label>
                </div>
            </div>

        let fileSampleTable = null;

        if ('samples' in this.props.fileInformation){
            fileSampleTable = <FileSampleTable rowCount={rowCount}
                                               viz={true}
                                               selectColumn={false} />
        }

        if (this.props.isUserNew){
            let dataTypeStyle = {display: "none"};
            let editFileStyle = {display: "none"};

            if (this.props.tutorialStep > 1){
                dataTypeStyle = {display: "block"}
            }

            if (this.props.tutorialStep > 2){
                editFileStyle = {display: "block"}
            }

            return (
                <div className="dashboardDisplayIndex">
                    {this.props.tutorialStep === 0 ? <WelcomeMessage/> : null}
                    <TutorialPopup />
                    {uploadBody}
                    <div>
                        <div ref={this.fileInfoRef}>
                            <FileInfoBox rowCount={rowCount} isUserNew={true} scrollToDataType={this.scrollToDataType}/>
                        </div>
                        <div ref={this.dataTypeToolbarRef} style={dataTypeStyle}>
                            <DataTypeToolbar />
                        </div>
                        <div ref={this.editFileToolbarRef} style={editFileStyle}>
                            <EditFileToolbar rowCount={rowCount} />
                        </div>
                        {fileSampleTable}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="dashboardDisplayIndex">
                    {uploadBody}
                    <div>
                        <div ref={this.fileInfoRef}>
                            <FileInfoBox rowCount={rowCount} isUserNew={false}/>
                        </div>
                        <DataTypeToolbar />
                        <EditFileToolbar rowCount={rowCount} />
                        {fileSampleTable}
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    status: state.mainState.status,
    sheetRead: state.mainState.sheetRead,
    userCredits: state.mainState.userCredits,
    isUserNew: state.mainState.isUserNew,
    tutorialStep: state.mainState.tutorialStep
});

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    modifyStatus: modifyStatus,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    resetStateAfterUpload: resetStateAfterUpload,
    modifyStepsCompleted: modifyStepsCompleted,
    toggleToast: toggleToast,
    modifyColumnCleanTypes: modifyColumnCleanTypes,
    modifyColumnCleanInfo: modifyColumnCleanInfo,
    resetDataCleaning: resetDataCleaning,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyTutorialStep: modifyTutorialStep,
    toggleShowTutorial: toggleShowTutorial
};

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesIndex)