import React, {Component} from 'react'
import images from "../../../Constants/Images";
import { Image, Menu, Segment, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import CodeSnippetBody from './CodeSnippetBody'
import capitalizeHyphenated from "../../../Constants/capitalizeHyphenated";

class CodeSnippetsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cleanType: "name",
            guide: "python"
        }

        this.changeMenuItem = this.changeMenuItem.bind(this);
        this.changeGuide = this.changeGuide.bind(this);

    }

    changeGuide(e, { name }){
        this.setState({ guide: name })
    }


    changeMenuItem(e, {name}){
        this.setState({
            cleanType: name
        })
    }

    render() {
        const firstRow = ["name", "address", "date", "company"];
        const secondRow = ["notes", "currency", "phone", "email"]
        const menuItems = firstRow.map((x, idx) =>
            <Menu.Item name={x} active={this.state.cleanType === x} key={idx} onClick={this.changeMenuItem}>
                <Image src={images[x + "Icon"]} avatar />
                <span>{capitalizeHyphenated(x)}</span>
            </Menu.Item>);

        const menuItems2 = secondRow.map((x, idx) =>
            <Menu.Item name={x} active={this.state.cleanType === x} key={idx} onClick={this.changeMenuItem}>
                <Image src={images[x + "Icon"]} avatar />
                <span>{capitalizeHyphenated(x)}</span>
            </Menu.Item>);

        const apiKey = this.props.apiKeys === null ? "YOUR_API_KEY" : this.props.apiKeys.accessKey;

        return (
            <div>
                <Grid>
                    <Grid.Column width={4}>
                        <Menu fluid vertical tabular>
                            <Menu.Item
                                name='python'
                                active={this.state.guide === 'python'}
                                onClick={this.changeGuide}
                            >
                                <Image src={images["pythonLogo"]} avatar />
                                <span>Python</span>
                            </Menu.Item>
                            {/*<Menu.Item*/}
                                {/*name='r'*/}
                                {/*active={this.state.guide === 'r'}*/}
                                {/*onClick={this.changeGuide}*/}
                            {/*>*/}
                                {/*<Image src={images["rLogo"]} avatar />*/}
                                {/*<span>R</span>*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item
                                name='javascript'
                                active={this.state.guide === 'javascript'}
                                onClick={this.changeGuide}
                            >
                                <Image src={images["javascriptLogo"]} avatar />
                                <span>Javascript</span>
                            </Menu.Item>
                            <Menu.Item
                                name='php'
                                active={this.state.guide === 'php'}
                                onClick={this.changeGuide}
                            >
                                <Image src={images["phpLogo"]} avatar />
                                <span>PHP</span>
                            </Menu.Item>
                            <Menu.Item
                                name='ruby'
                                active={this.state.guide === 'ruby'}
                                onClick={this.changeGuide}
                            >
                                <Image src={images["rubyLogo"]} avatar />
                                <span>Ruby</span>
                            </Menu.Item>
                            <Menu.Item
                                name='cURL'
                                active={this.state.guide === 'cURL'}
                                onClick={this.changeGuide}
                            >
                                <Image src={images["cURLLogo"]} avatar />
                                <span>cURL</span>
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                    <Grid.Column stretched width={12}>
                        <Menu attached='top' tabular>
                            {menuItems}
                        </Menu>
                        <Menu attached='top' tabular>
                            {menuItems2}
                        </Menu>
                        <Segment attached='bottom'>
                            <CodeSnippetBody apiKey={apiKey} cleanType={this.state.cleanType} guide={this.state.guide}/>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    apiKeys: state.mainState.apiKeys
})

export default connect(mapStateToProps, null)(CodeSnippetsIndex)