import React, {Component} from 'react'

class LandingPagePricingCard extends Component {
    render() {
        return (
            <div className="landingPagePricingCard">
                <img style={{width: "50%"}} src={this.props.img} alt={this.props.imgAlt}/>
                <br/><br/>
                <h4 style={{fontWeight: "900"}}>{this.props.desc}</h4>
            </div>
        )
    }
}

export default LandingPagePricingCard