import React, {Component} from 'react'
import {connect} from 'react-redux'
import AccessKey from "./AccessKey"
import CodeSnippetsIndex from "./Docs/CodeSnippets/CodeSnippetsIndex";
import { Icon } from 'semantic-ui-react'
import SummaryGraph from "./Analytics/SummaryGraph"
import APIGuidesIndex from "./APIGuidesIndex"
import APINewUserToolbar from "./APINewUserToolbar";

class APIBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuItem: "user",
            summaryGraph: {
                cleanType: ['name', 'address', 'company', 'email', 'date', 'phone', 'notes', 'currency'],
                statusCode: [200, 400, 402, 403, 404, 405, 500]
            },
            isNewUser: false
        }

        this.getMenuClassName = this.getMenuClassName.bind(this);
        this.changeMenuItem = this.changeMenuItem.bind(this);
        this.modifySummaryGraphFilters = this.modifySummaryGraphFilters.bind(this);
        this.toggleIsNewUser = this.toggleIsNewUser.bind(this);
    }

    getMenuClassName(menuItem){
        let menuClassName = "apiDocsMenuItem"

        if (this.state.menuItem === menuItem){
            menuClassName = "apiDocsMenuItemSelected"
        }

        return menuClassName
    }

    changeMenuItem(menuItem){
        this.setState({ menuItem: menuItem })
    }

    modifySummaryGraphFilters(filterName, filterValues){
        let currFilters = JSON.parse(JSON.stringify(this.state.summaryGraph));

        currFilters[filterName] = filterValues

        this.setState({
            summaryGraph: currFilters
        })
    }

    toggleIsNewUser(isNewUser){
        this.setState({ isNewUser: isNewUser })
    }

    render() {
        const body = {
            user: <div>
                {
                    this.state.isNewUser ? <div style={{marginTop: "50px", marginBottom: "20px"}}>
                        <APINewUserToolbar toggleIsNewUser={this.toggleIsNewUser} changeMenuItem={this.changeMenuItem}/>
                    </div> : null
                }
                <div style={{marginTop: "50px", marginBottom: "20px"}}>
                    <AccessKey />
                </div>
                <hr/>
                <div style={{marginTop: "100px", marginBottom: "150px"}}>
                    <SummaryGraph
                        filters={this.state.summaryGraph}
                        modifyFilters={this.modifySummaryGraphFilters}
                        pageLocation="home"
                        toggleIsNewUser={this.toggleIsNewUser}
                    />
                </div>
            </div>,
            code: <div style={{marginTop: "50px", marginBottom: "150px"}}>
                <h2 className="thinHeading">Quickstart Code Samples</h2>
                <br/>
                <CodeSnippetsIndex />
            </div>,
            guides: <div style={{marginTop: "50px", marginBottom: "150px"}}>
                <APIGuidesIndex />
            </div>
        }

        return (
            <div className="row">
                <div className='col-sm-2' style={{borderRight: "1px solid lightgrey",
                    boxShadow: "2px 2px 5px grey",
                    padding: "50px 25px 35px 25px",
                    overflow: "auto",
                    minHeight: '100vh'}}>
                    <p className={this.getMenuClassName("user")} onClick={(e) => this.changeMenuItem("user")}>
                        <Icon name="user" /> Your Info
                    </p>
                    <p className={this.getMenuClassName("code")} onClick={(e) => this.changeMenuItem("code")}>
                        <Icon name="code" /> Code Samples
                    </p>
                    <p className={this.getMenuClassName("guides")} onClick={(e) => this.changeMenuItem("guides")}>
                        <Icon name="book" /> Quickstart Guides
                    </p>
                    <div style={{marginTop: "50px", fontSize: "110%", fontWeight: "600"}}><a href="/api-docs" target="_blank" rel="noopener noreferrer">Full API Docs</a></div>
                </div>
                <div className="col-sm-1" />
                <div className="col-sm-8">
                    {body[this.state.menuItem]}
                </div>
                <div className='col-sm-1' />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APIBody)