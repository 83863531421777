import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon, Button, Popup } from 'semantic-ui-react'
import Profile from "./Profile.svg"
import PasswordForget from "../../Authentication/UserPages/Password/PasswordForget";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";


class AccountIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            copied: false,
            refURL: "https://www.cleanspreadsheets.com/signup/?promo=" + localStorage.getItem("email")
        }

        this.copyReferralLink = this.copyReferralLink.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.emailModal = this.emailModal.bind(this);
    }

    copyReferralLink(e){
        const el = this.referralURL
        el.select()
        document.execCommand("copy")

        this.setState({ copied: true })

        setTimeout(() => this.setState({ copied: false}), 2000);
    }

    closePopup(e){
        this.setState({ copied: false })
    }

    emailModal(){
        this.props.modifyModalInfo({ promoLink: this.state.refURL })
        this.props.toggleModal("emailPromoModal")
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div id="fileInfoDiv">
                        <h2><Icon name="user" /> Your Profile</h2>
                        <br/>
                        <div className="row">
                            <div className="col-sm-2">
                                <h5 className="text-muted">Email</h5>
                            </div>
                            <div className="col-sm-10">
                                <h5 className="thinHeading">{localStorage.getItem("email")}</h5>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h5 className="text-muted">Referral Link
                                    <span style={{paddingLeft: '15px'}}>
                                        <Popup
                                            trigger={<Button content="Copy Link" icon="copy" color="blue" size="mini" onClick={this.copyReferralLink} basic/>}
                                            content={'Copied!'}
                                            on='click'
                                            open={this.state.copied}
                                            onClose={this.closePopup}
                                            onOpen={this.handleOpen}
                                            position='top right'
                                        />
                                    </span>
                                    <span>
                                        <Button content="Email Link" icon="paper plane outline" color="green" size="mini" onClick={this.emailModal} basic/>
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <input style={{width: '100%', marginTop: '10px'}} readOnly={true} ref={(referralURL) => this.referralURL = referralURL} value={this.state.refURL} />
                                <small>Send this Sign Up link to a friend. If they sign up you both get 1500 credits!</small>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h5 className="text-muted" style={{color: 'red'}}>Cancel Your Account</h5>
                                <h5 className="thinHeading">
                                    Email us at <a href="mailto:cancel@cleanspreadsheets.com?Subject=Clean%20Spreadsheets%Cancel%20Account" style={{color: 'red'}}> cancel@cleanspreadsheets.com</a> from your account email.
                                </h5>
                            </div>
                        </div>
                        <br/>
                        <PasswordForget/>
                        <br/>
                    </div>
                </div>
                <div className="col-sm-6">
                    <img src={Profile} alt="Profile Illustration" style={{width: '100%'}}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(AccountIndex)