import React, {Component} from 'react'
import { Icon, Table } from 'semantic-ui-react'

class GeocodingColumn extends Component {
    render() {
        const highlightCell = {backgroundColor: 'lightgreen'}
        const errorCell = {backgroundColor: 'red', color: 'white'};

        return (
            <div>
                <h1>...entirely in one column <Icon name="check" style={{color: 'green'}}/></h1>
                <p>Your address values are entirely within one column</p>
                <Table celled compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Address</Table.HeaderCell>
                            <Table.HeaderCell>Customer Name</Table.HeaderCell>
                            <Table.HeaderCell>Date Joined</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>415 W 52nd Street, New York, NY 10019, USA</Table.Cell>
                            <Table.Cell>Anderson Cooper</Table.Cell>
                            <Table.Cell>June 14, 2010</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>34 Rue des Rosiers, 75004 Paris, France</Table.Cell>
                            <Table.Cell>Marion Cotillard</Table.Cell>
                            <Table.Cell>2018-04-15</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>Rua Senador Vergueiro Flamengo 11, Flamengo, Rio de Janeiro - RJ, 22230-000, Brazil</Table.Cell>
                            <Table.Cell>Giselle Bundchen</Table.Cell>
                            <Table.Cell>January 1, 2019</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <br/><br/>
                <h1>...split across columns <Icon name="check" style={{color: 'green'}}/></h1>
                <p>Your address components are split across columns. We don't need all components to Geocode but enough to identify the location. This would be the ideal address: <b>Number, Street1/Address1, Street2/Address2, City, State/Region/Province, Postal/Zip Code, Country</b></p>
                <Table celled compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Address1</Table.HeaderCell>
                            <Table.HeaderCell>City (Region)</Table.HeaderCell>
                            <Table.HeaderCell>Postal Code</Table.HeaderCell>
                            <Table.HeaderCell>Customer Name</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>415 W 52nd Street</Table.Cell>
                            <Table.Cell style={highlightCell}>New York, NY</Table.Cell>
                            <Table.Cell style={highlightCell}>10019</Table.Cell>
                            <Table.Cell>Anderson Cooper</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>34 Rue des Rosiers</Table.Cell>
                            <Table.Cell style={highlightCell}>Paris</Table.Cell>
                            <Table.Cell style={highlightCell}>75004</Table.Cell>
                            <Table.Cell>Marion Cotillard</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>Rua Senador Vergueiro Flamengo 11</Table.Cell>
                            <Table.Cell style={highlightCell}>Flamengo, Rio de Janeiro</Table.Cell>
                            <Table.Cell style={highlightCell}>22230-000</Table.Cell>
                            <Table.Cell>Giselle Bundchen</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <br/><br/>
                <h1>...entirely in one column <Icon name="check" style={{color: 'green'}}/></h1>
                <p>You have multiple addresses but they are each in their own 1 column</p>
                <Table celled compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Home Address</Table.HeaderCell>
                            <Table.HeaderCell>Customer Name</Table.HeaderCell>
                            <Table.HeaderCell>Work Address</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>415 W 52nd Street, New York, NY 10019, USA</Table.Cell>
                            <Table.Cell>Anderson Cooper</Table.Cell>
                            <Table.Cell style={highlightCell}>151 W 42nd St, New York, NY 10036, USA</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>34 Rue des Rosiers, 75004 Paris, France</Table.Cell>
                            <Table.Cell>Marion Cotillard</Table.Cell>
                            <Table.Cell style={highlightCell}>Infanteriestr. 11A, Haus C 80797 Munich, Germany</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={highlightCell}>Rua Senador Vergueiro Flamengo 11, Flamengo, Rio de Janeiro - RJ, 22230-000, Brazil</Table.Cell>
                            <Table.Cell>Giselle Bundchen</Table.Cell>
                            <Table.Cell style={highlightCell}>CEO - Av. João Cabral de Mello Neto, 850 - Bloco 2, Sala 1629 - Barra da Tijuca, Rio de Janeiro - RJ, 22775-057, Brazil</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <br/><br/>
                <h1>...entirely in one column AND ...split across columns <Icon name="x" style={{color: 'red'}}/></h1>
                <p>THIS IS NOT ALLOWED</p>
                <Table celled compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Address</Table.HeaderCell>
                            <Table.HeaderCell>Customer Name</Table.HeaderCell>
                            <Table.HeaderCell>Street</Table.HeaderCell>
                            <Table.HeaderCell>City (Region) Zip</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={errorCell}>415 W 52nd Street, New York, NY 10019, USA</Table.Cell>
                            <Table.Cell>Anderson Cooper</Table.Cell>
                            <Table.Cell style={errorCell}>415 W 52nd Street</Table.Cell>
                            <Table.Cell style={errorCell}>New York, NY 10019, USA</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={errorCell}>34 Rue des Rosiers, 75004 Paris, France</Table.Cell>
                            <Table.Cell>Marion Cotillard</Table.Cell>
                            <Table.Cell style={errorCell}>34 Rue des Rosiers</Table.Cell>
                            <Table.Cell style={errorCell}>75004 Paris, France</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={errorCell}>Rua Senador Vergueiro Flamengo 11, Flamengo, Rio de Janeiro - RJ, 22230-000, Brazil</Table.Cell>
                            <Table.Cell>Giselle Bundchen</Table.Cell>
                            <Table.Cell style={errorCell}>34 Rue des Rosiers</Table.Cell>
                            <Table.Cell style={errorCell}>75004 Paris, France</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <br/><br/><br/><br/><br/><br/>
            </div>
        )
    }
}

export default GeocodingColumn