import React, {Component} from 'react'

class BenefitsCard extends Component {
    render() {
        return (
            <div style={{textAlign: "center", width: "75%", float: "right", marginBottom: "20px", marginRight: "50px", border: "0.5pt solid #006837", padding: "25px", borderRadius: "2px"}}>
                {/*<h2 className="thinHeading"><img src={this.props.imgSrc} style={{width: "50px", marginRight: '10px'}} alt={this.props.header + " Icon"}/> {this.props.header}</h2>*/}
                {/*<p style={{fontSize: "115%"}}>{this.props.desc}</p>*/}
                <h5 style={{color: "#006837"}}><img src={this.props.imgSrc} style={{width: "50px"}} alt={this.props.header + " Icon"}/> <b>{this.props.header}</b></h5>
                <h5 className="thinHeading">{this.props.desc}</h5>
            </div>
        )
    }
}

export default BenefitsCard