import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Popup, Form, TextArea, Button, Message, Icon} from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root"
import AuthComponent from "../AuthComponent"

class NavbarFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feedbackText: '',
            popupOpen: false,
            sending: false,
            sendError: null
        }

        this.togglePopup = this.togglePopup.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.storeFeedback = this.storeFeedback.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    togglePopup(e, toggle){
        this.setState({
            popupOpen: toggle
        })
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    dismissMessage(e){
        this.setState({
            sendError: null
        })
    }

    storeFeedback(e){
        this.setState({ sending: true });

        const errorMessage = <Message
            compact
            onDismiss={this.dismissMessage}
            content={<span>There was a problem sending your feedback. Try again or
                                <a href="mailto:info@cleanspreadsheets.com?Subject=Feedback">
                                email us</a>
                            </span>}
            negative
        />;

        fetch(API_Root + 'api/submit-feedback/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                feedback: this.state.feedbackText
            })
        }).then(res => {
            if (res.status === 201){
                this.setState({
                    sending: false,
                    sendError: <Message
                        compact
                        onDismiss={this.dismissMessage}
                        content="Thank your for feedback. We love reading what you have to say!"
                        positive
                    />
                })
            }
            else{
                this.setState({
                    sending: false,
                    sendError: errorMessage
                })
            }
        }).catch(err => this.setState({
                sending: false,
                sendError: errorMessage }))
    }

    render() {
        let marginTop = '6px';
        let fontSize = '115%';

        const style = {color: 'gray', cursor: 'pointer', textDecoration: 'none', fontSize: fontSize, marginTop: marginTop}

        return (
            <Popup
                trigger={<span style={style}><AuthComponent authComponent={<Icon name="sticky note outline" />} nonAuthComponent={null}/>Feedback</span>}
                flowing
                on='click'
                open={this.state.popupOpen}
                onOpen={(e) => this.togglePopup(e, true)}
                onClose={(e) => this.togglePopup(e, false)}
                style={{backgroundColor: 'whitesmoke'}}
            >
                <div id="feedbackDiv segment segment-raised">
                    <h6 className="thinHeading"><Icon name="sticky note" /> Give Us Feedback</h6>
                    <Form>
                        <TextArea
                            value={this.state.feedbackText}
                            onChange={this.handleInputChange}
                            name="feedbackText"
                            id="feedbackDiv"
                            style={{backgroundColor: 'white'}}
                            rows={6}
                            placeholder="What I like or dislike most about Clean Spreadsheets is...."
                        />
                    </Form>
                    <div className="feedbackButtons">
                        <Button size="tiny" basic floated="left" onClick={(e) => this.togglePopup(e, false)}>
                            Cancel
                        </Button>
                        <Button size="tiny" basic color="green" floated="right" onClick={this.storeFeedback} loading={this.state.sending}>
                            Send
                        </Button>
                    </div>
                    <div className="feedbackResponseMessage">
                        <br/>
                        {this.state.sendError}
                    </div>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(NavbarFeedback)
