import React, {Component} from 'react'
import { Segment } from 'semantic-ui-react'
import SignUpForm from "./SignUpForm"
import {push} from "react-router-redux";
import { connect } from 'react-redux';

class SignUpIndex extends Component {
    constructor(props){
        super(props);

        this.login = this.login.bind(this);
    }

    login(){
        this.props.navigateTo("/login")
    }

    render() {
        return (
            <div className="row" style={{backgroundColor: '#0E4D92', minHeight: '100vh'}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <br/><br/><br/>
                    <Segment raised>
                        <div>
                            <SignUpForm />
                            <br/>
                            <p style={{textAlign: 'center'}} onClick={this.login} className="linkStyle">I Already Have An Account! Let me Log In</p>
                            <br/>
                        </div>
                    </Segment>
                </div>
                <div className="col-sm-3" />
            </div>

        )
    }
}

const mapActionsToProps = {
    navigateTo: push
}

export default connect(null, mapActionsToProps)(SignUpIndex)