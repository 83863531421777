import React, {Component} from 'react'
import ClockIcon from "./Illustrations/ClockIcon.svg"
import ROIIcon from "./Illustrations/ROIIcon.svg"
import MarketingIcon from "./Illustrations/MarketingIcon.svg"
import BenefitsCard from "./BenefitsCard";
import { isMobile } from "react-device-detect";

class Benefits extends Component {
    render() {
        return (
            <div className="row" style={{backgroundColor: "rgba(26, 113, 198, 0.12)", paddingTop: "30px", paddingBottom: "30px"}}>
                <div className="col-sm-6">
                    <h1 style={{color: "#006837", fontSize: "350%", textAlign: "center", marginTop: isMobile ? "20px" : "250px", fontWeight: "600"}}><i>Clean Spreadsheets will help you...</i></h1>
                </div>
                <div className="col-sm-6">
                    <BenefitsCard
                        imgSrc={MarketingIcon}
                        header="Improve Marketing"
                        desc="Target prospects, customers and leads much more accurately with cleaned addresses, emails and phone numbers"
                    />
                    <BenefitsCard
                        imgSrc={ROIIcon}
                        header="Increase ROI"
                        desc="Deliver significant results with your Data & Analytics projects with cleaned and standardized data"
                    />
                    <BenefitsCard
                        imgSrc={ClockIcon}
                        header="Save Money & Time"
                        desc="No need to shell out a ton of money for expensive Business Intelligence tools or programmers"
                    />
                </div>
            </div>
        )
    }
}

export default Benefits