import React, {Component} from 'react'

class ScheduleDemoButton extends Component {
    constructor(props) {
        super(props);

        this.goToSchedulePage = this.goToSchedulePage.bind(this);
    }

    goToSchedulePage(e){
        this.scheduleRef.click()
    }

    render() {
        const url = "https://calendly.com/love-spreadsheets-demos/30min";

        return (
            <span>
                <a
                    style={{display: 'none'}}
                    ref={scheduleRef => this.scheduleRef = scheduleRef}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >Download hidden</a>
                <button className="scheduleDemoButton" onClick={this.goToSchedulePage} style={'style' in this.props ? this.props.style : null}>
                    {"Request Demo".toUpperCase()}
                </button>
            </span>
        )
    }
}

export default ScheduleDemoButton