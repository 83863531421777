import React, {Component} from 'react'

class PhoneExample extends Component {
    render() {
        let pro = 'pro' in this.props;

        if (pro){
            pro = this.props.pro
        }

        return (
            <div>
                <table className="table table-sm table-bordered">
                    <thead>
                    <tr>
                        {'faq' in this.props ? <th>Your Value</th> : <th>Input</th> }
                        {'faq' in this.props ? <th>Cleaned Value</th> : null }
                        <th>country code</th>
                        <th>area code</th>
                        <th>number</th>
                        {pro ? <th>valid</th> : null}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>(212) 5551924</td>
                        {'faq' in this.props ? <td>+1-212-5551924</td> : null }
                        <td> </td>
                        <td>212</td>
                        <td>5551924</td>
                        {pro ? <td>INVALID</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>+91-98-55-155555</td>
                        {'faq' in this.props ? <td>+91-98-55155555</td> : null }
                        <td>+91</td>
                        <td>98</td>
                        <td>55155555</td>
                        {pro ? <td>VALID</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>82 045-888-6666</td>
                        {'faq' in this.props ? <td>+82-45-8886666</td> : null }
                        <td>+82</td>
                        <td>45</td>
                        <td>8886666</td>
                        {pro ? <td>VALID</td> : null}
                    </tr>
                    <tr>
                        <td style={{backgroundColor: 'mistyrose'}}>+45 33 15 32 86</td>
                        {'faq' in this.props ? <td>+45-33-153286</td> : null }
                        <td>+45</td>
                        <td>33</td>
                        <td>153286</td>
                        {pro ? <td>INVALID</td> : null}
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default PhoneExample