import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import { Icon } from 'semantic-ui-react'
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import { isMobile } from 'react-device-detect';

class ModalApp extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleESC = this.handleESC.bind(this);
        this.close = this.close.bind(this);
    }

    componentWillMount(){
        document.addEventListener('keydown', this.handleESC, false);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.handleESC, false);
    }

    close(){
        this.props.toggleModal(null);
        this.props.modifyModalInfo(null);
    }

    handleESC(e){
        if (!('noClose' in this.props)) {
            if (e.keyCode === 27) {
                this.close()
            }
        }
    }

    handleClick(e){
        if (!('noClose' in this.props)){
            if (e.target.id === "outerModal"){
                this.close()
            }
        }
    }

    render() {
        const modalStyle =
            {
                display: 'none', /* Hidden by default */
                position: 'fixed', /* Stay in place */
                zIndex: '99', /* Sit on top */
                left: '0',
                top: '0',
                width: '100%', /* Full width */
                height: '100%', /* Full height */
                overflow: 'auto', /* Enable scroll if needed */
                // backgroundColor: 'rgb(0,0,0)', /* Fallback color */
                backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
            };

        const open = this.props.modalName !== null && this.props.modalName === this.props.name;

        if (open){
            modalStyle.display = "block"
        }
        else{
            modalStyle.display = "none"
        }

        let footer = null;

        if ('footer' in this.props){
            footer =
                <div id="modalFooter">
                    {this.props.footer}
                </div>
        }

        let size = "large";

        if ('size' in this.props){
            size = this.props.size
        }

        if (isMobile){
            size = "full"
        }

        const sizeWidth =
            {
                small: '40%',
                medium: '60%',
                large: '80%',
                full: '100%'
            };

        const modalContent = {
            // marginTop: '5%',
            position: 'relative',
            backgroundColor: "bodyColor" in this.props ? this.props.bodyColor : '#fefefe',
            top: '13%',
            margin: 'auto',
            padding: '0',
            // border: '1px solid #888',
            borderRadius: '10px',
            width: sizeWidth[size],
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)',
            animationName: 'animatetop',
            animationDuration: '0.4s',
            marginBottom: '50px'
        };

        const loadingModalContent = {
            width: sizeWidth[size],
            position: 'relative',
            top: '25%',
            margin: 'auto',
            padding: '0',
            backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
        }

        const modalBody =
        {
            padding: '15px 16px',
            backgroundColor: "bodyColor" in this.props ? this.props.bodyColor : "white"
        };

        let close =
        <div className="closeIconClass" id="closeModalIcon" onClick={this.close}>
            <Icon name="remove" />
        </div>;

        if ('noClose' in this.props){
            close = null;
        }

        if (open){
            let modalContentStyle = modalContent;
            let modalBodyStyle = modalBody;

            if (this.props.name.includes("loadingModal")){
                modalContentStyle = loadingModalContent;
                modalBodyStyle = null;
            }

            return (
                <div style={modalStyle} id="outerModal" onClick={this.handleClick}>
                    <div style={modalContentStyle} id="modalContent">
                        <div style={modalBodyStyle} id="modalBody">
                            {
                                'noHeader' in this.props ?
                                    null :
                                    <div className="row" style={{ borderBottom: "1px solid lightgrey", paddingBottom: '5px', marginBottom: '10px'}}>
                                        <div className="col-sm-11">
                                            <h2 className="thinHeading"> {this.props.header}</h2>
                                        </div>
                                        <div className="col-sm-1">
                                            {close}
                                        </div>
                                    </div>
                            }
                            {this.props.body}
                        </div>
                        {footer}
                    </div>
                </div>
            )
        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    modalName: state.mainState.modalName
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(ModalApp)