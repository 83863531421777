import React, {Component} from 'react'
import ModalApp from '../../../../../SharedComponents/Modal'
import EditFileBody from './EditFileBody'
import {connect} from 'react-redux'
import getExtension from "../../../../../Constants/getExtension";

class EditModal extends Component {
    render() {
        const header = "Read File With These Options";

        if ("name" in this.props.fileInformation){
            const extension = getExtension(this.props.fileInformation.name);

            const size = extension.includes("xls") ? "medium" : "small";

            return (
                <ModalApp name="editModal"
                          header={header}
                          size={size}
                          body={<EditFileBody extension={extension}/>}
                />
            )
        }
        else{
            return(
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EditModal)