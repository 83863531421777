import React, {Component} from 'react'
import CommentSection from "../CommentSection";

class GeocodeQuickstart extends Component {
    render() {
        return (
            <div>
                <p><i>This tutorial will be going into how to get started with geocoding. If you want an in-dept tutorial on how to use Clean Spreadsheets, check out our guide <a href="/guides/clean-spreadsheets-quickstart"> Get Started Quickly With Clean Spreadsheets</a></i></p>
               <br/>
               <h1> What is Forward & Reverse Geocoding?</h1>
                <br/>
                <p> Before we begin, <b>Forward Geocoding</b> is when you start with an address or location and get latitude and longitude coordinates back.
                    <b> Reverse Geocoding</b> is when you start with latitude and longitude coordinates and ge an address or location back.</p>
                <hr/>
                <h1>Forward Geocoding Entirely In One Column</h1>
                <br/>
                <p>You can clean and geocode addresses in an entire column and more than one column at a time. In the file I uploaded, I have <i>Home Address</i> and <i>Work Address</i> to clean in my file. </p>
               <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/fwdGeoEntirePic1.png" style={{margin: '0 auto', display: 'block',  width:"100%"}}/>
                <br/><br/><br/>
                    <h2> <b>Step 1:</b> Select <b>Forward Geocoding</b> and <b>entirely in one column</b> </h2>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/fwd_oneColumn.png" style={{margin: '0 auto', display: 'block',  width:"80%"}}/>
                <br/><br/><br/>
                <h2> <b>Step 2:</b>  Choose what columns you want to clean </h2>
                <br/>
                <p>I'm cleaning both <i><b>Work Address</b></i> and <i><b>Home Address.</b></i> </p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoEntire2.png" style={{margin: '0 auto', display: 'block',  width:"110%"}}/>
                <br/><br/><br/>
                <h2> <b>Step 3:</b> Review Column Selection </h2>
                <br/>
                <p> Under <b>Your Cleaning Selection</b> you can see what columns you have selected and their datatype. You can remove the selection as well, but if all looks good, click on <b>Review & Clean.</b> </p>
                <br/>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoEntire3.png" style={{margin: '0 auto', display: 'block',  width:"70%"}}/>
                <br/><br/><br/><br/>

                <h2> <b>Step 4:</b>  Review Cleaned Sample & Clean Your Data</h2>
                <br/>
                <p> When you get to the <b>Clean Data</b> section, you can see how many credits you have in your account and a sample of your cleaned data.</p>
                <p> You can see a sample of all your columns. If everything looks good, click on <b>Clean Your Data.</b>  </p>
                <br/><br/>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoEntire4.png" style={{margin: '0 auto', display: 'block',  width:"100%"}}/>
                <br/><br/><br/>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoEntire5.png"  style={{margin: '0 auto', display: 'block',  width:"110%"}}/>
                <br/><br/><br/><br/>

                <h2> <b>Step 5:</b>  Download Your Cleaned File </h2>
                <br/>
                <p> You can download the whole file together by clicking <b>Download Your Cleaned File</b>. Or you can download individual columns by clicking <b>Download Your Cleaned Column.</b></p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoEntire6.png" style={{margin: '0 auto', display: 'block',  width:"90%"}}/>
                <br/>

                <br/><br/><br/>
                <h2> <b>Step 6:</b>  The Clean & Geocode Results</h2>
                <br/>
                <p>Here's a sample of how my downloaded file looks. The addresses in both my columns were cleaned and I got the geocoded results for them! </p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoEntire7.png"style={{margin: '0 auto', display: 'block',  width:"110%"}}/>
                <br/>
                <br/>
                <br/>
<hr/>
<br/>

                <h1>Forward Geocoding Split Across Columns</h1>
                <br/>
                    <p> This section is for when your address components are split across multiple columns, such as the file below. </p>
                    <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>
                    <br/><br/><br/>
                    <h2><b> Step 1: </b>Select <b>Forward Geocoding</b> and <b>split across columns</b></h2>
                    <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit2.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>
                    <br/><br/><br/>

                    <h2> <b>Step 2:</b>  Choose what columns you want to clean</h2>
                <br/>
                <p> An initial arrangement of the columns that form the address will be displayed here using our smart address parsing. You can drag and drop, and remove the columns you want to clean and form your address. I have removed my column <b>zip</b> and dragged over <b>region</b>. Click <b>Confirm Columns</b> when you're ready.</p>

                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit3.png" style={{margin: '0 auto', display: 'block',  width:"100%"}}/>
                <br/><br/><br/>

                <h2><b>Step 3:</b> Review Column Selection</h2>
                <br/>
                <p> Under <b>Your Cleaning Selection</b> you can see what columns you have selected and their datatype. You can remove the selection as well, but if all looks good, click on <b>Review & Clean.</b> </p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit4.png" style={{margin: '0 auto', display: 'block',  width:"80%"}}/>
                <br/><br/><br/>

                <h2> <b>Step 4:</b>  Review Cleaned Sample & Clean Your Data </h2>
                <br/>
                <p> When you get to the <b>Clean Data</b> section, you can see how many credits you have in your account and a sample of your cleaned data.</p>
                <p> You can see a sample of all your columns. If everything looks good, click on <b>Clean Your Data.</b>  </p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit5.png" style={{margin: '0 auto', display: 'block',  width:"100%"}}/>
                <br/>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit6.png" style={{margin: '0 auto', display: 'block',  width:"100%"}}/>
                <br/>
                <br/><br/>

                <h2> <b>Step 5:</b>  Download Your Cleaned File </h2>
                <br/>
                <p> You can download the whole file together by clicking <b>Download Your Cleaned File</b>. Or you can download individual columns by clicking <b>Download Your Cleaned Column.</b></p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit7.png" style={{margin: '0 auto', display: 'block',  width:"100%"}}/>
                <br/><br/><br/>

                <h2> <b>Step 6:</b>  The Clean & Geocode Results</h2>
                <br/>
                <p>Here's a sample of how my downloaded file looks. The top is when you download your cleaned columns individually and the bottom is when you download the whole file. </p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit8.png"style={{margin: '0 auto', display: 'block',  width:"140%"}}/>
                <br/>
                <br/>
                <br/>
<hr/>
<br/>

                {/*<h2>Reverse Geocoding Entirely In One Column</h2>*/}
                {/*    <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/reverseEntire.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>*/}
                {/*    <br/>*/}
                {/*    <p>If you want to reverse geocode you can select the columns you want to have latitude and longitude converted to an address. Click on "Review & Clean" when you're ready. </p>*/}
                {/*    <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/reverseEntire2.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>*/}
                {/*    <br/>*/}
                {/*    <p>You can review your file and check each column separately to review. Once you're ready, click on "Clean Your Data" to download it.</p>*/}
                {/*    <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/reverseEntire3.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>*/}
                {/*    <br/>*/}
                {/*    <p>You can download the columns seperately or the whole file together. As you can see, you will get the address of the coordinates you had on your file. </p>*/}
                <h1>Reverse Geocoding Split Across Columns</h1>
                    <br/>
                <p> I'm going to be using the same file as I did for forward geocoding split across columns.  </p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/FwdGeoSplit.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>
                <br/><br/><br/>
                <h2> <b>Step 1:</b> Select <b>Reverse Geocoding</b> and <b>split across columns</b></h2>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/RevGeoSplit1.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>
                <br/><br/><br/>

                <h2> <b>Step 2:</b>   Choose what columns you want to clean</h2>
                <p> You can select which column is your <b>Latitude</b> and which is your <b>Longitude</b>. My names for the respective columns are <b>lat</b> and <b>lng</b>. Click <b>Confirm Columns</b> when you're ready.</p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/RevGeoSplit2.png" style={{margin: '0 auto', display: 'block',  width:"100%"}}/>
                <br/><br/><br/>

                <h2><b>Step 3:</b> Review Column Selection </h2>
                <p> Under <b>Your Cleaning Selection</b> you can see what columns you have selected and their datatype. You can remove the selection as well, but if all looks good, click on <b>Review & Clean.</b> </p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/RevGeoSplit3.png" style={{margin: '0 auto', display: 'block',  width:"80%"}}/>
                <br/><br/><br/>

                <h2><b>Step 4:</b>  Review Cleaned Sample & Clean Your Data </h2>
                <br/>
                <p> When you get to the <b>Clean Data</b> section, you can see how many credits you have in your account and a sample of your cleaned data.</p>
                <p> You can see a sample of all your columns. If everything looks good, click on <b>Clean Your Data.</b>  </p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/RevGeoSplit4.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>
                <br/><br/><br/>

                <h2> <b>Step 5:</b>  Download Your Cleaned File </h2>
                <br/>
                <p> You can download the whole file together by clicking <b>Download Your Cleaned File</b>. Or you can download individual columns by clicking <b>Download Your Cleaned Column.</b></p>
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/RevGeoSplit5.png" style={{margin: '0 auto', display: 'block',  width:"120%"}}/>
                <br/><br/><br/>

                <h2> <b>Step 6:</b>  The Clean & Geocode Results</h2>
                <br/>
                <p>Here's a sample of how my downloaded file looks. </p>ag
                <img src="https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Images+/RevGeoSplit6.png"style={{margin: '0 auto', display: 'block',  width:"140%"}}/>
                <br/>
                <br/><br/>

                    <br/>
                    <hr/>
                <h1>Wrapping Everything Up</h1>
                <p>There you have it! A quick way to get started for geocoding. If you still have questions, don’t hesitate to reach out to us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a></p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://cleanspreadsheets.com/guides/geocode-quickstart" data-numposts="5" data-width="" />}/>
            </div>

        )
    }
}

export default GeocodeQuickstart