import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import addCommaToNumbers from "../../../../Constants/addCommaToNumbers";
import ProOrBasicTag from "../../../../Dashboard/UpdatedDashboard/Clean/CleanDisplayPreview/ProOrBasicTag";
import CreditConversionButton from "./CreditConversionButton"

class CreditConversionModalBodyIndex extends Component {
    constructor(props) {
        super(props);

        const fromAndToCreditsType = this.initFromCredits()

        this.state = {
            fromCreditsType: fromAndToCreditsType.fromCreditsType,
            toCreditsType: fromAndToCreditsType.toCreditsType,
            creditsToConvert: 1000,
            creditsToConvertDropdownOptions: this.initCreditsToConvertDropdown()
        }
    }

    conversionFinished = () => {
        const fromAndToCreditsType = this.initFromCredits()

        this.setState({
            fromCreditsType: fromAndToCreditsType.fromCreditsType,
            toCreditsType: fromAndToCreditsType.toCreditsType,
            creditsToConvert: 1000,
            creditsToConvertDropdownOptions: this.initCreditsToConvertDropdown()
        })
    }

    initCreditsToConvertDropdown = () => {
        let proOptions = [];
        let basicOptions = [];

        const proCredits = this.props.userCredits.pro;
        const basicCredits = this.props.userCredits.basic;

        if (proCredits >= 1000){
            for (let i=1000; i<=proCredits; i+=1000){
                proOptions.push({
                    key: i / 1000,
                    value: i,
                    text: addCommaToNumbers(i) + " credits"
                })
            }
        }

        if (basicCredits >= 1000){
            for (let i=1000; i<=basicCredits; i+=1000){
                basicOptions.push({
                    key: i / 1000,
                    value: i,
                    text: addCommaToNumbers(i) + " credits"
                })
            }
        }

        return { pro: proOptions, basic: basicOptions }
    }

    initFromCredits = () => {
        const proCredits = this.props.userCredits.pro;

        const fromCreditsType = proCredits < 1000 ? "basic" : "pro";
        const toCreditsType = fromCreditsType === "pro" ? "basic" : "pro";

        return { fromCreditsType: fromCreditsType, toCreditsType: toCreditsType }
    }

    changeFromCreditsType = (e, { value }) => {
        this.setState({ fromCreditsType: value, toCreditsType: value === "pro" ? "basic" : "pro" })
    }

    changeCreditsToConvert = (e, { value }) => {
        this.setState({ creditsToConvert: value })
    }

    getCreditsToConvertDropdown = (creditType) => {
        return <div>
            <h4>Select number of credits to convert</h4>
            <Dropdown fluid selection options={this.state.creditsToConvertDropdownOptions[creditType]} value={this.state.creditsToConvert} placeholder='Select Number of Credits to Convert' onChange={this.changeCreditsToConvert}/>
            <small>Credits can only be converted in increments of 1,000</small>
        </div>
    }

    getCreditsConvertedMsg = (creditType, credits) => {
        let convertedNum;
        let convertedType;

        if (creditType === "pro"){
            convertedNum = addCommaToNumbers(credits * 5)
            convertedType = <ProOrBasicTag pro={false}/>
        } else {
            convertedNum = addCommaToNumbers(credits / 5)
            convertedType = <ProOrBasicTag pro={true}/>
        }

        return <h4>You will have <span className="userCredits bigNumber" style={{color: "green"}}> {convertedNum}</span> {convertedType} credits after conversion</h4>
    }

    render() {
        const proCredits = this.props.userCredits.pro;
        const basicCredits = this.props.userCredits.basic;

        const toFromOptions = [{key: 0, value: "pro", text: "PRO"}, {key: 1, value: "basic", text: "BASIC"}]

        let creditsToConvertDropdown = null;
        let creditsConvertedMsg = null;
        let btnDisabled = false;

        let creditsToConvertDropdownMsg = (creditType) => <h4 style={{color: "red"}}>You need at least 1,000 {creditType.toUpperCase()} credits to convert</h4>;

        if (this.state.fromCreditsType === "pro"){
            if (proCredits >= 1000){
                creditsConvertedMsg = this.getCreditsConvertedMsg("pro", this.state.creditsToConvert)
                creditsToConvertDropdown = this.getCreditsToConvertDropdown("pro")
                btnDisabled = false
            } else {
                creditsConvertedMsg = null
                creditsToConvertDropdown = creditsToConvertDropdownMsg("pro")
                btnDisabled = true
            }
        } else {
            if (basicCredits >= 1000){
                creditsConvertedMsg = this.getCreditsConvertedMsg("basic", this.state.creditsToConvert)
                creditsToConvertDropdown = this.getCreditsToConvertDropdown("basic")
                btnDisabled = false
            } else {
                creditsConvertedMsg = null
                creditsToConvertDropdown = creditsToConvertDropdownMsg("basic")
                btnDisabled = true
            }
        }

        return (
            <div style={{marginTop: "50px"}}>
                <div className="row">
                    <div className="col-sm-6" style={{borderRight: "1px solid lightgrey", paddingTop: "20px", paddingBottom: "20px"}}>
                        <h2>Convert from {' '}
                            <Dropdown
                                inline
                                options={toFromOptions}
                                value={this.state.fromCreditsType}
                                onChange={this.changeFromCreditsType}
                            /> Credits
                        </h2>
                        <br/>
                        {creditsToConvertDropdown}
                    </div>
                    <div className="col-sm-6" style={{paddingTop: "20px", paddingBottom: "20px"}}>
                        <h2>Converting to <b>{this.state.toCreditsType.toUpperCase()}</b> Credits</h2>
                        <br/>
                        {creditsConvertedMsg}
                    </div>
                </div>
                <div style={{marginTop: "50px"}}>
                    <CreditConversionButton btnDisabled={btnDisabled}
                                            conversionFinished={this.conversionFinished}
                                            fromCreditsType={this.state.fromCreditsType}
                                            creditsToConvert={this.state.creditsToConvert} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userCredits: state.mainState.userCredits
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CreditConversionModalBodyIndex)