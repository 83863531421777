import { CreditPricing } from "./CreditPricing";
import currencyInfo from "./currencyInfo";

export const getTotalPriceForCredits = (currency, totalCredits, isPro=false) => {
    let price = null;
    if (totalCredits < CreditPricing[0].values){
        price = CreditPricing[0].price
    }
    else{
        for (let i=0; i<CreditPricing.length - 1; i++){
            if (totalCredits > CreditPricing[i].values && totalCredits <= CreditPricing[i+1].values){
                price = CreditPricing[i+1].price
                break
            }
            else if (totalCredits >= CreditPricing[i].values && totalCredits < CreditPricing[i+1].values){
                price = CreditPricing[i].price
                break
            }
        }
    }


    if (price === null){
        price = CreditPricing[CreditPricing.length - 1].price
    }


    const currencyMultiplier = currencyInfo[currency].multiplier
    const proMultiplier = isPro ? 5 : 1

    const totalPrice = totalCredits * price * currencyMultiplier * proMultiplier

    return Math.round((totalPrice + Number.EPSILON) * 100) / 100
}