import React, {Component} from 'react'
import PricingCard from "./PricingCard";
import CTA from "../../LandingPage/CTA"
import { isMobile, isBrowser } from "react-device-detect"
import { Button } from 'semantic-ui-react'

class MonthlyPricing extends Component {
    render() {
        return (
            <div style={{margin: isBrowser ? "25px 150px 25px 150px" : "25px"}}>
                <div className="row">
                    <div className="col-sm-4">
                        <PricingCard priceIdx={0}/>
                        {isMobile ? <br/> : null}
                    </div>
                    <div className="col-sm-4">
                        <PricingCard priceIdx={1}/>
                        {isMobile ? <br/> : null}
                    </div>
                    <div className="col-sm-4">
                        <PricingCard priceIdx={2}/>
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-sm-4">
                        <PricingCard priceIdx={3}/>
                        {isMobile ? <br/> : null}
                    </div>
                    <div className="col-sm-4">
                        <PricingCard priceIdx={4}/>
                        {isMobile ? <br/> : null}
                    </div>
                    <div className="col-sm-4">
                        <PricingCard priceIdx={5}/>
                    </div>
                </div>
                <div style={{paddingTop: '50px', textAlign: 'center', paddingBottom: '20px', width: '50%', margin: '0 auto'}}>
                    <div className="cardDiv">
                        <h3 className="thinHeading">Enterprise? Custom Requirements?</h3>
                        <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
                            <Button basic color="orange" content="Contact Us" icon="paper plane outline" onClick={(e) => this.openModal(e, "contactUsModal")}/>
                            <Button basic content="Email Us" icon="envelope" href="mailto:info@cleanspreadsheets.com?Subject=Please%20Clean%20My%20Spreadsheets" />
                        </div>
                    </div>
                </div>
                <div style={{border: "1px solid lightgrey", borderRadius: "15px", padding: "30px 0 30px 0", marginTop: "40px"}}>
                    <h2 className="thinHeading">Want to try out Clean Spreadsheets first?</h2>
                    <h4 className="thinHeading">All accounts start with <span className="thinHeading" style={{ color: 'green'}}> 250</span> FREE credits</h4>
                    <br/>
                    <CTA/>
                </div>
            </div>
        )
    }
}


export default MonthlyPricing