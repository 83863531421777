import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'

class FilterCleanType extends Component {
    constructor(props) {
        super(props);

        this.changeCleanType = this.changeCleanType.bind(this);

    }

    changeCleanType(e, { value }){
        this.props.modifyFilters("cleanType", value);
    }

    render() {
        const options = [
            {key:0, value: "name", text: "Name"},
            {key:1, value: "company", text: "Company"},
            {key:2, value: "address", text: "Address"},
            {key:3, value: "date", text: "Date"},
            {key:4, value: "email", text: "Email"},
            {key:5, value: "phone", text: "Phone"},
            {key:6, value: "currency", text: "Currency"},
            {key:7, value: "notes", text: "Notes"}
        ];

        return (
            <Dropdown
                fluid
                selection
                multiple={true}
                options={options}
                value={this.props.cleanType}
                placeholder='Select Clean Types'
                onChange={this.changeCleanType}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FilterCleanType)