import React, {Component} from 'react'
import CleanIcon from "../Illustrations/CleanIcon.svg"
import ReviewIcon from "../Illustrations/ReviewIcon.svg"
import UploadIcon from "../Illustrations/UploadIcon.svg"
import { Icon } from 'semantic-ui-react'
import UploadBody from "./UploadBody";
import ReviewOptions from "./ReviewOptions";
import CleanBody from "./CleanBody";

const cardTypeBody = {
    upload: {preHoverText: "Upload Your Spreadsheet", icon: UploadIcon, postHoverBody: <UploadBody/>, color: "#16b1a6"},
    review: {preHoverText: "Review Your Options", icon: ReviewIcon, postHoverBody: <ReviewOptions/>, color: "#45b3e4"},
    clean: {preHoverText: "Clean Your Data", icon: CleanIcon, postHoverBody: <CleanBody/>, color: "#2d7cc1"},
}

class HowItWorksCard extends Component {
    constructor(props){
        super(props);

        this.state = {
            hover: false,
            height: null
        }

        this.hoverElement = this.hoverElement.bind(this);
        this.leaveElement = this.leaveElement.bind(this);
        this.getRef = this.getRef.bind(this);
    }

    getRef(){
        if (this.props.cardType === "upload"){
            return ((cardTypeUpload) => this.cardTypeUpload = cardTypeUpload)
        } else if (this.props.cardType === "review"){
            return ((cardTypeReview) => this.cardTypeReview = cardTypeReview)
        } else if (this.props.cardType === "clean"){
            return ((cardTypeClean) => this.cardTypeClean = cardTypeClean)
        }
    }

    componentDidMount(){
        if (this.props.cardType === "upload"){
            this.setState({ height: this.cardTypeUpload.clientHeight })
        } else if (this.props.cardType === "review"){
            this.setState({ height: this.cardTypeReview.clientHeight })
        } else if (this.props.cardType === "clean"){
            this.setState({ height: this.cardTypeClean.clientHeight })
        }
    }

    hoverElement(e){
        this.setState({ hover: true })
    }

    leaveElement(e){
        this.setState({ hover: false })
    }

    render() {
        let body;
        const cardTypeInformation = cardTypeBody[this.props.cardType];


        if (this.state.hover){
            body = <div style={{height: (this.state.height + 40), backgroundColor: cardTypeInformation.color, color: "white", padding: "40px 20px 10px 20px", position: "relative"}} onMouseEnter={this.hoverElement} onMouseLeave={this.leaveElement}>
                <h2 className="thinHeading">{cardTypeInformation.preHoverText}</h2>
                {cardTypeInformation.postHoverBody}
                {/*<div className="howItWorksCardHoverCaret" onClick={this.leaveElement}>*/}
                    {/*<Icon name="caret up" />*/}
                {/*</div>*/}
            </div>
        } else {
            let headingWords = cardTypeInformation.preHoverText.split(" ");

            body =
            <div ref={this.getRef()} style={{padding: "40px 20px 10px 20px"}}>
                <img src={cardTypeInformation.icon} style={{width: "20%"}} alt={this.props.cardType + "Logo"}/>
                <h1 style={{marginTop: "40px", marginBottom: "40px", fontSize: "300%"}} className="thinHeading">
                    <span style={{color: cardTypeInformation.color, fontSize: "115%", fontWeight: "bold"}}>{headingWords[0]}</span>
                    <br/>
                    <span>{headingWords[1]}</span>
                    <br/>
                    <span> </span> <span>{headingWords[2]}</span>
                </h1>
                <div className="howItWorksCardCaret" onMouseEnter={this.hoverElement} onMouseLeave={this.leaveElement} onClick={this.hoverElement}>
                    <Icon name="caret down" />
                </div>
            </div>
        }

        return (
            <div className="howItWorksCard"
                 id={this.props.cardType + "HowItWorksCard"}>
                {body}
            </div>
        )
    }
}

export default HowItWorksCard