import React, {Component} from 'react'
import images from "../../../Constants/Images";
import FAQBlock from "../FAQBlock";
import AddressExample from "../../../LandingPage/DetailExamples/AddressExample"

const addressFAQ = [
    {question: 'Can you really clean and Geocode any worldwide address?', answer: 'Yes! We can really clean and Geocode any worldwide address. '},
    {question: 'Really?', answer: "Yes! However, the value should have some components that can reasonably identify it as an address. For example, for a US address, you need either a Zip code or City, State."},
    {question: 'Where do you get the address data?', answer: <span>We get our address data from our friends over at <a href="https://www.opencagedata.com/">Open Cage Data</a>. Check them out if you need a simple Geocoding API</span>},
    {question: 'How does the final output look like?', answer: <div>The final output from cleaning addresses will look like the following <br/> <br/> <AddressExample faq/> </div>},
    {question: "What if my value isn't an address?", answer: "If your value hit an error. Then the Cleaned Value and the components will be completely blank."},
]

class Address extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <FAQBlock
                        title="Address" icon={images.addressIcon}
                        FAQs={addressFAQ}
                        dataType
                    />
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default Address