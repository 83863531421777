import React, {Component} from 'react'
import DisplayBox from "../DisplayBox";
import ReactJson from 'react-json-view'
import APIRespBoxHeader from "./APIRespBoxHeader";
import ProLabel from "./ProLabel";

class NameResponse extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPro: true,
            height: 0
        }
    }

    componentDidMount(){
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        let height = 0;
        const div = document.getElementById("apiNameSectionIntro")

        if (div !== null && div !== undefined){
            height = div.offsetHeight;
        }

        this.setState({ height: height })
    }

    changeProToggle = (isPro) => {
        this.setState({ isPro: isPro })
    }

    render() {
        let resp = [{"formatted":"James Mcavoy","split":{"prefix":"Dr.","suffix":"Jr.","first":"James", "middle": "M","last": "Mcavoy","nicknames": "JM", "otherNames": "Awesome,Possum"},"error": false,"score": 100,"input": "Dr. james M. mcavoy Jr. \"JM\" Awesome Possum"}]

        if (!this.state.isPro){
            delete resp["split"]["prefix"]
            delete resp["split"]["suffix"]
            delete resp["split"]["middle"]
            delete resp["split"]["otherNames"]
            delete resp["split"]["nicknames"]
        }

        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="apiSectionDesc thinHeading">
                        <div id="apiNameSectionIntro">
                            <p>Cleaning a name will format it and split it into its components.
                                You must pass a full name for cleaning and our algorithm will clean it smartly.
                                If the value you try to clean is something way off a name, for e.g., Robot People Mover Inc., you will get odd results but no error.
                            </p>
                            <br/>
                            <p>The split components and format style are listed below and examples can be seen in the sample response. The components will be different based on the <b>Pro</b> & <b>Basic</b> cleaning</p>
                            <br/>
                            {/*<p>*/}
                                {/*<b><u>NOTE</u></b> <b>Pro</b> cleaning will contain everything from <b>Basic</b> cleaning as well*/}
                            {/*</p>*/}
                            {/*<br/>*/}
                        </div>
                        <div className="apiDocSection">
                            <h5>Formatted String</h5>
                            <p>The formatted name is a string <span className="parameterName">first</span> <span className="parameterName">last</span> with a space in the middle.
                            Both the <span className="parameterName">first</span> and <span className="parameterName">last</span> will have their first letter capitalized. <i>E.g., Bill Gates</i></p>
                        </div>
                        <br/>
                        <div className="apiDocSection">
                            <h5>Split Object Components</h5>
                            <p><span className="parameterName">first</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">First Name if it exists. First letter capitalized and any trailing punctuation removed. This makes up the formatted value.</p>
                            <br/>
                            <p><span className="parameterName">last</span> <span className="parameterType">string</span></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Last Name if it exists. First letter capitalized and any trailing punctuation removed. This makes up the formatted value.</p>
                            <br/>
                            <p><span className="parameterName">prefix</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Prefix of the name if it exists. First letter capitalized and retains any punctuation. E.g., <i>Dr. Mrs.</i></p>
                            <br/>
                            <p><span className="parameterName">middle</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Middle Name or Initial if it exists. First letter capitalized and any trailing punctuation removed.</p>
                            <br/>
                            <p><span className="parameterName">suffix</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Suffix if it exists. First letter capitalized and retains any punctuation. E.g., <i>Jr. Sr.</i></p>
                            <br/>
                            <p><span className="parameterName">nicknames</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">Nicknames if they exist. They are found here in a comma delimited string. E.g., <i>JM, Jimmy</i></p>
                            <br/>
                            <p><span className="parameterName">otherNames</span> <span className="parameterType">string</span> <ProLabel/></p>
                            <p className="parameterSecondLine">Default ""</p>
                            <p className="parameterDesc">If any other names are found then they are present here in a comma delimited string.</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{marginTop: this.state.height}}>
                        <DisplayBox
                            header={
                                <APIRespBoxHeader
                                    header='Dr. james M. mcavoy Jr. "JM" Awesome Possum'
                                    isPro={this.state.isPro}
                                    changeProToggle={this.changeProToggle}
                                />}
                            desc={<div>
                                <ReactJson src={resp} name={null} theme="ocean" displayObjectSize={false}/>
                            </div>}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default NameResponse