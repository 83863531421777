import React, {Component} from 'react'
import color from "../../../Constants/colors";

class ProLabel extends Component {
    render() {
        return (
            <span style={{color: "white", backgroundColor: color['pro'], padding: "1px 5px 1px 5px", borderRadius: "4px", fontSize: "10px"}}>PRO</span>
        )
    }
}

export default ProLabel