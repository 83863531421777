import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../../../Actions/ToggleModal";
import AddressOptions from "./DataTypeOptions/Address/AddressOptions"
import OtherOptions from "./DataTypeOptions/Other/OtherOptions"
import CleaningSteps from "../CleaningSteps"
import CurrencyOptions from "./DataTypeOptions/Currency/CurrencyOptions"

class EditFileToolbar extends Component {
    constructor(props) {
        super(props);

        this.openEditModal = this.openEditModal.bind(this);
    }

    openEditModal(e){
        this.props.toggleModal("editModal");
    }

    render() {
        if ('samples' in this.props.fileInformation) {
            return (
                <div id="editFileToolbarDiv">
                    <div id="editButtonDiv">
                        {this.props.uploadDataType === "address" ? <AddressOptions /> : <OtherOptions />}
                        <br/>
                        <CleaningSteps />
                    </div>
                    <div id="fileSummaryDiv">

                    </div>
                    <div id="removeFileButtonDiv">

                    </div>
                </div>
            )
        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    sheetRead: state.mainState.sheetRead,
    uploadDataType: state.mainState.uploadDataType
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(EditFileToolbar)
