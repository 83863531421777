import React, {Component} from 'react'
import FAQBlock from "../FAQBlock";
import images from "../../../Constants/Images";
import NameExample from "../../../LandingPage/DetailExamples/NameExample"

const nameFAQ = [
    {question: 'Can you really automatically clean and split any name?', answer: 'Yes! Any latin character name that follows Western European, North & South American, Middle Eastern and South Asian naming conventions. We are not yet adept at handling East Asian, African or the rest of the world names'},
    {question: 'I am skeptical', answer: "That's okay! That's why we give you 250 free credits to start. You can try out if our algorithm works for your data. If it doesn't please let us know as we are constantly striving to improve!"},
    {question: 'How do you split the name?', answer: "That's the work of our algorithm. It looks for 6 different components: Prefix, Suffix, First, Middle, Last and OtherNames"},
    {question: 'How does the final output look like?', answer: <div>The final output from cleaning a column of names will look like the following <br/> <br/> <NameExample faq/> </div>},
    {question: "What if my value isn't a name?", answer: "If your value hit an error. Then all the Cleaned Value and the components will be completely blank."}
]


class Name extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <FAQBlock
                        title="Name" icon={images.nameIcon}
                        FAQs={nameFAQ}
                        dataType
                    />
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default Name