import React, {Component} from 'react'
import {connect} from 'react-redux'
import isObjectEmpty from "../../../Constants/isObjectEmpty";
import {isMobile} from "react-device-detect";
import addCommaToNumbers from "../../../Constants/addCommaToNumbers";
import Loader from 'react-loader-spinner'
import monthlyPricing from "../../../Constants/monthlyPricing";
import currencyInfo from "../../../Constants/currencyInfo";
import ProOrBasicTag from "../../../Dashboard/UpdatedDashboard/Clean/CleanDisplayPreview/ProOrBasicTag"

class SubscriptionInfo extends Component {
    render() {
        let body;
        if (this.props.fetching){
            body =  <Loader
                type="TailSpin"
                color="black"
                height="50"
                width="50"
            />
        } else {
            const credits = this.props.userCredits.pro;
            const basicCredits = this.props.userCredits.basic;

            body = <h4>
                <div><ProOrBasicTag pro={true}/> You have <span className="userCredits bigNumber" style={{color: credits < 100 ? 'red' : credits < 2500 ? 'orange' : 'green'}}>{addCommaToNumbers(credits)}</span> <b>PRO</b> credits</div>
                <div><ProOrBasicTag pro={false}/> You have <span className="userCredits bigNumber" style={{color: basicCredits < 100 ? 'red' : basicCredits < 2500 ? 'orange' : 'green'}}>{addCommaToNumbers(basicCredits)}</span> <b>BASIC</b> credits</div>
            </h4>

        }

        return (
            <div style={{textAlign: 'center', margin: '10px', backgroundColor: 'white'}} className={isMobile ? "creditsSegment" : "creditsSegmentTop creditsSegment"}>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SubscriptionInfo)