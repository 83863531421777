import React, {Component} from 'react'
import LoginForm from './LoginForm'
import { Segment } from 'semantic-ui-react'

class LoginIndex extends Component {
    render() {
        return (
            <div className="row" style={{backgroundColor: '#7fd3fd', minHeight: '100vh'}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <Segment raised>
                        <LoginForm/>
                    </Segment>
                </div>
                <div className="col-sm-3" />
            </div>
        )
    }
}

export default LoginIndex