import React, {Component} from 'react'
import { Menu, Image } from 'semantic-ui-react'
import images from '../../Constants/Images'
import NavbarItem from './NavbarItem'
import NavbarLogIn from './NavbarLogIn'
import NavbarSignUp from './NavbarSignUp'
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {BrowserView, isBrowser } from 'react-device-detect'

class NavbarNonAuth extends Component {
    constructor(props){
        super(props);

        this.state = {
            urlEnd: '',
            mobileView: false,
            navbarBoxShadow: null
        };

        this.goToURL = this.goToURL.bind(this);
        this.changeURLEnd = this.changeURLEnd.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        if (window.scrollY === 0){
            this.setState({ navbarBoxShadow: null })
        } else {
            if (this.state.navbarBoxShadow === null){
                this.setState({ navbarBoxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"})
            }
        }

    }

    changeURLEnd(changedURLEnd){
        this.setState({
            urlEnd: changedURLEnd
        })
    }

    goToURL(e){
        e.preventDefault();
        this.setState({
            urlEnd: ''
        })
        this.props.navigateTo("/")
    }

    toggle(e, state){
        this.setState({
            mobileView: state
        })
    }

    render() {
        return (
            <div style={{marginBottom: "65px"}}>
                <Menu secondary stackable fixed={isBrowser ? "top" : null} style={ isBrowser ? {backgroundColor: this.state.urlEnd === "" ? "white" : "none", boxShadow: this.state.navbarBoxShadow, border: "1px solid white"} : null}>
                    <Menu.Menu position="left">
                        <BrowserView>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <Menu.Item>
                                    <Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="small"/>
                                </Menu.Item>
                            </div>
                        </BrowserView>
                        {/*<Menu.Item>*/}
                            {/*<NavbarItem to="upload"*/}
                                        {/*name="Upload Files"*/}
                                        {/*color="black"*/}
                                        {/*urlEnd={this.state.urlEnd}*/}
                                        {/*changeURLEnd={this.changeURLEnd}/>*/}
                        {/*</Menu.Item>*/}
                    </Menu.Menu>
                    <Menu.Menu position="right" stackable>
                        <Menu.Item>
                            <NavbarItem to="demo" name="Demo" color="black" urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd} />
                            {/*<a href="https://www.youtube.com/watch?v=LClqBdaxPcU&feature=youtu.be" style={{color: "black", cursor: 'pointer', textDecoration: 'none', fontSize: '115%', marginTop: '6px'}} target="_blank" rel="noopener noreferrer">70 Second Demo</a>*/}
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="pricing" name="Pricing" color="black" urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd} />
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="api-docs" name="API" color="black" urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd} />
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="guides"
                                        name="Guides"
                                        color="black"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="FAQs"
                                        name="FAQs"
                                        color="black"
                                        urlEnd={this.state.urlEnd}
                                        changeURLEnd={this.changeURLEnd}/>
                        </Menu.Item>
                        <Menu.Item>
                            {isBrowser ? <NavbarItem to="contactUsModal" name="Contact Us" color="gray" modal auth/> : <NavbarItem to="contact" name="Contact Us" color="gray" urlEnd={this.state.urlEnd} changeURLEnd={this.changeURLEnd}/>}
                        </Menu.Item>
                        {/*<Menu.Item>*/}
                            {/*<NavbarItem to="feedbackModal" name="Feedback" color="gray" modal auth/>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item>
                            <NavbarLogIn/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarSignUp />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarNonAuth)