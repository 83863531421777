import React, {Component} from 'react'
import {connect} from 'react-redux'
import AccountInfo from "./AccountInfo"
import {toggleModal} from "../../../../Actions/ToggleModal";

class MonthlyAccountInfo extends Component {
    render() {
        const planIdx = this.props.planIdx;
        const planType = this.props.planType;

        return (
            <div className="monthlyAccountInfoBox">
                <div className="monthlyAccountInfoHeader">
                    <h4 style={{textAlign: "center"}}>You are on the <span style={{fontWeight: "bold", color: "green"}}>{planType.toUpperCase()}</span> monthly plan</h4>
                </div>
                <AccountInfo planIdx={planIdx} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    subscriptionInfo: state.mainState.subscriptionInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(MonthlyAccountInfo)