import React, {Component} from 'react'
import { connect } from 'react-redux'
import {shouldFetchInfo} from "../Constants/shouldFetchInfo";
import API_Root from "../Constants/API_Root";
import {modifySubscriptionInfo} from "../Actions/ModifySubscriptionInfo";
import {modifyUserCredits} from "../Actions/ModifyUserCredits";
import {modifyFetchedTime} from "../Actions/ModifyFetchedTime";
import {modifyPaymentInfo} from "../Actions/ModifyPaymentInfo";
import {modifyAPIKeys} from "../Actions/ModifyAPIKeys";
import Loader from 'react-loader-spinner'
import APIBody from "./APIBody"
import {modifyIsUserNew} from "../Actions/ModifyIsUserNew";

class APIIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            fetching: false,

        }

    }

    componentDidMount(){
        const stopFetch = () => this.setState({ fetching : false })
        if (shouldFetchInfo(this.props.profileInformationFetchedTime, 5)) {
            this.setState({fetching: true})
            fetch(API_Root + "api/get-clean-spreadsheets-profile-info/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email")
                })
            }).then(res => {
                if (res.status === 200) {
                    res.json().then(
                        (data) => {
                            this.props.modifyIsUserNew(true)
                            this.props.modifyAPIKeys(data.apiKeys)
                            this.props.modifySubscriptionInfo(data.subscriptionInfo)
                            this.props.modifyUserCredits(data.totalCredits)
                            this.props.modifyFetchedTime("profile")
                            this.props.modifyPaymentInfo(data.paymentInfo)
                            stopFetch()
                        }).catch((err) => {
                        stopFetch();
                    })
                }
            }).catch((err) => {
                stopFetch()
            })
        }
    }

    render() {
        let body = null;

        if (this.state.fetching){
            body = <Loader
                type="TailSpin"
                color="black"
                height="50"
                width="50"
            />
        } else {
            body = <APIBody />
        }

        return (
            <div style={{minHeight: "100vh"}}>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userCredits: state.mainState.userCredits,
    profileInformationFetchedTime: state.mainState.profileInformationFetchedTime,
    subscriptionInfo: state.mainState.subscriptionInfo,
    apiKeys: state.mainState.apiKeys
})

const mapActionsToProps = {
    modifyAPIKeys: modifyAPIKeys,
    modifyUserCredits: modifyUserCredits,
    modifyFetchedTime: modifyFetchedTime,
    modifyPaymentInfo: modifyPaymentInfo,
    modifySubscriptionInfo: modifySubscriptionInfo,
    modifyIsUserNew: modifyIsUserNew
}

export default connect(mapStateToProps, mapActionsToProps)(APIIndex)