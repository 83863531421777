import React, {Component} from 'react'
import APIIndex from "./APIIndex"
import withAuthorization from "../Authentication/withAuthorization";

class AuthAPIIndex extends Component {
    render() {
        return (
            <APIIndex/>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthAPIIndex);