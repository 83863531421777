import React, {Component} from 'react'
import API_Root from "../../../../Constants/API_Root";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

class JavascriptCodeSnippet extends Component {
    // componentDidMount(){
    //     fetch(API_Root, {
    //         mode: 'POST',
    //         data: ["APO"]
    //     }).then((res) => {
    //         if (res.status)
    //     })
    // }

    render() {
        const apiKey = this.props.apiKey === null ? "YOUR_API_KEY" : this.props.apiKey

        const data = {
            name: '"Dr. Martin Luther King Jr.", "Stephen A. Smith", "Washington, George"',
            address: '"32 London Bridge St, London, United Kingdom","413 w 52nd Street, New York, 10019", "624 Brush St, Detroit, MI 48226"',
            date: '"Jan 23, 1981", "11/15/12 2:30 PM", "2 February, 2017 15:45:43"',
            company: '"Glaxosmithkline Pharma gmbh", "apple inc", "Infosys Ltd."',
            email: '"joecool@gmail.com", "khaleesi@got.org", "monkey bananas", "first.last@somecompany.org"'
        }

        const dataSample = data[this.props.cleanType];

        const code = 'fetch("' +
            API_Root +
            'clean/' +
            this.props.cleanType +
            '/", {\n\tmethod: "POST",\n\tbody: JSON.stringify({\n\t\t"apiKey": "' +
            apiKey + '", \n\t\t"data": [' +
            dataSample + ']\n\t})\n }).then((res) => {\n\t\tif (res.status === 200){\n\t\t\tres.json().then((data) => {\n\t\t\t' +
            '\t// YOUR CLEAN DATA\n\t\t\t\t' +
            'const cleanData = data.cleanData\n\t\t\t\t' +
            'const formattedData = cleanData.map(x => x.formatted)\n\t\t\t' +
            '})\n\t\t}\n' +
            '\t\telse {\n' +
            '\t\t\t// HANDLE ERROR HERE\n' +
            '\t\t}\n' +
            '\t}).catch(err => console.log(err))'

        return (
            <div>
                <AceEditor
                    mode="javascript"
                    theme="monokai"
                    name="javascriptQuickstartGuide"
                    value={code}
                    editorProps={{ $blockScrolling: true }}
                    width='100%'
                    height='350px'
                />
            </div>
        )
    }
}

export default JavascriptCodeSnippet