import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyAlddsf_mwNeSHARrE7HkGd7DbVvm5_4d0",
    authDomain: "clean-spreadsheets.firebaseapp.com",
    databaseURL: "https://clean-spreadsheets.firebaseio.com",
    projectId: "clean-spreadsheets",
    storageBucket: "clean-spreadsheets.appspot.com",
    messagingSenderId: "461146653442",
    appId: "1:461146653442:web:bfbe8f18cb34b952"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
    auth,
};
