const images = {
    logoNav: 'https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Logo.png',
    logoImage: 'https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/LogoIcon.png',
    logoImageSmall: 'https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/LogoIconSmall.png',
    nameIcon: "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Name.png",
    dateIcon: "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Date.png",
    companyIcon: "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Company.png",
    addressIcon: "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Address.png",
    emailIcon : "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Email.png",
    currencyIcon : "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Currency.png",
    phoneIcon : "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Phone.png",
    sentimentIcon : "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Email.png",
    keywordIcon : "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Notes.png",
    notesIcon: "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Notes.png",
    uploadFileIcon: "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Upload.png",
    customIcon: "https://project-static-assets.s3.amazonaws.com/CleanSpreadsheets/Icons/Sketched+Icons+/Customize.png",
    loveLogo: 'https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/Logo.png',
    pythonLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/PythonLogo.png',
    rLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/R_Logo.png',
    javascriptLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/JavaScriptLogo.png',
    phpLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/PHPLogo.png',
    cURLLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/cURLLogo.png',
    rubyLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/RubyLogo.png',
};

export default images